import axios from 'axios'
import { userUrl, getHeader, roleUrl } from './../config'

const state = {
  positions: [],
  authUser: {
    id: null,
    username: null,
    email: null,
    company_no: null,
    profile_id: null,
    name: null,
    shortname: null,
    greetings: null,
    profile: null,
    profile_thumbnail: null,
    profile_medium: null,
    position_id: null,
    now: null,
    permissions: {
      setting: true,
      memo_save: true,
      memo_delete: true,
      memo_report: true,
      memo_inbox: true,
      memo_path_save: true,
      memo_path_delete: true,
      set_company: true,
      employee_save: true,
      employee_delete: true
    }
  },
  userAll: [],
  todos: [
    { id: 1, no: 1, name: 'Tirta Mulya', email: 'tmulya@gmail.com' },
    { id: 2, no: 2, name: 'Pramanda', email: 'pramandatirta@gmail.com' }
  ],
  roles: []
}

const mutations = {
  SET_POSITION_USER (state, obj) {
    state.positions = obj
  },
  SET_AUTH_USER (state, userObj) {
    state.authUser = userObj
  },
  SET_USER_ALL (state, obj) {
    state.userAll = obj
  },
  SET_ROLE_LIST (state, obj) {
    state.roles = obj
  }
}

const actions = {
  setUserObject: ({ commit }, payload) => {
    commit('SET_AUTH_USER', payload)
  },
  getUserAll: ({ commit }) => {
    axios.get(userUrl, { headers: getHeader() })
      .then(response => {
        console.log(response.data.data)
        commit('SET_USER_ALL', response.data.data)
      })
  },
  getRoleList: ({ commit }) => {
    axios.get(roleUrl, { headers: getHeader() })
      .then(response => {
        commit('SET_ROLE_LIST', response.data.data)
      })
  }
}

export default {
  state, mutations, actions
}
