<template>
    <div>
        <h5>Company Form</h5>
        <div class="row">
            <div class="col-md-12">
                <div class="top-list-toolbar">
                    <h4 class="text-blue"><span @click="backParent" style="cursor: pointer;color: blue;">Company</span> /
                        <span v-if="company.id === null">New</span>
                        <span v-else>Edit</span>
                    </h4>
                    <div>
                        <button type="button" @click="backParent" class="btn btn-sm btn-danger pull-left"><i class="fa fa-reply" aria-hidden="true"></i> Back</button>
                        <button type="button" @click="save" class="btn btn-sm btn-success pull-left"><i class="fa fa-check" aria-hidden="true"></i> Save</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="card card-list">
            <div class="card-header">
                <h5 v-if="company.id === null">New</h5>
                <h5 v-else>{{ company.company_no }}</h5>
            </div>
            <div class="card-body">
                <div class="row">
                  <div class="col-md-6">
                      <div class="form-group row">
                          <label for="name" class="col-sm-3 col-form-label text-right">Name</label>
                          <div class="col-sm-9">
                              <input type="text" v-model="company.name" class="form-control">
                          </div>
                      </div>
                      <div class="form-group row">
                          <label for="alias" class="col-sm-3 col-form-label text-right">Alias</label>
                          <div class="col-sm-9">
                              <input type="text" v-model="company.alias" class="form-control">
                          </div>
                      </div>
                      <div class="form-group row">
                          <label for="email" class="col-sm-3 col-form-label text-right">Email</label>
                          <div class="col-sm-9">
                              <input type="text" v-model="company.email" class="form-control">
                          </div>
                      </div>
                      <div class="form-group row">
                          <label for="phone" class="col-sm-3 col-form-label text-right">Phone</label>
                          <div class="col-sm-9">
                              <input type="text" v-model="company.phone" class="form-control">
                          </div>
                      </div>
                      <div class="form-group row">
                          <label for="npwp" class="col-sm-3 col-form-label text-right">VAT</label>
                          <div class="col-sm-9">
                              <input type="text" v-model="company.npwp" class="form-control"/>
                          </div>
                      </div>
                      <div class="form-group row">
                          <label for="address" class="col-sm-3 col-form-label text-right">Address</label>
                          <div class="col-sm-9">
                              <textarea v-model="company.address" class="form-control" rows="5"/>
                          </div>
                      </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group row">
                        <label for="username" class="col-sm-3 col-form-label text-left">&nbsp;</label>
                        <div class="col-sm-9">
                          <div id="profile-border" @click="chooseFiles">
                            <img :src="company.logo" id="profile">
                            <input type="file" id="file" ref="file" @change="handleFileUpload()" hidden/>
                          </div>
                        </div>
                      </div>
                  </div>
                </div>
                <hr>
                <div class="row mt-5">
                  <div class="col-md-12">
                    <h5 >Rekening <button class="btn btn-sm btn-primary"><i class="fa fa-plus"></i></button></h5>
                  </div>
                  <div class="col-md-6 mt-5" v-for="(acc, index) in company.accounts" :key="acc.id">
                    <div class="form-group row">
                      <label for="name" class="col-sm-3 col-form-label text-right align-label">
                        Alias
                      </label>
                      <div class="col-sm-9">
                        <input type="text" v-model="acc.alias" class="form-control">
                      </div>
                    </div>
                    <div class="form-group row">
                      <label for="type" class="col-sm-3 col-form-label text-right align-label">Type</label>
                      <div class="col-sm-9">
                        <select v-model="acc.type" class="form-control">
                          <option value="rekening">Rekening</option>
                          <option value="billing">Billing</option>
                        </select>
                      </div>
                    </div>
                    <span v-if="acc.type === 'rekening'">
                      <div class="form-group row">
                        <label for="bank_id" class="col-sm-3 col-form-label text-right align-label">Bank</label>
                        <div class="col-sm-9">
                          <select v-model="acc.bank_id" class="form-control">
                            <option v-for="b in bankList" :key="b.id" :value="b.id">{{ b.name }}</option>
                          </select>
                        </div>
                      </div>
                      <div class="form-group row">
                        <label for="bank_account_name" class="col-sm-3 col-form-label text-right align-label">Bank Account Name</label>
                        <div class="col-sm-9">
                          <input type="text" v-model="acc.bank_account_name" class="form-control">
                        </div>
                      </div>
                      <div class="form-group row">
                        <label for="bank_account" class="col-sm-3 col-form-label text-right align-label">Bank Account No. <span style="color: red;">*</span></label>
                        <div class="col-sm-9">
                            <input type="text" v-model="acc.bank_account" class="form-control">
                        </div>
                      </div>
                      <div class="form-group row">
                        <label for="bank_address" class="col-sm-3 col-form-label text-right align-label">Bank Address</label>
                        <div class="col-sm-9">
                            <input type="text" v-model="acc.bank_address" class="form-control">
                        </div>
                      </div>
                      <div class="form-group row">
                      <label for="bank_account" class="col-sm-3 col-form-label text-right align-label">&nbsp;</label>
                      <div class="col-sm-9">
                        <button type="button" class="btn btn-outline-danger btn-sm" @click="removeAccount(index)"><i class="fa fa-trash"></i> Delete</button>
                      </div>
                      </div>
                    </span>
                    <span v-else-if="acc.type === 'billing'">
                      <div class="form-group row">
                        <label for="billing_no" class="col-sm-3 col-form-label text-right align-label">Billing No.</label>
                        <div class="col-sm-9">
                            <input type="text" v-model="acc.billing_no" class="form-control">
                        </div>
                      </div>
                      <div class="form-group row">
                        <label for="bank_account" class="col-sm-3 col-form-label text-right align-label">&nbsp;</label>
                        <div class="col-sm-9">
                          <button type="button" class="btn btn-outline-danger btn-sm" @click="removeAccount(index)"><i class="fa fa-trash"></i> Delete</button>
                        </div>
                      </div>
                    </span>
                  </div>
                </div>
            </div>
        </div>
        <!-- <pre>{{ company }}</pre> -->
    </div>
</template>

<script>
import axios from 'axios'
import { getHeader, companyUrl } from '../../config'
import { mapState } from 'vuex'
export default {
  name: 'CompanyForm',
  props: ['company'],
  computed: {
    ...mapState({
      bankList: state => state.banks.bankList
    })
  },
  methods: {
    removeAccount (index) {
      alert(index)
      const account = this.company.accounts[index]
      if (account.id) {
        var obj = {
          id: account.id
        }
        axios.post(companyUrl + '/account/', obj, { headers: getHeader() })
          .then(response => {
            this.company.accounts.splice(index, 1)
            this.$toasted.show(response.data.message, {
              theme: 'toasted-primary',
              position: 'bottom-left',
              duration: 5000,
              icon: 'check',
              type: 'success'
            })
          })
          .catch(error => {
            console.log(error.response)
            this.$toasted.show(error.response.data.message, {
              theme: 'toasted-primary',
              position: 'bottom-left',
              duration: 5000,
              icon: 'check',
              type: 'error'
            })
          })
      } else {
        this.company.accounts.splice(index, 1)
        this.$toasted.show('Delete Rekening', {
          theme: 'toasted-primary',
          position: 'bottom-left',
          duration: 5000,
          icon: 'check',
          type: 'success'
        })
      }
    },
    chooseFiles () {
      document.getElementById('file').click()
    },
    async handleFileUpload () {
      this.file = this.$refs.file.files[0]
      const formData = new FormData()
      formData.append('file', this.file)
      formData.append('company_no', this.company.company_no)
      const newHeader = {
        'Content-Type': 'multipart/form-data'
      }
      const newObj = Object.assign(getHeader(), newHeader)
      console.log(companyUrl + '/logo/')
      await axios.post(companyUrl + '/logo/', formData, { headers: newObj })
        .then(response => {
          console.log(response.data.data)
          this.company.logo = response.data.data.url
        })
        .catch(error => {
          console.log(error.response)
        })
    },
    backParent: function () {
      this.$emit('backClicked')
    },
    async save () {
      var id = this.company.id
      if (id === null) {
        console.log(companyUrl)
        axios.post(companyUrl, this.company, { headers: getHeader() })
          .then(response => {
            console.log(response)
            this.$swal(
              'Success',
              response.data.message,
              'success'
            )
            this.backParent()
          })
          .catch(error => {
            console.log(error.response)
            this.$swal(
              'Error',
              error.response.data.message,
              'error'
            )
          })
      } else {
        var url = companyUrl + '/' + id
        axios.put(url, this.company, { headers: getHeader() })
          .then(response => {
            console.log(response)
            this.$swal(
              'Success',
              response.data.message,
              'success'
            )
            this.backParent()
          })
          .catch(error => {
            console.log(error.response)
            this.$swal(
              'Error',
              error.response.data.message,
              'error'
            )
          })
      }
    }
  }
}
</script>

<style>
  #profile-border {
    position: relative;
    border: 2px dashed #000;
    width: 200px;
    height: 200px;
    background-color: #fff;
    padding: 10px;
  }
</style>
