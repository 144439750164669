<template>
  <div>
    <div class="page-title-box">
      <div class="float-right">
        <div class="form-inline ml-auto">
          <span>Company</span>
          <select v-model="company" class="form-control">
            <option v-for="c in companyList" :key="c.id" :value="c.company_no">{{ c.name }}</option>
          </select>
          <span>Date range:</span>
          <date-picker v-model="startDate" type="date" range placeholder="Select date range"></date-picker>
          <button type="button" class="btn btn-sm btn-primary" @click="searchDashboard">
            <i class="fa fa-search" aria-hidden="true"></i>
          </button>
        </div>
      </div>
      <h4 class="page-title">Report</h4>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <v-chart class="chart" :option="option2" />
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <v-chart class="chart2" :option="option"/>
          </div>
        </div>
      </div>
    </div>
</div>
</template>

<script>
import { use } from 'echarts/core'
import { CanvasRenderer } from 'echarts/renderers'
import { PieChart, BarChart } from 'echarts/charts'
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent
} from 'echarts/components'
import VChart, { THEME_KEY } from 'vue-echarts'
import Axios from 'axios'
import { getHeader, reportUrl } from '../config'
import { mapState } from 'vuex'
import DatePicker from 'vue2-datepicker'

use([
  CanvasRenderer,
  PieChart,
  BarChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent
])

export default {
  name: 'Report',
  components: {
    VChart,
    DatePicker
  },
  provide: {
    [THEME_KEY]: 'light'
  },
  data () {
    var date = new Date()
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0)
    const date1 = new Date(firstDay.getFullYear(), firstDay.getMonth(), firstDay.getDate())
    const date2 = new Date(lastDay.getFullYear(), lastDay.getMonth(), lastDay.getDate())
    return {
      startDate: [date1, date2],
      company: null,
      option2: {},
      option: {
        title: {
          text: 'Traffic Sources',
          left: 'center'
        },
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)'
        },
        legend: {
          orient: 'vertical',
          left: 'right',
          data: [
            'Direct',
            'Email',
            'Ad Networks',
            'Video Ads',
            'Search Engines'
          ]
        },
        series: [
          {
            name: 'Traffic Sources',
            type: 'pie',
            radius: '55%',
            center: ['50%', '60%'],
            data: [
              { value: 335, name: 'Direct' },
              { value: 310, name: 'Email' },
              { value: 234, name: 'Ad Networks' },
              { value: 135, name: 'Video Ads' },
              { value: 1548, name: 'Search Engines' }
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      }
    }
  },
  computed: {
    ...mapState({
      companyList: state => state.companies.companyList,
      authUser: state => state.users.authUser
    })
  },
  methods: {
    async searchDashboard () {
      await this.getChartMemoMonthly()
      await this.getChartMemoCategory()
    },
    async getChartMemoMonthly () {
      var obj = {
        company: this.company
      }
      await Axios.post(reportUrl + '/memo-total', obj, { headers: getHeader() })
        .then(response => {
          this.option2 = {
            title: {
              text: 'Total Expense (Memo)',
              left: 'center'
            },
            tooltip: {
              trigger: 'axis'
            },
            xAxis: {
              type: 'category',
              data: response.data.label
            },
            yAxis: {
              type: 'value'
            },
            series: [{
              data: response.data.data,
              type: 'bar',
              showBackground: true,
              backgroundStyle: {
                color: 'rgba(180, 180, 180, 0.2)'
              }
            }]
          }
        })
        .catch(error => {
          console.log(error.response)
        })
    },
    async getChartMemoCategory () {
      const begin = this.startDate[0] ? this.startDate[0].getFullYear() + '-' + (this.startDate[0].getMonth() + 1) + '-' + this.startDate[0].getDate() : null
      const end = this.startDate[1] ? this.startDate[1].getFullYear() + '-' + (this.startDate[1].getMonth() + 1) + '-' + this.startDate[1].getDate() : null
      var obj = {
        company: this.company,
        begin: begin,
        end: end,
        status: 'match'
      }
      await Axios.post(reportUrl + '/memo-by-category', obj, { headers: getHeader() })
        .then(response => {
          console.log(response.data.data)
          this.option = {
            title: {
              text: 'Memo By Category',
              left: 'center'
            },
            tooltip: {
              trigger: 'item',
              formatter: '{a} <br/>{b} : {c} ({d}%)'
            },
            legend: {
              type: 'scroll',
              orient: 'vertical',
              left: 10,
              data: response.data.label
            },
            series: [
              {
                name: 'Total Memo',
                type: 'pie',
                radius: '55%',
                center: ['50%', '60%'],
                data: response.data.data2,
                emphasis: {
                  itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                  }
                }
              }
            ]
          }
        })
        .catch(error => {
          console.log(error.response)
        })
    }
  },
  mounted () {
    this.$store.dispatch('getCompanyList')
    const tokenData = JSON.parse(window.localStorage.getItem('authUser'))
    if (tokenData) {
      this.company = tokenData.user.company_no
    }
    this.searchDashboard()
  }
}
</script>

<style scoped>
.chart {
  height: 300px;
}
.chart2 {
  height: 400px;
}
</style>
