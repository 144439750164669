<template>
    <div>
        <div class="modal hide fade" id="modalUserSetting" tabindex="-1" role="dialog" aria-labelledby="modalUserSetting" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered" role="document">
              <div class="modal-content">
                  <div class="modal-header red darken-3 text-left text-white">
                      <h4 class="modal-title w-100 font-weight-bold">User</h4>
                      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                      </button>
                  </div>
                    <div class="modal-body">
                        <form>
                            <div class="form-group">
                                <label for="username" class="col-form-label">Username:</label>
                                <input type="text" v-model="user.username" class="form-control"/>
                            </div>
                            <div class="form-group">
                                <label for="name" class="col-form-label">Name:</label>
                                <input type="text" v-model="user.name" class="form-control"/>
                            </div>
                            <div class="form-group">
                                <label for="email" class="col-form-label">Email:</label>
                                <input type="text" v-model="user.email" class="form-control"/>
                            </div>
                            <div class="form-group">
                                <label for="password" class="col-form-label">Password:</label>
                                <input type="text" v-model="user.password" class="form-control"/>
                            </div>
                            <div class="form-group">
                                <label for="company" class="col-form-label">Company:</label>
                                <select v-model="user.company_no" class="form-control">
                                    <option v-for="c in companyList" :key="c.id" :value="c.company_no">{{ c.name }}</option>
                                </select>
                            </div>
                            <div class="form-group">
                                <label for="id" class="col-form-label">Role:</label>
                                <v-select :options="roleSelect" :reduce="role => role.meta.code" label="role" class="vselect-custom" v-model="user.role"  :multiple="true"/>
                            </div>
                        </form>
                        <!-- <pre>{{ user }}</pre> -->
                  </div>
                  <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                  <button type="button" class="btn btn-primary" data-dismiss="modal" @click="process">Save</button>
                  </div>
              </div>
          </div>
      </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'SettingUserModal',
  props: ['user'],
  methods: {
    process: function () {
      this.$emit('saveUser', this.user)
    }
  },
  computed: {
    ...mapState({
      companyList: state => state.companies.companyList,
      roles: state => state.users.roles
    }),
    roleSelect: function () {
      var loop = []
      var data = this.roles
      for (let z = 0; z < data.length; z++) {
        var obj = {
          role: data[z].name,
          meta: {
            code: data[z].id
          }
        }
        loop.push(obj)
      }
      return loop
    }
  }
}
</script>
