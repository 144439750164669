<template>
  <div class="home">
    <EmployeeList v-show="listView" @newEmployee="onClickNewFromList" @editEmployee="onClickEditFromList" ref="refreshList"/>
    <EmployeeForm v-show="formView" :employee="employee" @backClicked="onBackChild" @backParentID="backParentID"/>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios'
import EmployeeList from '@/components/employee/EmployeeList.vue'
import EmployeeForm from '@/components/employee/EmployeeForm.vue'
import { getHeader, employeeUrl } from '../config'
import { mapState } from 'vuex'

export default {
  name: 'Employee',
  components: {
    EmployeeList,
    EmployeeForm
  },
  data () {
    return {
      listView: true,
      formView: false,
      employee: {
        id: null,
        employee_no: null,
        name: null,
        company_no: null,
        company: [],
        position_id: null,
        position: [],
        department_id: null,
        email: null,
        phone: null,
        address: null,
        npwp: null,
        ktp: null,
        kk: null,
        absen_id: null,
        status: null,
        job_start: null,
        job_end: null,
        bank_account_no: null,
        bank_account_name: null,
        bank_id: null,
        mother_name: null,
        grade_id: null,
        picture: null,
        birthday: null,
        birtplace: null,
        profiles: null
      }
    }
  },
  computed: {
    ...mapState({
      authUser: state => state.users.authUser
    })
  },
  methods: {
    backParentID: function (id) {
      axios.get(employeeUrl + '/' + id, { headers: getHeader() })
        .then(response => {
          this.listView = false
          this.formView = true
          this.employee = response.data.data
        })
        .catch(error => {
          console.log(error.response)
          this.$swal(
            'Error',
            error.response.data.message,
            'error'
          )
        })
    },
    onClickEditFromList: function (value) {
      this.listView = false
      this.formView = true
      this.employee = value
    },
    getEdit: function (value) {
      console.log(employeeUrl + '/' + value)
      axios.get(employeeUrl + '/' + value, { headers: getHeader() })
        .then(response => {
          console.log(response.data.data)
          this.listView = false
          this.formView = true
          this.employee = response.data.data
        })
        .catch(error => {
          console.log(error.response)
        })
    },
    onClickNewFromList: function () {
      this.listView = false
      this.formView = true
      this.employee = {
        id: null,
        employee_no: null,
        name: null,
        company_no: this.authUser.company_no,
        company: [{
          id: null,
          company_no: null
        }],
        position_id: null,
        department_id: null,
        email: null,
        phone: null,
        address: null,
        npwp: null,
        ktp: null,
        kk: null,
        absen_id: null,
        status: null,
        job_start: null,
        job_end: null,
        bank_account_no: null,
        bank_account_name: null,
        bank_id: null,
        mother_name: null,
        grade_id: null,
        picture: null,
        birthday: null,
        birtplace: null,
        profiles: null
      }
    },
    onBackChild: function () {
      this.listView = true
      this.formView = false
      this.employee = {
        id: null,
        employee_no: null,
        name: null,
        company_no: null,
        company: [{
          id: null,
          company_no: null
        }],
        position_id: null,
        department_id: null,
        email: null,
        phone: null,
        address: null,
        npwp: null,
        ktp: null,
        kk: null,
        absen_id: null,
        status: null,
        job_start: null,
        job_end: null,
        bank_account_no: null,
        bank_account_name: null,
        bank_id: null,
        mother_name: null,
        grade_id: null,
        picture: null,
        birthday: null,
        birtplace: null,
        profiles: null
      }
      this.$refs.refreshList.refreshList()
      // this.$router.replace({ path: 'sales', query: null })
    }
  },
  mounted () {
    this.$store.dispatch('getCompanyList')
    this.$store.dispatch('getEmployeeGrade')
    this.$store.dispatch('getEmployeePosition')
    this.$store.dispatch('getEmployeeDepartment')
    this.$store.dispatch('getBankList')
    this.$store.dispatch('getRowTable')
  }
}
</script>
