<template>
    <div class="row">
      <loading
        :active.sync="loader.isLoading"
        :can-cancel="loader.canCancel"
        :is-full-page="loader.fullPage"
        :color="loader.color"
        :height="loader.height"
        :width="loader.width"
        :background-color="loader.backgroundColor"
        :loader="loader.loader">
      </loading>
    <div class="col-md-12">
        <div class="table-responsive">
            <div class="form-inline ml-auto">
                <div class="button-group">
                    <button type="button" class="btn btn-sm  btn-soft-primary waves-light waves-effect" @click="refreshList" data-placement="bottom" title="Refresh">
                    <i class="fa fa-refresh" aria-hidden="true"></i>
                    </button>
                    rows:
                    <select id="select-rows" v-model="pageSize" @change="setPageSizeOnChange">
                    <option v-for="r in rowsTable" :key="r.row" :value="r.row">{{ r.row }}</option>
                    </select>
                    <span class="mr-1">
                    Total rows: <b>{{ filterData.length }}</b>
                    </span>
                    <date-picker v-model="startDate" type="date" range placeholder="Select date range" @change="refreshList"></date-picker>
                </div>
                <div class="form-group mx-sm-3 mb-2">
                    <input type="search" placeholder="search..." class="form-control mr-1 input-search" v-model="searchQuery">
                </div>
                <span class="mr-1">{{ currentPage }} / {{ pageLength }}</span>
                <nav aria-label="Page navigation example" class="pull-right">
                    <ul class="pagination">
                        <li class="page-item">
                            <a class="btn btn-default btn-sm" href="#" @click="prevPage">
                                <i class="fa fa-angle-left" aria-hidden="true"></i>
                            </a>
                        </li>
                        <li class="page-item">
                            <a class="btn btn-default btn-sm" href="#" @click="nextPage">
                                <i class="fa fa-angle-right" aria-hidden="true"></i>
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
            <table class="table table-item mb-0 table-sm table-striped table-hover">
                <thead>
                    <tr>
                        <th>
                            <label class="form-checkbox">
                            <input type="checkbox" v-model="selectAll" @click="select">
                            <i class="form-icon"></i>
                            </label>
                        </th>
                        <th  v-for="t in title" v-bind:key="t.prop" @click="sortTable(t.prop)">
                            <span v-if="t.name === 'Payment'" class="text-right">
                            {{ t.name }}
                            <div class="float-right fa" v-if="t.prop == sortColumn" v-bind:class="ascending ? 'fa-arrow-up' : 'fa-arrow-down'"></div>
                            </span>

                            <span v-else>
                                {{ t.name }}
                                <div class="float-right fa" v-if="t.prop == sortColumn" v-bind:class="ascending ? 'fa-arrow-up' : 'fa-arrow-down'"></div>
                            </span>
                        </th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-if="filterData.length < 1">
                      <td colspan="11" style="text-align: center;"><i>No Data</i></td>
                    </tr>
                    <tr v-for="u in filterData" v-bind:key="u.id" style="cursor: pointer">
                    <td>
                        <label class="form-checkbox">
                        <input type="checkbox" :value="u.id" v-model="selected">
                        <i class="form-icon"></i>
                        </label>
                    </td>
                    <td @click="showList(u.id)">
                        <span class="badge badge-success" v-if="u.status === 'on progress'">{{ u.status }}</span>
                        <span class="badge badge-warning" v-else-if="u.status === 'revise'">{{ u.status }}</span>
                        <span class="badge badge-danger" v-else-if="u.status === 'reject'">{{ u.status }}</span>
                        <span class="badge badge-primary" v-else>{{ u.status }}</span>
                    </td>
                    <td>{{ u.memo_no }}</td>
                    <td @click="showList(u.id)">{{ u.memo_date | dateFormat('DD/MM/YYYY') }}</td>
                    <td @click="showList(u.id)">{{ u.approve_bank_date | dateFormat('DD/MM/YYYY') }}</td>
                    <td @click="showList(u.id)">{{ u.category }}</td>
                    <td @click="showList(u.id)">[{{ u.from }}] {{ u.user_from }}</td>
                    <td @click="showList(u.id)">
                        <span v-if="u.to !== null">[{{ u.to }}] {{ u.user_to }}</span>
                    </td>
                    <td @click="showList(u.id)">{{ u.subject }}</td>
                    <td @click="showList(u.id)">{{ u.total | currency }}</td>
                    <td @click="showList(u.id)">
                        <span v-if="u.supplier_no !== null">{{ u.supplier }}</span>
                        <span v-else>{{ u.employee }}</span>
                    </td>
                    <td @click="showList(u.id)">{{ u.journal_no }}</td>
                    <td @click="showList(u.id)">
                      <ul v-for="att in u.attachments" :key="att.id">
                        <li class="filename"  @click="downloadAttachment(att.url, att.filename)">{{ att.filename }}</li>
                      </ul>
                    </td>
                    <td>
                      <button type="button" class="btn btn-sm btn-soft-primary" @click="print(u.id)"><i class="fa fa-print" aria-hidden="true"></i> Print</button>
                    </td>
                    </tr>
                </tbody>
            </table>
        </div>
      </div>
      <!-- <pre>{{ memo }}</pre> -->
      <!-- <pre>{{ dataRaw }}</pre> -->
      <!-- <pre>{{ filterStatus }}</pre> -->
    </div>
</template>

<script>
import axios from 'axios'
import Loading from 'vue-loading-overlay'
import DatePicker from 'vue2-datepicker'
import 'vue-loading-overlay/dist/vue-loading.css'
import { memoUrl, getHeader, memo2Url } from '../../config'
import { mapState } from 'vuex'

export default {
  name: 'MemoReport',
  components: {
    Loading,
    DatePicker
  },
  data () {
    var date = new Date()
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0)
    const date1 = new Date(firstDay.getFullYear(), firstDay.getMonth(), firstDay.getDate())
    const date2 = new Date(lastDay.getFullYear(), lastDay.getMonth(), lastDay.getDate())
    return {
      filterStatus: [],
      startDate: [date1, date2],
      loader: {
        isLoading: false,
        fullPage: true,
        color: '#007bff',
        canCancel: true,
        height: 128,
        width: 128,
        backgroundColor: '#ffffff',
        loader: 'spinner'
      },
      dataRaw: [],
      data: {},
      searchQuery: '',
      ascending: false,
      sortClick: false,
      sortColumn: 'approve_bank_date',
      pageSize: 100,
      currentPage: 1,
      checkFilter: [],
      title: [{
        name: 'Status',
        prop: 'status'
      }, {
        name: 'Memo No.',
        prop: 'memo_no'
      }, {
        name: 'Date',
        prop: 'memo_date'
      }, {
        name: 'Approve Bank Date',
        prop: 'approve_bank_date'
      }, {
        name: 'Category',
        prop: 'category_id'
      }, {
        name: 'From',
        prop: 'from'
      }, {
        name: 'To',
        prop: 'to'
      }, {
        name: 'Subject',
        prop: 'subject'
      }, {
        name: 'Total',
        prop: 'total'
      }, {
        name: 'Supplier',
        prop: 'supplier_no'
      }, {
        name: 'Journal',
        prop: 'journal_no'
      }, {
        name: 'Attachment',
        prop: 'attachment'
      }],
      selectAll: false,
      selected: []
    }
  },
  mounted () {
    window.scrollTo(0, 0)
    this.refreshList()
  },
  computed: {
    ...mapState({
      rowsTable: state => state.data.rowsTable
    }),
    pageLength: function () {
      var length = this.dataRaw.length / this.pageSize
      return Math.ceil(length)
    },
    filterData: function () {
      if (this.searchQuery.length > 0) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.currentPage = 1
      }
      var col = this.sortColumn
      var ascending = this.ascending
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.dataRaw.sort(function (a, b) {
        if (a[col] > b[col]) {
          return ascending ? 1 : -1
        } else if (a[col] < b[col]) {
          return ascending ? -1 : 1
        }
        return 0
      }).filter(d => {
        for (const name in d) {
          if (d[name] !== null) {
            if (d[name].toString().toLowerCase().indexOf(this.searchQuery.toLowerCase()) > -1) {
              return d
            }
          }
        }
      }).filter(d => {
        if (this.filterStatus.length > 0) {
          for (let i = 0; i < this.filterStatus.length; i++) {
            if (d.status === this.filterStatus[i]) {
              return d
            }
          }
        } else {
          return d
        }
      }).filter((row, index) => {
        const start = (this.currentPage - 1) * this.pageSize
        const end = this.currentPage * this.pageSize
        if (index >= start && index < end) return true
      })
    }
  },
  methods: {
    async downloadAttachment (url, filename) {
      console.log(memoUrl + '/attachment/file/' + url)
      await axios({
        url: memoUrl + '/attachment/file/' + url,
        method: 'GET',
        responseType: 'blob',
        headers: getHeader()
      }).then((response) => {
        var blob = new Blob([response.data], { type: 'application/' + response.data.type })
        var fileURL = window.URL.createObjectURL(blob)
        var fileLink = document.createElement('a')
        fileLink.href = fileURL
        fileLink.setAttribute('download', filename)
        document.body.appendChild(fileLink)
        fileLink.click()
      })
    },
    print (id) {
      const url = memo2Url + '/pdf/' + id
      window.open(url, '_blank')
    },
    setPageSizeOnChange: function () {
      this.currentPage = 1
    },
    sortTable: function sortTable (col) {
      this.currentPage = 1
      if (this.sortColumn === col) {
        this.ascending = !this.ascending
      } else {
        this.ascending = true
        this.sortColumn = col
      }
    },
    nextPage: function () {
      if ((this.currentPage * this.pageSize) < this.dataRaw.length) this.currentPage++
    },
    prevPage: function () {
      if (this.currentPage > 1) this.currentPage--
    },
    select () {
      this.selected = []
      this.currentPage = 1
      if (!this.selectAll) {
        for (const i in this.filterData) {
          this.selected.push(this.filterData[i].id)
        }
      }
    },
    showList (id) {
      axios.get(memoUrl + '/' + id, { headers: getHeader() })
        .then(response => {
          this.$emit('showMemo', response.data.data)
        })
        .catch(error => {
          console.log(error.response)
          this.$swal(
            'Error!!!',
            error.response.data.message,
            'error'
          )
        })
    },
    editList (id) {
      axios.get(memoUrl + '/' + id, { headers: getHeader() })
        .then(response => {
          this.$emit('showMemo', response.data.data)
        })
        .catch(error => {
          console.log(error.response)
          this.$swal(
            'Error!!!',
            error.response.data.message,
            'error'
          )
        })
    },
    addList () {
      this.$emit('newMemo', true)
    },
    refreshList () {
      console.log('refresh')
      this.$Progress.start()
      this.loader.isLoading = true
      const begin = this.startDate[0] ? this.startDate[0].getFullYear() + '-' + (this.startDate[0].getMonth() + 1) + '-' + this.startDate[0].getDate() : null
      const end = this.startDate[1] ? this.startDate[1].getFullYear() + '-' + (this.startDate[1].getMonth() + 1) + '-' + this.startDate[1].getDate() : null
      var url = memoUrl + '?begin=' + begin + '&end=' + end + '&status=finish'
      console.log(url)
      axios.get(url, { headers: getHeader() })
        .then(response => {
          this.dataRaw = response.data.data.filter(d => d.journal_no === null || d.journal_no === '')
          this.$Progress.finish()
          this.loader.isLoading = false
          this.currentPage = 1
          this.searchQuery = ''
        })
        .catch(error => {
          console.log(error.response)
          this.$swal(
            'Error',
            error.response.data.message,
            'error'
          )
        })
    }
  }
}
</script>

<style>
.filename {
  font-size: 10px;
  /* letter-spacing: 1px; */
  font-weight: 700;
  padding: 5px;
  margin: 10px;
  background: #e7f1fd;
  color: #156e6a;
  /* display: inline-block; */
  text-transform: uppercase;
  border-radius: 10px;
  text-align: center;
}
</style>
