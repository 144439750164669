<template>
  <div class="home">
    <MemoList v-show="listView" @newMemo="onClickNewFromList" @editMemo="onClickEditFromList" @showMemo="onClickShowFromList" @editMemoInbox="onClickEditFromInboxList" ref="refreshList"/>
    <MemoForm v-show="formView" :memo="memo" @backClicked="onBackChild" @backParentID="backParentID"/>
    <MemoFormPrepayment v-show="formPrepaymentView" :memo="memo" @backClicked="onBackChild" @backParentID="backParentID"/>
    <MemoShow v-show="showView" :memo="memo2" @backClicked="onBackChild" @backParentID="backParentShowID"/>
    <MemoInboxForm v-show="formInboxView" :memo="inbox" @backClicked="onBackChild" @backParentID="backParentID" ref="setApproval"/>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios'
import MemoList from '@/components/memo/MemoList.vue'
import MemoForm from '@/components/memo/MemoForm.vue'
import MemoFormPrepayment from '@/components/memo/MemoFormPrepayment.vue'
import MemoShow from '@/components/memo/MemoShow.vue'
import MemoInboxForm from '@/components/memo/MemoInboxForm.vue'
import { getHeader, memoUrl } from '../config'
import { mapState } from 'vuex'

export default {
  name: 'Memo',
  components: {
    MemoList,
    MemoForm,
    MemoShow,
    MemoInboxForm,
    MemoFormPrepayment
  },
  data () {
    return {
      listView: true,
      formView: false,
      showView: false,
      formPrepaymentView: false,
      formInboxView: false,
      memo: {
        id: null,
        memo_no: null,
        memo_date: null,
        from: '',
        to: '',
        path_id: null,
        path: null,
        path_array: [{
          path: null,
          position: null,
          class: null
        }],
        remaining_budget: '0',
        subject: null,
        supplier_no: null,
        employee_no: null,
        company_no: null,
        category_id: null,
        type_id: null,
        total_prepayment: null,
        notes: null,
        subtotal: '0',
        discount: '0',
        tax: '0',
        total: '0',
        status: null,
        lock: null,
        position_id: null,
        alias_id: null,
        account_no: null,
        account_name: null,
        account_bank_id: null,
        billing_no: null,
        company_alias_id: null,
        company_account_no: null,
        company_account_name: null,
        company_account_bank_id: null,
        prepayment_finish: false,
        journal_no: null,
        prepayment_report_finish: null,
        payment_amount: null,
        payment_date: null,
        approve_list: [{
          employee_no: null,
          name: null
        }],
        details: [{
          id: null,
          memo_no: null,
          company_no: null,
          memo_date: null,
          description: null,
          qty: '0',
          amount: '0',
          discount: '0',
          tax: '0',
          total: '0'
        }],
        attachments: [{
          id: null,
          memo_no: null,
          filename: null,
          mime_type: null,
          extension: null,
          url: null
        }],
        logs: [{
          id: null,
          memo_no: null,
          memo_type: null,
          log_date: null,
          log_notes: null,
          log_user: null,
          subtotal: null,
          discount: null,
          tax: null,
          total: null,
          path_id: null,
          path: null,
          from: null,
          to: null,
          user: null
        }],
        employee: {
          id: null,
          employee_no: null,
          bank_account_no: null,
          bank_account_name: null,
          bank_id: null
        },
        supplier: {
          id: null,
          supplier_no: null,
          bank_account_no: null,
          bank_account_name: null,
          bank_id: null
        },
        bank_approval: [{
          id: null,
          memo_no: null,
          type: null,
          approval_date: null,
          approval_by: null,
          approval: null
        }],
        payments: [{
          id: null,
          memo_no: null,
          payment_amount: null,
          payment_date: null,
          type: null
        }],
        accounts: [{
          id: null,
          memo_no: null,
          type: null,
          method: null,
          employee_no: null,
          alias_id: null,
          account_no: null,
          account_name: null,
          account_bank_id: null,
          billing_no: null
        }]
      },
      memo2: {
        id: null,
        memo_no: null,
        memo_date: null,
        from: '',
        to: '',
        path_id: null,
        path: null,
        path_array: [{
          path: null,
          position: null,
          class: null
        }],
        remaining_budget: '0',
        subject: null,
        supplier_no: null,
        employee_no: null,
        company_no: null,
        category_id: null,
        type_id: null,
        total_prepayment: null,
        notes: null,
        subtotal: '0',
        discount: '0',
        tax: '0',
        total: '0',
        status: null,
        lock: null,
        position_id: null,
        alias_id: null,
        account_no: null,
        account_name: null,
        account_bank_id: null,
        billing_no: null,
        company_alias_id: null,
        company_account_no: null,
        company_account_name: null,
        company_account_bank_id: null,
        prepayment_finish: false,
        journal_no: null,
        prepayment_report_finish: null,
        payment_amount: null,
        payment_date: null,
        approve_list: [{
          employee_no: null,
          name: null
        }],
        details: [{
          id: null,
          memo_no: null,
          company_no: null,
          memo_date: null,
          description: null,
          qty: '0',
          amount: '0',
          discount: '0',
          tax: '0',
          total: '0'
        }],
        attachments: [{
          id: null,
          memo_no: null,
          filename: null,
          mime_type: null,
          extension: null,
          url: null
        }],
        logs: [{
          id: null,
          memo_no: null,
          memo_type: null,
          log_date: null,
          log_notes: null,
          log_user: null,
          subtotal: null,
          discount: null,
          tax: null,
          total: null,
          path_id: null,
          path: null,
          from: null,
          to: null,
          user: null
        }],
        employee: {
          id: null,
          employee_no: null,
          bank_account_no: null,
          bank_account_name: null,
          bank_id: null
        },
        supplier: {
          id: null,
          supplier_no: null,
          bank_account_no: null,
          bank_account_name: null,
          bank_id: null
        },
        bank_approval: [{
          id: null,
          memo_no: null,
          type: null,
          approval_date: null,
          approval_by: null,
          approval: null
        }],
        payment_bank_id: null,
        payment_bank_account: null,
        payment_name: null,
        payments: [{
          id: null,
          memo_no: null,
          payment_amount: null,
          payment_date: null,
          type: null
        }],
        accounts: [{
          id: null,
          memo_no: null,
          type: null,
          method: null,
          employee_no: null,
          alias_id: null,
          account_no: null,
          account_name: null,
          account_bank_id: null,
          billing_no: null
        }]
      },
      inbox: {
        id: null,
        memo_no: null,
        memo_date: null,
        from: '',
        to: null,
        path_id: null,
        path: null,
        path_array: [{
          path: null,
          position: null,
          class: null
        }],
        remaining_budget: '0',
        subject: null,
        supplier_no: null,
        employee_no: null,
        company_no: null,
        category_id: null,
        type_id: null,
        total_prepayment: null,
        notes: null,
        subtotal: '0',
        discount: '0',
        tax: '0',
        total: '0',
        status: null,
        lock: null,
        position_id: null,
        alias_id: null,
        alias: null,
        account_no: null,
        account_name: null,
        account_bank_id: null,
        billing_no: null,
        company_alias_id: null,
        company_account_no: null,
        company_account_name: null,
        company_account_bank_id: null,
        prepayment_finish: false,
        journal_no: null,
        prepayment_report_finish: null,
        payment_amount: null,
        payment_date: null,
        approve_list: [{
          employee_no: null,
          name: null
        }],
        details: [{
          id: null,
          memo_no: null,
          company_no: null,
          memo_date: null,
          description: null,
          qty: '0',
          amount: '0',
          discount: '0',
          tax: '0',
          total: '0'
        }],
        attachments: [{
          id: null,
          memo_no: null,
          filename: null,
          mime_type: null,
          extension: null,
          url: null
        }],
        logs: [{
          id: null,
          memo_no: null,
          memo_type: null,
          log_date: null,
          log_notes: null,
          log_user: null,
          subtotal: null,
          discount: null,
          tax: null,
          total: null,
          path_id: null,
          path: null,
          from: null,
          to: null,
          user: null
        }],
        employee: {
          id: null,
          employee_no: null,
          bank_account_no: null,
          bank_account_name: null,
          bank_id: null
        },
        supplier: {
          id: null,
          supplier_no: null
        },
        payment_bank_id: null,
        payment_bank_account: null,
        payment_name: null,
        bank_approval: [{
          no: null,
          id: null,
          type: null,
          approval_date: null,
          approval_by: null,
          approval: null
        }],
        payments: [{
          id: null,
          memo_no: null,
          payment_amount: null,
          payment_date: null,
          type: null
        }],
        accounts: [{
          id: null,
          memo_no: null,
          type: null,
          method: null,
          employee_no: null,
          alias_id: null,
          account_no: null,
          account_name: null,
          account_bank_id: null,
          billing_no: null
        }]
      }
    }
  },
  computed: {
    ...mapState({
      authUser: state => state.users.authUser
    })
  },
  methods: {
    onClickEditFromInboxList: function (value) {
      this.listView = false
      this.formView = false
      this.formPrepaymentView = false
      this.showView = false
      this.formInboxView = true
      // set memo value
      this.inbox.id = value.id
      this.inbox.memo_no = value.memo_no
      this.inbox.memo_date = value.memo_date
      this.inbox.from = value.from
      this.inbox.to = value.to
      this.inbox.path_id = value.path_id
      this.inbox.path = value.path
      this.inbox.path_array = value.path_array
      this.inbox.remaining_budget = value.remaining_budget
      this.inbox.subject = value.subject
      this.inbox.supplier_no = value.supplier_no
      this.inbox.employee_no = value.employee_no
      this.inbox.company_no = value.company_no
      this.inbox.category_id = value.category_id
      this.inbox.type_id = value.type_id
      this.inbox.total_prepayment = value.total_prepayment
      this.inbox.notes = value.notes
      this.inbox.subtotal = value.subtotal
      this.inbox.discount = value.discount
      this.inbox.tax = value.tax
      this.inbox.total = value.total
      this.inbox.status = value.status
      this.inbox.lock = value.lock
      this.inbox.position_id = value.position_id
      this.inbox.alias_id = value.alias_id
      this.inbox.alias = value.alias
      this.inbox.account_no = value.account_no
      this.inbox.account_name = value.account_name
      this.inbox.account_bank_id = value.account_bank_id
      this.inbox.billing_no = value.billing_no
      this.inbox.company_alias_id = value.company_alias_id
      this.inbox.company_account_no = value.company_account_no
      this.inbox.company_account_name = value.company_account_name
      this.inbox.company_account_bank_id = value.company_account_bank_id
      this.inbox.prepayment_finish = value.prepayment_finish
      this.inbox.journal_no = value.journal_no
      this.inbox.prepayment_report_finish = value.prepayment_report_finish
      this.inbox.payment_amount = value.payment_amount
      this.inbox.payment_date = value.payment_date
      this.inbox.approve_list = value.approve_list
      this.inbox.details = value.details
      this.inbox.attachments = value.attachments
      this.inbox.logs = value.logs
      this.inbox.employee = value.employee
      this.inbox.supplier = value.supplier
      this.inbox.from_profile = value.from_profile
      this.inbox.payment_bank_id = value.payment_bank_id
      this.inbox.payment_bank_account = value.payment_bank_account
      this.inbox.payment_name = value.payment_name
      this.inbox.bank_approval = value.bank_approval
      this.inbox.payments = value.payments
      this.inbox.accounts = value.accounts
    },
    async backParentShowID (id) {
      await axios.get(memoUrl + '/' + id, { headers: getHeader() })
        .then(response => {
          this.listView = false
          this.formView = false
          this.formPrepaymentView = false
          this.showView = true
          this.formInboxView = false
          this.memo2 = response.data.data
        })
        .catch(error => {
          console.log(error.response)
          this.$swal(
            'Error',
            error.response.data.message,
            'error'
          )
        })
    },
    backParentID: function (id) {
      axios.get(memoUrl + '/' + id, { headers: getHeader() })
        .then(response => {
          this.listView = false
          this.formView = true
          this.formInboxView = false
          this.memo = response.data.data
        })
        .catch(error => {
          console.log(error.response)
          this.$swal(
            'Error',
            error.response.data.message,
            'error'
          )
        })
    },
    onClickEditFromList: function (value) {
      this.listView = false
      this.formView = true
      this.formPrepaymentView = false
      this.showView = false
      this.formInboxView = false
      this.memo = value.data
      this.memo.type_id = value.type
      this.memo.status = 'on progress'
    },
    onClickShowFromList: function (value) {
      this.listView = false
      this.formView = false
      this.formPrepaymentView = false
      this.showView = true
      this.formInboxView = false
      this.memo2 = {
        id: value.id,
        memo_no: value.memo_no,
        memo_date: value.memo_date,
        from: value.from,
        to: value.to,
        path_id: value.path_id,
        path: value.path,
        path_array: value.path_array,
        remaining_budget: value.remaining_budget,
        subject: value.subject,
        supplier_no: value.supplier_no,
        employee_no: value.employee_no,
        company_no: value.company_no,
        category_id: value.category_id,
        type_id: value.type_id,
        total_prepayment: value.total_prepayment,
        notes: value.notes,
        subtotal: value.subtotal,
        discount: value.discount,
        tax: value.tax,
        total: value.total,
        status: value.status,
        lock: value.lock,
        position_id: value.position_id,
        alias_id: value.position_id,
        account_no: value.account_no,
        account_name: value.account_name,
        account_bank_id: value.account_bank_id,
        billing_no: value.billing_no,
        company_alias_id: value.company_alias_id,
        company_account_no: value.company_account_no,
        company_account_name: value.company_account_name,
        company_account_bank_id: value.company_account_bank_id,
        prepayment_finish: value.prepayment_finish,
        journal_no: value.journal_no,
        prepayment_report_finish: value.prepayment_report_finish,
        payment_amount: value.payment_amount,
        payment_date: value.payment_date,
        approve_list: value.approve_list,
        details: value.details,
        attachments: value.attachments,
        logs: value.logs,
        employee: value.employee,
        supplier: value.supplier,
        from_profile: value.from_profile,
        payment_bank_id: value.payment_bank_id,
        payment_bank_account: value.payment_bank_account,
        payment_name: value.payment_name,
        bank_approval: value.bank_approval,
        payments: value.payments,
        accounts: value.accounts
      }
    },
    onClickNewFromList: function (type) {
      var username = this.authUser.username
      var date = new Date()
      date = ('0' + (date.getDate())).slice(-2) + '/' + ('0' + (date.getMonth() + 1)).slice(-2) + '/' + date.getFullYear()
      this.listView = false
      this.showView = false
      this.formInboxView = false
      this.formView = true
      this.memo = {
        id: null,
        memo_no: null,
        memo_date: date,
        from: username,
        to: '',
        path_id: null,
        path: null,
        path_array: [{
          path: null,
          position: null,
          class: null
        }],
        remaining_budget: '0',
        subject: null,
        supplier_no: null,
        employee_no: null,
        company_no: this.authUser.company_no,
        category_id: null,
        type_id: type,
        total_prepayment: '0',
        notes: null,
        subtotal: '0',
        discount: '0',
        tax: '0',
        total: '0',
        status: null,
        lock: null,
        position_id: this.authUser.position_id,
        alias_id: null,
        account_no: null,
        account_name: null,
        account_bank_id: null,
        billing_no: null,
        company_alias_id: null,
        company_account_no: null,
        company_account_name: null,
        company_account_bank_id: null,
        prepayment_finish: false,
        journal_no: null,
        prepayment_report_finish: null,
        payment_amount: null,
        payment_date: null,
        approve_list: [{
          employee_no: null,
          name: null
        }],
        details: [{
          memo_no: null,
          company_no: null,
          memo_date: date,
          description: null,
          qty: '0',
          amount: '0',
          discount: '0',
          tax: '0',
          total: '0'
        }],
        attachments: [{
          id: null,
          memo_no: null,
          filename: null,
          mime_type: null,
          extension: null,
          url: null
        }],
        logs: [{
          id: null,
          memo_no: null,
          memo_type: null,
          log_date: null,
          log_notes: null,
          log_user: null,
          subtotal: null,
          discount: null,
          tax: null,
          total: null,
          path_id: null,
          path: null,
          from: null,
          to: null,
          user: null
        }],
        employee: {
          id: null,
          employee_no: null,
          bank_account_no: null,
          bank_account_name: null,
          bank_id: null
        },
        supplier: {
          id: null,
          supplier_no: null,
          bank_account: '',
          bank_address: '',
          bank_id: null
        },
        payments: [{
          memo_no: null,
          payment_amount: null,
          payment_date: null,
          type: null
        }],
        accounts: [{
          memo_no: null,
          type: type,
          method: 'to',
          employee_no: null,
          alias_id: null,
          account_no: null,
          account_name: null,
          account_bank_id: null,
          billing_no: null
        }, {
          memo_no: null,
          type: type,
          method: 'from',
          employee_no: null,
          alias_id: null,
          account_no: null,
          account_name: null,
          account_bank_id: null,
          billing_no: null
        }]
      }
    },
    onBackChild: function () {
      this.listView = true
      this.formView = false
      this.formPrepaymentView = false
      this.showView = false
      this.formInboxView = false
      this.memo = {
        id: null,
        memo_no: null,
        memo_date: null,
        from: null,
        to: null,
        path_id: null,
        path: null,
        path_array: [{
          path: null,
          position: null,
          class: null
        }],
        remaining_budget: '0',
        subject: null,
        supplier_no: null,
        employee_no: null,
        company_no: null,
        category_id: null,
        type_id: null,
        total_prepayment: null,
        notes: null,
        subtotal: '0',
        discount: '0',
        tax: '0',
        total: '0',
        status: null,
        lock: null,
        position_id: null,
        alias_id: null,
        account_no: null,
        account_name: null,
        account_bank_id: null,
        billing_no: null,
        company_alias_id: null,
        company_account_no: null,
        company_account_name: null,
        company_account_bank_id: null,
        prepayment_finish: false,
        journal_no: null,
        prepayment_report_finish: null,
        payment_amount: null,
        payment_date: null,
        approve_list: [{
          employee_no: null,
          name: null
        }],
        details: [{
          id: null,
          memo_no: null,
          company_no: null,
          memo_date: null,
          description: null,
          qty: '0',
          amount: '0',
          discount: '0',
          tax: '0',
          total: '0'
        }],
        attachments: [{
          id: null,
          memo_no: null,
          filename: null,
          mime_type: null,
          extension: null,
          url: null
        }],
        logs: [{
          id: null,
          memo_no: null,
          memo_type: null,
          log_date: null,
          log_notes: null,
          log_user: null,
          subtotal: null,
          discount: null,
          tax: null,
          total: null,
          path_id: null,
          path: null,
          from: null,
          to: null,
          user: null
        }],
        employee: {
          id: null,
          employee_no: null,
          bank_account_no: null,
          bank_account_name: null,
          bank_id: null
        },
        supplier: {
          id: null,
          supplier_no: null,
          bank_account_no: null,
          bank_account_name: null,
          bank_id: null
        },
        payments: [{
          id: null,
          memo_no: null,
          payment_amount: null,
          payment_date: null,
          type: null
        }],
        accounts: [{
          id: null,
          memo_no: null,
          type: null,
          method: null,
          alias_id: null,
          account_no: null,
          account_name: null,
          account_bank_id: null,
          billing_no: null
        }]
      }
      this.$refs.refreshList.refreshList()
    },
    async getFromParam (id) {
      await axios.get(memoUrl + '/' + id, { headers: getHeader() })
        .then(response => {
          if (response.data.data.status !== 'revise') {
            this.$swal(
              'Error',
              'Cannot Update because not memo revise',
              'error'
            )
            return false
          }
          var acc = response.data.data.accounts.find(d => d.method === 'to')
          var obj = {
            memo_no: acc.memo_no,
            type: 'regular',
            method: 'to',
            employee_no: acc.employee_no,
            alias_id: acc.alias_id,
            account_no: acc.account_no,
            account_name: acc.account_name,
            account_bank_id: acc.account_bank_id,
            billing_no: acc.billing_no
          }
          response.data.data.accounts.push(obj)
          response.data.data.accounts.push({
            memo_no: acc.memo_no,
            type: 'regular',
            method: 'from',
            employee_no: null,
            alias_id: null,
            account_no: null,
            account_name: null,
            account_bank_id: null,
            billing_no: null
          })
          var data = {
            data: response.data.data,
            type: 'regular'
          }
          this.onClickEditFromList(data)
        })
        .catch(error => {
          console.log(error.response)
        })
    }
  },
  mounted () {
    this.$store.dispatch('getCompanyList')
    this.$store.dispatch('getEmployeeList')
    this.$store.dispatch('getMemoCategoryList')
    this.$store.dispatch('getBankList')
    this.$store.dispatch('getRowTable')
    this.$store.dispatch('getEmployeePosition')
    this.$store.dispatch('getSupplierList')
    this.$store.dispatch('getMemoInboxNotif')
    if (typeof this.$route.query.id !== 'undefined') {
      var id = this.$route.query.id
      this.getFromParam(id)
    }
  }
}
</script>
