import axios from 'axios'
import { getHeader, bankUrl } from './../config'

const state = {
  bankList: []
}

const mutations = {
  SET_BANK_LIST (state, obj) {
    state.bankList = obj
  }
}

const actions = {
  getBankList: ({ commit }) => {
    console.log(bankUrl)
    axios.get(bankUrl, { headers: getHeader() })
      .then(response => {
        commit('SET_BANK_LIST', response.data.data)
      })
  }
}

export default {
  state, mutations, actions
}
