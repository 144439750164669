<template>
  <div class="home">
    <h1>Setting</h1>
    <div class="row">
        <div class="col-md-12">
            <div class="card">
                <div class="card-body">
                    <ul class="nav nav-tabs nav-bordered">
                      <li class="nav-item">
                        <a href="#user" data-toggle="tab" aria-expanded="false" class="nav-link active">User</a>
                      </li>
                      <li class="nav-item">
                        <a href="#role" data-toggle="tab" aria-expanded="false" class="nav-link">Role</a>
                      </li>
                      <li class="nav-item">
                        <a href="#bank" data-toggle="tab" aria-expanded="false" class="nav-link">Bank</a>
                      </li>
                      <li class="nav-item">
                        <a href="#memo-category" data-toggle="tab" aria-expanded="false" class="nav-link">Memo Category</a>
                      </li>
                      <li class="nav-item">
                        <a href="#memo-path" data-toggle="tab" aria-expanded="false" class="nav-link">Memo Path</a>
                      </li>
                      <li class="nav-item">
                        <a href="#integration" data-toggle="tab" aria-expanded="false" class="nav-link">Integration</a>
                      </li>
                    </ul>
                    <div class="tab-content">
                        <div class="tab-pane active" id="user"><SettingUserList/></div>
                        <div class="tab-pane" id="role">
                          <SettingRoleList v-if="roleList" @newRole="onClickNewRoleFromList" @editRole="onClickEditRoleFromList" ref="refreshRoleList" />
                          <SettingRoleForm v-else-if="roleForm" :role="role" @backParent="backRoleParent"/>
                        </div>
                        <div class="tab-pane" id="bank"><SettingBankList/></div>
                        <div class="tab-pane" id="memo-category"><SettingMemoCategoryList/></div>
                        <div class="tab-pane" id="memo-path"><SettingMemoPathList/></div>
                        <div class="tab-pane" id="integration"><SettingIntegrationList/></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import SettingUserList from '@/components/setting/SettingUserList.vue'
import SettingBankList from '@/components/setting/SettingBankList.vue'
import SettingMemoCategoryList from '@/components/setting/SettingMemoCategoryList.vue'
import SettingMemoPathList from '@/components/setting/SettingMemoPathList.vue'
import SettingRoleList from '@/components/setting/SettingRoleList.vue'
import SettingRoleForm from '@/components/setting/SettingRoleForm.vue'
import SettingIntegrationList from '@/components/setting/SettingIntegrationList.vue'

export default {
  name: 'Setting',
  components: {
    SettingUserList,
    SettingBankList,
    SettingMemoCategoryList,
    SettingMemoPathList,
    SettingRoleList,
    SettingRoleForm,
    SettingIntegrationList
  },
  data () {
    return {
      roleList: true,
      roleForm: false,
      role: {
        id: null,
        name: null,
        permissions: {
          memo_save: false,
          memo_delete: false,
          memo_report: false,
          memo_inbox: false,
          memo_path_save: false,
          memo_path_delete: false,
          setting: false,
          set_company: false,
          employee_save: false,
          employee_delete: false
        }
      }
    }
  },
  mounted () {
    this.$store.dispatch('getRowTable')
  },
  methods: {
    backRoleParent: function () {
      this.roleList = true
      this.roleForm = false
      this.role = {
        id: null,
        name: null,
        permissions: {}
      }
    },
    onClickNewRoleFromList: function () {
      this.role = {
        id: null,
        name: null,
        permissions: {}
      }
      this.roleList = false
      this.roleForm = true
    },
    onClickEditRoleFromList: function (value) {
      this.role = {
        id: value.id,
        name: value.name,
        permissions: value.permissions
      }
      this.roleList = false
      this.roleForm = true
    }
  }
}
</script>
