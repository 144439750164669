<template>
    <div>
        <h5>Memo</h5>
        <div class="row">
            <div class="col-md-12">
                <div class="top-list-toolbar">
                  <h4>Memo Inbox</h4>
                  <div>
                    <button type="button" @click="backParent" class="btn btn-sm btn-danger pull-left"><i class="fa fa-reply" aria-hidden="true"></i> Back</button>
                    <button type="button" @click="reject" class="btn btn-sm btn-danger pull-right" data-toggle="modal" data-target="#modalRejectMemo" data-backdrop="static" data-keyboard="false"><i class="fa fa-check" aria-hidden="true"></i> Reject</button>
                    <button type="button" class="btn btn-sm btn-warning pull-right" data-toggle="modal" data-target="#modalReviseMemo" data-backdrop="static" data-keyboard="false"><i class="fa fa-check" aria-hidden="true"></i> Revise</button>
                    <button type="button" @click="approve" class="btn btn-sm btn-success pull-right"><i class="fa fa-check" aria-hidden="true"></i> Approve</button>
                  </div>
                </div>
            </div>
        </div>
        <div class="card card-list">
          <div class="card-header">
            <h5>
              <b class="mr-1">{{ memo.memo_no }}</b>
            </h5>
          </div>
          <div class="card-body">
            <ul class="nav nav-tabs nav-bordered">
                <li class="nav-item"><a href="#general" data-toggle="tab" aria-expanded="false" class="nav-link active">General</a></li>
              </ul>
              <div class="tab-content">
                <div class="tab-pane active" id="general">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label for="memo_date" class="col-sm-3 col-form-label text-right">Date</label>
                        <div class="col-sm-9">
                          <input type="text" v-model="memo.memo_date" class="form-control" disabled="disabled">
                        </div>
                      </div>
                      <div class="form-group row">
                        <label for="company_no" class="col-sm-3 col-form-label text-right">Company</label>
                        <div class="col-sm-9">
                          <select v-model="memo.company_no" class="form-control" disabled="disabled">
                            <option v-for="c in companyList" :key="c.id" :value="c.company_no">[{{ c.company_no }}] {{ c.name }}</option>
                          </select>
                        </div>
                      </div>
                      <div class="form-group row">
                        <label for="from" class="col-sm-3 col-form-label text-right">From</label>
                        <div class="col-sm-9">
                          <select v-model="memo.from" class="form-control" disabled="disabled">
                            <option v-for="e in employeeSelect" :key="e.id" :value="e.employee_no">
                              [{{ e.employee_no }}] {{ e.name }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="form-group row">
                            <label for="position_id" class="col-sm-3 col-form-label text-right">Position</label>
                            <div class="col-sm-9">
                              <select v-model="memo.position_id" class="form-control" disabled="disabled">
                                <option v-for="p in positionList" :key="p.id" :value="p.id">[{{ p.id }}] {{ p.name }}</option>
                              </select>
                            </div>
                          </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group row">
                          <label for="category_id" class="col-sm-3 col-form-label text-right">Category</label>
                          <div class="col-sm-9">
                            <select v-model="memo.category_id" class="form-control" @change="setApproval" disabled="disabled">
                                <option v-for="cat in getCategory" :key="cat.id" :value="cat.id">{{ cat.name }}</option>
                            </select>
                          </div>
                      </div>
                      <div class="form-group row">
                        <label for="type_id" class="col-sm-3 col-form-label text-right">Type</label>
                        <div class="col-sm-9">
                          <input type="text" v-model="memo.type_id" class="form-control" disabled="disabled"/>
                        </div>
                      </div>
                      <div class="form-group row" v-if="memo.status === 'approve'">
                        <label for="to" class="col-sm-3 col-form-label text-right">Approve By</label>
                        <div class="col-sm-9">
                          <input type="text" class="form-control" v-model="approveBy" disabled="disabled"/>
                        </div>
                      </div>
                      <div class="form-group row" v-else>
                        <label for="to" class="col-sm-3 col-form-label text-right">Approval</label>
                        <div class="col-sm-9">
                          <select v-model="memo.to" class="form-control">
                            <option v-for="e in memo.approve_list" :key="e.id" :value="e.employee_no">
                              <span v-if="e.employee_no == null">{{ e.name }}</span>
                              <span v-else>{{ e.employee_no }} - {{ e.name }}</span>
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="form-group row pb-5">
                        <label for="approval_path" class="col-sm-3 col-form-label text-right">&nbsp;</label>
                        <div class="col-sm-9">
                          <div class="progress-container">
                            <ul class="progressbar">
                              <li v-for="al in memo.path_array" :key="al.path" :class="al.class">{{ al.position }}</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group row">
                        <label for="subject" class="col-sm-2 col-form-label text-right">Subject</label>
                        <div class="col-sm-10">
                          <input type="text" class="form-control" v-model="memo.subject" disabled="disabled"/>
                        </div>
                      </div>
                      <div class="form-group row">
                        <label for="notes" class="col-sm-2 col-form-label text-right">Notes:</label>
                        <div class="col-sm-10">
                          <textarea type="text" class="form-control" v-model="memo.notes" rows="5" disabled="disabled"/>
                        </div>
                      </div>
                      <div class="form-group row" v-if="memo.type_id === 'prepayment'">
                        <label for="total_prepayment" class="col-sm-2 col-form-label text-right">Total Prepayment</label>
                        <div class="col-sm-10">
                          <input type="text" class="form-control" v-model="memo.total_prepayment" v-money="money" disabled="disabled"/>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr>
                  <div class="row" v-if="this.memo.type_id !== 'prepayment' || this.memo.prepayment_finish === true">
                      <div class="col-md-12">
                          <div class="table-responsive">
                              <table class="table table-item mb-0 table-sm table-hover">
                                  <thead>
                                    <th>Date</th>
                                    <th>Description</th>
                                    <th>Qty</th>
                                    <th>Amount</th>
                                    <th class="text-right">Total</th>
                                  </thead>
                                  <tbody>
                                    <tr v-for="d in memo.details" :key="d.id">
                                      <td>{{ d.memo_date | dateFormat('DD/MM/YYYY') }}</td>
                                      <td>{{ d.description }}</td>
                                      <td>{{ d.qty }}</td>
                                      <td>{{ d.amount | currency }}</td>
                                      <td class="text-right"><b>{{ d.total | currency }}</b></td>
                                    </tr>
                                  </tbody>
                                  <tfoot>
                                    <tr>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td class="text-right"><b>Subtotal:</b></td>
                                      <td class="text-right"><b>{{ getSubtotal | currency }}</b></td>
                                      <td></td>
                                    </tr>
                                    <tr>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td class="text-right"><b>Total:</b></td>
                                      <td class="text-right"><b>{{ getTotal | currency }}</b></td>
                                      <td></td>
                                    </tr>
                                    <tr v-if="memo.prepayment_finish === true">
                                      <td colspan="5">&nbsp;</td>
                                    </tr>
                                    <tr v-if="memo.prepayment_finish === true">
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td class="text-right"><b>Prepayment</b></td>
                                      <td class="text-right"><b>{{ getTotalPrepayment | currency }}</b></td>
                                      <td></td>
                                    </tr>
                                    <tr v-if="memo.prepayment_finish === true">
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td class="text-right" style="color: blue;">
                                        <span v-if="getOutstanding === 0"><b>Outstanding</b></span>
                                        <span v-else-if="getOutstanding < 0"><b>Process</b></span>
                                        <span v-else-if="getOutstanding > 0"><b>Return</b></span>
                                      </td>
                                      <td class="text-right" style="color: blue;">
                                        <b><u>{{ Math.abs(getOutstanding) | currency }}</u></b>
                                      </td>
                                      <td></td>
                                    </tr>
                                  </tfoot>
                              </table>
                          </div>
                      </div>
                  </div>
                  <hr>
                  <div class="row">
                      <div class="col-md-12">
                      <div class="upload-border">
                        <span class="filename" v-for="a in attachmentList" :key="a.id">
                          <span @click="downloadAttachment(a.url, a.filename)" style="cursor: pointer;">{{ a.filename }}</span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <h5>Payment to: </h5>
                      <div class="form-group row">
                        <label for="bank" class="col-sm-3 col-form-label align-label text-right">Bank</label>
                        <div class="col-sm-9">
                          <select v-model="accountTo.account_bank_id" class="form-control" disabled="disabled">
                            <option v-for="b in bankList" :key="b.id" :value="b.id">{{ b.name }}</option>
                          </select>
                        </div>
                      </div>
                      <div class="form-group row">
                        <label for="bank_account_no" class="col-sm-3 col-form-label align-label text-right">Account No.</label>
                        <div class="col-sm-9">
                          <input type="text" class="form-control" v-model="accountTo.account_no" disabled="disabled"/>
                        </div>
                      </div>
                      <div class="form-group row">
                        <label for="account_name" class="col-sm-3 col-form-label align-label text-right">Account Name.</label>
                        <div class="col-sm-9">
                          <input type="text" class="form-control" v-model="accountTo.account_name" disabled="disabled"/>
                        </div>
                      </div>
                      <div class="form-group row">
                        <label for="billing_no" class="col-sm-3 col-form-label align-label text-right">Billing No.</label>
                        <div class="col-sm-9">
                          <input type="text" class="form-control" v-model="accountTo.billing_no" disabled="disabled"/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
        <hr>
        <div class="row justify-content-md-center">
          <div class="col-md-6">
            <h5>Logs:</h5>
            <div class="alert alert-info" role="alert" v-for="l in memo.logs" :key="l.id">
              <b>{{ l.log_user }}</b> - {{ l.log_date | dateFormat('DD/MM/YYYY HH:mm:ss') }} <span>"{{ l.log_notes }}"</span>
            </div>
          </div>
        </div>
        <MemoInboxModal @processRevise="revise" @processReject="reject"/>
        <MemoSupplierModal :supplier="vendor" :memo="memo" />
        <!-- <pre>{{ memo }}</pre> -->
    </div>
</template>

<script>
import axios from 'axios'
import { getHeader, memoUrl, supplierUrl } from '../../config'
import { mapState } from 'vuex'
import MemoInboxModal from '@/components/memo/MemoInboxModal.vue'
import MemoSupplierModal from '@/components/memo/MemoSupplierModal.vue'

export default {
  name: 'MemoInboxForm',
  components: {
    MemoInboxModal,
    MemoSupplierModal
  },
  props: ['memo'],
  data () {
    return {
      isBudget: false,
      vendor: {
        id: null,
        supplier_no: null,
        name: null,
        phone: null,
        address: null,
        vat: null,
        bank_id: null,
        account_no: null,
        pkp: null,
        description: null
      },
      remainingBudget: 0,
      employeeData: {
        employee_no: null,
        bank: null,
        bank_account_no: null,
        bank_account_name: null
      },
      money: {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 0,
        masked: true /* doesn't work with directive */
      },
      approvalList: [{
        employee_no: null,
        name: null,
        path: [{
          path: 1,
          position: 'Step 1',
          class: null
        }, {
          path: 2,
          position: 'Step 2',
          class: null
        }]
      }]
    }
  },
  computed: {
    ...mapState({
      companyList: state => state.companies.companyList,
      memoCategoryList: state => state.memos.memoCategoryList,
      supplierList: state => state.suppliers.supplierList,
      employeeList: state => state.employees.employeeList,
      bankList: state => state.banks.bankList,
      positionList: state => state.employees.positionList,
      authUser: state => state.users.authUser
    }),
    accountFrom () {
      var acc = this.memo.accounts.find(d => d.method === 'from')
      var obj = {}
      if (acc) {
        obj = acc
      } else {
        obj = {
          id: null,
          memo_no: null,
          type: null,
          method: null,
          employee_no: null,
          alias_id: null,
          account_no: null,
          account_name: null,
          account_bank_id: null,
          billing_no: null
        }
      }
      return obj
    },
    accountTo () {
      var acc = this.memo.accounts.find(d => d.method === 'to')
      var obj = {}
      if (acc) {
        obj = acc
      } else {
        obj = {
          id: null,
          memo_no: null,
          type: null,
          method: null,
          employee_no: null,
          alias_id: null,
          account_no: null,
          account_name: null,
          account_bank_id: null,
          billing_no: null
        }
      }
      return obj
    },
    approveBy: function () {
      const approve = this.memo.approve_list[0]
      return '[' + approve.employee_no + ']' + ' ' + approve.name
    },
    getApprovalList: function () {
      return this.approvalList[0].pathArray
    },
    attachmentList: function () {
      return this.memo.attachments.filter(d => d.id !== null)
    },
    employeeSelect: function () {
      return this.employeeList
    },
    getCategory: function () {
      return this.memoCategoryList.filter(d => d.company_no === this.memo.company_no)
    },
    getSubtotal: function () {
      var type = this.memo.type_id
      var array = this.memo.details
      var total = 0
      if (type === 'prepayment') {
        if (this.memo.prepayment_finish === true) {
          var tp = this.memo.total_prepayment.replaceAll('.', '')
          console.log(tp)
          for (let i = 0; i < array.length; i++) {
            var am = array[i].amount.replaceAll('.', '')
            am = am.replace(',', '.')
            total += parseInt(array[i].qty) * parseFloat(am)
          }
        } else {
          var totalPrepayment = this.memo.total_prepayment.replaceAll('.', '')
          total = totalPrepayment.replace(',', '.')
        }
      } else {
        for (let i = 0; i < array.length; i++) {
          var amount = array[i].amount
          total += parseInt(array[i].qty) * parseFloat(amount)
        }
      }
      return total
    },
    getDiscount: function () {
      var array = this.memo.details
      var total = 0
      for (let i = 0; i < array.length; i++) {
        total += parseFloat(array[i].discount.replace(/\./g, ''))
      }
      return total
    },
    getTax: function () {
      var array = this.memo.details
      var total = 0
      for (let i = 0; i < array.length; i++) {
        total += parseFloat(array[i].tax.replace(/\./g, ''))
      }
      return total
    },
    getTotal: function () {
      var type = this.memo.type_id
      var array = this.memo.details
      var total = 0
      if (type === 'prepayment') {
        if (this.memo.prepayment_finish === true) {
          var tp = this.memo.total_prepayment.replaceAll('.', '')
          console.log(tp)
          for (let i = 0; i < array.length; i++) {
            var am = array[i].amount.replaceAll('.', '')
            am = am.replace(',', '.')
            total += parseInt(array[i].qty) * parseFloat(am)
          }
        } else {
          var totalPrepayment = this.memo.total_prepayment.replaceAll('.', '')
          total = totalPrepayment.replace(',', '.')
        }
      } else {
        for (let i = 0; i < array.length; i++) {
          var amount = array[i].amount
          total += parseInt(array[i].qty) * parseFloat(amount)
        }
      }
      return total
    },
    getTotalPrepayment () {
      var totalPrepayment = this.memo.total_prepayment.replaceAll('.', '')
      totalPrepayment = totalPrepayment.replace(',', '.')
      return totalPrepayment
    },
    getOutstanding () {
      var outstanding = 0
      var totalPrepayment = this.memo.total_prepayment.replaceAll('.', '')
      totalPrepayment = totalPrepayment.replace(',', '.')
      outstanding = parseFloat(totalPrepayment) - parseFloat(this.getSubtotal)
      return outstanding
    }
  },
  methods: {
    async createBank () {
      var obj = {
        memo_no: this.memo.memo_no
      }
      await axios.post(memoUrl + '/create-bank-payment', obj, { headers: getHeader() })
        .then(response => {
          this.$emit('backParentID', this.memo.id)
          this.$toasted.show(response.data.message, {
            theme: 'toasted-primary',
            position: 'bottom-left',
            duration: 5000,
            icon: 'check',
            type: 'success'
          })
        })
        .catch(error => {
          console.log(error.response)
          this.$toasted.show(error.response.data.message, {
            theme: 'toasted-primary',
            position: 'bottom-left',
            duration: 5000,
            icon: 'check',
            type: 'error'
          })
        })
    },
    async approveBank () {
      var obj = {
        memo_no: this.memo.memo_no
      }
      await axios.post(memoUrl + '/approve-bank-payment', obj, { headers: getHeader() })
        .then(response => {
          this.$toasted.show(response.data.message, {
            theme: 'toasted-primary',
            position: 'bottom-left',
            duration: 5000,
            icon: 'check',
            type: 'success'
          })
        })
        .catch(error => {
          console.log(error.response)
          this.$toasted.show(error.response.data.message, {
            theme: 'toasted-primary',
            position: 'bottom-left',
            duration: 5000,
            icon: 'check',
            type: 'error'
          })
        })
    },
    async getRekeningTujuanData () {
      if (this.memo.supplier_no !== null) {
        var obj = {
          supplier_no: this.memo.supplier_no
        }
        await axios.post(supplierUrl + '/get-by-no', obj, { headers: getHeader() })
          .then(response => {
            const data = response.data.data
            // const account = response.data.data.accounts.find(d => d.id === this.memo.alias_id)
            this.vendor = {
              id: data.id,
              supplier_no: data.supplier_no,
              name: data.name,
              phone: data.phone,
              address: data.address,
              vat: data.vat,
              pkp: data.pkp,
              pic_name: data.pic_name,
              pic_phone: data.pic_phone,
              pic_jabatan: data.pic_jabatan,
              pic_email: data.pic_email,
              accounts: data.accounts
            }
          })
      }
    },
    async reject () {
      var obj = {
        id: this.memo.id,
        status: this.memo.status
      }
      await axios.post(memoUrl + '/inbox/reject', obj, { headers: getHeader() })
        .then(response => {
          this.$toasted.show(response.data.message, {
            theme: 'toasted-primary',
            position: 'bottom-left',
            duration: 5000,
            icon: 'check',
            type: 'success'
          })
          axios.get(memoUrl + '/inbox/notif/reject/' + response.data.data.id, { headers: getHeader() })
            .then(response => {
              this.$toasted.show(response.data.message, {
                theme: 'toasted-primary',
                position: 'bottom-left',
                duration: 5000,
                icon: 'check',
                type: 'success'
              })
            })
            .catch(error => {
              console.log(error.response)
              this.$toasted.show('Failed to send notif approve', {
                theme: 'toasted-primary',
                position: 'bottom-left',
                duration: 5000,
                icon: 'check',
                type: 'error'
              })
            })
          this.backParent()
        })
        .catch(error => {
          console.log(error.response)
        })
    },
    async revise (value) {
      var obj = {
        id: this.memo.id,
        status: this.memo.status,
        notes: value.notes
      }
      await axios.post(memoUrl + '/inbox/revise', obj, { headers: getHeader() })
        .then(response => {
          this.$toasted.show(response.data.message, {
            theme: 'toasted-primary',
            position: 'bottom-left',
            duration: 5000,
            icon: 'check',
            type: 'success'
          })
          axios.get(memoUrl + '/inbox/notif/revise/' + response.data.data.id, { headers: getHeader() })
            .then(response => {
              this.$toasted.show(response.data.message, {
                theme: 'toasted-primary',
                position: 'bottom-left',
                duration: 5000,
                icon: 'check',
                type: 'success'
              })
            })
            .catch(error => {
              console.log(error.response)
              this.$toasted.show('Failed to send notif approve', {
                theme: 'toasted-primary',
                position: 'bottom-left',
                duration: 5000,
                icon: 'check',
                type: 'error'
              })
            })
          this.backParent()
        })
        .catch(error => {
          console.log(error.response)
        })
    },
    async approve () {
      var obj = {
        id: this.memo.id,
        to: this.memo.to,
        status: this.memo.status
      }
      await axios.post(memoUrl + '/inbox/approve', obj, { headers: getHeader() })
        .then(response => {
          this.$toasted.show(response.data.message, {
            theme: 'toasted-primary',
            position: 'bottom-left',
            duration: 5000,
            icon: 'check',
            type: 'success'
          })
          const memo = response.data.data
          console.log('data memo: ')
          console.log(memo)
          if (memo.status === 'finish') {
            axios.get(memoUrl + '/inbox/notif/finish/' + response.data.data.id, { headers: getHeader() })
              .then(response => {
                this.$toasted.show(response.data.message, {
                  theme: 'toasted-primary',
                  position: 'bottom-left',
                  duration: 5000,
                  icon: 'check',
                  type: 'success'
                })
              })
              .catch(error => {
                console.log(error.response)
                this.$toasted.show('Failed to send notif approve', {
                  theme: 'toasted-primary',
                  position: 'bottom-left',
                  duration: 5000,
                  icon: 'check',
                  type: 'error'
                })
              })
          } else {
            axios.get(memoUrl + '/inbox/notif/approve/' + response.data.data.id, { headers: getHeader() })
              .then(response => {
                this.$toasted.show(response.data.message, {
                  theme: 'toasted-primary',
                  position: 'bottom-left',
                  duration: 5000,
                  icon: 'check',
                  type: 'success'
                })
              })
              .catch(error => {
                console.log(error.response)
                this.$toasted.show('Failed to send notif approve', {
                  theme: 'toasted-primary',
                  position: 'bottom-left',
                  duration: 5000,
                  icon: 'check',
                  type: 'error'
                })
              })
          }
          this.backParent()
        })
        .catch(error => {
          console.log(error.response)
        })
    },
    getEmployee () {
      var emp = this.employeeList.find(d => d.employee_no === this.memo.employee_no)
      console.log(emp)
      this.employeeData.bank = emp.bank
      this.employeeData.bank_account_no = emp.bank_account_no
      this.employeeData.bank_account_name = emp.bank_account_name
      console.log(emp.bank)
      console.log(this.employeeData)
    },
    async removeAttachment (id) {
      var index = this.memo.attachments.findIndex(d => d.id === id)
      await axios.delete(memoUrl + '/attachment/' + id, { headers: getHeader() })
        .then(response => {
          this.memo.attachments.splice(index, 1)
          this.$swal(
            'Success',
            response.data.message,
            'success'
          )
        })
        .catch(error => {
          console.log(error.response)
          this.$swal(
            'Error',
            error.response.data.message,
            'error'
          )
        })
    },
    async downloadAttachment (url, filename) {
      window.open(memoUrl + '/attachment/file/' + url, '', 'fullscreen=yes')
    },
    handleFileUpload: function () {
      this.file = this.$refs.file.files[0]
    },
    async upload () {
      const formData = new FormData()
      formData.append('file', this.file)
      if (this.memo.memo_no) {
        formData.append('memo_no', this.memo.memo_no)
      }
      console.log(formData)
      const newHeader = {
        'Content-Type': 'multipart/form-data'
      }
      const newObj = Object.assign(getHeader(), newHeader)
      await axios.post(memoUrl + '/attachment', formData, { headers: newObj })
        .then(response => {
          var data = response.data.data
          var obj = {
            memo_no: data.memo_no,
            filename: data.filename,
            mime_type: data.mime_type,
            extension: data.extension,
            url: data.url,
            id: data.id
          }
          this.memo.attachments.push(obj)
        })
        .catch(error => {
          console.log(error.response)
        })
    },
    validate () {
      var total = 0
      var array = this.memo.details
      for (let i = 0; i < array.length; i++) {
        total += parseFloat(array[i].total)
      }
      if (this.memo.to === null) {
        this.$swal(
          'Error',
          'Approval Cannot be empty',
          'error'
        )
        return false
      }
      if (this.isBudget === true) {
        alert('isBudget true')
        var rb = parseFloat(this.remainingBudget.replace(/\./g, ''))
        if (total > rb) {
          this.$swal(
            'Error',
            'Limit Budget',
            'error'
          )
          return false
        }
      }
    },
    calc: function (index) {
      var detail = this.memo.details[index]
      var qty = parseInt(detail.qty)
      var amount = parseFloat(detail.amount.replace(/\./g, ''))
      var discount = parseFloat(detail.discount.replace(/\./g, ''))
      var tax = parseFloat(detail.tax.replace(/\./g, ''))
      var total = qty * (amount - discount + tax)
      detail.total = total
    },
    removeDetail: function (index) {
      var detail = this.memo.details[index]
      if (detail.id) {
        alert(detail.id)
      } else {
        this.memo.details.splice(index, 1)
      }
    },
    addLine: function () {
      var date = new Date()
      date = ('0' + (date.getDate() + 1)).slice(-2) + '/' + ('0' + (date.getMonth() + 1)).slice(-2) + '/' + date.getFullYear()
      var obj = {
        memo_no: null,
        company_no: null,
        memo_date: date,
        description: null,
        qty: '0',
        amount: '0',
        discount: '0',
        tax: '0',
        total: '0'
      }
      this.memo.details.push(obj)
    },
    async setApproval () {
      var emp = this.employeeList.find(d => d.employee_no === this.memo.from)
      console.log(emp)
      var obj = {
        category: this.memo.category_id,
        create_user: emp.position_id,
        company: this.memo.company_no,
        date: this.memo.memo_date.replaceAll('/', '-')
      }
      console.log(obj)
      console.log(memoUrl + '/path/approval')
      axios.post(memoUrl + '/path/approval', obj, { headers: getHeader() })
        .then(response => {
          console.log(response.data.data[0].employee_no)
          this.approvalList = response.data.data
          this.memo.to = response.data.data[0].employee_no
          this.remainingBudget = response.data.data[0].remaining_budget
          this.isBudget = response.data.data[0].budget
          this.memo.path_id = response.data.data[0].path_id
          this.memo.path = response.data.data[0].path
        })
        .catch(error => {
          console.log(error.response)
          this.approvalList = []
          this.memo.to = null
          this.remainingBudget = 0
          this.isBudget = false
          this.$swal(
            'Error',
            error.response.data.message,
            'error'
          )
        })
    },
    backParent: function () {
      this.$store.dispatch('getMemoInboxNotif')
      this.$emit('backClicked')
    },
    async save () {
      this.validate()
      var memoData = {
        memo_date: this.memo.memo_date.replaceAll('/', '-'),
        from: this.memo.from,
        to: this.memo.to,
        path_id: this.memo.path_id,
        path: this.memo.path,
        subject: this.memo.subject,
        supplier_no: this.memo.supplier_no,
        employee_no: this.memo.employee_no,
        company_no: this.memo.company_no,
        category_id: this.memo.category_id,
        notes: this.memo.notes,
        subtotal: this.getSubtotal,
        discount: this.getDiscount,
        tax: this.getTax,
        total: this.getTotal,
        status: 'on progress',
        lock: false
      }
      var memoNo = null
      var companyNo = null
      await axios.post(memoUrl, memoData, { headers: getHeader() })
        .then(response => {
          console.log(response)
          memoNo = response.data.data.memo_no
          companyNo = response.data.data.company_no
          var array = this.memo.details
          for (let i = 0; i < array.length; i++) {
            var obj = {
              memo_no: memoNo,
              company_no: companyNo,
              memo_date: array[i].memo_date.replaceAll('/', '-'),
              description: array[i].description,
              qty: array[i].qty,
              amount: array[i].amount.replace(/\./g, ''),
              discount: array[i].discount.replace(/\./g, ''),
              tax: array[i].tax.replace(/\./g, ''),
              total: array[i].total
            }
            axios.post(memoUrl + '/detail', obj, { headers: getHeader() })
              .then(response => {
                console.log(response.data.data)
              })
              .catch(error => {
                console.log(error.response)
              })
          }
          var array2 = this.memo.attachments.filter(d => d.id !== null)
          for (let a = 0; a < array2.length; a++) {
            var obj2 = {
              id: array2[a].id,
              memo_no: memoNo,
              filename: array2[a].filename,
              mime_type: array2[a].mime_type,
              extension: array2[a].extension,
              url: array2[a].url
            }
            axios.put(memoUrl + '/attachment/' + array2[a].id, obj2, { headers: getHeader() })
              .then(response => {
                console.log(response.data.data)
              })
              .catch(error => {
                console.log(error.response)
              })
          }
        })
        .catch(error => {
          console.log(error.response)
          this.$swal(
            'Error',
            error.response.data.message,
            'error'
          )
        })
      this.$swal(
        'Success',
        'Success create memo',
        'success'
      )
      this.backParent()
    },
    async update () {
      this.validate()
      axios.put(memoUrl + '/' + this.memo.id, this.memo, { headers: getHeader() })
        .then(response => {
          console.log(response)
          this.$swal(
            'Success',
            response.data.message,
            'success'
          )
          this.backParent()
        })
        .catch(error => {
          console.log(error.response)
          this.$swal(
            'Error',
            error.response.data.message,
            'error'
          )
        })
    }
  }
}
</script>

<style>
.upload-border{
  min-height: 100px;
  border: 2px #ccc dashed;
  padding-top: 10px;
}
.upload-border .filename {
  font-size: 13px;
  letter-spacing: 1px;
  font-weight: 700;
  padding: 8px 20px;
  margin: 10px;
  background: #e7f1fd;
  color: #45A49F;
  display: inline-block;
  /* text-transform: uppercase; */
  border-radius: 10px;
}
.progress-container{
  width: 100%;
  position: absolute;
  z-index: 1;
}
.progressbar li{
  float: left;
  width: 20%;
  position: relative;
  text-align: center;
}
.progressbar{
  counter-reset: step;
}
.progressbar li:before{
  content:counter(step);
  counter-increment: step;
  width: 30px;
  height: 30px;
  border: 2px solid #bebebe;
  display: block;
  margin: 0 auto 10px auto;
  border-radius: 50%;
  line-height: 27px;
  background: white;
  color: #bebebe;
  text-align: center;
  font-weight: bold;
}
.progressbar li:after{
  content: '';
  position: absolute;
  width:100%;
  height: 3px;
  background: #979797;
  top: 15px;
  left: -50%;
  z-index: -1;
}

.progressbar li:first-child:after{
  content: none;
}
.progressbar li.active + li:after{
  background: #3aac5d;
}
.progressbar li.active + li:before{
  border-color: #3aac5d;
  background: #3aac5d;
  color: white
}
</style>
