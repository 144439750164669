<template>
    <div>
        <h5>Integration</h5>
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-12">
                        <div class="table-responsive">
                        <div class="form-inline ml-auto">
                            <div class="button-group">
                                <button type="button" class="btn btn-sm btn-soft-primary" @click="generateKey" data-placement="bottom">
                                    Generate Key
                                </button>
                                <button type="button" class="btn btn-sm  btn-soft-primary waves-light waves-effect" @click="refreshList" data-placement="bottom" title="Refresh">
                                    <i class="fa fa-refresh" aria-hidden="true"></i>
                                </button>
                                rows:
                                <select id="select-rows" v-model="pageSize" @change="setPageSizeOnChange">
                                    <option v-for="r in rowsTable" :key="r.row" :value="r.row">{{ r.row }}</option>
                                </select>
                                <span class="mr-1">
                                    Total rows: <b>{{ filterData.length }}</b>
                                </span>
                            </div>
                            <div class="form-group mx-sm-3 mb-2">
                                <input type="search" placeholder="search..." class="form-control mr-1 input-search" v-model="searchQuery">
                            </div>
                            <span class="mr-1">{{ currentPage }} / {{ pageLength }}</span>
                            <nav aria-label="Page navigation example" class="pull-right">
                                <ul class="pagination">
                                    <li class="page-item">
                                        <a class="btn btn-default btn-sm" href="#" @click="prevPage">
                                            <i class="fa fa-angle-left" aria-hidden="true"></i>
                                        </a>
                                    </li>
                                    <li class="page-item">
                                        <a class="btn btn-default btn-sm" href="#" @click="nextPage">
                                            <i class="fa fa-angle-right" aria-hidden="true"></i>
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                        </div>
                        <table class="table table-item mb-0 table-sm table-striped table-hover">
                          <thead>
                            <tr>
                                <th>
                                  <label class="form-checkbox">
                                    <input type="checkbox" v-model="selectAll" @click="select">
                                    <i class="form-icon"></i>
                                  </label>
                                </th>
                                <th  v-for="t in title" v-bind:key="t.prop" @click="sortTable(t.prop)">
                                    <span v-if="t.name === 'Payment'" class="text-right">
                                    {{ t.name }}
                                    <div class="float-right fa" v-if="t.prop == sortColumn" v-bind:class="ascending ? 'fa-arrow-up' : 'fa-arrow-down'"></div>
                                    </span>

                                    <span v-else>
                                      {{ t.name }}
                                      <div class="float-right fa" v-if="t.prop == sortColumn" v-bind:class="ascending ? 'fa-arrow-up' : 'fa-arrow-down'"></div>
                                    </span>
                                </th>
                                <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="u in filterData" :key="u.id">
                              <td></td>
                              <td>{{ u.api_id }}</td>
                              <td>{{ u.api_date | dateFormat('DD/MM/YYYY') }}</td>
                              <td>{{ u.secret_id }}</td>
                              <td>{{ u.notes }}</td>
                              <td>
                                <button type="button" class="btn btn-sm btn-outline-default" @click="remove(u.id)"><i class="fa fa-trash"></i></button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Axios from 'axios'
import { mapState } from 'vuex'
import { getHeader, integrationApiUrl } from '../config'
export default {
  name: 'IntegrationApi',
  data () {
    return {
      loader: {
        isLoading: false,
        fullPage: true,
        color: '#007bff',
        canCancel: true,
        height: 128,
        width: 128,
        backgroundColor: '#ffffff',
        loader: 'spinner'
      },
      dataRaw: [],
      data: {},
      searchQuery: '',
      ascending: false,
      sortClick: false,
      sortColumn: '',
      pageSize: 10,
      currentPage: 1,
      checkFilter: [],
      title: [{
        name: 'API ID',
        prop: 'api_id'
      }, {
        name: 'Date',
        prop: 'api_date'
      }, {
        name: 'Secret',
        prop: 'secret_id'
      }, {
        name: 'Notes',
        prop: 'notes'
      }],
      selectAll: false,
      selected: []
    }
  },
  computed: {
    ...mapState({
      rowsTable: state => state.data.rowsTable
    }),
    pageLength: function () {
      var length = this.dataRaw.length / this.pageSize
      return Math.ceil(length)
    },
    filterData: function () {
      if (this.searchQuery.length > 0) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.currentPage = 1
      }
      var col = this.sortColumn
      var ascending = this.ascending
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.dataRaw.sort(function (a, b) {
        if (a[col] > b[col]) {
          return ascending ? 1 : -1
        } else if (a[col] < b[col]) {
          return ascending ? -1 : 1
        }
        return 0
      }).filter(d => {
        for (const name in d) {
          if (d[name] !== null) {
            if (d[name].toString().toLowerCase().indexOf(this.searchQuery.toLowerCase()) > -1) {
              return d
            }
          }
        }
      }).filter((row, index) => {
        const start = (this.currentPage - 1) * this.pageSize
        const end = this.currentPage * this.pageSize
        if (index >= start && index < end) return true
      })
    }
  },
  methods: {
    async remove (id) {
      const index = this.dataRaw.findIndex(d => d.id === id)
      await Axios.delete(integrationApiUrl + '/' + id, { headers: getHeader() })
        .then(response => {
          console.log(response.data.data)
          this.$toasted.show(response.data.message, {
            theme: 'toasted-primary',
            position: 'top-right',
            duration: 5000,
            icon: 'check',
            type: 'success'
          })
          this.dataRaw.splice(index, 1)
        })
        .catch(error => {
          console.log(error.response)
          this.$toasted.show(error.response.data.message, {
            theme: 'toasted-primary',
            position: 'top-right',
            duration: 5000,
            icon: 'check',
            type: 'error'
          })
        })
    },
    generateKey: function () {
      this.$Progress.start()
      this.loader.isLoading = true
      Axios.post(integrationApiUrl, {}, { headers: getHeader() })
        .then(response => {
          this.refreshList()
          this.$toasted.show(response.data.message, {
            theme: 'toasted-primary',
            position: 'bottom-left',
            duration: 5000,
            icon: 'check',
            type: 'success'
          })
        })
        .catch(error => {
          console.log(error.response)
          this.$toasted.show(error.response.data.message, {
            theme: 'toasted-primary',
            position: 'bottom-left',
            duration: 5000,
            icon: 'check',
            type: 'error'
          })
        })
    },
    setPageSizeOnChange: function () {
      this.currentPage = 1
    },
    sortTable: function sortTable (col) {
      this.currentPage = 1
      if (this.sortColumn === col) {
        this.ascending = !this.ascending
      } else {
        this.ascending = true
        this.sortColumn = col
      }
    },
    nextPage: function () {
      if ((this.currentPage * this.pageSize) < this.dataRaw.length) this.currentPage++
    },
    prevPage: function () {
      if (this.currentPage > 1) this.currentPage--
    },
    select () {
      this.selected = []
      this.currentPage = 1
      if (!this.selectAll) {
        for (const i in this.filterData) {
          this.selected.push(this.filterData[i].id)
        }
      }
    },
    refreshList () {
      this.$Progress.start()
      this.loader.isLoading = true
      var url = integrationApiUrl
      Axios.get(url, { headers: getHeader() })
        .then(response => {
          this.dataRaw = response.data.data
          this.$Progress.finish()
          this.loader.isLoading = false
          this.currentPage = 1
          this.searchQuery = ''
        })
        .catch(error => {
          console.log(error.response)
          this.$swal(
            'Error',
            error.response.data.message,
            'error'
          )
        })
    }
  },
  mounted () {
    this.refreshList()
  }
}
</script>
