import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import locale from 'element-ui/lib/locale/lang/en'
import VueSlimScroll from 'vue-slimscroll'
import VueProgressBar from 'vue-progressbar'
import money from 'v-money'
import VueTheMask from 'vue-the-mask'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import Datetime from 'vue-datetime'
// You need a specific loader for CSS files
import 'vue-datetime/dist/vue-datetime.css'
import { dateFilter } from 'vue-date-fns'
import Notifications from 'vue-notification'
import AirbnbStyleDatepicker from 'vue-airbnb-style-datepicker'
import 'vue-airbnb-style-datepicker/dist/vue-airbnb-style-datepicker.min.css'
import VueBarcodeScanner from 'vue-barcode-scanner'
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'
import VueRangedatePicker from 'vue-rangedate-picker'
// import VueFusionCharts from 'vue-fusioncharts'
// import FusionCharts from 'fusioncharts'
// import Column2D from 'fusioncharts/fusioncharts.charts'
// import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion'
import zingchartVue from 'zingchart-vue'
import wysiwyg from 'vue-wysiwyg'
import 'vue-wysiwyg/dist/vueWysiwyg.css'
import Toasted from 'vue-toasted'
import Vidle from 'v-idle'
import ECharts from 'vue-echarts'
import 'echarts/lib/chart/bar'
import 'echarts/lib/component/tooltip'

Vue.use(Datetime)
Vue.component('v-select', vSelect)
Vue.use(VueTheMask)
Vue.use(money, { precision: 4 })
Vue.use(VueProgressBar, {
  color: 'rgb(57, 227, 139)',
  failedColor: 'red',
  height: '5px'
})
Vue.use(ElementUI, { locale })
Vue.use(VueSlimScroll)
Vue.use(Notifications)
Vue.use(VueSweetalert2)

// Currency Filter
Vue.filter('currency', value => {
  if (value === null) {
    return '0'
  } else if (typeof value === 'string') {
    return parseFloat(value).toLocaleString('id-ID')
  } else {
    return value.toLocaleString('id-ID')
  }
})
Vue.filter('dateFormat', dateFilter)

Vue.filter('badge', value => {
  return '<span class="badge badge-success">' + value + '</span>'
})

Vue.filter('capitalize', value => {
  if (!value) return ''
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
})
// Barcode Scanner
// const optionBarcode = {
//   sound: false, // default is false
//   soundSrc: '/static/sound.wav', // default is blank
//   sensitivity: 300, // default is 100
//   requiredAttr: true, // default is false
//   controlSequenceKeys: ['NumLock', 'Clear'], // default is null
//   callbackAfterTimeout: false // default is false
// }
Vue.use(VueBarcodeScanner)
// Vue.use(VueBarcodeScanner, optionBarcode)
// see docs for available options
const datepickerOptions = {}
// make sure we can use it in our components
Vue.use(AirbnbStyleDatepicker, datepickerOptions)
Vue.use(VueRangedatePicker)

// chart fusion
// Vue.use(VueFusionCharts, FusionCharts, Column2D, FusionTheme)
// Zingchart
Vue.component('zingchart', zingchartVue)

Vue.use(wysiwyg, {}) // config is optional. more below

Vue.use(Toasted, {
  iconPack: 'fontawesome' // set your iconPack, defaults to material. material|fontawesome|custom-class
})

Vue.use(Vidle)

Vue.component('v-chart', ECharts)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  components: {
    Notifications
  },
  render: h => h(App)
}).$mount('#app')
