<template>
  <div>
    <loading
      :active.sync="loader.isLoading"
      :can-cancel="loader.canCancel"
      :is-full-page="loader.fullPage"
      :color="loader.color"
      :height="loader.height"
      :width="loader.width"
      :background-color="loader.backgroundColor"
      :loader="loader.loader">
      </loading>
    <div class="page-title-box">
      <div class="float-right">
        <div class="form-inline ml-auto">
          <span class="mr-2">Company</span>
          <select v-model="company" class="form-control" v-if="authUser.permissions.dashboard_chart_memo_category_by_company === false" disabled="disabled">
            <option v-for="c in companyList" :key="c.id" :value="c.company_no">{{ c.name }}</option>
          </select>
          <select v-model="company" class="form-control" v-else-if="authUser.permissions.dashboard_chart_memo_category_by_company === true">
            <option v-for="c in companyList" :key="c.id" :value="c.company_no">{{ c.name }}</option>
          </select>
          <span class="ml-2 mr-2">Date range:</span>
          <date-picker v-model="startDate" type="date" range placeholder="Select date range"></date-picker>
          <button type="button" class="btn btn-sm btn-primary ml-2" @click="searchDashboard">
            <i class="fa fa-search" aria-hidden="true"></i>
          </button>
        </div>
      </div>
      <h4>{{ authUser.greetings}}, {{ authUser.shortname }} !!!</h4>
    </div>
    <div class="row justify-content-center mt-5">
      <div class="col-md-4">
        <div class="card report-card">
          <div class="card-body">
            <div class="float-right">
              <i class="lni lni-investment"></i>
            </div>
            <span class="badge badge-primary">Total Finish</span>
            <h3 class="my-3">
              <!-- <span v-if="loadingSales === false">
                <div class="loadingio-spinner-bars-km984sg9h2">
                  <div class="ldio-m41ff6uvz8">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </div>
              </span> -->
              <span>
                {{ totalFinish | currency }}
              </span>
            </h3>
          </div>
          <!--end card-body-->
        </div>
        <!--end card-->
      </div>
      <div class="col-md-4">
        <div class="card report-card">
          <div class="card-body">
            <div class="float-right">
              <i class="lni lni-pie-chart"></i>
            </div>
            <span class="badge badge-success">Total Progress</span>
            <h3 class="my-3">
              <!-- <span v-if="loadingSales === false">
                <div class="loadingio-spinner-bars-km984sg9h2">
                  <div class="ldio-m41ff6uvz8">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </div>
              </span> -->
              <span>
                {{ totalProgress | currency }}
              </span>
            </h3>
          </div>
          <!--end card-body-->
        </div>
        <!--end card-->
      </div>
      <div class="col-md-4">
        <div class="card report-card">
          <div class="card-body">
            <div class="float-right">
              <i class="lni lni-notepad"></i>
            </div>
            <span class="badge badge-danger">Total Reject</span>
            <h3 class="my-3">
              <!-- <span v-if="loadingSales === false">
                <div class="loadingio-spinner-bars-km984sg9h2">
                  <div class="ldio-m41ff6uvz8">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </div>
              </span> -->
              <span>
                {{ totalReject | currency }}
              </span>
            </h3>
          </div>
          <!--end card-body-->
        </div>
        <!--end card-->
      </div>
    </div>
    <div class="row mt-1">
      <div class="col-md-8">
        <div class="card">
          <div class="card-body">
            <v-chart class="chart2" :option="option3"/>
          </div>
        </div>
        <div class="card" v-if="authUser.permissions.dashboard_chart_memo_category_by_company">
          <div class="card-body">
            <v-chart class="chart2" :option="optionCompany"/>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card">
          <div class="card-body">
            <h5>Memo Need Approval & Revise</h5>
            <ul id="step" v-if="memoInboxNotif.length === 0">
              <li><i>No Data</i></li>
            </ul>
            <ul id="step" v-for="i in memoInboxNotif" :key="i.id" v-else>
              <li v-if="i.status !== 'revise'">
                <div class="col-md-8">
                <span>{{ i.memo_date | dateFormat('DD/MM/YYYY') }} - <b>{{ i.memo_no }}</b></span> {{ i.status }}<br>
                <span><b>{{ i.subject }}</b></span><br>
                <span>{{ i.from }} - {{ i.user_to }}</span><br>
                <span><b>{{ i.total | currency }}</b></span>
                </div>
                <div class="col-md-4">
                  <button class="btn btn-sm btn-soft-primary" type="button" @click="toInbox(i.id)" v-if="i.status !== 'approve' && i.status !== 'finish'">Approve</button>
                </div>
              </li>
              <li v-else>
                <div class="col-md-8">
                <span>{{ i.memo_date | dateFormat('DD/MM/YYYY') }} - <b>{{ i.memo_no }}</b></span><br>
                <span><b>{{ i.subject }}</b></span><br>
                <span><b>{{ i.total | currency }}</b></span>
                </div>
                <div class="col-md-4">
                  <button class="btn btn-sm btn-warning" type="button" @click="toRevise(i.id)" >Revise</button>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card" v-if="authUser.permissions.dashboard_chart_memo_category_by_company">
          <div class="card-body">
            <v-chart class="chart" :option="option2" />
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
          </div>
        </div>
      </div>
    </div>
    <!-- <pre>{{ option2 }}</pre> -->
    <!-- <pre>{{ option3 }}</pre> -->
  </div>
</template>

<script>
import { mapState } from 'vuex'
import 'vue2-datepicker/index.css'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import { getHeader, reportUrl } from '../config'
import Axios from 'axios'
import DatePicker from 'vue2-datepicker'
import { use } from 'echarts/core'
import { CanvasRenderer } from 'echarts/renderers'
import { PieChart, BarChart } from 'echarts/charts'
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent
} from 'echarts/components'
import VChart, { THEME_KEY } from 'vue-echarts'

use([
  CanvasRenderer,
  PieChart,
  BarChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent
])

export default {
  name: 'Home',
  components: {
    Loading,
    VChart,
    DatePicker
  },
  provide: {
    [THEME_KEY]: 'light'
  },
  data () {
    var date = new Date()
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0)
    const date1 = new Date(firstDay.getFullYear(), firstDay.getMonth(), firstDay.getDate())
    const date2 = new Date(lastDay.getFullYear(), lastDay.getMonth(), lastDay.getDate())
    return {
      company: null,
      loadedChart: false,
      chartdata: {
        labels: [],
        datasets: []
      },
      optionCompany: {},
      option2: {},
      option3: {
        title: {
          text: 'Traffic Sources',
          left: 'center'
        },
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)'
        },
        legend: {
          orient: 'vertical',
          left: 'right',
          data: [
            'Direct',
            'Email',
            'Ad Networks',
            'Video Ads',
            'Search Engines'
          ]
        },
        series: [
          {
            name: 'Traffic Sources',
            type: 'pie',
            radius: '55%',
            center: ['50%', '60%'],
            data: [
              { value: 335, name: 'Direct' },
              { value: 310, name: 'Email' },
              { value: 234, name: 'Ad Networks' },
              { value: 135, name: 'Video Ads' },
              { value: 1548, name: 'Search Engines' }
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: true,
          position: 'bottom'
        },
        tooltips: {
          callbacks: {
            label: function (tooltipItem, data) {
              console.log(data)
              var label = data.labels[tooltipItem.index]
              var total = data.datasets[0].data[tooltipItem.index]
              if (total === null) {
                return label + ': ' + '0'
              } else if (typeof total === 'string') {
                return label + ': ' + parseFloat(total).toLocaleString('id-ID')
              } else {
                return label + ': ' + total.toLocaleString('id-ID')
              }
            }
          }
        }
      },
      loader: {
        isLoading: false,
        fullPage: true,
        color: '#41A29D',
        canCancel: true,
        height: 128,
        width: 128,
        backgroundColor: '#ffffff',
        loader: 'spinner'
      },
      loadingSales: false,
      startDate: [date1, date2],
      totalFinish: '0',
      totalProgress: '0',
      totalReject: '0'
    }
  },
  created () {
    this.getData()
  },
  async mounted () {
    this.$store.dispatch('getCompanyList')
    this.$store.dispatch('getMemoInboxNotif')
    const tokenData = JSON.parse(window.localStorage.getItem('authUser'))
    if (tokenData) {
      this.company = tokenData.user.company_no
    }
    this.searchDashboard()
  },
  methods: {
    toRevise (id) {
      this.$router.replace({ path: '/memo', query: { id: id } })
    },
    toInbox (id) {
      this.$router.replace({ path: '/memo/inbox', query: { id: id } })
    },
    async searchDashboard () {
      await this.$Progress.start()
      this.loader.isLoading = true
      await this.getData()
      await this.getChartMemoMonthly()
      await this.getChartMemoCategory()
      await this.getChartMemoCategoryCompany()
      await this.$store.dispatch('getMemoInboxNotif')
      await this.$Progress.finish()
      this.loader.isLoading = false
    },
    async getChartMemoMonthly () {
      var obj = {
        company: this.company
      }
      await Axios.post(reportUrl + '/memo-total', obj, { headers: getHeader() })
        .then(response => {
          this.option2 = {
            title: {
              text: 'Total Expense (Memo)',
              left: 'center'
            },
            tooltip: {
              trigger: 'axis'
            },
            xAxis: {
              type: 'category',
              data: response.data.label
            },
            yAxis: {
              type: 'value'
            },
            series: [{
              data: response.data.data,
              type: 'bar',
              showBackground: true,
              backgroundStyle: {
                color: 'rgba(180, 180, 180, 0.2)'
              }
            }]
          }
        })
        .catch(error => {
          console.log(error.response)
        })
    },
    async getChartMemoCategoryCompany () {
      const begin = this.startDate[0] ? this.startDate[0].getFullYear() + '-' + (this.startDate[0].getMonth() + 1) + '-' + this.startDate[0].getDate() : null
      const end = this.startDate[1] ? this.startDate[1].getFullYear() + '-' + (this.startDate[1].getMonth() + 1) + '-' + this.startDate[1].getDate() : null
      var obj = {
        company: this.company,
        begin: begin,
        end: end,
        status: 'match',
        employee: null
      }
      await Axios.post(reportUrl + '/memo-by-category', obj, { headers: getHeader() })
        .then(response => {
          console.log(response.data.data)
          this.optionCompany = {
            title: {
              text: 'Memo By Category Company',
              left: 'center'
            },
            tooltip: {
              trigger: 'item',
              formatter: '{a} <br/>{b} : {c} ({d}%)'
            },
            legend: {
              type: 'scroll',
              orient: 'vertical',
              left: 10,
              data: response.data.label
            },
            series: [
              {
                name: 'Total Memo',
                type: 'pie',
                radius: '55%',
                center: ['50%', '60%'],
                data: response.data.data2,
                emphasis: {
                  itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                  }
                }
              }
            ]
          }
        })
        .catch(error => {
          console.log(error.response)
        })
    },
    async getChartMemoCategory () {
      const begin = this.startDate[0] ? this.startDate[0].getFullYear() + '-' + (this.startDate[0].getMonth() + 1) + '-' + this.startDate[0].getDate() : null
      const end = this.startDate[1] ? this.startDate[1].getFullYear() + '-' + (this.startDate[1].getMonth() + 1) + '-' + this.startDate[1].getDate() : null
      var obj = {
        company: this.company,
        begin: begin,
        end: end,
        status: 'match',
        employee: this.authUser.username
      }
      await Axios.post(reportUrl + '/memo-by-category', obj, { headers: getHeader() })
        .then(response => {
          console.log(response.data.data)
          this.option3 = {
            title: {
              text: 'Memo By Category (user)',
              left: 'center'
            },
            tooltip: {
              trigger: 'item',
              formatter: '{a} <br/>{b} : {c} ({d}%)'
            },
            legend: {
              type: 'scroll',
              orient: 'vertical',
              left: 10,
              data: response.data.label
            },
            series: [
              {
                name: 'Total Memo',
                type: 'pie',
                radius: '55%',
                center: ['50%', '60%'],
                data: response.data.data2,
                emphasis: {
                  itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                  }
                }
              }
            ]
          }
        })
        .catch(error => {
          console.log(error.response)
        })
    },
    async getChartMemo () {
      // var company = this.authUser.company_no
      var employee = this.authUser.username
      console.log(reportUrl + '/memo-by-category?employee=' + employee)
      await Axios.get(reportUrl + '/memo-by-category?employee=' + employee, { headers: getHeader() })
        .then(response => {
          console.log(response)
          this.chartdata = {
            labels: response.data.label,
            datasets: [{
              label: 'Memo',
              backgroundColor: ['#f87979'],
              data: response.data.data
            }]
          }
        })
        .catch(error => {
          console.log(error.response)
        })
    },
    getData () {
      const begin = this.startDate[0] ? this.startDate[0].getFullYear() + '-' + (this.startDate[0].getMonth() + 1) + '-' + this.startDate[0].getDate() : null
      const end = this.startDate[1] ? this.startDate[1].getFullYear() + '-' + (this.startDate[1].getMonth() + 1) + '-' + this.startDate[1].getDate() : null
      var url = reportUrl + '?begin=' + begin + '&end=' + end + '&user=true'
      Axios.get(url, { headers: getHeader() })
        .then(response => {
          console.log(response.data.data)
          this.totalFinish = response.data.data.total_finish
          this.totalProgress = response.data.data.total_on_progress
          this.totalReject = response.data.data.total_reject
        })
        .catch(error => {
          console.log(error.response)
        })
    }
  },
  computed: {
    ...mapState({
      authUser: state => state.users.authUser,
      companyList: state => state.companies.companyList,
      memoInboxNotif: state => state.memos.memoInboxNotif
    })
  }
}
</script>

<style type="text/css">
#step {
  max-width: 400px;
  margin: 0 auto;
  list-style-type: none;
  counter-reset: steps;
  margin: 0;
  font-family: sans-serif;
}
#step li {
  padding: 0 0 20px 50px;
  position: relative;
  margin: 0;
}
#step li:after {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  counter-increment: steps;
  border: 2px solid #41A29D;
  border-radius: 50%;
  display: inline-block;
  height: 24px;
  width: 24px;
  text-align: center;
  line-height: 24px;
  background: #41A29D;
}
#step li:before {
  position: absolute;
  left: 12px;
  top: 0;
  content: "";
  height: 100%;
  width: 0;
  border-left: 1px solid rgb(78, 78, 78) !important;
}
#step li:last-of-type:before {
  border: none;
}

.chart {
  height: 300px;
}
.chart2 {
  height: 400px;
}
.report-card .badge{
  font-size: 20px;
}
.report-card i {
  font-size: 60px;
  color: #41A29D;
}
@keyframes ldio-m41ff6uvz8 {
  0% { opacity: 1 }
  50% { opacity: .5 }
  100% { opacity: 1 }
}
.ldio-m41ff6uvz8 div {
  position: absolute;
  width: 20px;
  height: 80px;
  top: 60px;
  animation: ldio-m41ff6uvz8 1s cubic-bezier(0.5,0,0.5,1) infinite;
}.ldio-m41ff6uvz8 div:nth-child(1) {
  transform: translate(30px,0);
  background: #e15b64;
  animation-delay: -0.6s;
}.ldio-m41ff6uvz8 div:nth-child(2) {
  transform: translate(70px,0);
  background: #f47e60;
  animation-delay: -0.4s;
}.ldio-m41ff6uvz8 div:nth-child(3) {
  transform: translate(110px,0);
  background: #f8b26a;
  animation-delay: -0.2s;
}.ldio-m41ff6uvz8 div:nth-child(4) {
  transform: translate(150px,0);
  background: #abbd81;
  animation-delay: -1s;
}
.loadingio-spinner-bars-km984sg9h2 {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
  background: #ffffff;
}
.ldio-m41ff6uvz8 {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-m41ff6uvz8 div { box-sizing: content-box; }
/* generated by https://loading.io/ */
</style>
