<template>
<div>
    <loading
        :active.sync="loader.isLoading"
        :can-cancel="loader.canCancel"
        :is-full-page="loader.fullPage"
        :color="loader.color"
        :height="loader.height"
        :width="loader.width"
        :background-color="loader.backgroundColor"
        :loader="loader.loader">
        </loading>
    <div class="row">
        <div class="col-md-6">
            <div class="form-group row">
                <label for="category" class="col-sm-3 col-form-label align-label text-right">Category</label>
                <div class="col-sm-9">
                    <select v-model="category" class="form-control">
                        <option v-for="c in selectMemoCategory" :key="c.id" :value="c.id">{{ c.name }}</option>
                    </select>
                </div>
            </div>
            <div class="form-group row">
                <label for="startdate" class="col-sm-3 col-form-label align-label text-right">Date</label>
                <div class="col-sm-9">
                    <date-picker v-model="startDate" type="date" range placeholder="Select date range"></date-picker>
                </div>
            </div>
            <div class="form-group row">
                <label for="company" class="col-sm-3 col-form-label align-label text-right">Company</label>
                <div class="col-sm-9">
                    <select v-model="company" class="form-control" @change="setCompany">
                        <option v-for="c in selectCompany" :key="c.id" :value="c.company_no">{{ c.name }}</option>
                    </select>
                </div>
            </div>
            <div class="form-group row">
                <label for="position_id" class="col-sm-3 col-form-label align-label text-right">Position</label>
                <div class="col-sm-9">
                    <select v-model="position" class="form-control" disabled="disabled">
                      <option v-for="p in positionList" :key="p.id" :value="p.id">[{{ p.id }}] {{ p.name }}</option>
                    </select>
                  </div>
            </div>
            <div class="form-group row">
                <label for="search" class="col-sm-3 col-form-label align-label text-right">&nbsp;</label>
                <div class="col-sm-9">
                    <button type="button" class="btn btn-sm btn-primary" @click="refreshList">Search</button>
                </div>
            </div>
        </div>
        <div class="col-md-6">
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-md-12">
            <!-- div.memoCategoryList -->
            <div class="table-responsive">
                <div class="form-inline ml-auto">
                    <div class="button-group">
                        <button type="button" class="btn btn-outline-soft-primary btn-sm dropdown-toggle" data-toggle="dropdown">
                            <i class="fa fa-filter" aria-hidden="true"></i>
                              Filter
                              <span class="caret"></span>
                        </button>
                        <ul class="dropdown-menu p-2" >
                            <span>Company</span>
                            <div class="dropdown-divider"></div>
                            <li v-for="c in companyList" :key="c.id">
                              <a href="javascript:void(0)" class="small" tabIndex="-1"><input type="checkbox" v-model="filterCompany" :value="c.company_no"/> {{ c.name }}</a>
                            </li>
                            <li>
                              <a href="javascript:void(0)"  class="small" tabIndex="-1" @click="uncheckfilter"><i style="color: blue">Uncheck All</i></a>
                            </li>
                        </ul>
                        rows:
                        <select id="select-rows" v-model="pageSize" @change="setPageSizeOnChange">
                            <option v-for="r in rowsTable" :key="r.row" :value="r.row">{{ r.row }}</option>
                        </select>
                        <span class="mr-1">
                            Total rows: <b>{{ filterData.length }}</b>
                        </span>
                    </div>
                    <div class="form-group mx-sm-3 mb-2">
                        <input type="search" placeholder="search..." class="form-control mr-1 input-search" v-model="searchQuery">
                    </div>
                    <span class="mr-1">{{ currentPage }} / {{ pageLength }}</span>
                    <nav aria-label="Page navigation example" class="pull-right">
                        <ul class="pagination">
                            <li class="page-item">
                                <a class="btn btn-default btn-sm" href="javascript:void(0)" @click="prevPage">
                                  <i class="fa fa-angle-left" aria-hidden="true"></i>
                                </a>
                            </li>
                            <li class="page-item">
                                <a class="btn btn-default btn-sm" href="javascript:void(0)" @click="nextPage">
                                  <i class="fa fa-angle-right" aria-hidden="true"></i>
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>
                <table class="table table-item mb-0 table-sm table-striped table-hover">
                    <thead>
                        <tr>
                            <th v-for="t in title" v-bind:key="t.prop" @click="sortTable(t.prop)">
                                <span v-if="t.name === 'Payment'" class="text-right">
                                {{ t.name }}
                                <div class="float-right fa" v-if="t.prop == sortColumn" v-bind:class="ascending ? 'fa-arrow-up' : 'fa-arrow-down'"></div>
                                </span>

                                <span v-else>
                                    {{ t.name }}
                                    <div class="float-right fa" v-if="t.prop == sortColumn" v-bind:class="ascending ? 'fa-arrow-up' : 'fa-arrow-down'"></div>
                                </span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-if="filterData.length < 1">
                            <td colspan="11" style="text-align: center;"><i>No Data</i></td>
                        </tr>
                        <tr v-for="u in filterData" v-bind:key="u.id" style="cursor: pointer">
                            <td>
                                <span class="badge badge-success" v-if="u.status === 'on progress'">{{ u.status }}</span>
                                <span class="badge badge-warning" v-else-if="u.status === 'revise'">{{ u.status }}</span>
                                <span class="badge badge-danger" v-else-if="u.status === 'reject'">{{ u.status }}</span>
                                <span class="badge badge-primary" v-else>{{ u.status }}</span>
                            </td>
                            <td><b>{{ u.memo_no }}</b></td>
                            <td>{{ u.memo_date | dateFormat('DD/MM/YYYY') }}</td>
                            <td>{{ u.category }}</td>
                            <td>{{ u.subject }}</td>
                            <td class="text-right">{{ u.total | currency }}</td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td class="text-right"><b>Budget</b></td>
                            <td class="text-right"><b>{{ getOutstanding | currency }}</b></td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
      <!-- <pre>{{ authUser }}</pre> -->
      <!-- <pre>{{ dataRaw }}</pre> -->
    </div>
</div>
</template>

<script>
import axios from 'axios'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import { memoUrl, getHeader } from '../../config'
import { mapState } from 'vuex'
import DatePicker from 'vue2-datepicker'

export default {
  name: 'MemoBudgetPrepaymentList',
  components: {
    Loading,
    DatePicker
  },
  data () {
    var date = new Date()
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0)
    const date1 = new Date(firstDay.getFullYear(), firstDay.getMonth(), firstDay.getDate())
    const date2 = new Date(lastDay.getFullYear(), lastDay.getMonth(), lastDay.getDate())
    return {
      position: null,
      company: null,
      category: null,
      filterCompany: [],
      filterStatus: [],
      startDate: [date1, date2],
      loader: {
        isLoading: false,
        fullPage: true,
        color: '#007bff',
        canCancel: true,
        height: 128,
        width: 128,
        backgroundColor: '#ffffff',
        loader: 'spinner'
      },
      dataRaw: [],
      data: {},
      searchQuery: '',
      ascending: false,
      sortClick: false,
      sortColumn: 'memo_date',
      pageSize: 100,
      currentPage: 1,
      checkFilter: [],
      title: [{
        name: 'Status',
        prop: 'status'
      }, {
        name: 'Memo No.',
        prop: 'memo_no'
      }, {
        name: 'Date',
        prop: 'memo_date'
      }, {
        name: 'Category',
        prop: 'category'
      }, {
        name: 'Subject',
        prop: 'subject'
      }, {
        name: 'Total',
        prop: 'total'
      }],
      selectAll: false,
      selectAll2: false,
      selected: [],
      selected2: []
    }
  },
  mounted () {
    window.scrollTo(0, 0)
  },
  computed: {
    ...mapState({
      authUser: state => state.users.authUser,
      rowsTable: state => state.data.rowsTable,
      companyList: state => state.companies.companyList,
      memoCategoryList: state => state.memos.memoCategoryList,
      positionList: state => state.employees.positionList
    }),
    getOutstanding () {
      var totalBudget = 0
      var total = 0
      var array = this.dataRaw
      for (let i = 0; i < array.length; i++) {
        if (array[i].status === 'budget') {
          totalBudget += array[i].total
        } else if (array[i].status === 'on progress') {
          total += array[i].total
        }
      }
      return totalBudget - total
    },
    selectCompany () {
      var company = this.authUser.company
      return company
    },
    selectMemoCategory () {
      var cat = this.memoCategoryList.filter(d => d.prepayment === true)
      return cat
    },
    pageLength: function () {
      var length = this.dataRaw.length / this.pageSize
      return Math.ceil(length)
    },
    filterData: function () {
      if (this.searchQuery.length > 0) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.currentPage = 1
      }
      var col = this.sortColumn
      var ascending = this.ascending
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.dataRaw.sort(function (a, b) {
        if (a[col] > b[col]) {
          return ascending ? 1 : -1
        } else if (a[col] < b[col]) {
          return ascending ? -1 : 1
        }
        return 0
      }).filter(d => {
        for (const name in d) {
          if (d[name] !== null) {
            if (d[name].toString().toLowerCase().indexOf(this.searchQuery.toLowerCase()) > -1) {
              return d
            }
          }
        }
      })
    }
  },
  methods: {
    setCompany () {
      const user = this.authUser.position.find(d => d.company_no === this.company)
      this.position = user.position_id
    },
    uncheckfilter: function () {
      this.filterCompany = []
    },
    setPageSizeOnChange: function () {
      this.currentPage = 1
    },
    sortTable: function sortTable (col) {
      this.currentPage = 1
      if (this.sortColumn === col) {
        this.ascending = !this.ascending
      } else {
        this.ascending = true
        this.sortColumn = col
      }
    },
    nextPage: function () {
      if ((this.currentPage * this.pageSize) < this.dataRaw.length) this.currentPage++
    },
    prevPage: function () {
      if (this.currentPage > 1) this.currentPage--
    },
    select () {
      this.selected = []
      this.currentPage = 1
      if (!this.selectAll) {
        for (const i in this.filterData) {
          this.selected.push(this.filterData[i].id)
        }
      }
    },
    async refreshList () {
      this.$Progress.start()
      this.loader.isLoading = true
      const begin = this.startDate[0] ? this.startDate[0].getFullYear() + '-' + (this.startDate[0].getMonth() + 1) + '-' + this.startDate[0].getDate() : null
      const end = this.startDate[1] ? this.startDate[1].getFullYear() + '-' + (this.startDate[1].getMonth() + 1) + '-' + this.startDate[1].getDate() : null
      var user = this.authUser.username
      var url = memoUrl + '/budget-prepayment'
      var obj = {
        begin: begin,
        end: end,
        user: user,
        company: this.company,
        create_user: this.position,
        category: this.category
      }
      console.log(obj)
      await axios.post(url, obj, { headers: getHeader() })
        .then(response => {
          this.dataRaw = response.data.data
          this.$Progress.finish()
          this.loader.isLoading = false
          this.currentPage = 1
          this.searchQuery = ''
          this.sortColumn = 'memo_date'
          this.ascending = true
        })
        .catch(error => {
          console.log(error.response)
          this.$swal(
            'Error',
            error.response.data.message,
            'error'
          )
        })
    }
  }
}
</script>
