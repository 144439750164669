import axios from 'axios'
import { getHeader, companyUrl, companyListUrl } from './../config'

const state = {
  company: [],
  companyList: []
}

const mutations = {
  SET_COMPANY_LIST (state, obj) {
    state.companyList = obj
  },
  SET_COMPANY (state, obj) {
    state.company = obj
  }
}

const actions = {
  getCompanyList: ({ commit }) => {
    console.log(companyUrl)
    axios.get(companyUrl, { headers: getHeader() })
      .then(response => {
        // console.log(response.data.data)
        commit('SET_COMPANY_LIST', response.data.data)
      })
  },
  getCompany: ({ commit }) => {
    axios.get(companyListUrl)
      .then(response => {
        commit('SET_COMPANY', response.data.data)
      })
  }
}

export default {
  state, mutations, actions
}
