<template>
  <div class="home">
    <NotificationTemplateList v-show="listView" @newTemplate="onClickNewFromList" @editTemplate="onClickEditFromList" ref="refreshList"/>
    <NotificationTemplateForm v-show="formView" :template="template" @backClicked="onBackChild" @backParentID="backParentID"/>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios'
import NotificationTemplateList from '@/components/notif/NotificationTemplateList.vue'
import NotificationTemplateForm from '@/components/notif/NotificationTemplateForm.vue'
import { getHeader, notifUrl } from '../config'
import { mapState } from 'vuex'

export default {
  name: 'NotificationTemplate',
  components: {
    NotificationTemplateList,
    NotificationTemplateForm
  },
  data () {
    return {
      listView: true,
      formView: false,
      template: {
        id: null,
        template_no: null,
        name: null,
        message: null,
        url: null,
        api_key: null,
        type: null
      }
    }
  },
  computed: {
    ...mapState({
      authUser: state => state.users.authUser
    })
  },
  methods: {
    backParentID: function (id) {
      axios.get(notifUrl + '/' + id, { headers: getHeader() })
        .then(response => {
          this.listView = false
          this.formView = true
          this.employee = response.data.data
        })
        .catch(error => {
          console.log(error.response)
          this.$swal(
            'Error',
            error.response.data.message,
            'error'
          )
        })
    },
    onClickEditFromList: function (value) {
      this.listView = false
      this.formView = true
      this.template = value
    },
    getEdit: function (value) {
      console.log(notifUrl + '/' + value)
      axios.get(notifUrl + '/' + value, { headers: getHeader() })
        .then(response => {
          console.log(response.data.data)
          this.listView = false
          this.formView = true
          this.template = response.data.data
        })
        .catch(error => {
          console.log(error.response)
        })
    },
    onClickNewFromList: function () {
      this.listView = false
      this.formView = true
      this.template = {
        id: null,
        template_no: null,
        name: null,
        message: null,
        url: null,
        api_key: null,
        type: null
      }
    },
    onBackChild: function () {
      this.listView = true
      this.formView = false
      this.template = {
        id: null,
        template_no: null,
        name: null,
        message: null,
        url: null,
        api_key: null,
        type: null
      }
      this.$refs.refreshList.refreshList()
    }
  },
  mounted () {
    this.$store.dispatch('getRowTable')
  }
}
</script>
