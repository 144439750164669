export const domain = 'http://89.116.179.179:8005/'
export const apiDomain = domain + 'api/'
export const domain2 = 'http://89.116.179.179:8888/'
export const apiDomain2 = domain2 + 'api/'

export const companyListUrl = domain + 'company'
// prefix api
export const logUrl = apiDomain + 'log'
export const lockUrl = apiDomain + 'lock'
export const loginUrl = domain + 'auth/login'
export const userUrl = apiDomain + 'user'
export const roleUrl = apiDomain + 'role'
export const employeeUrl = apiDomain + 'employee'
export const memoUrl = apiDomain + 'memo'
export const supplierUrl = apiDomain + 'supplier'
export const companyUrl = apiDomain + 'company'
export const bankUrl = apiDomain + 'bank'
export const reportUrl = apiDomain + 'report'
export const syncUrl = apiDomain + 'sync'
export const integrationApiUrl = apiDomain + 'integration-api'
export const notifUrl = apiDomain + 'notification'

export const memo2Url = apiDomain2 + 'memo'

export const getHeader = function () {
  const tokenData = JSON.parse(window.localStorage.getItem('authUser'))

  const headers = {
    Accept: 'application/json',
    Authorization: 'Bearer ' + tokenData.token
  }

  return headers
}
