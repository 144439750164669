<template>
  <div class="row login-page">
    <div class="col-md-6 image-right">
      <div class="form-login">
        <div class="middle-box col-md-10 md-auto">
          <div class="card">
              <div class="card-body p-4" id="card-login">
                  <h1>Hello, {{ authUser.shortname }}</h1>
                  <p class="login-description mb-1"><b>Please reset your password</b></p>

                  <form action="#" class="mt-5">
                      <div class="form-group">
                          <label class="float-left" for="old_password">Old Password</label>
                          <input class="form-control" type="password" required="" id="password" placeholder="Old password" v-model="login.old_password">
                      </div>
                      <div class="form-group">
                          <label class="float-left" for="password">Password</label>
                          <input class="form-control" type="password" required="" id="password" placeholder="Enter New password" v-model="login.password">
                      </div>
                      <div class="form-group">
                          <label class="float-left" for="password">Password Confirm</label>
                          <input class="form-control" type="password" required="" id="password_confirmation" placeholder="" v-model="login.password_confirmation">
                      </div>

                      <!-- <div class="form-group mb-3">
                          <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" id="checkbox-signin" checked="">
                              <label class="custom-control-label" for="checkbox-signin">Remember me</label>
                          </div>
                      </div> -->

                      <div class="form-group mb-0 text-center">
                          <button class="btn btn-primary btn-block btn-login" type="button" @click="save" >Create password</button>
                          <button class="btn btn-primary btn-block btn-login" type="button" disabled v-show="submitLoading">
                              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                              Loading...
                          </button>
                          <p class="mt-5 text-left">
                            <!-- {{ errorMessage }} -->
                            Password require number, character and special character
                            <br>
                            ex: ddd123+
                          </p>
                      </div>
                      <!-- <pre>{{ authUser }}</pre> -->
                  </form>
              </div> <!-- end card-body -->
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6 image-left"></div>
  </div>
</template>
<script>
import Axios from 'axios'
import { mapState } from 'vuex'
import { getHeader, userUrl } from '../config'
export default {
  data () {
    return {
      publicPath: process.env.BASE_URL,
      login: {
        username: null,
        email: null,
        old_password: null,
        password: null,
        password_confirmation: null
      },
      submitLoading: false,
      signIn: true,
      errorMessage: null
    }
  },
  computed: {
    ...mapState({
      authUser: state => state.users.authUser
    })
  },
  methods: {
    async save () {
      var obj = {
        old_password: this.login.old_password,
        password: this.login.password,
        password_confirmation: this.login.password_confirmation,
        username: this.authUser.username
      }
      console.log(obj)
      await Axios.post(userUrl + '/reset', obj, { headers: getHeader() })
        .then(response => {
          this.$toasted.show(response.data.message, {
            theme: 'toasted-primary',
            position: 'bottom-left',
            duration: 5000,
            icon: 'check',
            type: 'success'
          })
          this.$router.push({ name: 'login' })
        })
        .catch(error => {
          console.log(error.response)
          this.$toasted.show(error.response.data.message, {
            theme: 'toasted-primary',
            position: 'bottom-left',
            duration: 5000,
            icon: 'time',
            type: 'error'
          })
        })
    }
  }
}
</script>

<style>
.login-page {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* background-image: url('/asset/img/pexels-helena-lopes-697243.jpg'); */
  /* background-size: cover; */
  /* background-position: center; */
  /* background-repeat: no-repeat; */
  background-color: white;
  z-index: 999;
}
.image-left {
  background-image: url('/asset/img/funandfunky.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.image-right{
  background-color: #fff;
}
.middle-box {
    height: 100vh;
    background-color: #fff;
    color: #000;
}
.middle-box .card {
    background-color: #fff;
    color: #000;
}
.middle-box .login-description {
  color: rgb(90, 90, 90);
}
.middle-box h1 {
    color: #41A29D;
    font-weight: bold;
}
.btn-login{
    background-color: #41A29D;
    border: 0;
}
.btn-login:hover,
.btn-login:disabled{
    background-color: #236b67;
    color: #fff;
}
#card-login {
  margin-top: 75px;
}
@media only screen and (max-width: 767px) {
  #card-login {
    margin-top: 0px;
  }
}
</style>
