<template>
  <div class="row login-page" @keyup.enter="handleLoginFormSubmit()">
    <div class="col-md-6 image-left"></div>
    <div class="col-md-6 image-right">
      <div class="form-login">
        <div class="middle-box col-md-10 md-auto">
          <div class="card">
              <div class="card-body p-4" id="card-login">
                  <!-- Logo -->
                  <div class="card-body-login text-left">
                    <!-- <img src="http://demo.riktheme.com/zinox/side-menu-full-2/img/core-img/logo.png" alt=""> -->
                    <img class="desktop-logo mx-auto" :src="`${publicPath}asset/img/logo.png`" alt="Desktop Logo" style="width: auto; height: 100px">
                    <h3 style="color: white"><b></b></h3>
                  </div>

                  <!-- <h1>Bucket<span style="color: #000">.</span></h1> -->
                  <p class="login-description mb-1">Easy budgeting process</p>

                  <form action="#">
                      <div class="form-group">
                          <label class="float-left" for="emailaddress">Username</label>
                          <input class="form-control" type="email" id="emailaddress" required="" placeholder="Enter username" v-model="login.username" @keyup.enter="handleLoginFormSubmit()">
                      </div>

                      <div class="form-group">
                          <label class="float-left" for="password">Password</label>
                          <input class="form-control" type="password" required="" id="password" placeholder="Enter your password" v-model="login.password" @keyup.enter="handleLoginFormSubmit()">
                      </div>

                      <!-- <div class="form-group mb-3">
                          <div class="custom-control custom-checkbox">
                              <input type="checkbox" class="custom-control-input" id="checkbox-signin" checked="">
                              <label class="custom-control-label" for="checkbox-signin">Remember me</label>
                          </div>
                      </div> -->

                      <div class="form-group mb-0 text-center">
                          <button class="btn btn-primary btn-block btn-login" type="button" @click="handleLoginFormSubmit()" v-show="signIn">Sign In</button>
                          <button class="btn btn-primary btn-block btn-login" type="button" disabled v-show="submitLoading">
                              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                              Loading...
                          </button>
                          <p class="text-red mt-5 text-left">
                            {{ errorMessage }}
                          </p>
                      </div>

                  </form>
              </div> <!-- end card-body -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
// import { loginUrl, userTokenUrl, getHeader } from './../config'
import { getHeader, loginUrl, userUrl } from './../config'
import { companyName } from './../env'
export default {
  data () {
    return {
      publicPath: process.env.BASE_URL,
      company: companyName,
      login: {
        username: null,
        email: null,
        password: null
      },
      submitLoading: false,
      signIn: true,
      errorMessage: null
    }
  },
  created () {
    if (window.localStorage.getItem('authUser')) {
      this.$router.push({ name: 'home' })
    }
  },
  methods: {
    handleLoginFormSubmit () {
      console.log('login url: ' + loginUrl)
      this.submitLoading = true
      this.signIn = false
      const postData = {
        username: this.login.username,
        password: this.login.password
      }
      const authUser = {}
      axios.post(loginUrl, postData)
        .then(response => {
          console.log(response)
          if (response.data.token) {
            authUser.token = response.data.token
            window.localStorage.setItem('authUser', JSON.stringify(authUser))
            axios.get(userUrl + '/token', { headers: getHeader() })
              .then(response => {
                console.log(response.data.data)
                // authUser.permissions = response.data.data.permissions
                // authUser.organization = response.data.data.branch.org.name
                authUser.user = response.data.data
                window.localStorage.setItem('authUser', JSON.stringify(authUser))
                this.$store.dispatch('setUserObject', response.data.data)
                if (response.data.data.reset) {
                  this.$router.push({ name: 'reset.login' })
                  location.reload()
                } else {
                  this.$router.push({ name: 'home' })
                  location.reload()
                }
              })
              .catch(error => {
                console.log(error)
                window.localStorage.removeItem('authUser')
                this.$router.push({ name: 'login' })
              })
            this.submitLoading = false
            this.signIn = true
          } else {
            console.log('token not found')
          }
        })
        .catch(error => {
          this.submitLoading = false
          this.signIn = true
          this.errorMessage = error
        })
    }
  }
}
</script>

<style>
.login-page {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* background-image: url('/asset/img/pexels-helena-lopes-697243.jpg'); */
  /* background-size: cover; */
  /* background-position: center; */
  /* background-repeat: no-repeat; */
  background-color: white;
  z-index: 999;
}
.image-left {
  background-image: url('/asset/img/funandfunky.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.image-right{
  background-color: #fff;
}
.middle-box {
    height: 100vh;
    background-color: #fff;
    color: #000;
}
.middle-box .card {
    background-color: #fff;
    color: #000;
}
.middle-box .login-description {
  color: rgb(90, 90, 90);
}
.middle-box h1 {
    color: #41A29D;
    font-weight: bold;
}
.btn-login{
    background-color: #41A29D;
    border: 0;
}
.btn-login:hover,
.btn-login:disabled{
    background-color: #236b67;
    color: #fff;
}
#card-login {
  margin-top: 75px;
}
@media only screen and (max-width: 767px) {
  #card-login {
    margin-top: 0px;
  }
}
</style>
