<template>
    <div>
        <h1>Memo Path</h1>
    </div>
</template>

<script>
export default {
  name: 'SettingMemoPath'
}
</script>
