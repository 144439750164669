import axios from 'axios'
import { getHeader, supplierUrl } from './../config'

const state = {
  supplierList: []
}

const mutations = {
  SET_SUPPLIER_LIST (state, obj) {
    state.supplierList = obj
  }
}

const actions = {
  getSupplierList: ({ commit }) => {
    console.log(supplierUrl)
    axios.get(supplierUrl, { headers: getHeader() })
      .then(response => {
        commit('SET_SUPPLIER_LIST', response.data.data)
      })
  },
  getSupplierByCompany: ({ commit }, payload) => {
    var url = supplierUrl + '?company=' + payload.payload
    console.log(url)
    axios.get(url, { headers: getHeader() })
      .then(response => {
        commit('SET_SUPPLIER_LIST', response.data.data)
      })
  }
}

export default {
  state, mutations, actions
}
