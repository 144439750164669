<template>
    <div>
        <div class="modal hide fade" id="modalBankSetting" tabindex="-1" role="dialog" aria-labelledby="modalBankSetting" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered" role="document">
              <div class="modal-content">
                  <div class="modal-header red darken-3 text-left text-white">
                      <h4 class="modal-title w-100 font-weight-bold">Bank Form</h4>
                      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                      </button>
                  </div>
                  <div class="modal-body">
                      <form>
                          <div class="form-group">
                              <label for="id" class="col-form-label">ID:</label>
                              <input type="text" v-model="bank.id" class="form-control"/>
                          </div>
                          <div class="form-group">
                              <label for="name" class="col-form-label">Name:</label>
                              <input type="text" v-model="bank.name" class="form-control"/>
                          </div>
                      </form>
                      <!-- <pre>{{ bank }}</pre> -->
                  </div>
                  <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                  <button type="button" class="btn btn-primary" data-dismiss="modal" @click="process">Process</button>
                  </div>
              </div>
          </div>
      </div>
    </div>
</template>

<script>
export default {
  name: 'SettingBankModal',
  props: ['bank'],
  methods: {
    process: function () {
      console.log(this.bank)
      this.$emit('processBank', this.bank)
    }
  }
}
</script>
