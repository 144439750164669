<template>
    <div>
        <div class="row">
            <div class="col-md-6">
                <h4>Role</h4>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="form-group row">
                  <label for="name" class="col-sm-3 col-form-label text-right">Name</label>
                  <div class="col-sm-9">
                    <input type="text" v-model="role.name" class="form-control">
                  </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <h5>Permissions</h5>
                <div class="form-group row">
                    <label for="memo_view" class="col-sm-3 col-form-label text-right">&nbsp;</label>
                    <div class="col-sm-9">
                        <div class="custom-control custom-checkbox">
                            <input v-model="role.permissions.memo_save" type="checkbox" class="custom-control-input" id="memo_view" data-parsley-multiple="groups" data-parsley-mincheck="2">
                            <label class="custom-control-label" for="memo_save">Memo View</label>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label for="memo_save" class="col-sm-3 col-form-label text-right">&nbsp;</label>
                    <div class="col-sm-9">
                        <div class="custom-control custom-checkbox">
                            <input v-model="role.permissions.memo_save" type="checkbox" class="custom-control-input" id="memo_save" data-parsley-multiple="groups" data-parsley-mincheck="2">
                            <label class="custom-control-label" for="memo_save">Memo Save</label>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label for="memo_delete" class="col-sm-3 col-form-label text-right">&nbsp;</label>
                    <div class="col-sm-9">
                        <div class="custom-control custom-checkbox">
                            <input v-model="role.permissions.memo_delete" type="checkbox" class="custom-control-input" id="memo_delete" data-parsley-multiple="groups" data-parsley-mincheck="2">
                            <label class="custom-control-label" for="memo_delete">Memo Delete</label>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label for="memo_inbox" class="col-sm-3 col-form-label text-right">&nbsp;</label>
                    <div class="col-sm-9">
                        <div class="custom-control custom-checkbox">
                            <input v-model="role.permissions.memo_inbox" type="checkbox" class="custom-control-input" id="memo_inbox" data-parsley-multiple="groups" data-parsley-mincheck="2">
                            <label class="custom-control-label" for="memo_inbox">Memo Inbox</label>
                        </div>
                    </div>
                </div>
                 <div class="form-group row">
                    <label for="memo_bank_approval" class="col-sm-3 col-form-label text-right">&nbsp;</label>
                    <div class="col-sm-9">
                        <div class="custom-control custom-checkbox">
                            <input v-model="role.permissions.memo_bank_approval" type="checkbox" class="custom-control-input" id="memo_bank_approval" data-parsley-multiple="groups" data-parsley-mincheck="2">
                            <label class="custom-control-label" for="memo_bank_approval">Memo Bank Approval</label>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label for="memo_all_list" class="col-sm-3 col-form-label text-right">&nbsp;</label>
                    <div class="col-sm-9">
                        <div class="custom-control custom-checkbox">
                            <input v-model="role.permissions.memo_all_list" type="checkbox" class="custom-control-input" id="memo_all_list" data-parsley-multiple="groups" data-parsley-mincheck="2">
                            <label class="custom-control-label" for="memo_all_list">Memo All List</label>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label for="memo_path_view" class="col-sm-3 col-form-label text-right">&nbsp;</label>
                    <div class="col-sm-9">
                        <div class="custom-control custom-checkbox">
                            <input v-model="role.permissions.memo_path_view" type="checkbox" class="custom-control-input" id="memo_path_view" data-parsley-multiple="groups" data-parsley-mincheck="2">
                            <label class="custom-control-label" for="memo_path_view">Memo Path View</label>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label for="memo_path_save" class="col-sm-3 col-form-label text-right">&nbsp;</label>
                    <div class="col-sm-9">
                        <div class="custom-control custom-checkbox">
                            <input v-model="role.permissions.memo_path_save" type="checkbox" class="custom-control-input" id="memo_path_save" data-parsley-multiple="groups" data-parsley-mincheck="2">
                            <label class="custom-control-label" for="memo_path_save">Memo Path Save</label>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label for="memo_path_delete" class="col-sm-3 col-form-label text-right">&nbsp;</label>
                    <div class="col-sm-9">
                        <div class="custom-control custom-checkbox">
                            <input v-model="role.permissions.memo_path_delete" type="checkbox" class="custom-control-input" id="memo_path_delete" data-parsley-multiple="groups" data-parsley-mincheck="2">
                            <label class="custom-control-label" for="memo_path_delete">Memo Path Delete</label>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label for="set_company" class="col-sm-3 col-form-label text-right">&nbsp;</label>
                    <div class="col-sm-9">
                        <div class="custom-control custom-checkbox">
                            <input v-model="role.permissions.set_company" type="checkbox" class="custom-control-input" id="set_company" data-parsley-multiple="groups" data-parsley-mincheck="2">
                            <label class="custom-control-label" for="set_company">Set Company in Header</label>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label for="employee_save" class="col-sm-3 col-form-label text-right">&nbsp;</label>
                    <div class="col-sm-9">
                        <div class="custom-control custom-checkbox">
                            <input v-model="role.permissions.employee_save" type="checkbox" class="custom-control-input" id="employee_save" data-parsley-multiple="groups" data-parsley-mincheck="2">
                            <label class="custom-control-label" for="employee_save">Employee Save</label>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label for="employee_delete" class="col-sm-3 col-form-label text-right">&nbsp;</label>
                    <div class="col-sm-9">
                        <div class="custom-control custom-checkbox">
                            <input v-model="role.permissions.employee_delete" type="checkbox" class="custom-control-input" id="employee_delete" data-parsley-multiple="groups" data-parsley-mincheck="2">
                            <label class="custom-control-label" for="employee_delete">Employee Delete</label>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label for="setting" class="col-sm-3 col-form-label text-right">&nbsp;</label>
                    <div class="col-sm-9">
                        <div class="custom-control custom-checkbox">
                            <input v-model="role.permissions.setting" type="checkbox" class="custom-control-input" id="setting" data-parsley-multiple="groups" data-parsley-mincheck="2">
                            <label class="custom-control-label" for="setting">Setting</label>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label for="supplier_approval" class="col-sm-3 col-form-label text-right">&nbsp;</label>
                    <div class="col-sm-9">
                        <div class="custom-control custom-checkbox">
                            <input v-model="role.permissions.supplier_approval" type="checkbox" class="custom-control-input" id="supplier_approval" data-parsley-multiple="groups" data-parsley-mincheck="2">
                            <label class="custom-control-label" for="supplier_approval">Supplier Approval</label>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label for="report_view" class="col-sm-3 col-form-label text-right">&nbsp;</label>
                    <div class="col-sm-9">
                        <div class="custom-control custom-checkbox">
                            <input v-model="role.permissions.report_view" type="checkbox" class="custom-control-input" id="report_view" data-parsley-multiple="groups" data-parsley-mincheck="2">
                            <label class="custom-control-label" for="report_view">Report</label>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label for="notification" class="col-sm-3 col-form-label text-right">&nbsp;</label>
                    <div class="col-sm-9">
                        <div class="custom-control custom-checkbox">
                            <input v-model="role.permissions.notification" type="checkbox" class="custom-control-input" id="notification" data-parsley-multiple="groups" data-parsley-mincheck="2">
                            <label class="custom-control-label" for="notification">Notification</label>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label for="dashboard_chart_memo_category_by_company" class="col-sm-3 col-form-label text-right">&nbsp;</label>
                    <div class="col-sm-9">
                        <div class="custom-control custom-checkbox">
                            <input v-model="role.permissions.dashboard_chart_memo_category_by_company" type="checkbox" class="custom-control-input" id="dashboard_chart_memo_category_by_company" data-parsley-multiple="groups" data-parsley-mincheck="2">
                            <label class="custom-control-label" for="dashboard_chart_memo_category_by_company">Dashboard Chart Memo Category By Company</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="col-md-12">
                <button type="button" class="btn btn-sm btn-primary pull-right" v-if="role.id === null" @click="save">Save</button>
                <button type="button" class="btn btn-sm btn-primary pull-right" v-else @click="update">Update</button>
                <button type="button" class="btn btn-sm btn-danger pull-right" @click="backParent">Cancel</button>
            </div>
        </div>
    </div>
</template>

<script>
import Axios from 'axios'
import { getHeader, roleUrl } from '@/config'
export default {
  name: 'SettingRoleForm',
  props: ['role'],
  methods: {
    backParent: function () {
      this.$emit('backParent')
    },
    async save () {
      Axios.post(roleUrl, this.role, { header: getHeader() })
        .then(response => {
          console.log(response.data.data)
          this.$toasted.show(response.data.message, {
            theme: 'toasted-primary',
            position: 'top-right',
            duration: 5000,
            icon: 'check',
            type: 'success'
          })
          this.backParent()
        })
        .catch(error => {
          console.log(error.response)
          this.$toasted.show(error.response.data.message, {
            theme: 'toasted-primary',
            position: 'top-right',
            duration: 5000,
            icon: 'check',
            type: 'error'
          })
        })
    },
    async update () {
      const url = roleUrl + '/' + this.role.id
      await Axios.put(url, this.role, { headers: getHeader() })
        .then(response => {
          console.log(response.data.data)
          this.$toasted.show(response.data.message, {
            theme: 'toasted-primary',
            position: 'top-right',
            duration: 5000,
            icon: 'check',
            type: 'success'
          })
          this.backParent()
        })
        .catch(error => {
          console.log(error.response)
          this.$toasted.show(error.response.data.message, {
            theme: 'toasted-primary',
            position: 'top-right',
            duration: 5000,
            icon: 'check',
            type: 'error'
          })
        })
    }
  }
}
</script>
