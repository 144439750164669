<template>
    <div>
        <div class="modal hide fade" id="modalMemoCategorySetting" tabindex="-1" role="dialog" aria-labelledby="modalBankSetting" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered" role="document">
              <div class="modal-content">
                  <div class="modal-header red darken-3 text-left text-white">
                      <h4 class="modal-title w-100 font-weight-bold">Memo Category Form</h4>
                      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                      </button>
                  </div>
                  <div class="modal-body">
                      <form>
                        <div class="form-group">
                          <div class="custom-control custom-checkbox">
                            <input type="checkbox" id="prepayment" data-parsley-multiple="groups" data-parsley-mincheck="2" class="custom-control-input" v-model="category.prepayment">
                            <label for="prepayment" class="custom-control-label">Prepayment</label>
                          </div>
                        </div>
                        <div class="form-group">
                            <label for="name" class="col-form-label">Name:</label>
                            <input type="text" v-model="category.name" class="form-control"/>
                        </div>
                        <div class="form-group">
                          <label for="type" class="col-form-label">Type</label>
                          <select v-model="category.type" class="form-control">
                            <option value="payment">Payment</option>
                            <option value="no-payment">No Payment</option>
                          </select>
                        </div>
                        <div class="form-group">
                            <label for="company" class="col-form-label">Company:</label>
                            <select v-model="category.company_no" class="form-control">
                              <option v-for="c in companyList" :key="c.id" :value="c.company_no">{{ c.company_no }} - {{ c.name }}</option>
                            </select>
                        </div>
                      </form>
                      <!-- <pre>{{ category }}</pre> -->
                  </div>
                  <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                  <button type="button" class="btn btn-primary" data-dismiss="modal" @click="process">Process</button>
                  </div>
              </div>
          </div>
      </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'SettingMemoCategoryModal',
  props: ['category'],
  methods: {
    process: function () {
      this.$emit('processCategory', this.category)
    }
  },
  computed: {
    ...mapState({
      companyList: state => state.companies.companyList
    })
  }
}
</script>
