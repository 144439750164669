import Vue from 'vue'
import Vuex from 'vuex'

import users from '@/store/user'
import data from '@/store/data'
import companies from '@/store/companies'
import memos from '@/store/memos'
import suppliers from '@/store/suppliers'
import employees from '@/store/employees'
import banks from '@/store/banks'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    companies,
    users,
    data,
    memos,
    suppliers,
    employees,
    banks
  }
})
