<template>
    <div class="wrapper menu-collapsed" id="page-wrapper">
      <!-- set progressbar -->
      <vue-progress-bar></vue-progress-bar>
      <v-idle @idle="onidle" @remind="onremind" :loop="true" :reminders="[10, 15]" :wait="5" :duration="1800" />
      <!-- Notification -->
      <notifications group="foo" position="top right" />
        <!-- Sidebar -->
        <div id="sidebar">
          <div class="sidebar-header">
            <a href="/" style="color: #41A29D">
              <img class="small-logo" :src="`${publicPath}asset/img/logo-font.png`" alt="Desktop Logo">
            </a>
          </div>

          <div class="side-menu-area" v-slimscroll="options">
              <!-- Sidebar Menu -->
              <nav>
                  <ul class="sidebar-menu tree" data-widget="tree">
                    <!-- <li class="mb-5">
                      <img :src="authUser.profile" style="margin-left: 10px;width: 75px;height: 75px;border-radius: 50%; object-fit: cover;">
                    </li> -->
                    <span>
                      <li @click="setMenu('dashboard')" v-if="this.currentMenu === 'dashboard'" class="active" data-placement="right" title="Dashboard"><a href="/"><i class="lni lni-layers"></i></a></li>
                      <li @click="setMenu('dashboard')" v-else data-placement="right" title="Dashboard"><a href="/" data-toggle="tooltip"><i class="lni lni-layers"></i></a></li>
                    </span>
                    <span>
                      <li @click="setMenu('memo')" v-if="this.currentMenu === 'memo'" class="active" data-placement="right" title="Memo"><router-link :to="{ name: 'memo' }"><i class="lni lni-sprout"></i></router-link></li>
                      <li @click="setMenu('memo')" v-else data-placement="right" title="Memo"><router-link :to="{ name: 'memo' }"><i class="lni lni-sprout"></i></router-link></li>
                    </span>
                    <!-- <span>
                      <li @click="setMenu('inbox')" v-if="this.currentMenu === 'inbox'" class="active" data-placement="right" title="Inbox"><router-link :to="{ name: 'memo.inbox' }"><i class="lni lni-archive"></i></router-link></li>
                      <li @click="setMenu('inbox')" v-else data-placement="right" title="Inbox"><router-link :to="{ name: 'memo.inbox' }"><i class="lni lni-archive"></i></router-link></li>
                    </span> -->
                    <span v-if="authUser.permissions.report_view">
                      <li @click="setMenu('report')" v-if="this.currentMenu === 'report'" class="active" data-placement="right" title="Report"><router-link :to="{ name: 'report' }"><i class="lni lni-stats-up"></i></router-link></li>
                      <li @click="setMenu('report')" v-else data-placement="right" title="Report"><router-link :to="{ name: 'report' }"><i class="lni lni-stats-up"></i></router-link></li>
                    </span>
                    <span v-if="authUser.permissions.memo_path_view">
                      <li @click="setMenu('memo.path')" v-if="this.currentMenu === 'memo.path'" class="active" data-placement="right" title="Memo Path"><router-link :to="{ name: 'memo.path' }"><i class="lni lni-map-marker"></i></router-link></li>
                      <li @click="setMenu('memo.path')" v-else data-placement="right" title="Memo Path"><router-link :to="{ name: 'memo.path' }"><i class="lni lni-map-marker"></i></router-link></li>
                    </span>
                    <span>
                      <li @click="setMenu('company')" v-if="this.currentMenu === 'company'" class="active" data-placement="right" title="Company"><router-link :to="{ name: 'company' }"><i class="lni lni-consulting"></i></router-link></li>
                      <li @click="setMenu('company')" v-else data-placement="right" title="Company"><router-link :to="{ name: 'company' }"><i class="lni lni-consulting"></i></router-link></li>
                    </span>
                    <span v-if="authUser.permissions.employee_view">
                      <li @click="setMenu('employee')" v-if="this.currentMenu === 'employee'" class="active" data-placement="right" title="Employee"><router-link :to="{ name: 'employee' }"><i class="lni lni-users"></i></router-link></li>
                      <li @click="setMenu('employee')" v-else data-placement="right" title="Employee"><router-link :to="{ name: 'employee' }"><i class="lni lni-users"></i></router-link></li>
                    </span>

                    <!-- <li @click="setMenu('user')" v-if="this.currentMenu === 'user'" class="active" data-placement="right" title="User"><router-link :to="{ name: 'user' }"><i class="lni lni-user"></i></router-link></li>
                    <li @click="setMenu('user')" v-else data-placement="right" title="User"><router-link :to="{ name: 'user' }"><i class="lni lni-user"></i></router-link></li> -->
                    <span>
                      <li @click="setMenu('supplier')" v-if="this.currentMenu === 'supplier'" class="active" data-placement="right" title="supplier"><router-link :to="{ name: 'supplier' }"><i class="lni lni-apartment"></i></router-link></li>
                      <li @click="setMenu('supplier')" v-else data-placement="right" title="supplier"><router-link :to="{ name: 'supplier' }"><i class="lni lni-apartment"></i></router-link></li>
                    </span>
                    <span v-if="authUser.permissions.setting">
                      <li @click="setMenu('setting')" v-if="this.currentMenu === 'setting'" class="active" data-placement="right" title="Setting"><router-link :to="{ name: 'setting' }"><i class="lni lni-cog"></i></router-link></li>
                      <li @click="setMenu('setting')" v-else data-placement="right" title="Setting"><router-link :to="{ name: 'setting' }"><i class="lni lni-cog"></i></router-link></li>
                    </span>

                    <span v-if="authUser.permissions.notification">
                      <li @click="setMenu('notification')" v-if="this.currentMenu === 'notification'" class="active" data-placement="right" title="Notification"><router-link :to="{ name: 'notification' }"><i class="lni lni-bullhorn"></i></router-link></li>
                      <li @click="setMenu('notification')" v-else data-placement="right" title="Notification"><router-link :to="{ name: 'notification' }"><i class="lni lni-bullhorn"></i></router-link></li>
                    </span>

                    <li data-placement="right" title="Logout"><a href="javascript:void(0)" @click="logout"><i class="lni lni-exit"></i></a></li>
                  </ul>
              </nav>
          </div>
        </div>
        <!-- End Sidebar -->

        <!-- Page Content -->
        <div id="content">
            <!-- Top Header Area -->
            <header class="top-header-area d-flex align-items-center justify-content-between">
                <div class="left-side-content-area d-flex align-items-center">
                    <!-- Mobile Logo -->
                    <div class="mobile-logo mr-3 mr-sm-4">
                        <!-- <a href="/"><img class="desktop-logo" :src="`${publicPath}asset/img/small-logo-callistech.png`" alt="Desktop Logo"></a> -->
                    </div>

                    <!-- Triggers -->
                    <div class="ecaps-triggers mr-1 mr-sm-3">
                        <div class="menu-collasped" id="menuCollasped">
                            <i class="zmdi zmdi-menu" style="color:black;" @click="menuCollapsed()"></i>
                        </div>
                        <div class="mobile-menu-open" id="mobileMenuOpen">
                            <i class="zmdi zmdi-menu" style="color:black;" @click="mobileCollapsed()"></i>
                        </div>
                    </div>
                    <!-- <ul class="right-side-content d-flex align-items-center main-menu">
                      <li v-if="authUser.company">
                        <select class="select-form-control" v-model="companyUser" @change="setCompany" v-if="authUser.permissions.set_company">
                          <option v-for="c in authUser.company" :key="c.id" :value="c.company_no">{{ c.name }}</option>
                        </select>
                        <select class="select-form-control" v-model="companyUser" @change="setCompany" v-else disabled="disabled">
                          <option v-for="c in  authUser.company" :key="c.id" :value="c.company_no">{{ c.name }}</option>
                        </select>
                      </li>
                      <li v-else>
                        <select class="select-form-control" v-model="companyUser" @change="setCompany" v-if="authUser.permissions.set_company">
                          <option v-for="c in companyList" :key="c.id" :value="c.company_no">{{ c.name }}</option>
                        </select>
                        <select class="select-form-control" v-model="companyUser" @change="setCompany" v-else disabled="disabled">
                          <option v-for="c in companyList" :key="c.id" :value="c.company_no">{{ c.name }}</option>
                        </select>
                      </li>
                    </ul> -->
                </div>

                <div class="right-side-navbar d-flex align-items-center justify-content-end">
                    <!-- Mobile Trigger -->
                    <div class="right-side-trigger" id="rightSideTrigger">
                        <i class="ti-align-left"></i>
                    </div>

                    <!-- Top Bar Nav -->
                    <ul class="right-side-content d-flex align-items-center">
                        <li class="hide-phone app-search mr-15">
                            <!-- <form role="search" class="">
                              <input type="text" placeholder="Search..." class="form-control">
                              <button type="submit" class="mr-0">
                                <i class="fa fa-search"></i>
                              </button>
                            </form> -->
                        </li>
                        <li class="nav-item dropdown">
                            <button type="button" class="btn dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                              <i class="icon_lightbulb_alt" aria-hidden="true"></i> <span class="active-status" v-if="totalmemoInboxNotif > 0"></span>
                            </button>
                            <div class="dropdown-menu dropdown-menu-right">
                                <!-- Top Notifications Area -->
                                <div class="top-notifications-area">
                                    <!-- Heading -->
                                    <div class="notifications-heading">
                                        <div class="heading-title">
                                            <h6>Notifications</h6>
                                        </div>
                                        <span>{{ totalmemoInboxNotif }} New</span>
                                    </div>
                                    <div class="slimScrollDiv" style="position: relative; overflow: hidden; width: auto; height: 260px;"><div class="notifications-box" id="notificationsBox" style="overflow: hidden; width: auto; height: 260px;">
                                      <a href="javascript:void(0)" class="dropdown-item" v-if="totalmemoInboxNotif == 0"><i style="color: #000; text-align: center">No notifications</i></a>
                                      <a href="javascript:void(0)" class="dropdown-item" v-for="i in memoInboxNotif" :key="i.id" @click="toInbox(i.id)">
                                        <div style="-webkit-box-flex: 0;-ms-flex: 0 0 30px;flex: 0 0 30px;max-width: 30px;width: 30px;margin-right: 10px; ">
                                          <img v-if="i.photo" :src="i.photo" alt="">
                                          <img v-else src="https://randomuser.me/api/portraits/women/21.jpg" alt="">
                                        </div>
                                        <span style="color: black;">{{ i.memo_date | dateFormat('DD/MM/YYYY') }} - {{ i.subject }} {{ i.total | currency }} </span>
                                      </a>
                                    </div><div class="slimScrollBar" style="background: rgb(140, 140, 140); width: 2px; position: absolute; top: 0px; opacity: 0.4; display: block; border-radius: 7px; z-index: 99; right: 0px;"></div><div class="slimScrollRail" style="width: 2px; height: 100%; position: absolute; top: 0px; display: none; border-radius: 7px; background: rgb(51, 51, 51); opacity: 0.2; z-index: 90; right: 0px;"></div></div>
                                </div>
                            </div>
                        </li>
                        <li class="nav-item dropdown">
                          <span style="color: #000">{{ authUser.name }}</span>
                          <button type="button" class="btn dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <img v-if="authUser.profile" :src="authUser.profile" alt="">
                            <img v-else src="https://randomuser.me/api/portraits/women/21.jpg" alt="">
                          </button>
                          <div class="dropdown-menu dropdown-menu-right">
                            <!-- User Profile Area -->
                            <div class="user-profile-area">
                                <div class="user-profile-heading">
                                    <!-- Thumb -->
                                    <div class="profile-thumbnail">
                                      <img v-if="authUser.profile" :src="authUser.profile" alt="">
                                      <img v-else src="https://randomuser.me/api/portraits/women/21.jpg" alt="">
                                    </div>
                                    <!-- Profile Text -->
                                    <div class="profile-text">
                                        <h6>{{ authUser.name }}</h6>
                                        <span>{{ authUser.email }}</span>
                                    </div>
                                </div>
                                <!-- <a href="#" class="dropdown-item"><i class="ti-user text-default" aria-hidden="true"></i> My profile</a>
                                <a href="#" class="dropdown-item"><i class="zmdi zmdi-email-open text-success" aria-hidden="true"></i> Messages</a>
                                <a href="#" class="dropdown-item"><i class="ti-settings text-default" aria-hidden="true"></i> Account settings</a>
                                <a href="#" class="dropdown-item"><i class="ti-heart text-purple" aria-hidden="true"></i> Support</a> -->
                                <a href="#" class="dropdown-item" @click="logout"><i class="ti-unlink text-warning" aria-hidden="true"></i> Sign-out</a>
                            </div>
                          </div>
                        </li>
                    </ul>
                </div>
            </header>
            <div class="main-content">
              <div class="container-fluid">
                <!-- <pre>{{ authUser }}</pre> -->
                <!-- <pre>{{ currentMenu }}</pre> -->
                <router-view></router-view>
              </div>
            </div>
        </div>
        <!-- End Page Content -->
    </div>
</template>

<script>
import Axios from 'axios'
// import axios from 'axios'
// import { userTokenUrl, getHeader } from '@/config'
import { mapState } from 'vuex'
import { employeeUrl, getHeader, userUrl } from './config'
export default {
  data () {
    return {
      activeMenu: 'active',
      publicPath: process.env.BASE_URL,
      options: { height: '100%' },
      companyUser: null
    }
  },
  created () {
    if (window.localStorage.getItem('authUser')) {
      Axios.get(userUrl + '/token', { headers: getHeader() })
        .then(response => {
          console.log('user token')
          console.log(response.data.data)
          this.$store.dispatch('setUserObject', response.data.data)
          this.companyUser = response.data.data.company_no
        })
        .catch(error => {
          console.log(error)
          window.localStorage.removeItem('authUser')
          this.$router.push({ name: 'login' })
        })
    }
    // get route name
    this.$store.commit('SET_CURRENT_MENU', this.$route.meta.menu)
  },
  computed: {
    ...mapState({
      authUser: state => state.users.authUser,
      currentMenu: state => state.data.currentMenu,
      mainMenu: state => state.data.mainMenu,
      companyList: state => state.companies.company,
      memoInboxNotif: state => state.memos.memoInboxNotif,
      memoInboxTotal: state => state.memos.memoInboxTotal
    }),
    totalmemoInboxNotif: function () {
      return this.memoInboxTotal
    },
    getActive: function () {
      return 'active'
    },
    getMainMenu: function () {
      var array = this.mainMenu.find(d => d.name === this.currentMenu)
      if (array) {
        return array.menu
      } else {
        return [{
          name: null,
          header: null,
          url: null,
          subMenu: [{
            header: null,
            list: [{
              name: null,
              url: null
            }]
          }]
        }]
      }
    },
    currentRouteName () {
      return this.$route.meta
    }
  },
  methods: {
    onidle: function () {
      console.log('trigger logout')
      this.logout()
    },
    onremind: function (time) {
    },
    toInbox: function (id) {
      this.$router.replace({ path: '/memo/inbox', query: { id: id } })
    },
    async setCompany () {
      console.log(this.authUser)
      var obj = {
        username: this.authUser.username,
        email: this.authUser.email,
        company_no: this.companyUser
      }
      await Axios.put(userUrl + '/' + this.authUser.id, obj, { headers: getHeader() })
        .then(response => {
          console.log(response.data.data)
          location.reload()
        })
        .catch(error => {
          console.log(error.response)
        })
    },
    setMenu: function (menu) {
      this.$store.commit('SET_CURRENT_MENU', menu)
    },
    async logout () {
      var obj = {
        employee_no: this.authUser.username
      }
      await Axios.post(employeeUrl + '/employee-no', obj, { headers: getHeader() })
        .then(response => {
          console.log(response.data.data)
          var obj = {
            username: this.authUser.username,
            email: this.authUser.email,
            company_no: response.data.data.company_no
          }
          Axios.put(userUrl + '/' + this.authUser.id, obj, { headers: getHeader() })
            .then(response => {
              console.log(response.data.data)
            })
            .catch(error => {
              console.log(error.response)
            })
        })
        .catch(error => {
          console.log(error.response)
        })
      await this.$store.dispatch('setUserObject', [])
      await window.localStorage.removeItem('authUser')
      await location.reload()
      // axios.post(logUrl, obj, { headers: getHeader() })
      //   .then(response => {
      //   })
    },
    menuCollapsed: function () {
      document.getElementById('page-wrapper').classList.toggle('menu-collapsed-active')
    },
    mobileCollapsed: function () {
      document.getElementById('page-wrapper').classList.toggle('mobile-collapsed-active')
    }
  },
  mounted () {
    this.$store.dispatch('getMemoInboxNotif')
    this.$store.dispatch('getCompany')
  }
}
</script>
<style>
@import 'https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css';

/* :: Calendar CSS */
.fc-event.draging-button {
  padding: 8px 10px;
  border-radius: 0;
  margin-bottom: 10px;
  background-color: transparent;
  color: #8492a5;
  border: 1px solid rgba(72, 94, 144, 0.15) !important; }

.fc-button {
  border: 1px solid transparent;
  padding: 5px 15px;
  font-size: 14px;
  border-radius: 2px; }

[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled),
button:not(:disabled) {
  margin-right: 4px; }

.fc-button-primary {
  color: #8492a5;
  background-color: transparent;
  border-color: rgba(72, 94, 144, 0.15); }
  .fc-button-primary:hover {
    color: #0178fa;
    border-color: #0178fa;
    box-shadow: none;
    background-color: transparent; }

.fc-button-primary:disabled {
  color: #8492a5;
  background-color: transparent;
  border-color: rgba(72, 94, 144, 0.15); }

.fc-button-primary:not(:disabled).fc-button-active,
.fc-button-primary:not(:disabled):active {
  color: #8492a5;
  background-color: transparent;
  border-color: rgba(72, 94, 144, 0.15); }

.fc-button-primary:not(:disabled).fc-button-active:focus,
.fc-button-primary:not(:disabled):active:focus {
  box-shadow: none; }

.fc-day-grid-event.fc-h-event.fc-event {
  padding: 8px 12px;
  border: none;
  background-color: #0178fa !important;
  border-radius: 0;
  color: #ffffff; }

.fc-day-grid-event.fc-h-event.fc-event.fc-event-warning {
  background-color: #f39c12 !important; }

a:not([href]):not([tabindex]):focus,
a:not([href]):not([tabindex]):hover {
  color: #fff;
  text-decoration: none; }

.fc-day-header.fc-widget-header {
  padding: 5px 10px;
  background-color: #eef5f9; }

.fc-day-header.fc-widget-header span {
  font-size: 14px;
  font-weight: 500;
  color: #333; }

.fc-day-number {
  font-size: 14px;
  color: #555; }

.fc-dayGrid-view .fc-day-number,
.fc-dayGrid-view .fc-week-number {
  padding: 10px; }

.fc-toolbar h2 {
  font-size: 18px; }
  @media only screen and (max-width: 767px) {
    .fc-toolbar h2 {
      margin-bottom: 15px; } }

@media only screen and (max-width: 767px) {
  .fc-toolbar {
    display: block; } }

@media only screen and (max-width: 767px) {
  .fc-left {
    margin-bottom: 15px; } }

.fc-unthemed .fc-content,
.fc-unthemed .fc-divider,
.fc-unthemed .fc-list-heading td,
.fc-unthemed .fc-list-view,
.fc-unthemed .fc-popover,
.fc-unthemed .fc-row,
.fc-unthemed tbody,
.fc-unthemed td,
.fc-unthemed th,
.fc-unthemed thead {
  border-color: rgba(72, 94, 144, 0.15); }
/* :: Reboot CSS */
* {
  margin: 0;
  padding: 0; }

body {
  background-color: #FFF8E5;
  color: #3b3b3b;
  overflow-x: hidden;
  font-family: "Roboto", sans-serif;
  font-size: 11px !important; }

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.4;
  color: #1c273c;
  font-weight: bold;
  font-family: "Roboto", sans-serif; }

p {
  color: #8492a5;
  line-height: 1.9;
  font-size: 14px;
  font-weight: 400;
  font-family: "Roboto", sans-serif; }

a,
a:hover,
a:focus {
  -webkit-transition-duration: 500ms;
  -o-transition-duration: 500ms;
  transition-duration: 500ms;
  text-decoration: none;
  outline: 0 solid transparent; }

ul,
ol {
  margin: 0; }

ul li,
ol li {
  list-style: none;
  text-decoration: none; }

img {
  max-width: 100%;
  height: auto; }

.bg-img {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat; }

.popover,
.tooltip {
  z-index: 9999999; }

.mt-15 {
  margin-top: 15px !important; }

.mt-30 {
  margin-top: 30px !important; }

.mt-20 {
  margin-top: 20px !important; }

.mt-50 {
  margin-top: 50px !important; }

.mt-70 {
  margin-top: 70px !important; }

.mt-100 {
  margin-top: 100px !important; }

.mt-150 {
  margin-top: 150px !important; }

.mt-200 {
  margin-top: 200px !important; }

.mt-250 {
  margin-top: 250px !important; }

.mt-300 {
  margin-top: 300px !important; }

.mb-10 {
  margin-bottom: 10px !important; }

.mb-15 {
  margin-bottom: 15px !important; }

.mb-20 {
  margin-bottom: 20px !important; }

.mb-25 {
  margin-bottom: 25px !important; }

.mb-30 {
  margin-bottom: 30px !important; }

.mb-50 {
  margin-bottom: 50px !important; }

.mb-70 {
  margin-bottom: 70px !important; }

.mb-100 {
  margin-bottom: 100px !important; }

.mb-150 {
  margin-bottom: 150px !important; }

.mb-200 {
  margin-bottom: 200px !important; }

.mb-250 {
  margin-bottom: 250px !important; }

.mb-300 {
  margin-bottom: 300px !important; }

.ml-15 {
  margin-left: 15px !important; }

.ml-30 {
  margin-left: 30px !important; }

.ml-50 {
  margin-left: 50px !important; }

.mr-10 {
  margin-right: 10px !important; }

.mr-15 {
  margin-right: 15px !important; }

.mr-30 {
  margin-right: 30px !important; }

.mr-50 {
  margin-right: 50px !important; }

.p-30 {
  padding: 30px !important; }

.p-20 {
  padding: 20px; }

.line-height-50-p-0 {
  line-height: 50px;
  padding: 0; }

.height-50 {
  height: 50px !important; }

.height-200 {
  height: 200px !important; }

.height-300 {
  height: 300px !important; }

.height-400 {
  height: 400px !important; }

.height-500 {
  height: 500px !important; }

.height-600 {
  height: 600px !important; }

.height-700 {
  height: 700px !important; }

.height-800 {
  height: 800px !important; }

.w-15 {
  width: 15% !important; }

.w-40 {
  width: 40% !important; }

.w-70 {
  width: 70% !important; }

.w-50 {
  width: 50% !important; }

.section-padding-50 {
  padding-top: 50px;
  padding-bottom: 50px; }

#preloader {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #ffffff;
  z-index: 999999; }

.h-100vh {
  height: 100vh !important; }

.bg-default-opacity-8- {
  background-color: rgba(113, 192, 22, 0.8) !important; }

.bg-green {
  background-color: #1dc9b7; }

.bg-overlay-white {
  position: relative;
  z-index: 1; }
  .bg-overlay-white::after {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    background-image: -webkit-gradient(linear, left top, right top, color-stop(0, white), to(rgba(255, 255, 255, 0.2)));
    background-image: -webkit-linear-gradient(left, white 0, rgba(255, 255, 255, 0.2) 100%);
    background-image: -o-linear-gradient(left, white 0, rgba(255, 255, 255, 0.2) 100%);
    background-image: linear-gradient(to right, white 0, rgba(255, 255, 255, 0.2) 100%); }

.green-color-overlay {
  position: relative;
  z-index: 1; }
  .green-color-overlay::after {
    background-color: rgba(113, 192, 22, 0.75);
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: -1; }

.green-color-overlay-2 {
  position: relative;
  z-index: 1; }
  .green-color-overlay-2::after {
    background-color: rgba(113, 192, 22, 0.3);
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: -1; }

.pink-color-overlay {
  position: relative;
  z-index: 1; }
  .pink-color-overlay::after {
    background-color: rgba(136, 14, 79, 0.75);
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: -1; }

.danger-color-overlay {
  position: relative;
  z-index: 1; }
  .danger-color-overlay::after {
    background-color: rgba(241, 83, 110, 0.75);
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: -1; }

.dark-color-overlay {
  position: relative;
  z-index: 1; }
  .dark-color-overlay::after {
    background-color: rgba(0, 0, 0, 0.7);
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: -1; }

.dark-color-overlay-2 {
  position: relative;
  z-index: 1; }
  .dark-color-overlay-2::after {
    background-color: rgba(0, 0, 0, 0.9);
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: -1; }

.primary-color-overlay {
  position: relative;
  z-index: 1; }
  .primary-color-overlay::after {
    background-color: rgba(114, 124, 245, 0.8);
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: -1; }

.btn-default {
  background-color: #ffffff;
  color: #222222;
  border-color: transparent;
  font-weight: 500;
}

.color-primary {
  color: #41A29D !important;
}

.bg-primary {
  background-color: #41A29D !important;}

.primary-color {
  background-color: #41A29D; }

.bg-danger {
  background-color: #E05D5D !important; }

.primary-color-text {
  color: #0178fa; }

.bg-info {
  background-color: #39afd1 !important; }

.bg-success {
  background-color: #0acf97 !important; }

.info-color {
  background-color: #f1536e; }

.danger-color {
  background-color: #f1536e; }

.success-color {
  background-color: #1dc9b7; }

.bg-success {
  background-color: #1dc9b7 !important; }

.success-text {
  color: #1dc9b7; }

.uppercase-text {
  text-transform: uppercase !important; }

.warning-color {
  background-color: #fda006; }

.bg-purple {
  background-color: #6610f2; }

.bg-blue {
  background-color: #0168fa; }

.bg-teal {
  background-color: #1dc9b7; }

.bg-chok {
  background-color: #462f3f; }

.pink-darken-4 {
  background-color: #880e4f; }

.g-transition-0_8 {
  -webkit-transition-duration: 0.8s;
  -o-transition-duration: 0.8s;
  transition-duration: 0.8s; }

.text-color-gray {
  color: #8492a5; }

.g-bg-black {
  background-color: #222222; }

.white-opacity-0_2 {
  border: 1px solid rgba(255, 255, 255, 0.2); }

.border {
  border: 1px solid rgba(72, 94, 144, 0.15); }

.border-2 {
  border: 5px solid rgba(72, 94, 144, 0.15); }

.border-primary {
  border: 8px solid #0178fa !important; }

.border-chok {
  border: 8px solid #462f3f; }

.border-danger {
  border: 8px solid #f1536e !important; }

.alert-outline-primary {
  border-color: #0178fa;
  border-radius: 5px; }

.alert-outline-warning {
  border-color: #fdb901;
  border-radius: 5px; }

.alert-outline-success {
  border-color: #1dc9b7;
  border-radius: 5px; }

.alert-outline-info {
  border-color: #5578eb;
  border-radius: 5px; }

.alert-outline-danger {
  border-color: #f1536e;
  border-radius: 5px; }

.alert-outline-default {
  border-color: rgba(72, 94, 144, 0.15);
  border-radius: 5px;
  background: transparent; }

.alert-outline-dark {
  border-color: #282f3a;
  border-radius: 5px; }

.bg-gray {
  background-color: #f2f4f8; }

.text-black {
  color: #000000 !important; }

.border-radius-50 {
  border-radius: 50px !important; }

.text-muted {
  color: #98a6ad !important; }

.card .header-title {
  margin-bottom: .5rem;
  text-transform: uppercase;
  letter-spacing: .02em;
  font-size: 18px;
  margin-top: 0; }

.btn:focus {
  -webkit-box-shadow: none !important;
  box-shadow: none !important; }

.g-transition-0_3 {
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s; }

button:focus {
  outline: none;
  box-shadow: none; }

.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none; }

.theme-bg-gra {
  background: -webkit-linear-gradient(225deg, #1de9b6 0, #1dc4e9 100%);
  background: -o-linear-gradient(225deg, #1de9b6 0, #1dc4e9 100%);
  background: linear-gradient(-135deg, #1de9b6 0, #1dc4e9 100%); }

.full-height {
  clear: both;
  min-height: 100%; }

.form-control {
  border-top: 0;
  border-right: 0;
  border-left: 0;
  border-radius: 0;
  border-bottom: 2px solid #000;
  height: 40px;
  font-size: 14px; }
.select-form-control {
  border: 1px #41A29D solid;
  background-color: rgba(0, 0, 0, 0);
  padding: 5px;
}
.select-form-control.active,
.select-form-control.focus {
  border: 1px #41A29D solid;
}
.form-right {
  text-align: right;
}
.vdatetime-input {
  height: 30px;
  border-radius: 0;
  font-size: 12px;
  border-color: #dbdbdb;
  display: block;
  width: 100%;
  /* height: calc(1.5em + .75rem + 2px); */
  padding: .375rem .75rem;
  font-size: 1rem;
  /* font-weight: 400; */
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  /* border-radius: .25rem; */
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.form-control .vdatetime-input {
  height: 30px;
  border-radius: 0;
  font-size: 12px;
  border-color: #dbdbdb;
  display: block;
  width: 100%;
  /* height: calc(1.5em + .75rem + 2px); */
  padding: 0;
  font-size: 1rem;
  /* font-weight: 400; */
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 0px;
  /* border-radius: .25rem; */
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.jarallax {
  position: relative;
  z-index: 0; }

.jarallax > .jarallax-img {
  position: absolute;
  object-fit: cover;
  font-family: 'object-fit: cover;';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; }

.jvectormap-zoomin,
.jvectormap-zoomout,
.jvectormap-goback {
  background: #ebebeb;
  color: #323232;
  -webkit-transition-duration: 500ms;
  -o-transition-duration: 500ms;
  transition-duration: 500ms; }
  .jvectormap-zoomin:hover, .jvectormap-zoomin:focus,
  .jvectormap-zoomout:hover,
  .jvectormap-zoomout:focus,
  .jvectormap-goback:hover,
  .jvectormap-goback:focus {
    background: #0178fa;
    color: #ffffff; }

.order-listing,
.unorder-listing {
  padding-left: 20px; }
  .order-listing li,
  .unorder-listing li {
    list-style: inherit; }

.styling-listing li i {
  color: #0178fa;
  padding-right: 5px; }

.visibility-hidden {
  visibility: hidden; }

.h-5 {
  height: 5px; }

.custom--checkbox {
  position: relative;
  display: inline-block;
  margin-bottom: 0;
  width: 15px;
  height: 15px;
  cursor: pointer; }
  .custom--checkbox input {
    top: 0;
    left: 0;
    z-index: 1;
    cursor: pointer;
    opacity: 0;
    position: absolute;
    display: none;
    width: 15px;
    height: 15px; }

.custom--checkbox input:checked + .input-helper:before {
  border-color: #0178fa; }

.custom--checkbox .input-helper:before,
.custom--checkbox .input-helper:after {
  position: absolute;
  content: "";
  transition: all 200ms; }

.custom--checkbox .input-helper:before {
  left: 0;
  border: 2px solid #7a7a7a;
  top: 0;
  width: 15px;
  height: 15px;
  border-radius: 2px;
  color: #fff; }

.custom--checkbox input:checked + .input-helper:before {
  background-color: #0178fa; }

.custom--checkbox input:checked + .input-helper:after {
  opacity: 1; }

.custom--checkbox .input-helper:after {
  content: '\f00c';
  font-size: 12px;
  left: 1px;
  top: 0px;
  color: #fff;
  transform: rotate(-10deg);
  font-family: "FontAwesome";
  opacity: 0; }

.card {
  background-color: #ffffff;
  margin-bottom: 30px;
  border-radius: 10px;
  border: none;
  -webkit-box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
  box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15); }

.font-11 {
  font-size: 11px; }

.font-12 {
  font-size: 12px; }

.font-13 {
  font-size: 13px; }

.font-14 {
  font-size: 14px; }

.font-15 {
  font-size: 15px !important; }

.font-16 {
  font-size: 16px !important; }

.font-18 {
  font-size: 18px !important; }

.font-22 {
  font-size: 22px; }

.font-24 {
  font-size: 24px; }

.font-30 {
  font-size: 30px; }

.font-36 {
  font-size: 36px; }

.font-40 {
  font-size: 40px; }

.apex-charts text {
  fill: #adb6bd; }

.text-info {
  color: #39afd1 !important; }

.text-body {
  color: #6c757d !important; }

.text-title {
  color: #6c757d; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.btn-link:hover {
  text-decoration: none; }

.custom-control-input:checked ~ .custom-control-label::before {
  color: #4d5cf2 !important;
  border-color: #4d5cf2 !important;
  background-color: #4d5cf2 !important; }

.float-right {
  float: right !important; }

.swal2-popup .swal2-styled.swal2-confirm {
  background-color: #4d5cf2 !important;
  font-size: 18px; }

@media only screen and (max-width: 767px) {
  .page-title {
    font-size: 14px; } }

.page-title-box .page-title-right .breadcrumb {
  background-color: transparent;
  padding: 0; }

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: .5rem;
  color: #777; }

.breadcrumb-item a {
  color: #0178fa; }

.breadcrumb-item.active {
  color: #777; }

@media only screen and (max-width: 767px) {
  .breadcrumb-item {
    font-size: 14px; } }

.card-title {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 20px;
  text-transform: capitalize;
  color: #1c273c; }

.map-container {
  position: relative;
  min-width: 100%;
  max-width: 100%;
  display: block;
  padding: 35% 0;
  overflow: hidden;
  border-radius: 5px; }

.map-container .google-map {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%; }

.badge-warning-lighten {
  color: #ffbc00;
  background-color: rgba(255, 188, 0, 0.2); }

.badge-danger-lighten {
  color: #fa5c7c;
  background-color: rgba(250, 92, 124, 0.2); }

.badge-success-lighten {
  color: #0acf97;
  background-color: rgba(10, 207, 151, 0.2); }

.overflow--hidden {
  overflow: hidden !important; }

.h-6 {
  height: 6px !important; }

.h-8 {
  height: 8px !important; }

.h-10 {
  height: 10px !important; }

.user-thumb {
  width: 80px;
  height: 80px; }

.border-radius-50 {
  border-radius: 50% !important; }

.text-color {
  color: #8492a5; }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0; }

.user-img {
  width: 50px;
  height: 50px; }

.custom-select {
  font-size: 14px; }

.dropdown-item {
  font-size: 13px; }

.align-middle {
  vertical-align: middle !important; }
/*
    DEMO STYLE
*/

@import "https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700";
body {
    font-family: 'Poppins', sans-serif;
    background: #EAF0F7;
    font-size: 13px;
}

p {
  font-family: 'Poppins', sans-serif;
  font-size: 1.1em;
  font-weight: 300;
  line-height: 1.7em;
  color: rgb(65, 65, 65);
}

a,
a:hover,
a:focus {
    color: inherit;
    text-decoration: none;
    transition: all 0.3s;
}

.navbar {
    padding: 15px 10px;
    background: #fff;
    border: none;
    border-radius: 0;
    margin-bottom: 40px;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
}

.navbar-btn {
    box-shadow: none;
    outline: none !important;
    border: none;
}

.line {
    width: 100%;
    height: 1px;
    border-bottom: 1px dashed #ddd;
    margin: 40px 0;
}

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */
.wrapper {
    display: flex;
    align-items: stretch;
}

#sidebar {
    width: 75px;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    z-index: 1;
    background: #fff;
    color: #fff;
    transition: all 0.3s;
}
#sidebar.active {
    margin-left: -100px;
}

#sidebar .sidebar-header {
  width: 100%;
  height: 70px;
  /* -webkit-box-shadow: 0 5px 15px -5px #cdd1e1; */
  /* box-shadow: 0 5px 15px -5px #cdd1e1; */
}
#sidebar .sidebar-header a {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 75px;
  background-color: #fff;
  color: #000;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  height: 70px;
  -webkit-transition-duration: 500ms;
  -o-transition-duration: 500ms;
  transition-duration: 500ms;
  padding: 0 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  /* -webkit-box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08); */
  /* box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08); */
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}

#sidebar .sidebar-header a img {
    max-height: 100px;
    width: auto;
    margin: auto;
}
#sidebar .sidebar-header a img.small-logo {
  max-height: 100px;
}

.side-menu-area {
  position: relative;
  width: 100%;
  /* padding-top: 50px; */
  z-index: 10;
  -webkit-transition-duration: 500ms;
  -o-transition-duration: 500ms;
  transition-duration: 500ms;
  background-color: #fff;
  color: #000;
}

.sidebar-menu li {
  position: relative;
  z-index: 1;
}

.sidebar-menu > span > li > a:hover,
.sidebar-menu > span > li.active > a,
.sidebar-menu > span > li.menu-open > a {
  color: #41A29D;
  background-color: transparent;
}

.sidebar-menu li a {
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  padding: 27px;
  color: #000;
  font-size: 12px;
  font-weight: 800;
  text-transform: capitalize;
  height: 54px;
  border-left: 3px solid transparent;
}
.sidebar-menu li a .bottom{
  bottom: 10px;
}
.sidebar-menu li a i {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25px;
  flex: 0 0 25px;
  max-width: 25px;
  width: 25px;
  font-size: 16px;
}

.sidebar-menu .menu-open a .fa-angle-right {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.sidebar-menu a .fa-angle-right {
  -webkit-transition-duration: 500ms;
  -o-transition-duration: 500ms;
  transition-duration: 500ms;
  font-size: 16px;
  position: absolute;
  right: 30px;
  width: auto;
  z-index: auto;
}
.sidebar-menu li .treeview-menu {
  display: none;
  margin-left: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.sidebar-menu li .treeview-menu li a {
  font-size: 13px;
  color: #3b3b3b;
  margin-left: 0;
  margin-right: 0;
  position: relative;
  z-index: 1;
  height: 38px;
  padding-left: 1rem;
}
.sidebar-menu li .treeview-menu li a:hover {
  color: #4764b4;
}

.side-menu-area .sidebar-menu > li > a > span {
  line-height: 1;
  -webkit-transition-duration: 500ms;
  -o-transition-duration: 500ms;
  transition-duration: 500ms;
}
.sidebar-menu li a span {
  -webkit-transition-duration: 500ms;
  -o-transition-duration: 500ms;
  transition-duration: 500ms;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 calc(100% - 30px);
  flex: 0 0 calc(100% - 30px);
  max-width: calc(100% - 30px);
  width: calc(100% - 30px);
}
.sidebar-menu > .active > .treeview-menu {
 display: block;
}

.menu-collapsed-active.wrapper #sidebar {
  left: -100px;
}
.menu-collapsed-active.wrapper #sidebar .sidebar-header a {
  left: -100px;
}
.menu-collapsed-active.wrapper #content {
  width: 100%;
}
.menu-collapsed-active.wrapper .mobile-logo {
    display: block;
  }
.menu-collasped-active.wrapper .ecaps-page-content {
  margin-left: 55px; }

.menu-collapsed-active.wrapper .ecaps-logo a {
  width: 55px; }

.menu-collapsed-active.wrapper .top-header-area {
  left:0;
  width: 100%;
}

.menu-collapsed-active.wrapper .top-list-toolbar {
  left: 0;
  width: 100%;
}

.menu-collapsed-active.wrapper .bottom-toolbar {
  left:0;
  width: 100%;
}

.mobile-collapsed-active.wrapper #sidebar {
  left: 0;
}
/* ---------------------------------------------------
    TOP HEADER
----------------------------------------------------- */
.top-header-area {
  position: fixed;
  z-index: 990;
  width: calc(100% - 75px);
  height: 70px;
  /* background-color: #00A19D; */
  background-color: #EAF0F7;
  left: 100px;
  top: 0;
  color: white;
  -webkit-transition-duration: 500ms;
  -o-transition-duration: 500ms;
  transition-duration: 500ms;
  /* -webkit-box-shadow: 0 5px 15px -5px #73788b; */
  /* box-shadow: 0 5px 15px -5px #cdd1e1; */
  padding: 0 15px 0 30px;
}
.top-list-toolbar {
  position: fixed;
  z-index: 980;
  width: calc(100% - 75px);
  height: 100px;
  background-color: #EAF0F7;
  top: 70px;
  left: 75px;
  /* box-shadow: 0 5px 15px -5px #cdd1e1; */
  padding: 10px 50px 10px 50px;
}
.card-list {
  margin-top: 75px;
}
.bottom-toolbar {
  position: fixed;
  z-index: 999;
  width: calc(100% - 75px);
  height: 50px;
  background-color: #c7c8d4;
  right: 0;
  bottom: 0;
  -webkit-transition-duration: 500ms;
  -o-transition-duration: 500ms;
  transition-duration: 500ms;
  -webkit-box-shadow: 0 5px 15px -5px #cdd1e1;
  box-shadow: 0 5px 15px -5px #cdd1e1;
  padding: 10px;
}
.bottom-toolbar .toolbar-text {
  color: white;
  font-weight: bold;
  font-size: 12px;
}

.top-header-area .left-side-content-area {
    position: relative;
    z-index: 1;
    -webkit-box-flex: 0;
    -ms-box-flex: 0;
    flex: 0 0 50%;
    max-width: 50%;
    width: 50%;
}
.mobile-logo a {
    position: relative;
    z-index: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.mobile-logo a img {
  max-height: 35px;
  width: auto;
}
.menu-collasped {
    position: relative;
    z-index: 10;
    width: 30px;
    height: 30px;
    cursor: pointer;
}
.menu-collasped > i {
  font-size: 22px;
  color: #1c273c;
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 5;
}
.mobile-menu-open {
    position: relative;
    z-index: 1;
    width: 30px;
    height: 30px;
    cursor: pointer;
}
.mobile-menu-open > i {
  font-size: 22px;
  color: #0178fa;
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 5;
}
/* RIGHT TOP BAR */
.top-header-area .right-side-navbar {
    position: relative;
    z-index: 1;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
    width: 50%;
}
.top-header-area .right-side-navbar .right-side-content {
    position: relative;
    z-index: 1;
}

.app-search {
  position: relative;
  margin-left: 20px; }

.app-search .form-control,
.app-search .form-control:focus {
  border: 1px solid rgba(72, 94, 144, 0.15);
  font-size: 13px;
  height: 34px;
  color: #333;
  padding-left: 20px;
  padding-right: 40px;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 5px;
  width: 175px; }
  @media only screen and (max-width: 767px) {
    .app-search .form-control,
    .app-search .form-control:focus {
      width: 160px; } }

.app-search button {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  height: 34px;
  line-height: 34px;
  width: 34px;
  text-align: center;
  border: 1px solid rgba(72, 94, 144, 0.15);
  border-radius: 0 5px 5px 0;
  color: #1c273c;
  background-color: transparent;
}
.right-side-navbar .nav-item.dropdown .btn {
  padding: 0;
  font-size: 20px;
  background-color: transparent;
  margin: 0 15px;
  color: #1c273c;
  border: none;
  line-height: 1;
  position: relative;
  height: 30px;
  width: 30px;
  z-index: 1;
}
.right-side-navbar .dropdown-toggle::after {
    display:none;
}

.right-side-navbar .nav-item.dropdown .btn > img {
    max-width: 30px;
    border-radius: 50%;
    object-fit: cover;
}
.right-side-navbar .nav-item.dropdown .active-status {
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    z-index: -5;
    top: 1px;
    right: -1px;
    border: 3px solid red;
    -webkit-animation: activeStatus linear 1500ms infinite;
    animation: activeStatus linear 1500ms infinite;
}
@-webkit-keyframes activeStatus {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }
@keyframes activeStatus {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }
.main-menu {
  color: #000000;
}
.main-menu .dropdown-menu {
  top: 25px !important;
  left: 100px !important;
  min-width: 940px !important;
  background-color: #ffffff;
  -webkit-box-shadow: 0px 4px 4px 0px #b8b8b8;
  box-shadow: 0px 4px 4 0px #b8b8b8;
}
.main-menu .dropdown-toggle::after {
  display: none !important;
}
.main-menu .dropdown-header {
  color: #0178fa;
}
.main-menu .dropdown-item:hover {
  color: #0178fa;
}
.top-header-area .nav-item.dropdown .dropdown-menu {
  /* -webkit-animation-name: dropdownAnimation;
  animation-name: dropdownAnimation;
  -webkit-animation-duration: 350ms;
  animation-duration: 350ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both; */
  border: none;
  border-radius: 0;
  -webkit-box-shadow: 0px 4px 4px 0px #3b3b3b;
  box-shadow: 0px 4px 4px 0px #3b3b3b;
  width: 300px;
  padding: 0;
  margin: 0;
  top: 25px !important;
}
/* .top-header-area .nav-item.dropdown .dropdown-menu .show {
  top: 20px;
} */
.top-header-area .nav-item.dropdown .dropdown-menu .dropdown-item {
  white-space: normal;
  color: #8492a5;
  font-size: 13px;
}
.top-notifications-area .notifications-heading {
  width: 100%;
  height: 50px;
  background-color: #41A29D;
  border-radius: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-left: 1rem;
  padding-right: 1rem;
}
.top-notifications-area .notifications-heading .heading-title h6 {
  color: #ffffff;
  display: inline-block;
  margin-bottom: 0;
  font-size: 14px;
}
.top-notifications-area .notifications-heading > span {
  color: #0178fa;
  padding: 2px 5px 1px 5px;
  background-color: #ffffff;
  border-radius: 40px;
  font-size: 10px;
  font-weight: 700;
}
.notifications-box .dropdown-item, .notifications-box .nav-link {
  padding: 1rem;
  border-bottom: 1px solid rgba(72, 94, 144, 0.15);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #8c8d9a;
  font-size: 13px;
}
/* User Area */
.user-profile-area {
  padding-bottom: 1rem;
  z-index: 1;
  position: relative; }

.user-profile-area .dropdown-item {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.user-profile-area .user-profile-heading {
  width: 100%;
  height: 70px;
  background-color: #41A29D;
  border-radius: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin-bottom: 1.5rem; }

.user-profile-area .profile-thumbnail {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 30px;
  flex: 0 0 30px;
  max-width: 30px;
  width: 30px;
  margin-right: 10px; }

.user-profile-area .profile-text h6 {
  font-size: 14px;
  color: #ffffff;
  margin-bottom: 0; }

.user-profile-area .profile-text span {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.9);
  margin-bottom: 0;
  display: block; }
.user-profile-area .dropdown-item {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}
.top-header-area .nav-item.dropdown .dropdown-menu .dropdown-item {
    white-space: normal;
    color: #8492a5;
    font-size: 13px;
}

.top-message-area .top-message-heading {
    width: 100%;
    height: 50px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding-left: 1rem;
    padding-right: 1rem;
    background-color: #0178fa;
}
/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */

#content {
    margin-top: 70px;
    width: calc(100% - 75px);
    padding: 20px;
    min-height: 100vh;
    transition: all 0.3s;
    position:absolute;
    top: 70;
    right: 0;
}

#content.active {
    width: 100%;
}

.page-title-box {
  padding: 25px 0;
}
.page-title-box .page-title {
  font-size: 18px;
  margin: 0;
  color: #50649c;
}

.page-title-box .breadcrumb {
  margin-bottom: 0;
  padding: 2px 0;
  background-color: transparent;
}
.breadcrumb {
  background-color: transparent;
  margin-bottom: 20px;
  padding-top: 10px;
  padding-left: 0;
}
.card {
  margin-bottom: 24px;
  border: none;
  -webkit-box-shadow: 0 0 3px rgba(31,30,47,.05);
  box-shadow: 0 0 3px rgba(31,30,47,.05);
  border-radius: 10px;
  background-color: #fff;
}
.header-title, .title-text {
  margin-bottom: 8px;
  text-transform: capitalize;
  letter-spacing: .02em;
  font-size: 15px;
  font-weight: 500;
  margin-top: 0;
  color: #50649c;
  text-shadow: 0 0 1px rgba(239,242,249,.1);
}
.text-muted {
  color: #a4abc5!important;
}
/* ---------------------------------------------------
    TABLE
----------------------------------------------------- */
.table-item th {
  border-top: 0px;
  cursor: pointer;
  background-color: #41A29D;
  color: #fff;
  font-weight: bold;
  font-size: 11px;
}
.table-item {
  margin-top: 10px;
  border: 0;
}

.table-item tr {
  cursor: pointer;
}
.table-item .form-control {
  height: 30px;
  border-radius: 0;
  font-size: 12px;
}
.table-item thead tr th .fa {
  color:rgb(139, 139, 139);
  margin-left: 10px;
}
.table-item tfoot tr {
  border: 1px solid #fff;
}
/* .table-search {
  position: relative;
}

.table-search .form-control,
.table-search .form-control:focus {
  border: 1px solid rgba(72, 94, 144, 0.15);
  font-size: 13px;
  height: 34px;
  color: #333;
  padding-left: 20px;
  padding-right: 40px;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 5px;
}

.table-search button {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  height: 34px;
  line-height: 34px;
  width: 34px;
  text-align: center;
  border: 1px solid rgba(72, 94, 144, 0.15);
  border-radius: 0 5px 5px 0;
  color: #1c273c;
  background-color: #fff;
  z-index: 999;
} */
/* ---------------------------------------------------
    FORM
----------------------------------------------------- */
.btn-toolbar {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
    margin-bottom: 20px;
}
.btn-soft-primary {
  background-color: #41A29D;
  color: #E7F1FD;
  font-size: 12px;
}

.btn-soft-secondary {
  background-color: #00A19D;
  color: #E7F1FD;
  font-size: 12px;
}
.btn-soft-info {
  background-color: #FFF8E5;
  color: #E7F1FD;
  font-size: 12px;
}

.btn-soft-warning {
  background-color: #FFB344;
  color: #000;
  font-size: 12px;
}
.btn-soft-danger {
  background-color: #E05D5D;
  color: #000;
  font-size: 12px;
}
.badge-soft-primary {
  background-color: #41A29D;
  color: #fff;
  padding: 5px;
}
.btn-outline-soft-primary {
  border: 1px solid #41A29D;
  background-color: #fff;
  color: #41A29D;
}
.btn-table-detail {
  height: 30px;
  font-size: 10px;
}
.form-access{
  margin-bottom: 10px;
}
.form-back {
  font-size: 24px;
  color: #2344B3;
}
.btn-table-sm{
  padding:4px 6px;
  font-size:80%;
  line-height: 1;
}
.btn-table-sm i{
  font-size:80%;
}
.vs__search,
.vs__search:focus {
  color: black;
}
.input-label {
  border-color: #ffffff !important;
  background-color: #ffffff;
}
.input-search {
  border: 0;
  border-bottom: 1px solid #000;
  border-radius: 0;
}

.input-fn {
  border: 0;
  border-bottom: 1px solid;
  border-radius: 0;
}

/* :: Form CSS */
.form-group label {
  font-size: 0.874rem; }

.form-control {
  border-color: #dbdbdb; }
.form-control:focus {
  border-color: #41A29D;
  box-shadow: none; }
.form-control:disabled, .form-control[readonly] {
  background-color: #F7F7F7;
  border: 0;
}
@media (max-width: 767px) {
  .form-group > label.align-label {
    text-align: left !important;
  }
}

.input-group,
.asColorPicker-wrap {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%; }

.form-group .file-upload-default {
  display: none; }

.success-box {
  margin: 50px 0;
  padding: 10px 10px;
  border: 1px solid rgba(72, 94, 144, 0.15);
  background: #f2f4f8; }
  .success-box div {
    vertical-align: top;
    display: inline-block;
    color: #777; }
  .success-box img {
    margin-right: 10px;
    display: inline-block;
    vertical-align: top; }

#example-vertical-wizard h3 {
  font-size: 20px; }

#demo {
  z-index: 99999999999 !important;
  position: relative; }

.input-form-area .nice-select {
  width: 80%;
  border-radius: 0;
  height: auto; }
.input-form-area .form-control {
  height: auto; }

.input-group-text {
  background-color: #fff; }

.typeahead-form-area .twitter-typeahead {
  width: 100%; }
.typeahead-form-area .typeahead.form-control.tt-input {
  font-size: 14px; }
.typeahead-form-area .tt-menu {
  background-color: #f8f8f8;
  padding: 15px;
  -webkit-box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.3);
  box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.3); }
  .typeahead-form-area .tt-menu .tt-suggestion.tt-selectable {
    font-size: 14px;
    margin-bottom: 10px;
    color: #1c273c;
    font-weight: 300;
    cursor: pointer; }

.layout-form-area .nice-select {
  height: calc(2.25rem + 2px);
  line-height: calc(2.25rem + 2px); }

.form-check-input {
  margin-left: 0;
}
/* Nav Tab */
.nav-bordered {
  /* border-bottom: 2px solid rgba(152, 166, 173, 0.25) !important; */
  border-bottom: 2px solid #ffffff !important;
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  color: #495057;
  background-color: #fff;
  border-color: #fff;
}
.nav-bordered a.active {
  border-bottom: 2px solid #41A29D !important;
}
.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
}
.nav-bordered li a {
  border: 0 !important;
  padding: 10px 20px !important;
}
.nav-pills > li > a, .nav-tabs > li > a {
    color: #6c857d;
    font-weight: 500;
    font-size: 15px;
}
.tab-content {
  padding: 20px 0 0 0;
}
/* Notification */
.notifications {
  padding: 10px;
  margin: 5px 5px 5px;

  font-size: 12px;

  color: #ffffff;
  /* background: #44A4FC; */
  /* border-left: 5px solid #187FE7; */
}
.notifications .warn {
  background: #ffb648;
  border-left-color: #f48a06;
}

.notifications .error {
  background: rgb(221, 13, 204);
  border-left-color: #de18a6;
}
.notifications .success {
  background: #68CD86;
  border-left-color: #42A85F;
}
.link-select {
  color: blue;
  font-weight: bold;
}
.breadcrumb-card-header {
  margin-bottom: 0;
}
/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */
@media (max-width: 768px) {
  /* #sidebar {
    margin-left: -220px;
  }
  #sidebar .sidebar-header a {
    margin-left: -220px;
  } */
  #sidebar.active {
    margin-left: 0;
  }
  #content {
    width: 100%;
  }
  #content.active {
    width: calc(100% - 200px);
  }
  #sidebarCollapse span {
    display: none;
  }
}
@media only screen and (max-width: 991px) {
  #sidebar {
    left: -100px;
  }
  #sidebar .sidebar-header a {
    margin-left: -50px;
  }
  #sidebar.active {
    margin-left: 0;
  }
  #content {
    width: 100%;
  }
  #content.active {
    width: calc(100% - 75px);
  }
  #sidebarCollapse span {
    display: none;
  }
  .top-header-area {
    left: 0;
    z-index: 100;
    width: 100%;
    padding: 0 0 0 15px;
  }
  .bottom-toolbar {
    left: 0;
    z-index: 100;
    width: 100%;
    height: 15px;
    padding-top: 1px;
  }
  .bottom-toolbar .btn{
    padding: 1px 1px;
    font-size: 5px;
    border-radius: 0;
  }
  .top-list-toolbar {
    left: 0;
    z-index: 100;
    width: 100%;
  }
  .menu-collasped {
    display: none;
  }
}
@media only screen and (min-width: 992px) {
  .mobile-menu-open {
    display: none;
  }
  .mobile-logo {
    display: none;
  }
}
</style>
