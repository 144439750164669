<template>
    <div>
        <div class="modal hide fade" id="modalSupplierMemo" tabindex="-1" role="dialog" aria-labelledby="modalSupplierMemo" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
              <div class="modal-content">
                  <div class="modal-header red darken-3 text-left text-white">
                      <h4 class="modal-title w-100 font-weight-bold">Payment Info:</h4>
                      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                      </button>
                  </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group row">
                                <label for="pkp" class="col-sm-3 col-form-label text-right align-label">&nbsp;</label>
                                <div class="col-sm-9">
                                    <div class="custom-control custom-checkbox">
                                    <input v-model="supplier.pkp" type="checkbox" class="custom-control-input" id="supplier-pkp" data-parsley-multiple="groups" data-parsley-mincheck="2" :disabled="supplier.lock">
                                    <label class="custom-control-label" for="supplier-pkp">Pengusaha Kena Pajak</label>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="name" class="col-sm-3 col-form-label text-right align-label">Supplier No.</label>
                                <div class="col-sm-9">
                                    <input type="text" v-model="supplier.supplier_no" class="form-control" disabled="disabled">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="name" class="col-sm-3 col-form-label text-right align-label">Name</label>
                                <div class="col-sm-9">
                                    <input type="text" v-model="supplier.name" class="form-control" disabled="disabled">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="phone" class="col-sm-3 col-form-label text-right align-label">Phone</label>
                                <div class="col-sm-9">
                                    <input type="text" v-model="supplier.phone" class="form-control" disabled="disabled">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="address" class="col-sm-3 col-form-label text-right align-label">Address</label>
                                <div class="col-sm-9">
                                    <textarea v-model="supplier.address" class="form-control"  disabled="disabled"></textarea>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="vat" class="col-sm-3 col-form-label text-right align-label">NPWP</label>
                                <div class="col-sm-9">
                                    <input type="text" v-model="supplier.vat" class="form-control" disabled="disabled">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="description" class="col-sm-3 col-form-label text-right align-label">Description</label>
                                <div class="col-sm-9">
                                    <textarea v-model="supplier.description" class="form-control" disabled="disabled" rows="3"/>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group row">
                                <h5 class="col-sm-3 text-right align-label">Person In Charge:</h5>
                                <div class="col-sm-9">&nbsp;</div>
                            </div>
                            <div class="form-group row">
                                <label for="pic_name" class="col-sm-3 col-form-label text-right align-label">PIC Name</label>
                                <div class="col-sm-9">
                                    <input type="text" class="form-control" v-model="supplier.pic_name" disabled="disabled"/>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="pic_phone" class="col-sm-3 col-form-label text-right align-label">PIC Phone</label>
                                <div class="col-sm-9">
                                    <input type="text" class="form-control" v-model="supplier.pic_phone" disabled="disabled"/>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="pic_jabatan" class="col-sm-3 col-form-label text-right align-label">PIC Jabatan</label>
                                <div class="col-sm-9">
                                    <input type="text" class="form-control" v-model="supplier.pic_jabatan" disabled="disabled"/>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="pic_email" class="col-sm-3 col-form-label text-right align-label">PIC Email</label>
                                <div class="col-sm-9">
                                    <input type="text" class="form-control" v-model="supplier.pic_email" disabled="disabled"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="table-responsive">
                                <table class="table table-item mb-0 table-sm table-hover">
                                    <thead>
                                        <th>alias</th>
                                        <th>Type</th>
                                        <th>bank</th>
                                        <th>Account No</th>
                                        <th>Account Name</th>
                                        <th>Billing No.</th>
                                    </thead>
                                    <tbody>
                                        <tr v-for="a in supplier.accounts" :key="a.id">
                                            <td>{{ a.alias }}</td>
                                            <td>{{ a.type }}</td>
                                            <td>{{ a.bank_id }}</td>
                                            <td>{{ a.bank_account }}</td>
                                            <td>{{ a.bank_account_name }}</td>
                                            <td>{{ a.billing_no }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                </div>
              </div>
          </div>
      </div>
    </div>
</template>

<script>
export default {
  name: 'MemoSupplierModal',
  props: ['supplier'],
  data () {
    return {
      notes: null
    }
  },
  methods: {
    process: function () {
      var obj = {
        notes: this.notes
      }
      this.notes = null
      this.$emit('processRevise', obj)
    }
  }
}
</script>
