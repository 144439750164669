<template>
  <div class="home">
    <MemoCategoryList v-show="listView" @newCategory="onClickNewFromList" @editCategory="onClickEditFromList" ref="refreshList"/>
    <MemoCategoryForm v-show="formView" :category="category" @backClicked="onBackChild" @backParentID="backParentID"/>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios'
import MemoCategoryList from '@/components/memo/MemoCategoryList.vue'
import MemoCategoryForm from '@/components/memo/MemoCategoryForm.vue'
import { getHeader, memoUrl } from '../config'

export default {
  name: 'MemoCategory',
  components: {
    MemoCategoryList,
    MemoCategoryForm
  },
  data () {
    return {
      listView: true,
      formView: false,
      category: {
        id: null,
        name: null,
        company_no: null
      }
    }
  },
  methods: {
    backParentID: function (id) {
      axios.get(memoUrl + '/category/' + id, { headers: getHeader() })
        .then(response => {
          this.listView = false
          this.formView = true
          this.category = response.data.data
        })
        .catch(error => {
          console.log(error.response)
          this.$swal(
            'Error',
            error.response.data.message,
            'error'
          )
        })
    },
    onClickEditFromList: function (value) {
      this.listView = false
      this.formView = true
      this.category = value
    },
    getEdit: function (value) {
      console.log(memoUrl + '/category/' + value)
      axios.get(memoUrl + '/category/' + value, { headers: getHeader() })
        .then(response => {
          console.log(response.data.data)
          this.listView = false
          this.formView = true
          this.category = response.data.data
        })
        .catch(error => {
          console.log(error.response)
        })
    },
    onClickNewFromList: function () {
      this.listView = false
      this.formView = true
      this.category = {
        id: null,
        name: null,
        company_no: null
      }
    },
    onBackChild: function () {
      this.listView = true
      this.formView = false
      this.category = {
        id: null,
        name: null,
        company_no: null
      }
      this.$refs.refreshList.refreshList()
      // this.$router.replace({ path: 'sales', query: null })
    }
  },
  mounted () {
    this.$store.dispatch('getCompanyList')
    this.$store.dispatch('getRowTable')
  }
}
</script>
