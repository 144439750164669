<template>
    <div class="row">
      <loading
        :active.sync="loader.isLoading"
        :can-cancel="loader.canCancel"
        :is-full-page="loader.fullPage"
        :color="loader.color"
        :height="loader.height"
        :width="loader.width"
        :background-color="loader.backgroundColor"
        :loader="loader.loader">
      </loading>
      <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <h5>Supplier</h5>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <ul class="nav nav-tabs nav-bordered">
                    <li class="nav-item"><a href="#entry" data-toggle="tab" aria-expanded="false" class="nav-link active" @click="resetFilter">Entry <span class="badge badge-success">{{ countEntry }}</span></a></li>
                    <li class="nav-item"><a href="#reject" data-toggle="tab" aria-expanded="false" class="nav-link" @click="resetFilter">Reject <span class="badge badge-success">{{ countReject }}</span></a></li>
                    <li class="nav-item"><a href="#revise" data-toggle="tab" aria-expanded="false" class="nav-link" @click="resetFilter">Revise <span class="badge badge-success">{{ countRevise }}</span></a></li>
                    <li class="nav-item"><a href="#approve" data-toggle="tab" aria-expanded="false" class="nav-link" @click="resetFilter">Active <span class="badge badge-success">{{ countApprove }}</span></a></li>
                  </ul>
                  <div class="tab-content">
                    <div class="tab-pane active" id="entry">
                      <div class="row">
                        <div class="col-md-12">
                          <SupplierListEntry @editList="editList" @addList="addList" ref="supplierListEntry"/>
                        </div>
                      </div>
                    </div>
                    <div class="tab-pane" id="reject">
                      <SupplierListReject @editList="editList" @addList="addList" ref="supplierListReject"/>
                    </div>
                    <div class="tab-pane" id="revise">
                      <SupplierListRevise @editList="editList" @addList="addList" ref="supplierListRevise"/>
                    </div>
                    <div class="tab-pane" id="approve">
                      <SupplierListApprove @editList="editList" @addList="addList" ref="supplierListApprove"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
      <!-- <pre>{{ checkFilter }}</pre> -->
      <!-- <pre>{{ dataRaw }}</pre> -->
    </div>
</template>

<script>
import axios from 'axios'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import { getHeader, supplierUrl } from '../../config'
import { mapState } from 'vuex'
import SupplierListApprove from '@/components/supplier/SupplierListApprove.vue'
import SupplierListEntry from '@/components/supplier/SupplierListEntry.vue'
import SupplierListReject from '@/components/supplier/SupplierListReject.vue'
import SupplierListRevise from '@/components/supplier/SupplierListRevise.vue'

export default {
  name: 'SupplierList',
  components: {
    Loading,
    SupplierListApprove,
    SupplierListEntry,
    SupplierListReject,
    SupplierListRevise
  },
  data () {
    var date = new Date()
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0)
    const date1 = new Date(firstDay.getFullYear(), firstDay.getMonth(), firstDay.getDate())
    const date2 = new Date(lastDay.getFullYear(), lastDay.getMonth(), lastDay.getDate())
    return {
      filterStatus: [],
      startDate: [date1, date2],
      loader: {
        isLoading: false,
        fullPage: true,
        color: '#007bff',
        canCancel: true,
        height: 128,
        width: 128,
        backgroundColor: '#ffffff',
        loader: 'spinner'
      },
      dataRaw: [],
      data: {},
      searchQuery: '',
      ascending: false,
      sortClick: false,
      sortColumn: '',
      pageSize: 50,
      currentPage: 1,
      checkFilter: [],
      title: [{
        name: 'Status',
        prop: 'status'
      }, {
        name: 'lock',
        prop: 'lock'
      }, {
        name: 'Active',
        prop: 'active'
      }, {
        name: 'Supplier No.',
        prop: 'supplier_no'
      }, {
        name: 'Name',
        prop: 'name'
      }, {
        name: 'Company',
        prop: 'company'
      }],
      selectAll: false,
      selected: []
    }
  },
  mounted () {
    this.refreshList()
  },
  computed: {
    ...mapState({
      rowsTable: state => state.data.rowsTable
    }),
    countApprove: function () {
      var datalength = 0
      var data = this.dataRaw.filter(d => d.status === 'approve')
      if (data) {
        datalength = data.length
      }
      return datalength
    },
    countReject: function () {
      var datalength = 0
      var data = this.dataRaw.filter(d => d.status === 'reject')
      if (data) {
        datalength = data.length
      }
      return datalength
    },
    countRevise: function () {
      var datalength = 0
      var data = this.dataRaw.filter(d => d.status === 'revise')
      if (data) {
        datalength = data.length
      }
      return datalength
    },
    countEntry: function () {
      var datalength = 0
      var data = this.dataRaw.filter(d => d.status === 'entry')
      if (data) {
        datalength = data.length
      }
      return datalength
    },
    pageLengthApprove: function () {
      var length = this.countApprove / this.pageSize
      return Math.ceil(length)
    },
    pageLengthReject: function () {
      var length = this.countReject / this.pageSize
      return Math.ceil(length)
    },
    pageLengthRevise: function () {
      var length = this.countRevise / this.pageSize
      return Math.ceil(length)
    },
    pageLengthEntry: function () {
      var length = this.countEntry / this.pageSize
      return Math.ceil(length)
    },
    filterData: function () {
      if (this.searchQuery.length > 0) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.currentPage = 1
      }
      var col = this.sortColumn
      var ascending = this.ascending
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.dataRaw.sort(function (a, b) {
        if (a[col] > b[col]) {
          return ascending ? 1 : -1
        } else if (a[col] < b[col]) {
          return ascending ? -1 : 1
        }
        return 0
      }).filter(d => d.status === 'entry').filter(d => {
        for (const name in d) {
          if (d[name] !== null) {
            if (d[name].toString().toLowerCase().indexOf(this.searchQuery.toLowerCase()) > -1) {
              return d
            }
          }
        }
      }).filter(d => {
        if (this.filterStatus.length > 0) {
          for (let a = 0; a < this.filterStatus.length; a++) {
            if (d.status === this.filterStatus[a]) {
              return d
            }
          }
        } else {
          return d
        }
      }).filter((row, index) => {
        const start = (this.currentPage - 1) * this.pageSize
        const end = this.currentPage * this.pageSize
        if (index >= start && index < end) return true
      })
    },
    filterDataReject: function () {
      if (this.searchQuery.length > 0) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.currentPage = 1
      }
      var col = this.sortColumn
      var ascending = this.ascending
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.dataRaw.sort(function (a, b) {
        if (a[col] > b[col]) {
          return ascending ? 1 : -1
        } else if (a[col] < b[col]) {
          return ascending ? -1 : 1
        }
        return 0
      }).filter(d => d.status === 'reject').filter(d => {
        for (const name in d) {
          if (d[name] !== null) {
            if (d[name].toString().toLowerCase().indexOf(this.searchQuery.toLowerCase()) > -1) {
              return d
            }
          }
        }
      }).filter(d => {
        if (this.filterStatus.length > 0) {
          for (let a = 0; a < this.filterStatus.length; a++) {
            if (d.status === this.filterStatus[a]) {
              return d
            }
          }
        } else {
          return d
        }
      }).filter((row, index) => {
        const start = (this.currentPage - 1) * this.pageSize
        const end = this.currentPage * this.pageSize
        if (index >= start && index < end) return true
      })
    },
    filterDataRevise: function () {
      if (this.searchQuery.length > 0) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.currentPage = 1
      }
      var col = this.sortColumn
      var ascending = this.ascending
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.dataRaw.sort(function (a, b) {
        if (a[col] > b[col]) {
          return ascending ? 1 : -1
        } else if (a[col] < b[col]) {
          return ascending ? -1 : 1
        }
        return 0
      }).filter(d => d.status === 'revise').filter(d => {
        for (const name in d) {
          if (d[name] !== null) {
            if (d[name].toString().toLowerCase().indexOf(this.searchQuery.toLowerCase()) > -1) {
              return d
            }
          }
        }
      }).filter(d => {
        if (this.filterStatus.length > 0) {
          for (let a = 0; a < this.filterStatus.length; a++) {
            if (d.status === this.filterStatus[a]) {
              return d
            }
          }
        } else {
          return d
        }
      }).filter((row, index) => {
        const start = (this.currentPage - 1) * this.pageSize
        const end = this.currentPage * this.pageSize
        if (index >= start && index < end) return true
      })
    },
    filterDataApprove: function () {
      if (this.searchQuery.length > 0) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.currentPage = 1
      }
      var col = this.sortColumn
      var ascending = this.ascending
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.dataRaw.sort(function (a, b) {
        if (a[col] > b[col]) {
          return ascending ? 1 : -1
        } else if (a[col] < b[col]) {
          return ascending ? -1 : 1
        }
        return 0
      }).filter(d => d.status === 'approve').filter(d => {
        for (const name in d) {
          if (d[name] !== null) {
            if (d[name].toString().toLowerCase().indexOf(this.searchQuery.toLowerCase()) > -1) {
              return d
            }
          }
        }
      }).filter(d => {
        if (this.filterStatus.length > 0) {
          for (let a = 0; a < this.filterStatus.length; a++) {
            if (d.status === this.filterStatus[a]) {
              return d
            }
          }
        } else {
          return d
        }
      }).filter((row, index) => {
        const start = (this.currentPage - 1) * this.pageSize
        const end = this.currentPage * this.pageSize
        if (index >= start && index < end) return true
      })
    }
  },
  methods: {
    resetFilter () {
      this.currentPage = 1
      this.searchQuery = ''
      this.filterStatus = []
    },
    setPageSizeOnChange: function () {
      this.currentPage = 1
    },
    sortTable: function sortTable (col) {
      this.currentPage = 1
      if (this.sortColumn === col) {
        this.ascending = !this.ascending
      } else {
        this.ascending = true
        this.sortColumn = col
      }
    },
    nextPageEntry: function () {
      if ((this.currentPage * this.pageSize) < this.countEntry) this.currentPage++
    },
    nextPageReject: function () {
      if ((this.currentPage * this.pageSize) < this.countReject) this.currentPage++
    },
    nextPageRevise: function () {
      if ((this.currentPage * this.pageSize) < this.countRevise) this.currentPage++
    },
    nextPageApprove: function () {
      if ((this.currentPage * this.pageSize) < this.countApprove) this.currentPage++
    },
    prevPage: function () {
      if (this.currentPage > 1) this.currentPage--
    },
    select () {
      this.selected = []
      this.currentPage = 1
      if (!this.selectAll) {
        for (const i in this.filterData) {
          this.selected.push(this.filterData[i].id)
        }
      }
    },
    editList (id) {
      axios.get(supplierUrl + '/' + id, { headers: getHeader() })
        .then(response => {
          this.$emit('editSupplier', response.data.data)
        })
        .catch(error => {
          console.log(error.response)
          this.$swal(
            'Error!!!',
            error.response.data.message,
            'error'
          )
        })
    },
    addList () {
      this.$emit('newSupplier', true)
    },
    async refreshList () {
      this.$Progress.start()
      this.loader.isLoading = true
      var url = supplierUrl
      console.log(url)
      await axios.get(url, { headers: getHeader() })
        .then(response => {
          this.dataRaw = response.data.data
          this.$Progress.finish()
          this.loader.isLoading = false
          this.currentPage = 1
          this.searchQuery = ''
          this.$refs.supplierListEntry.refreshList()
          this.$refs.supplierListReject.refreshList()
          this.$refs.supplierListRevise.refreshList()
          this.$refs.supplierListApprove.refreshList()
        })
        .catch(error => {
          console.log(error.response)
          this.$toasted.show(error.response.data.message, {
            theme: 'toasted-primary',
            position: 'top-right',
            duration: 5000,
            icon: 'check',
            type: 'error'
          })
        })
    }
  }
}
</script>
