<template>
  <div class="home">
    <BankList v-show="listView" @newBank="onClickNewFromList" @editBank="onClickEditFromList" ref="refreshList"/>
    <BankForm v-show="formView" :bank="bank" @backClicked="onBackChild" @backParentID="backParentID"/>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios'
import BankList from '@/components/bank/BankList.vue'
import BankForm from '@/components/bank/BankForm.vue'
import { getHeader, bankUrl } from '../config'

export default {
  name: 'Bank',
  components: {
    BankList,
    BankForm
  },
  data () {
    return {
      listView: true,
      formView: false,
      bank: {
        id: null,
        name: null
      }
    }
  },
  methods: {
    backParentID: function (id) {
      axios.get(bankUrl + '/' + id, { headers: getHeader() })
        .then(response => {
          this.listView = false
          this.formView = true
          this.bank = response.data.data
        })
        .catch(error => {
          console.log(error.response)
          this.$swal(
            'Error',
            error.response.data.message,
            'error'
          )
        })
    },
    onClickEditFromList: function (value) {
      this.listView = false
      this.formView = true
      this.bank = value
    },
    getEdit: function (value) {
      console.log(bankUrl + '/' + value)
      axios.get(bankUrl + '/' + value, { headers: getHeader() })
        .then(response => {
          console.log(response.data.data)
          this.listView = false
          this.formView = true
          this.company = response.data.data
        })
        .catch(error => {
          console.log(error.response)
        })
    },
    onClickNewFromList: function () {
      this.listView = false
      this.formView = true
      this.bank = {
        id: null,
        name: null
      }
    },
    onBackChild: function () {
      this.listView = true
      this.formView = false
      this.bank = {
        id: null,
        name: null
      }
      this.$refs.refreshList.refreshList()
      // this.$router.replace({ path: 'sales', query: null })
    }
  },
  mounted () {
    // this.$store.dispatch('getCompanyList')
  }
}
</script>
