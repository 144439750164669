<template>
    <div>
        <h5>User Form</h5>
        <div class="row">
            <div class="col-md-12">
                <div class="top-list-toolbar">
                    <h1><span @click="backParent" style="cursor: pointer">User</span></h1>
                    <div>
                        <button type="button" @click="backParent" class="btn btn-sm btn-danger pull-left"><i class="fa fa-reply" aria-hidden="true"></i> Back</button>
                        <button type="button" @click="save" class="btn btn-sm btn-outline-soft-primary pull-left"><i class="fa fa-check" aria-hidden="true"></i> Save</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="card card-list">
            <div class="card-header">
                <h5 v-if="user.id === null">New</h5>
                <h5 v-else>{{ user.name }}</h5>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label for="username" class="col-sm-3 col-form-label text-left">&nbsp;</label>
                        <div class="col-sm-9">
                          <div id="profile-border" @click="chooseFiles">
                            <img :src="user.profiles" id="profile">
                            <input type="file" id="file" ref="file" @change="handleFileUpload()" hidden/>
                          </div>
                        </div>
                      </div>
                      <div class="form-group row">
                          <label for="username" class="col-sm-3 col-form-label text-left">Username</label>
                          <div class="col-sm-9">
                              <input type="text" v-model="user.username" class="form-control">
                          </div>
                      </div>
                      <div class="form-group row">
                          <label for="email" class="col-sm-3 col-form-label text-left">Email</label>
                          <div class="col-sm-9">
                              <input type="text" v-model="user.email" class="form-control">
                          </div>
                      </div>
                      <div class="form-group row">
                          <label for="company" class="col-sm-3 col-form-label text-left">Company</label>
                          <div class="col-sm-9">
                              <select v-model="user.company_no" class="form-control">
                                  <option v-for="c in companyList" :key="c.id" :value="c.company_no">[{{ c.company_no }}] {{ c.name }}</option>
                              </select>
                          </div>
                      </div>
                    </div>
                </div>
            </div>
        </div>
        <pre>{{ user }}</pre>
    </div>
</template>

<script>
import axios from 'axios'
import { getHeader, userUrl } from '../../config'
import { mapState } from 'vuex'
export default {
  name: 'UserForm',
  props: ['user'],
  computed: {
    ...mapState({
      companyList: state => state.companies.companyList
    })
  },
  methods: {
    chooseFiles () {
      document.getElementById('file').click()
    },
    async uploadFile () {
      const formData = new FormData()
      formData.append('file', this.file)
      console.log(formData)
      const newHeader = {
        'Content-Type': 'multipart/form-data'
      }
      const newObj = Object.assign(getHeader(), newHeader)
      await axios.post(userUrl + '/profile', formData, { headers: newObj })
        .then(response => {
          var data = response.data.data
          this.user.profiles.push(data.url)
        })
        .catch(error => {
          console.log(error.response)
        })
    },
    async handleFileUpload () {
      this.file = this.$refs.file.files[0]
      const formData = new FormData()
      formData.append('file', this.file)
      formData.append('username', this.user.username)
      const newHeader = {
        'Content-Type': 'multipart/form-data'
      }
      const newObj = Object.assign(getHeader(), newHeader)
      await axios.post(userUrl + '/profile', formData, { headers: newObj })
        .then(response => {
          console.log(response.data.data)
          this.user.profiles = response.data.data.url
        })
        .catch(error => {
          console.log(error.response)
        })
    },
    backParent: function () {
      this.$emit('backClicked')
    },
    async save () {
      var id = this.user.id
      if (id === null) {
        console.log(userUrl)
        axios.post(userUrl, this.user, { headers: getHeader() })
          .then(response => {
            console.log(response)
            this.$toasted.show(response.data.message, {
              theme: 'toasted-primary',
              position: 'bottom-left',
              duration: 5000,
              icon: 'check',
              type: 'success'
            })
            this.backParent()
          })
          .catch(error => {
            console.log(error.response)
            this.$toasted.show(error.response.data.message, {
              theme: 'toasted-primary',
              position: 'bottom-left',
              duration: 5000,
              icon: 'check',
              type: 'error'
            })
          })
      } else {
        var url = userUrl + '/' + id
        axios.put(url, this.user, { headers: getHeader() })
          .then(response => {
            console.log(response)
            this.$toasted.show(response.data.message, {
              theme: 'toasted-primary',
              position: 'bottom-left',
              duration: 5000,
              icon: 'check',
              type: 'success'
            })
            this.backParent()
          })
          .catch(error => {
            console.log(error.response)
            this.$toasted.show(error.response.data.message, {
              theme: 'toasted-primary',
              position: 'bottom-left',
              duration: 5000,
              icon: 'check',
              type: 'error'
            })
          })
      }
    }
  }
}
</script>
