<template>
    <div>
      <h5>Memo</h5>
      <div class="row">
          <div class="col-md-12">
              <div class="top-list-toolbar">
                  <h4 class="text-blue"><span @click="backParent">Memo Prepayment</span></h4>
                  <div>
                      <button type="button" @click="backParent" class="btn btn-sm btn-danger pull-left"><i class="fa fa-reply" aria-hidden="true"></i> Back</button>
                      <button type="button" v-if="memo.id !== null" @click="update" class="btn btn-sm btn-success pull-left"><i class="fa fa-check" aria-hidden="true"></i> Revise</button>
                      <button type="button" v-else @click="save" class="btn btn-sm btn-success pull-left"><i class="fa fa-check" aria-hidden="true"></i> Save</button>
                  </div>
              </div>
          </div>
      </div>
      <div class="card card-list">
        <div class="card-header">
          <span v-if="memo.id !== null">
            <h5>
              <b class="mr-1 color-primary">{{ memo.memo_no }}</b>
              <span class="badge badge-success" v-if="memo.status === 'on progress'">{{ memo.status }}</span>
              <span class="badge badge-warning" v-else-if="memo.status === 'revise'">{{ memo.status }}</span>
              <span class="badge badge-danger" v-else-if="memo.status === 'reject'">{{ memo.status }}</span>
              <span class="badge badge-primary" v-else>{{ memo.status }}</span>
            </h5>
          </span>
          <span v-else><h5>New</h5></span>
        </div>
        <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group row">
                  <label for="memo_date" class="col-sm-3 col-form-label align-label text-right">Date</label>
                  <div class="col-sm-9">
                    <input type="text" v-model="memo.memo_date" class="form-control" disabled="disabled">
                  </div>
                </div>
                <div class="form-group row">
                  <label for="company_no" class="col-sm-3 col-form-label align-label text-right">Company</label>
                  <div class="col-sm-9">
                    <select v-model="memo.company_no" class="form-control" @change="setCompany">
                      <option v-for="c in authUser.company" :key="c.id" :value="c.company_no">[{{ c.company_no }}] {{ c.name }}</option>
                    </select>
                  </div>
                </div>
                <div class="form-group row">
                  <label for="from" class="col-sm-3 col-form-label align-label text-right">From</label>
                  <div class="col-sm-9">
                    <select v-model="memo.from" class="form-control" disabled="disabled">
                      <option v-for="e in employeeSelect" :key="e.id" :value="e.employee_no">
                        [{{ e.employee_no }}] {{ e.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="form-group row">
                  <label for="position_id" class="col-sm-3 col-form-label align-label text-right">Position</label>
                  <div class="col-sm-9">
                    <select v-model="memo.position_id" class="form-control" disabled="disabled">
                      <option v-for="p in positionList" :key="p.id" :value="p.id">[{{ p.id }}] {{ p.name }}</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group row">
                    <label for="type_id" class="col-sm-3 col-form-label align-label text-right">Type</label>
                    <div class="col-sm-9">
                        <select v-model="memo.type_id" class="form-control" disabled="disabled">
                            <option value="regular">Regular</option>
                            <option value="prepayment">Prepayment</option>
                        </select>
                    </div>
                </div>
                <div class="form-group row">
                    <label for="category_id" class="col-sm-3 col-form-label align-label text-right">Category</label>
                    <div class="col-sm-9">
                      <v-select :options="categorySelect" :reduce="category => category.meta.code" label="category" class="vselect-custom" v-model="memo.category_id" @input="setApproval"/>
                    </div>
                </div>
                <div class="form-group row">
                  <label for="to" class="col-sm-3 col-form-label align-label text-right">Approval</label>
                  <div class="col-sm-9">
                    <select v-model="memo.to" class="form-control">
                          <option v-for="e in memo.approve_list" :key="e.id" :value="e.employee_no">
                              [{{ e.employee_no }}] {{ e.name }}
                          </option>
                      </select>
                  </div>
                </div>
                <div class="form-group row">
                  <label for="remaining_budget" class="col-sm-3 col-form-label align-label text-right">Remaining Budget</label>
                  <div class="col-sm-9">
                    <input type="text" v-model="remainingBudget" class="form-control" disabled="disabled" v-money="money"/>
                    <span v-if="isBudget"><i class="fa fa-check"></i> {{ isBudget }}</span>
                  </div>
                </div>
                <div class="form-group row">
                  <label for="approval_path" class="col-sm-3 col-form-label align-label text-right">&nbsp;</label>
                  <div class="col-sm-9">
                    <div class="progress-container">
                      <ul class="progressbar">
                        <li v-for="al in memo.path_array" :key="al.path" :class="al.class">{{ al.position }}</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr class="mt-5">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group row">
                    <label for="subject" class="col-sm-3 col-form-label align-label text-right">Subject</label>
                    <div class="col-sm-9">
                      <input type="text" class="form-control" v-model="memo.subject"/>
                    </div>
                </div>
                <div class="form-group row">
                    <label for="notes" class="col-sm-3 col-form-label align-label text-right">Notes:</label>
                    <div class="col-sm-9">
                      <textarea type="text" class="form-control" v-model="memo.notes" rows="5"/>
                    </div>
                </div>
                <div class="form-group row">
                    <label for="total_prepayment" class="col-sm-3 col-form-label align-label text-right">Total Prepayment:</label>
                    <div class="col-sm-9">
                      <input type="text" class="form-control" v-model="memo.total_prepayment" v-money="money" />
                    </div>
                </div>
              </div>
              <div class="col-md-6" v-if="latestReviseLog.log_user !== null">
                <div class="form-group row">
                  <label for="subject" class="col-sm-3 col-form-label align-label text-right">Revise From:</label>
                    <div class="col-sm-9">
                      <select v-model="latestReviseLog.log_user" class="form-control" disabled="disabled">
                        <option v-for="e in employeeSelect" :key="e.id" :value="e.employee_no">
                          [{{ e.employee_no }}] {{ e.name }}
                        </option>
                      </select>
                    </div>
                </div>
                <div class="form-group row">
                  <label for="subject" class="col-sm-3 col-form-label align-label text-right">Revise Date:</label>
                    <div class="col-sm-9">
                      <input type="text" v-model="latestReviseLog.log_date" class="form-control" disabled="disabled">
                    </div>
                </div>
                <div class="form-group row">
                    <label for="log_notes" class="col-sm-3 col-form-label align-label text-right"><b>Revise Notes:</b></label>
                    <div class="col-sm-9">
                      <textarea class="form-control" v-model="latestReviseLog.log_notes" rows="5"  disabled="disabled"/>
                    </div>
                </div>
              </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-md-12">
                <div><input type="file" id="file" ref="file" @change="upload" hidden/></div>
                <div class="upload-border mt-5">
                  <span class="filename" v-for="a in attachmentList" :key="a.id">
                    <span @click="downloadAttachment(a.url, a.filename)" style="cursor: pointer;">{{ a.filename }}</span>
                    <i class="fa fa-times ml-1" aria-hidden="true"  style="cursor: pointer; color: red" @click="removeAttachment(a.id)"></i>
                  </span>
                  <span class="upload-button">
                    <a href="javascript:void(0)" @click="chooseFiles" class="btn btn-sm btn-outline-primary"><i class="lni lni-cloud-upload"></i> UPLOAD</a>
                  </span>
                </div>
              </div>
            </div>
            <div class="row mt-5" v-if="paymentType === 'payment'">
              <div class="col-md-12">
                <h5>Rekening Tujuan:</h5>
              </div>
            </div>
            <hr>
            <div class="row" v-if="paymentType === 'payment'">
              <div class="col-md-6">
                <div class="form-group row">
                  <label for="employee_no" class="col-sm-3 col-form-label align-label text-right">Employee</label>
                  <div class="col-sm-9">
                    <v-select :options="employeeSelectList" :reduce="employee => employee.meta.code" label="employee" class="vselect-custom" v-model="memo.employee_no" @input="getEmployee"/>
                  </div>
                </div>
                <div class="form-group row">
                  <label for="alias_id" class="col-sm-3 col-form-label align-label text-right">Supplier</label>
                  <div class="col-sm-9">
                    <v-select :options="supplierSelect" :reduce="supplier => supplier.meta.code" label="supplier" class="vselect-custom" v-model="memo.alias_id" @input="getSupplier"/>
                  </div>
                </div>
                <div class="form-group row">
                  <label for="bank" class="col-sm-3 col-form-label align-label text-right">Bank</label>
                  <div class="col-sm-9">
                    <select v-model="memo.account_bank_id" class="form-control" disabled="disabled">
                      <option v-for="b in bankList" :key="b.id" :value="b.id">{{ b.name }}</option>
                    </select>
                  </div>
                </div>
                <div class="form-group row">
                  <label for="bank_account_no" class="col-sm-3 col-form-label align-label text-right">Account No.</label>
                  <div class="col-sm-9">
                    <input type="text" class="form-control" v-model="memo.account_no" disabled="disabled"/>
                  </div>
                </div>
                <div class="form-group row">
                  <label for="account_name" class="col-sm-3 col-form-label align-label text-right">Account Name.</label>
                  <div class="col-sm-9">
                    <input type="text" class="form-control" v-model="memo.account_name" disabled="disabled"/>
                  </div>
                </div>
                <div class="form-group row">
                  <label for="billing_no" class="col-sm-3 col-form-label align-label text-right">Billing No.</label>
                  <div class="col-sm-9">
                    <input type="text" class="form-control" v-model="memo.billing_no" disabled="disabled"/>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
      <div class="row justify-content-md-center">
        <div class="col-md-6">
          <h5>Logs:</h5>
          <div class="alert alert-info" role="alert" v-for="l in memo.logs" :key="l.id">
            <b>{{ l.log_user }}</b> - {{ l.log_date | dateFormat('DD/MM/YYYY HH:mm:ss') }} <span>"{{ l.log_notes }}"</span>
          </div>
        </div>
      </div>
      <pre> {{ memo }}</pre>
      <!-- <pre>{{ positionList }}</pre> -->
    </div>
</template>

<script>
import axios from 'axios'
import { getHeader, memoUrl } from '../../config'
import { mapState } from 'vuex'
export default {
  name: 'MemoFormPrepayment',
  props: ['memo'],
  data () {
    return {
      memoNew: [],
      isBudget: false,
      remainingBudget: 0,
      employeeData: {
        employee_no: null,
        bank: null,
        bank_account_no: null,
        bank_account_name: null
      },
      money: {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 0,
        masked: true /* doesn't work with directive */
      },
      money2: {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 2,
        masked: true /* doesn't work with directive */
      },
      approvalList: [{
        employee_no: null,
        name: null,
        path: [{
          path: 1,
          position: 'Step 1',
          class: null
        }, {
          path: 2,
          position: 'Step 2',
          class: null
        }]
      }]
    }
  },
  computed: {
    ...mapState({
      authUser: state => state.users.authUser,
      companyList: state => state.companies.companyList,
      memoCategoryList: state => state.memos.memoCategoryList,
      supplierList: state => state.suppliers.supplierList,
      employeeList: state => state.employees.employeeList,
      bankList: state => state.banks.bankList,
      positionList: state => state.employees.positionList
    }),
    positionSelect: function () {
      var loop = []
      var array = this.positionList
      for (let i = 0; i < array.length; i++) {
        for (let a = 0; a < this.authUser.position.length; a++) {
          if (array[i].id === this.authUser.position[a].position_id) {
            var obj = {
              id: array[i].id,
              name: array[i].name
            }
          }
        }
        loop.push(obj)
      }
      return loop
    },
    paymentType: function () {
      var type = null
      const category = this.memoCategoryList.find(d => d.id === this.memo.category_id)
      if (category) {
        type = category.type
      }
      return type
    },
    latestReviseLog: function () {
      var obj = {
        log_user: null,
        log_notes: null,
        log_date: null
      }
      var logs = this.memo.logs.filter(d => d.memo_type === 'revise')
      if (logs.length > 0) {
        obj = {
          log_user: logs[0].log_user,
          log_notes: logs[0].log_notes,
          log_date: logs[0].log_date
        }
        return obj
      } else {
        return obj
      }
    },
    supplierSelect: function () {
      var loop = []
      var array = this.supplierList.filter(d => d.active === true).filter(d => d.lock === true)
      if (array.length > 0) {
        for (let a = 0; a < array.length; a++) {
          const company = array[a].company
          for (let b = 0; b < company.length; b++) {
            if (company[b].company_no === this.memo.company_no) {
              const array2 = array[a].accounts
              for (let b = 0; b < array2.length; b++) {
                var obj = {
                  id: array2[b].id,
                  supplier_no: array[a].supplier_no,
                  alias: array2[b].alias
                }
                loop.push(obj)
              }
            }
          }
        }
      }
      var loop2 = []
      for (let z = 0; z < loop.length; z++) {
        var obj2 = {
          supplier: loop[z].alias,
          meta: {
            code: loop[z].id
          }
        }
        loop2.push(obj2)
      }
      return loop2
    },
    employeeSelectList: function () {
      var loop = []
      var data = this.employeeList
      for (let z = 0; z < data.length; z++) {
        var obj = {
          employee: data[z].employee_no + ' - ' + data[z].name,
          meta: {
            code: data[z].employee_no
          }
        }
        loop.push(obj)
      }
      return loop
    },
    categorySelect: function () {
      var array = this.memoCategoryList.filter(d => d.prepayment === true)
      var data = [{
        id: null,
        name: null
      }]
      for (let i = 0; i < array.length; i++) {
        var path = array[i].paths.find(d => d.create_user === this.getEmployeeFrom.id)
        if (path) {
          data.push({
            id: array[i].id,
            name: array[i].name
          })
        }
      }
      var loop = []
      for (let z = 0; z < data.length; z++) {
        var obj = {
          category: data[z].name,
          meta: {
            code: data[z].id
          }
        }
        loop.push(obj)
      }
      return loop
    },
    getEmployeeFrom: function () {
      var obj = this.employeeList.find(d => d.employee_no === this.memo.from)
      var data = {
        id: null,
        name: null
      }
      if (obj) {
        data = obj.position.find(d => d.company_no === this.memo.company_no)
      }
      return data
    },
    getApprovalList: function () {
      return this.approvalList[0].pathArray
    },
    attachmentList: function () {
      return this.memo.attachments.filter(d => d.id !== null)
    },
    employeeSelect: function () {
      return this.employeeList
    },
    getCategory: function () {
      return this.memoCategoryList.filter(d => d.company_no === this.memo.company_no)
    },
    getSubtotal: function () {
      var array = this.memo.details
      var total = 0
      for (let i = 0; i < array.length; i++) {
        var amount = array[i].amount.replaceAll('.', '')
        amount = amount.replace(',', '.')
        total += parseInt(array[i].qty) * parseFloat(amount)
      }
      return total
    },
    getDiscount: function () {
      var array = this.memo.details
      var total = 0
      for (let i = 0; i < array.length; i++) {
        total += parseFloat(array[i].discount.replace(/\./g, ''))
      }
      return total
    },
    getTax: function () {
      var array = this.memo.details
      var total = 0
      for (let i = 0; i < array.length; i++) {
        total += parseFloat(array[i].tax.replace(/\./g, ''))
      }
      return total
    },
    getTotal: function () {
      var array = this.memo.details
      var total = 0
      for (let i = 0; i < array.length; i++) {
        total += parseFloat(array[i].total)
      }
      return total
    }
  },
  methods: {
    setCompany () {
      this.memo.category_id = null
      this.memo.to = null
      const user = this.authUser.position.find(d => d.company_no === this.memo.company_no)
      this.memo.position_id = user.position_id
    },
    getSupplier () {
      var supp = this.supplierList
      var supplierNo = null
      var supplierAccountNo = null
      var supplierAccountName = null
      var billingNo = null
      var supplierbankId = null
      for (let i = 0; i < supp.length; i++) {
        var accountList = supp[i].accounts
        console.log('alias_id: ' + this.memo.alias_id)
        console.log(accountList)
        var acc = accountList.find(d => d.id === this.memo.alias_id)
        if (acc) {
          console.log(acc)
          supplierNo = supp[i].supplier_no
          supplierAccountNo = acc.bank_account
          supplierAccountName = acc.bank_account_name
          supplierbankId = acc.bank_id
          billingNo = acc.billing_no
        }
      }
      this.memo.employee_no = null
      this.memo.supplier_no = supplierNo
      this.memo.account_no = supplierAccountNo
      this.memo.account_name = supplierAccountName
      this.memo.account_bank_id = supplierbankId
      this.memo.billing_no = billingNo
    },
    getEmployee () {
      var emp = this.employeeList.find(d => d.employee_no === this.memo.employee_no)
      this.memo.alias_id = null
      this.memo.account_no = emp.bank_account_no
      this.memo.account_name = emp.bank_account_name
      this.memo.account_bank_id = emp.bank_id
    },
    async removeAttachment (id) {
      var index = this.memo.attachments.findIndex(d => d.id === id)
      await axios.delete(memoUrl + '/attachment/' + id, { headers: getHeader() })
        .then(response => {
          this.memo.attachments.splice(index, 1)
          this.$toasted.show('Delete Attachment', {
            theme: 'toasted-primary',
            position: 'bottom-left',
            duration: 5000
          })
        })
        .catch(error => {
          console.log(error.response)
          this.$swal(
            'Error',
            error.response.data.message,
            'error'
          )
        })
    },
    async downloadAttachment (url, filename) {
      console.log(memoUrl + '/attachment/file/' + url)
      window.open(memoUrl + '/attachment/file/' + url, '', 'fullscreen=yes')
      // await axios({
      //   url: memoUrl + '/attachment/file/' + url,
      //   method: 'GET',
      //   responseType: 'blob',
      //   headers: getHeader()
      // }).then((response) => {
      //   var blob = new Blob([response.data], { type: 'application/' + response.data.type })
      //   var fileURL = window.URL.createObjectURL(blob)
      //   var fileLink = document.createElement('a')
      //   fileLink.href = fileURL
      //   fileLink.setAttribute('download', filename)
      //   document.body.appendChild(fileLink)
      //   fileLink.click()
      // })
    },
    chooseFiles () {
      document.getElementById('file').click()
    },
    handleFileUpload: function () {
      this.file = this.$refs.file.files[0]
    },
    async upload () {
      this.file = this.$refs.file.files[0]
      const formData = new FormData()
      formData.append('file', this.file)
      if (this.memo.memo_no) {
        formData.append('memo_no', this.memo.memo_no)
      }
      console.log(formData)
      const newHeader = {
        'Content-Type': 'multipart/form-data'
      }
      const newObj = Object.assign(getHeader(), newHeader)
      await axios.post(memoUrl + '/attachment', formData, { headers: newObj })
        .then(response => {
          var data = response.data.data
          var obj = {
            memo_no: data.memo_no,
            filename: data.filename,
            mime_type: data.mime_type,
            extension: data.extension,
            url: data.url,
            id: data.id
          }
          this.memo.attachments.push(obj)
          this.$toasted.show(response.data.message, {
            theme: 'toasted-primary',
            position: 'bottom-left',
            duration: 5000,
            icon: 'check',
            type: 'success'
          })
        })
        .catch(error => {
          console.log(error.response)
          this.$toasted.show(error.response.data.message, {
            theme: 'toasted-primary',
            position: 'bottom-left',
            duration: 5000,
            icon: 'check',
            type: 'error'
          })
        })
    },
    validate () {
      var total = 0
      var array = this.memo.details
      for (let i = 0; i < array.length; i++) {
        total += parseFloat(array[i].total)
      }
      if (this.memo.to === null) {
        this.$swal(
          'Error',
          'Approval Cannot be empty',
          'error'
        )
        return false
      }
      if (this.isBudget === true) {
        var rb = parseFloat(this.remainingBudget.replace(/\./g, ''))
        if (total > rb) {
          this.$swal(
            'Error',
            'Limit Budget',
            'error'
          )
          return false
        }
      }
    },
    calc: function (index) {
      var detail = this.memo.details[index]
      var qty = parseInt(detail.qty)
      var amount = detail.amount.replaceAll('.', '')
      amount = parseFloat(amount.replace(',', '.'))
      var discount = parseFloat(detail.discount.replace(/\./g, ''))
      var tax = parseFloat(detail.tax.replace(/\./g, ''))
      var total = qty * (amount - discount + tax)
      detail.total = total
    },
    removeDetail: function (index) {
      var detail = this.memo.details[index]
      if (detail.id) {
        axios.delete(memoUrl + '/detail/' + detail.id, { headers: getHeader() })
          .then(response => {
            this.memo.details.splice(index, 1)
            this.$toasted.show(response.data.message, {
              theme: 'toasted-primary',
              position: 'bottom-left',
              duration: 5000,
              icon: 'check',
              type: 'success'
            })
          })
          .catch(error => {
            console.log(error.response)
            this.$toasted.show(error.response.data.message, {
              theme: 'toasted-primary',
              position: 'bottom-left',
              duration: 5000,
              icon: 'check',
              type: 'error'
            })
          })
      } else {
        this.memo.details.splice(index, 1)
      }
    },
    addLine: function () {
      var date = new Date()
      date = ('0' + (date.getDate() + 1)).slice(-2) + '/' + ('0' + (date.getMonth() + 1)).slice(-2) + '/' + date.getFullYear()
      var obj = {
        memo_no: null,
        company_no: null,
        memo_date: date,
        description: null,
        qty: '0',
        amount: '0',
        discount: '0',
        tax: '0',
        total: '0'
      }
      this.memo.details.push(obj)
    },
    async setApproval () {
      var obj = {
        category: this.memo.category_id,
        create_user: this.memo.position_id,
        company: this.memo.company_no,
        date: this.memo.memo_date.replaceAll('/', '-')
      }
      axios.post(memoUrl + '/path/approval', obj, { headers: getHeader() })
        .then(response => {
          console.log(response.data.data)
          this.memo.approve_list = response.data.data
          this.memo.to = response.data.data[0].employee_no
          this.remainingBudget = response.data.data[0].remaining_budget
          this.isBudget = response.data.data[0].budget
          this.memo.path_id = response.data.data[0].path_id
          this.memo.path_array = response.data.data[0].pathArray
          this.memo.path = response.data.data[0].path
        })
        .catch(error => {
          console.log(error.response)
          this.approvalList = []
          this.memo.to = null
          this.remainingBudget = 0
          this.isBudget = false
          this.$toasted.show(error.response.data.message, {
            theme: 'toasted-primary',
            position: 'bottom-left',
            duration: 5000,
            icon: 'check',
            type: 'error'
          })
        })
    },
    backParent: function () {
      this.$emit('backClicked')
    },
    async save () {
      var check = this.validate()
      if (check === false) {
        return false
      }
      var newDate = this.memo.memo_date.split('/')
      newDate = newDate[2] + '-' + newDate[1] + '-' + newDate[0]
      var totalPrepayment = this.memo.total_prepayment.replaceAll('.', '')
      totalPrepayment = totalPrepayment.replace(',', '.')
      var memoData = {
        memo_date: newDate,
        from: this.memo.from,
        to: this.memo.to,
        path_id: this.memo.path_id,
        path: this.memo.path,
        subject: this.memo.subject,
        supplier_no: this.memo.supplier_no,
        employee_no: this.memo.employee_no,
        company_no: this.memo.company_no,
        category_id: this.memo.category_id,
        type_id: this.memo.type_id,
        total_prepayment: totalPrepayment,
        notes: this.memo.notes,
        subtotal: this.getSubtotal,
        discount: this.getDiscount,
        tax: this.getTax,
        total: this.getTotal,
        status: 'on progress',
        lock: false,
        position_id: this.memo.position_id,
        alias_id: this.memo.alias_id,
        account_no: this.memo.account_no,
        account_name: this.memo.account_name,
        account_bank_id: this.memo.account_bank_id,
        billing_no: this.memo.billing_no
      }
      var array = this.memo.details
      var details = []
      for (let i = 0; i < array.length; i++) {
        var newAmount = array[i].amount.replaceAll('.', '')
        newAmount = newAmount.replace(',', '.')
        var obj = {
          description: array[i].description,
          qty: array[i].qty,
          amount: newAmount,
          discount: array[i].discount.replace(/\./g, ''),
          tax: array[i].tax.replace(/\./g, ''),
          total: array[i].total
        }
        details.push(obj)
      }
      //   memoData.details = details
      var array2 = this.memo.attachments.filter(d => d.id !== null)
      var attachments = []
      for (let a = 0; a < array2.length; a++) {
        var obj2 = {
          id: array2[a].id,
          filename: array2[a].filename,
          mime_type: array2[a].mime_type,
          extension: array2[a].extension,
          url: array2[a].url
        }
        attachments.push(obj2)
      }
      memoData.attachments = attachments
      console.log(memoData)
      await axios.post(memoUrl, memoData, { headers: getHeader() })
        .then(response => {
          this.$toasted.show(response.data.message, {
            theme: 'toasted-primary',
            position: 'bottom-left',
            duration: 5000,
            icon: 'check',
            type: 'success'
          })
          axios.get(memoUrl + '/notif/' + response.data.data.id, { headers: getHeader() })
            .then(response => {
              this.$toasted.show(response.data.message, {
                theme: 'toasted-primary',
                position: 'bottom-left',
                duration: 5000,
                icon: 'check',
                type: 'success'
              })
            })
            .catch(error => {
              console.log(error.response)
              this.$toasted.show('Failed to send notif', {
                theme: 'toasted-primary',
                position: 'bottom-left',
                duration: 5000,
                icon: 'check',
                type: 'error'
              })
            })
          this.backParent()
        })
        .catch(error => {
          console.log(error.response)
          var array = error.response.data.message
          if (array.length > 0) {
            for (let i = 0; i < array.length; i++) {
              this.$toasted.show(array[i], {
                theme: 'toasted-primary',
                position: 'bottom-left',
                duration: 5000,
                icon: 'check',
                type: 'error'
              })
            }
          }
        })
    },
    async update () {
      var check = this.validate()
      if (check === false) {
        return false
      }
      var newDate = this.memo.memo_date.split('/')
      newDate = newDate[2] + '-' + newDate[1] + '-' + newDate[0]
      var memoData = {
        memo_date: newDate,
        from: this.memo.from,
        to: this.memo.to,
        path_id: this.memo.path_id,
        path: this.memo.path,
        subject: this.memo.subject,
        supplier_no: this.memo.supplier_no,
        employee_no: this.memo.employee_no,
        company_no: this.memo.company_no,
        category_id: this.memo.category_id,
        notes: this.memo.notes,
        subtotal: this.getSubtotal,
        discount: this.getDiscount,
        tax: this.getTax,
        total: this.getTotal,
        status: 'on progress',
        lock: false,
        position_id: this.memo.position_id,
        alias_id: this.memo.alias_id,
        account_no: this.memo.account_no,
        account_name: this.memo.account_name,
        account_bank_id: this.memo.account_bank_id,
        billing_no: this.memo.billing_no
      }
      var array = this.memo.details
      var details = []
      for (let i = 0; i < array.length; i++) {
        var newAmount = array[i].amount.replaceAll('.', '')
        newAmount = newAmount.replace(',', '.')
        var obj = {
          id: array[i].id,
          memo_no: array[i].memo_no,
          company_no: array[i].company_no,
          description: array[i].description,
          qty: array[i].qty,
          amount: newAmount,
          discount: array[i].discount.replace(/\./g, ''),
          tax: array[i].tax.replace(/\./g, ''),
          total: array[i].total
        }
        details.push(obj)
      }
      memoData.details = details
      var array2 = this.memo.attachments.filter(d => d.id !== null)
      var attachments = []
      for (let a = 0; a < array2.length; a++) {
        var obj2 = {
          id: array2[a].id,
          filename: array2[a].filename,
          mime_type: array2[a].mime_type,
          extension: array2[a].extension,
          url: array2[a].url
        }
        attachments.push(obj2)
      }
      memoData.attachments = attachments
      this.memoNew = memoData
      await axios.put(memoUrl + '/' + this.memo.id, memoData, { headers: getHeader() })
        .then(response => {
          this.$toasted.show(response.data.message, {
            theme: 'toasted-primary',
            position: 'bottom-left',
            duration: 5000,
            icon: 'check',
            type: 'success'
          })
          this.backParent()
        })
        .catch(error => {
          console.log(error.response)
          var array = error.response.data.message
          if (array.length > 0) {
            for (let i = 0; i < array.length; i++) {
              this.$toasted.show(array[i], {
                theme: 'toasted-primary',
                position: 'bottom-left',
                duration: 5000,
                icon: 'check',
                type: 'error'
              })
            }
          }
        })
    }
  }
}
</script>

<style>
.upload-border{
  min-height: 100px;
  border: 2px #41A29D dashed;
  padding-top: 10px;
  padding-bottom: 40px;
  position: relative;
}
.upload-border .upload-button {
  margin: 0;
  position: absolute;
  bottom: -10px;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.upload-border .filename {
  font-size: 13px;
  letter-spacing: 1px;
  font-weight: 700;
  padding: 8px 20px;
  margin: 10px;
  background: #e7f1fd;
  color: #45A49F;
  display: inline-block;
  /* text-transform: uppercase; */
  border-radius: 10px;
}
.progress-container{
  width: 100%;
  position: absolute;
  z-index: 1;
}
.progressbar li{
  float: left;
  width: 20%;
  position: relative;
  text-align: center;
}
.progressbar{
  counter-reset: step;
}
.progressbar li:before{
  content:counter(step);
  counter-increment: step;
  width: 30px;
  height: 30px;
  border: 2px solid #bebebe;
  display: block;
  margin: 0 auto 10px auto;
  border-radius: 50%;
  line-height: 27px;
  background: white;
  color: #bebebe;
  text-align: center;
  font-weight: bold;
}
.progressbar li:after{
  content: '';
  position: absolute;
  width:100%;
  height: 3px;
  background: #979797;
  top: 15px;
  left: -50%;
  z-index: -1;
}

.progressbar li:first-child:after{
  content: none;
}
.progressbar li.active + li:after{
  background: #3aac5d;
}
.progressbar li.active + li:before{
  border-color: #3aac5d;
  background: #3aac5d;
  color: white
}
</style>
