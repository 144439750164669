<template>
  <div class="home">
    <UserList v-show="listView" @newUser="onClickNewFromList" @editUser="onClickEditFromList" ref="refreshList"/>
    <UserForm v-show="formView" :user="user" @backClicked="onBackChild" @backParentID="backParentID"/>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios'
import UserList from '@/components/user/UserList.vue'
import UserForm from '@/components/user/UserForm.vue'
import { getHeader, userUrl } from '../config'

export default {
  name: 'User',
  components: {
    UserList,
    UserForm
  },
  data () {
    return {
      listView: true,
      formView: false,
      user: {
        id: null,
        username: null,
        name: null,
        email: null
      }
    }
  },
  methods: {
    backParentID: function (id) {
      axios.get(userUrl + '/' + id, { headers: getHeader() })
        .then(response => {
          this.listView = false
          this.formView = true
          this.user = response.data.data
        })
        .catch(error => {
          console.log(error.response)
          this.$swal(
            'Error',
            error.response.data.message,
            'error'
          )
        })
    },
    onClickEditFromList: function (value) {
      this.listView = false
      this.formView = true
      this.user = value
    },
    getEditSales: function (value) {
      console.log(userUrl + '/' + value)
      axios.get(userUrl + '/' + value, { headers: getHeader() })
        .then(response => {
          console.log(response.data.data)
          this.listView = false
          this.formView = true
          this.user = response.data.data
        })
        .catch(error => {
          console.log(error.response)
        })
    },
    onClickNewFromList: function () {
      this.listView = false
      this.formView = true
      this.user = {
        id: null,
        username: null,
        email: null
      }
    },
    onBackChild: function () {
      this.listView = true
      this.formView = false
      this.user = {
        id: null,
        username: null,
        email: null
      }
      this.$refs.refreshList.refreshList()
      // this.$router.replace({ path: 'sales', query: null })
    }
  },
  mounted () {
    this.$store.dispatch('getCompanyList')
  }
}
</script>
