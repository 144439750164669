<template>
    <div>
        <h5>Memo</h5>
        <div class="row">
            <div class="col-md-12">
                <div class="top-list-toolbar">
                    <h4 class="text-blue"><span @click="backParent" style="cursor: pointer;color: blue;">Memo</span> /
                        <span v-if="memo.id === null">New</span>
                        <span v-else>Show</span>
                    </h4>
                    <div>
                      <button type="button" @click="backParent" class="btn btn-sm btn-danger pull-left"><i class="fa fa-reply" aria-hidden="true"></i> Back</button>
                      <button type="button" @click="reject" v-if="getRejectStatus" class="btn btn-sm btn-danger pull-left" data-toggle="modal" data-target="#modalRejectMemo" data-backdrop="static" data-keyboard="false">
                        <i class="fa fa-check" aria-hidden="true"></i> Reject
                      </button>
                      <div class="btn-group" role="group" v-if="authUser.permissions.memo_bank_approval && memo.status === 'approve'">
                        <button id="btnGroupDrop1" type="button" class="btn btn-sm btn-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          Bank Process
                        </button>
                        <div class="dropdown-menu" aria-labelledby="btnGroupDrop1">
                          <a class="dropdown-item" href="#" @click="createBank">Create</a>
                          <a class="dropdown-item" href="#" @click="approveBank">Approve</a>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card card-list">
            <div class="card-header">
                <h5>
                    <b class="mr-1">{{ memo.memo_no }}</b>
                    <span class="badge badge-success" v-if="memo.status === 'on progress'">{{ memo.status }}</span>
                    <span class="badge badge-warning" v-else-if="memo.status === 'revise'">{{ memo.status }}</span>
                    <span class="badge badge-danger" v-else-if="memo.status === 'reject'">{{ memo.status }}</span>
                    <span class="badge badge-primary" v-else>{{ memo.status }}</span>

                    <span class="ml-1 badge badge-primary" v-if="memo.type_id === 'regular'">{{ memo.type_id }}</span>
                    <span class="ml-1 badge badge-info" v-else-if="memo.type_id === 'prepayment'">{{ memo.type_id }}</span>
                    <span class="ml-1 badge badge-success" v-else-if="memo.type_id === 'prepayment-final'">{{ memo.type_id }}</span>
                </h5>
            </div>
            <div class="card-body">
              <ul class="nav nav-tabs nav-bordered">
                <li class="nav-item"><a href="#general" data-toggle="tab" aria-expanded="false" class="nav-link active">General</a></li>
                <li class="nav-item"><a href="#bank-approval" data-toggle="tab" aria-expanded="false" class="nav-link" v-if="authUser.permissions.memo_bank_approval && memo.status !== 'on progress'">Bank Approval</a></li>
              </ul>
              <div class="tab-content">
                <div class="tab-pane active" id="general">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group row">
                          <label for="memo_date" class="col-sm-3 col-form-label text-right">Date</label>
                          <div class="col-sm-9">
                          <input type="text" v-model="memo.memo_date" class="form-control" disabled="disabled">
                          </div>
                      </div>
                      <div class="form-group row">
                          <label for="company_no" class="col-sm-3 col-form-label text-right">Company</label>
                          <div class="col-sm-9">
                          <select v-model="memo.company_no" class="form-control" disabled="disabled">
                              <option v-for="c in companyList" :key="c.id" :value="c.company_no">[{{ c.company_no }}] {{ c.name }}</option>
                          </select>
                          </div>
                      </div>
                      <div class="form-group row">
                          <label for="from" class="col-sm-3 col-form-label text-right">From</label>
                          <div class="col-sm-9">
                            <select v-model="memo.from" class="form-control" disabled="disabled">
                                <option v-for="e in employeeSelect" :key="e.id" :value="e.employee_no">
                                [{{ e.employee_no }}] {{ e.name }}
                                </option>
                            </select>
                          </div>
                      </div>
                      <div class="form-group row">
                        <label for="position_id" class="col-sm-3 col-form-label text-right">Position</label>
                        <div class="col-sm-9">
                          <select v-model="memo.position_id" class="form-control" disabled="disabled">
                            <option v-for="p in positionList" :key="p.id" :value="p.id">[{{ p.id }}] {{ p.name }}</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group row">
                          <label for="category_id" class="col-sm-3 col-form-label text-right">Category</label>
                          <div class="col-sm-9">
                              <select v-model="memo.category_id" class="form-control" disabled="disabled">
                                  <option v-for="cat in getCategory" :key="cat.id" :value="cat.id">{{ cat.name }}</option>
                              </select>
                          </div>
                      </div>
                      <div class="form-group row">
                        <label for="type_id" class="col-sm-3 col-form-label text-right">Type</label>
                        <div class="col-sm-9">
                          <input type="text" v-model="memo.type_id" class="form-control" disabled="disabled"/>
                        </div>
                      </div>
                      <div class="form-group row" v-if="memo.status === 'finish'">
                        <label for="journal_no" class="col-sm-3 col-form-label text-right">Journal No.</label>
                        <div class="col-sm-9">
                          <input type="text" v-model="memo.journal_no" class="form-control"/>
                        </div>
                      </div>
                      <div class="form-group row" v-if="memo.status === 'finish'">
                        <label for="journal_no" class="col-sm-3 col-form-label text-right">&nbsp;</label>
                        <div class="col-sm-9">
                          <button type="button" class="btn btn-sm btn-soft-primary" @click="saveJournal">Save Journal</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr>
                  <div class="row">
                      <div class="col-md-12">
                        <div class="form-group row">
                          <label for="subject" class="col-sm-1 col-form-label text-right">Subject</label>
                          <div class="col-sm-11">
                              <input type="text" class="form-control" v-model="memo.subject" disabled="disabled"/>
                          </div>
                        </div>
                        <div class="form-group row">
                          <label for="notes" class="col-sm-1 col-form-label text-right">Notes:</label>
                          <div class="col-sm-11">
                              <textarea type="text" class="form-control" v-model="memo.notes" rows="5" disabled="disabled"/>
                          </div>
                        </div>
                        <div class="form-group row" v-if="memo.type_id === 'prepayment' || memo.type_id == 'prepayment-final'">
                          <label for="total_prepayment" class="col-sm-1 col-form-label text-right">Total Prepayment</label>
                          <div class="col-sm-11">
                            <input type="text" class="form-control" v-model="memo.total_prepayment" v-money="money" disabled="disabled"/>
                          </div>
                        </div>
                      </div>
                  </div>
                  <hr>
                  <div class="row" v-if="memo.type_id !== 'prepayment'">
                      <div class="col-md-12">
                          <div class="table-responsive">
                              <table class="table table-item mb-0 table-sm table-striped">
                                  <thead>
                                      <th>Date</th>
                                      <th>Description</th>
                                      <th>Qty</th>
                                      <th class="text-right">Amount</th>
                                      <th class="text-right">Total</th>
                                  </thead>
                                  <tbody>
                                      <tr v-for="d in memo.details" :key="d.id">
                                          <td>{{ d.memo_date }}</td>
                                          <td>{{ d.description }}</td>
                                          <td>{{ d.qty }}</td>
                                          <td class="text-right">{{ d.amount | currency }}</td>
                                          <td class="text-right"><b>{{ d.total | currency }}</b></td>
                                      </tr>
                                  </tbody>
                                  <tfoot>
                                      <tr>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td class="text-right"><b>Subtotal:</b></td>
                                          <td class="text-right"><b>{{ getSubtotal | currency }}</b></td>
                                      </tr>
                                      <tr>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td class="text-right"><b>Total:</b></td>
                                          <td class="text-right"><b>{{ getTotal | currency }}</b></td>
                                          <td></td>
                                      </tr>
                                      <tr v-if="memo.prepayment_finish === true">
                                        <td colspan="5">&nbsp;</td>
                                      </tr>
                                      <tr v-if="memo.prepayment_finish === true">
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td class="text-right"><b>Prepayment</b></td>
                                        <td class="text-right"><b>{{ getTotalPrepayment | currency }}</b></td>
                                        <td></td>
                                      </tr>
                                      <tr v-if="memo.prepayment_finish === true">
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td class="text-right" style="color: blue;">
                                          <span v-if="getOutstanding === 0"><b>Outstanding</b></span>
                                          <span v-else-if="getOutstanding < 0"><b>Process</b></span>
                                          <span v-else-if="getOutstanding > 0"><b>Return</b></span>
                                        </td>
                                        <td class="text-right" style="color: blue;">
                                          <b><u>{{ Math.abs(getOutstanding) | currency }}</u></b>
                                        </td>
                                        <td></td>
                                      </tr>
                                  </tfoot>
                              </table>
                          </div>
                      </div>
                  </div>
                  <hr>
                  <div class="row">
                      <div class="col-md-12">
                          <div class="upload-border">
                              <span class="filename" v-for="a in attachmentList" :key="a.id">
                              <span @click="downloadAttachment(a.url, a.filename)" style="cursor: pointer;">{{ a.filename }}</span>
                              </span>
                          </div>
                      </div>
                  </div>
                  <hr>
                  <div class="row">
                    <div class="col-md-6" v-if="authUser.permissions.memo_bank_approval && this.memo.status !== 'on progress'">
                      <h5>Payment From: </h5>
                      <!-- company_alias_id','company_account_no','company_account_name','company_account_bank_id' -->
                      <div class="form-group row">
                        <label for="company_alias_id" class="col-sm-3 col-form-label align-label text-right">Company Account</label>
                        <div class="col-sm-9">
                          <select v-model="accountFrom.alias_id" class="form-control" @change="setCompanyAccount" :disabled="this.memo.status === 'finish'">
                            <option v-for="ca in getCompanyAccountList" :key="ca.id" :value="ca.id">{{ ca.alias }}</option>
                          </select>
                        </div>
                      </div>
                      <div class="form-group row">
                        <label for="company_account_bank_id" class="col-sm-3 col-form-label align-label text-right">Bank</label>
                        <div class="col-sm-9">
                          <select v-model="accountFrom.account_bank_id" class="form-control" disabled="disabled">
                            <option v-for="b in bankList" :key="b.id" :value="b.id">{{ b.name }}</option>
                          </select>
                        </div>
                      </div>
                      <div class="form-group row">
                        <label for="company_account_no" class="col-sm-3 col-form-label align-label text-right">Account No.</label>
                        <div class="col-sm-9">
                          <input type="text" class="form-control" v-model="accountFrom.account_no" disabled="disabled"/>
                        </div>
                      </div>
                      <div class="form-group row">
                        <label for="company_account_name" class="col-sm-3 col-form-label align-label text-right">Account Name</label>
                        <div class="col-sm-9">
                          <input type="text" class="form-control" v-model="accountFrom.account_name" disabled="disabled"/>
                        </div>
                      </div>
                      <!-- <pre>{{ companyList }}</pre> -->
                    </div>
                    <div class="col-md-6">
                      <h5>Payment to: </h5>
                      <div class="form-group row">
                        <label for="bank" class="col-sm-3 col-form-label align-label text-right">Bank</label>
                        <div class="col-sm-9">
                          <select v-model="accountTo.account_bank_id" class="form-control" disabled="disabled">
                            <option v-for="b in bankList" :key="b.id" :value="b.id">{{ b.name }}</option>
                          </select>
                        </div>
                      </div>
                      <div class="form-group row">
                        <label for="bank_account_no" class="col-sm-3 col-form-label align-label text-right">Account No.</label>
                        <div class="col-sm-9">
                          <input type="text" class="form-control" v-model="accountTo.account_no" disabled="disabled"/>
                        </div>
                      </div>
                      <div class="form-group row">
                        <label for="account_name" class="col-sm-3 col-form-label align-label text-right">Account Name.</label>
                        <div class="col-sm-9">
                          <input type="text" class="form-control" v-model="accountTo.account_name" disabled="disabled"/>
                        </div>
                      </div>
                      <div class="form-group row">
                        <label for="billing_no" class="col-sm-3 col-form-label align-label text-right">Billing No.</label>
                        <div class="col-sm-9">
                          <input type="text" class="form-control" v-model="accountTo.billing_no" disabled="disabled"/>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr>
                  <div class="row">
                    <div class="col-md-12">
                      <h5>Payment</h5>
                      <table class="table table-item table-sm table-striped table-hover">
                        <thead>
                          <th>Memo No.</th>
                          <th>Amount</th>
                          <th>Date</th>
                          <th>Type</th>
                          <th>Method</th>
                        </thead>
                        <tbody>
                          <tr v-for="p in memo.payments" :key="p.id">
                            <td>{{ p.memo_no }}</td>
                            <td>{{ p.payment_amount | currency }}</td>
                            <td>{{ p.payment_date | dateFormat('DD/MM/YYYY H:mm:ss') }}</td>
                            <td>{{ p.type }}</td>
                            <td>
                              <span class="badge badge-primary" v-if="p.method === 'process'">{{ p.method }}</span>
                              <span class="badge badge-warning" v-else-if="p.method === 'return'">{{ p.method }}</span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div class="tab-pane" id="bank-approval">
                  <table class="table table-item mb-0 table-sm table-striped">
                    <thead>
                      <th>No.</th>
                      <th>Type</th>
                      <th>Category</th>
                      <th>Approval Date</th>
                      <th>Approval</th>
                    </thead>
                    <tbody>
                      <tr v-for="b in memo.bank_approval" :key="b.id">
                        <td>{{ b.no }}</td>
                        <td>
                          <span class="badge badge-success" v-if="b.type === 'create'">Create</span>
                          <span class="badge badge-primary" v-else-if="b.type === 'approve'">Approve</span>
                          <span class="badge badge-danger" v-else-if="b.type === 'expired'">Expired</span>
                        </td>
                        <td>{{ b.category }}</td>
                        <td>{{ b.approval_date | dateFormat('DD/MM/YYYY HH:mm:ss') }}</td>
                        <td>{{ b.approval }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
        </div>
        <div class="row justify-content-md-center">
          <div class="col-md-6">
            <h5>Logs:</h5>
            <div v-for="l in memo.logs" :key="l.id">
              <div v-if="l.memo_type === 'revise'" class="alert alert-warning" role="alert"><b>{{ l.user }}</b> - {{ l.log_date | dateFormat('DD/MM/YYYY HH:mm:ss') }} <span>"{{ l.log_notes }}"</span></div>
              <div v-else-if="l.memo_type === 'reject'" class="alert alert-danger" role="alert"><b>{{ l.user }}</b> - {{ l.log_date | dateFormat('DD/MM/YYYY HH:mm:ss') }} <span>"{{ l.log_notes }}"</span></div>
              <div v-else class="alert alert-primary" role="alert"><b>{{ l.user }}</b> - {{ l.log_date | dateFormat('DD/MM/YYYY HH:mm:ss') }} <span>"{{ l.log_notes }}"</span></div>
            </div>
          </div>
        </div>
        <!-- <pre>{{ memo }}</pre> -->
    </div>
</template>

<script>
import axios from 'axios'
import { getHeader, memoUrl } from '../../config'
import { mapState } from 'vuex'
export default {
  name: 'MemoShow',
  props: ['memo'],
  data () {
    return {
      isBudget: false,
      remainingBudget: 0,
      employeeData: {
        employee_no: null,
        bank: null,
        bank_account_no: null,
        bank_account_name: null
      },
      money: {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 0,
        masked: true /* doesn't work with directive */
      },
      approvalList: [{
        employee_no: null,
        name: null,
        path: [{
          path: 1,
          position: 'Step 1',
          class: null
        }, {
          path: 2,
          position: 'Step 2',
          class: null
        }]
      }]
    }
  },
  computed: {
    ...mapState({
      companyList: state => state.companies.companyList,
      memoCategoryList: state => state.memos.memoCategoryList,
      supplierList: state => state.suppliers.supplierList,
      employeeList: state => state.employees.employeeList,
      bankList: state => state.banks.bankList,
      positionList: state => state.employees.positionList,
      authUser: state => state.users.authUser
    }),
    getRejectStatus () {
      if (this.memo.status === 'on progress' && this.memo.from === this.authUser.username) {
        return true
      } else {
        return false
      }
    },
    getTotalPrepayment () {
      var totalPrepayment = this.memo.total_prepayment.replaceAll('.', '')
      totalPrepayment = totalPrepayment.replace(',', '.')
      return totalPrepayment
    },
    getOutstanding () {
      var outstanding = 0
      var totalPrepayment = this.memo.total_prepayment.replaceAll('.', '')
      totalPrepayment = totalPrepayment.replace(',', '.')
      outstanding = parseFloat(totalPrepayment) - parseFloat(this.getSubtotal)
      return outstanding
    },
    accountFrom () {
      var acc = this.memo.accounts.find(d => d.method === 'from' && d.type === this.memo.type_id)
      var obj = {}
      if (acc) {
        obj = acc
      } else {
        obj = {
          id: null,
          memo_no: null,
          type: null,
          method: null,
          employee_no: null,
          alias_id: null,
          account_no: null,
          account_name: null,
          account_bank_id: null,
          billing_no: null
        }
      }
      return obj
    },
    accountTo () {
      var acc = this.memo.accounts.find(d => d.method === 'to' && d.type === this.memo.type_id)
      var obj = {}
      if (acc) {
        obj = acc
      } else {
        obj = {
          id: null,
          memo_no: null,
          type: null,
          method: null,
          employee_no: null,
          alias_id: null,
          account_no: null,
          account_name: null,
          account_bank_id: null,
          billing_no: null
        }
      }
      return obj
    },
    getCompanyAccountList: function () {
      var loop = []
      var company = this.companyList.find(d => d.company_no === this.memo.company_no)
      if (company) {
        var acc = company.accounts
        if (acc.length > 0) {
          for (let a = 0; a < acc.length; a++) {
            var obj = {
              id: acc[a].id,
              alias: acc[a].alias,
              company_no: acc[a].company_no,
              bank_id: acc[a].bank_id,
              bank_account: acc[a].bank_account,
              bank_address: acc[a].bank_address,
              bank_account_name: acc[a].bank_account_name,
              notes: acc[a].notes,
              billing_no: acc[a].billing_no,
              type: acc[a].type
            }
            loop.push(obj)
          }
        }
      }
      return loop
    },
    getApprovalList: function () {
      return this.approvalList[0].pathArray
    },
    attachmentList: function () {
      return this.memo.attachments.filter(d => d.id !== null)
    },
    employeeSelect: function () {
      return this.employeeList
    },
    getCategory: function () {
      return this.memoCategoryList.filter(d => d.company_no === this.memo.company_no)
    },
    getSubtotal: function () {
      var array = this.memo.details
      var total = 0
      for (let i = 0; i < array.length; i++) {
        var amount = array[i].amount
        total += parseInt(array[i].qty) * parseFloat(amount)
      }
      return total
    },
    getDiscount: function () {
      var array = this.memo.details
      var total = 0
      for (let i = 0; i < array.length; i++) {
        total += parseFloat(array[i].discount.replace(/\./g, ''))
      }
      return total
    },
    getTax: function () {
      var array = this.memo.details
      var total = 0
      for (let i = 0; i < array.length; i++) {
        total += parseFloat(array[i].tax.replace(/\./g, ''))
      }
      return total
    },
    getTotal: function () {
      var array = this.memo.details
      var total = 0
      for (let i = 0; i < array.length; i++) {
        total += parseFloat(array[i].total)
      }
      return total
    }
  },
  methods: {
    async reject () {
      var obj = {
        id: this.memo.id,
        status: this.memo.status
      }
      await axios.post(memoUrl + '/inbox/reject', obj, { headers: getHeader() })
        .then(response => {
          this.$toasted.show(response.data.message, {
            theme: 'toasted-primary',
            position: 'bottom-left',
            duration: 5000,
            icon: 'check',
            type: 'success'
          })
          axios.get(memoUrl + '/inbox/notif/reject/' + response.data.data.id, { headers: getHeader() })
            .then(response => {
              this.$toasted.show(response.data.message, {
                theme: 'toasted-primary',
                position: 'bottom-left',
                duration: 5000,
                icon: 'check',
                type: 'success'
              })
            })
            .catch(error => {
              console.log(error.response)
              this.$toasted.show('Failed to send notif approve', {
                theme: 'toasted-primary',
                position: 'bottom-left',
                duration: 5000,
                icon: 'check',
                type: 'error'
              })
            })
          this.backParent()
        })
        .catch(error => {
          console.log(error.response)
        })
    },
    async saveJournal () {
      var obj = {
        memo_no: this.memo.memo_no,
        journal_no: this.memo.journal_no
      }
      await axios.post(memoUrl + '/journal-post', obj, { headers: getHeader() })
        .then(response => {
          this.$toasted.show(response.data.message, {
            theme: 'toasted-primary',
            position: 'bottom-left',
            duration: 5000,
            icon: 'check',
            type: 'success'
          })
        })
        .catch(error => {
          console.log(error.response)
          this.$toasted.show(error.response.data.message, {
            theme: 'toasted-primary',
            position: 'bottom-left',
            duration: 5000,
            icon: 'check',
            type: 'error'
          })
        })
    },
    setCompanyAccount () {
      const ca = this.getCompanyAccountList.find(d => d.id === this.accountFrom.alias_id)
      this.accountFrom.account_no = ca.bank_account
      this.accountFrom.account_name = ca.bank_account_name
      this.accountFrom.account_bank_id = ca.bank_id
    },
    async createBank () {
      var obj = {
        memo_no: this.memo.memo_no,
        company_alias_id: this.accountFrom.alias_id,
        company_account_no: this.accountFrom.account_no,
        company_account_name: this.accountFrom.account_name,
        company_account_bank_id: this.accountFrom.account_bank_id
      }
      console.log(obj)
      await axios.post(memoUrl + '/create-bank-payment', obj, { headers: getHeader() })
        .then(response => {
          this.backParent()
          this.$toasted.show(response.data.message, {
            theme: 'toasted-primary',
            position: 'bottom-left',
            duration: 5000,
            icon: 'check',
            type: 'success'
          })
        })
        .catch(error => {
          console.log(error.response)
          this.$toasted.show(error.response.data.message, {
            theme: 'toasted-primary',
            position: 'bottom-left',
            duration: 5000,
            icon: 'check',
            type: 'error'
          })
        })
    },
    async approveBank () {
      var obj = {
        memo_no: this.memo.memo_no
      }
      await axios.post(memoUrl + '/approve-bank-payment', obj, { headers: getHeader() })
        .then(response => {
          this.backParent()
          this.$toasted.show(response.data.message, {
            theme: 'toasted-primary',
            position: 'bottom-left',
            duration: 5000,
            icon: 'check',
            type: 'success'
          })
        })
        .catch(error => {
          console.log(error.response)
          this.$toasted.show(error.response.data.message, {
            theme: 'toasted-primary',
            position: 'bottom-left',
            duration: 5000,
            icon: 'check',
            type: 'error'
          })
        })
    },
    getEmployee () {
      var emp = this.employeeList.find(d => d.emp_no === this.memo.employee_no)
      console.log(emp)
      this.memo.employee.bank_id = emp.bank_id
      this.memo.employee.bank_account_no = emp.bank_account_no
      this.memo.employee.bank_account_name = emp.bank_account_name
    },
    async downloadAttachment (url, filename) {
      window.open(memoUrl + '/attachment/file/' + url, '', 'fullscreen=yes')
    },
    backParent: function () {
      this.$emit('backClicked')
    }
  }
}
</script>

<style>
.upload-border{
  min-height: 100px;
  border: 2px #ccc dashed;
  padding-top: 10px;
}
.upload-border .filename {
  font-size: 13px;
  letter-spacing: 1px;
  font-weight: 700;
  padding: 8px 20px;
  margin: 10px;
  background: #e7f1fd;
  color: #45A49F;
  display: inline-block;
  /* text-transform: uppercase; */
  border-radius: 10px;
}
.progress-container{
  width: 100%;
  position: absolute;
  z-index: 1;
}
.progressbar li{
  float: left;
  width: 20%;
  position: relative;
  text-align: center;
}
.progressbar{
  counter-reset: step;
}
.progressbar li:before{
  content:counter(step);
  counter-increment: step;
  width: 30px;
  height: 30px;
  border: 2px solid #bebebe;
  display: block;
  margin: 0 auto 10px auto;
  border-radius: 50%;
  line-height: 27px;
  background: white;
  color: #bebebe;
  text-align: center;
  font-weight: bold;
}
.progressbar li:after{
  content: '';
  position: absolute;
  width:100%;
  height: 3px;
  background: #979797;
  top: 15px;
  left: -50%;
  z-index: -1;
}

.progressbar li:first-child:after{
  content: none;
}
.progressbar li.active + li:after{
  background: #3aac5d;
}
.progressbar li.active + li:before{
  border-color: #3aac5d;
  background: #3aac5d;
  color: white
}
</style>
