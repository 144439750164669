<template>
  <div class="home">
    <SupplierList v-show="listView" @newSupplier="onClickNewFromList" @editSupplier="onClickEditFromList" ref="refreshList"/>
    <SupplierForm v-show="formView" :supplier="supplier" @backClicked="onBackChild" @backParentID="backParentID"/>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios'
import SupplierList from '@/components/supplier/SupplierList.vue'
import SupplierForm from '@/components/supplier/SupplierForm.vue'
import { getHeader, supplierUrl } from '../config'

export default {
  name: 'Supplier',
  components: {
    SupplierList,
    SupplierForm
  },
  data () {
    return {
      listView: true,
      formView: false,
      supplier: {
        id: null,
        supplier_no: null,
        name: null,
        phone: null,
        address: null,
        vat: null,
        bank_id: null,
        bank_account: null,
        bank_address: null,
        approve_by: null,
        lock: null,
        status: null,
        approve_date: null,
        active: null,
        alias: null,
        pkp: null,
        pic_name: null,
        pic_phone: null,
        pic_jabatan: null,
        pic_email: null,
        bank_account_name: null,
        description: null,
        attachments: [{
          id: null,
          supplier_no: null,
          filename: null,
          mime_type: null,
          extension: null,
          url: null
        }],
        accounts: [{
          id: null,
          alias: null,
          supplier_no: null,
          bank_id: null,
          bank_account: null,
          bank_address: null,
          bank_account_name: null,
          notes: null,
          billing_no: null,
          type: null
        }],
        logs: [{
          id: null,
          log_date: null,
          username: null,
          user: null,
          ref_no: null,
          description: null
        }]
      },
      currentX: null,
      currentY: null
    }
  },
  methods: {
    backParentID: function (id) {
      axios.get(supplierUrl + '/' + id, { headers: getHeader() })
        .then(response => {
          this.listView = false
          this.formView = true
          this.supplier = response.data.data
        })
        .catch(error => {
          console.log(error.response)
          this.$swal(
            'Error',
            error.response.data.message,
            'error'
          )
        })
    },
    onClickEditFromList: function (value) {
      this.currentY = window.scrollY
      this.currentX = window.scrollX
      this.listView = false
      this.formView = true
      this.supplier = value
      window.scrollTo(0, 0)
    },
    getEdit: function (value) {
      console.log(supplierUrl + '/' + value)
      axios.get(supplierUrl + '/' + value, { headers: getHeader() })
        .then(response => {
          console.log(response.data.data)
          this.listView = false
          this.formView = true
          this.supplier = response.data.data
        })
        .catch(error => {
          console.log(error.response)
        })
    },
    onClickNewFromList: function () {
      this.listView = false
      this.formView = true
      this.supplier = {
        id: null,
        supplier_no: null,
        name: null,
        phone: null,
        address: null,
        vat: null,
        bank_id: null,
        bank_account: null,
        bank_address: null,
        approve_by: null,
        lock: null,
        status: null,
        approve_date: null,
        active: null,
        alias: null,
        pkp: null,
        pic_name: null,
        pic_phone: null,
        pic_jabatan: null,
        pic_email: null,
        bank_account_name: null,
        billing_no: null,
        description: null,
        attachments: [{
          id: null,
          supplier_no: null,
          filename: null,
          mime_type: null,
          extension: null,
          url: null
        }],
        accounts: [{
          id: null,
          alias: null,
          supplier_no: null,
          bank_id: null,
          bank_account: null,
          bank_address: null,
          bank_account_name: null,
          notes: null,
          billing_no: null,
          type: null
        }],
        logs: [{
          id: null,
          log_date: null,
          username: null,
          user: null,
          ref_no: null,
          description: null
        }]
      }
    },
    onBackChild: function () {
      this.listView = true
      this.formView = false
      this.supplier = {
        id: null,
        supplier_no: null,
        name: null,
        phone: null,
        address: null,
        vat: null,
        bank_id: null,
        bank_account: null,
        bank_address: null,
        approve_by: null,
        lock: null,
        status: null,
        approve_date: null,
        active: null,
        alias: null,
        pkp: null,
        pic_name: null,
        pic_phone: null,
        pic_jabatan: null,
        pic_email: null,
        bank_account_name: null,
        billing_no: null,
        description: null,
        attachments: [{
          id: null,
          supplier_no: null,
          filename: null,
          mime_type: null,
          extension: null,
          url: null
        }],
        accounts: [{
          id: null,
          alias: null,
          supplier_no: null,
          bank_id: null,
          bank_account: null,
          bank_address: null,
          bank_account_name: null,
          notes: null,
          billing_no: null,
          type: null
        }],
        logs: [{
          id: null,
          log_date: null,
          username: null,
          user: null,
          ref_no: null,
          description: null
        }]
      }
      window.scrollTo(this.currentX, this.currentY)
      this.$refs.refreshList.refreshList()
      // this.$router.replace({ path: 'sales', query: null })
    }
  },
  mounted () {
    this.$store.dispatch('getCompanyList')
    this.$store.dispatch('getBankList')
    this.$store.dispatch('getRowTable')
  }
}
</script>
