<template>
    <div class="row">
      <loading
        :active.sync="loader.isLoading"
        :can-cancel="loader.canCancel"
        :is-full-page="loader.fullPage"
        :color="loader.color"
        :height="loader.height"
        :width="loader.width"
        :background-color="loader.backgroundColor"
        :loader="loader.loader">
      </loading>
      <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <h5>Employee Grade</h5>
            </div>
            <div class="card-body">
              <div class="table-responsive">
                <div class="form-inline ml-auto">
                  <div class="button-group">
                      <button type="button" class="btn btn-outline-primary btn-sm dropdown-toggle" data-toggle="dropdown">
                        <i class="fa fa-filter" aria-hidden="true"></i>
                          Filter
                          <span class="caret"></span>
                      </button>
                      <ul class="dropdown-menu p-2" >
                        <span>Status:</span>
                        <div class="dropdown-divider"></div>
                        <!-- <li><a href="#" class="small" tabIndex="-1"><input type="checkbox" v-model="filterStatus" value="completed"/> Completed</a></li> -->
                        <!-- <li><a href="#" class="small" tabIndex="-1"><input type="checkbox" v-model="filterStatus" value="outstanding"/> Outstanding</a></li> -->
                      </ul>
                      <button type="button" class="btn btn-sm btn-soft-primary waves-light waves-effect" @click="addList">
                        <i class="fa fa-plus" aria-hidden="true"></i>
                      </button>
                      <button type="button" class="btn btn-sm  btn-soft-primary waves-light waves-effect" @click="refreshList">
                        <i class="fa fa-refresh" aria-hidden="true"></i>
                      </button>
                      rows:
                      <select id="select-rows" v-model="pageSize" @change="setPageSizeOnChange">
                        <option v-for="r in rowsTable" :key="r.row" :value="r.row">{{ r.row }}</option>
                      </select>
                      <span class="mr-1">
                        Total rows: <b>{{ filterData.length }}</b>
                      </span>
                      <date-picker v-model="startDate" type="date" range placeholder="Select date range" @change="refreshList"></date-picker>
                  </div>
                  <div class="form-group mx-sm-3 mb-2">
                      <input type="search" placeholder="search..." class="form-control mr-1 input-search" v-model="searchQuery">
                  </div>
                  <span class="mr-1">{{ currentPage }} / {{ pageLength }}</span>
                  <nav aria-label="Page navigation example" class="pull-right">
                      <ul class="pagination">
                          <li class="page-item">
                              <a class="btn btn-default btn-sm" href="#" @click="prevPage">
                                  <i class="fa fa-angle-left" aria-hidden="true"></i>
                              </a>
                          </li>
                          <li class="page-item">
                              <a class="btn btn-default btn-sm" href="#" @click="nextPage">
                                  <i class="fa fa-angle-right" aria-hidden="true"></i>
                              </a>
                          </li>
                      </ul>
                  </nav>
                </div>
                <table class="table table-item mb-0 table-sm table-striped">
                    <thead>
                        <tr>
                            <th>
                              <label class="form-checkbox">
                                <input type="checkbox" v-model="selectAll" @click="select">
                                <i class="form-icon"></i>
                              </label>
                            </th>
                            <th  v-for="t in title" v-bind:key="t.prop" @click="sortTable(t.prop)">
                                <span v-if="t.name === 'Payment'" class="text-right">
                                {{ t.name }}
                                <div class="float-right fa" v-if="t.prop == sortColumn" v-bind:class="ascending ? 'fa-arrow-up' : 'fa-arrow-down'"></div>
                                </span>

                                <span v-else>
                                  {{ t.name }}
                                  <div class="float-right fa" v-if="t.prop == sortColumn" v-bind:class="ascending ? 'fa-arrow-up' : 'fa-arrow-down'"></div>
                                </span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                      <tr v-if="filterData.length < 1">
                        <td colspan="11" style="text-align: center;"><i>No Data</i></td>
                      </tr>
                      <tr v-for="u in filterData" v-bind:key="u.id" @click="editList(u.id)" style="cursor: pointer">
                        <td>
                          <label class="form-checkbox">
                            <input type="checkbox" :value="u.id" v-model="selected">
                            <i class="form-icon"></i>
                          </label>
                        </td>
                        <td>{{ u.id }}</td>
                        <td>{{ u.name }}</td>
                        <td>{{ u.company_no }}</td>
                      </tr>
                    </tbody>
                </table>
              </div>
            </div>
          </div>
      </div>
      <!-- <pre>{{ checkFilter }}</pre> -->
      <!-- <pre>{{ dataRaw }}</pre> -->
    </div>
</template>

<script>
import axios from 'axios'
import Loading from 'vue-loading-overlay'
import DatePicker from 'vue2-datepicker'
import 'vue-loading-overlay/dist/vue-loading.css'
import { employeeUrl, getHeader } from '../../config'
import { mapState } from 'vuex'

export default {
  name: 'GradeList',
  components: {
    Loading,
    DatePicker
  },
  data () {
    var date = new Date()
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0)
    const date1 = new Date(firstDay.getFullYear(), firstDay.getMonth(), firstDay.getDate())
    const date2 = new Date(lastDay.getFullYear(), lastDay.getMonth(), lastDay.getDate())
    return {
      filterStatus: [],
      startDate: [date1, date2],
      loader: {
        isLoading: false,
        fullPage: true,
        color: '#007bff',
        canCancel: true,
        height: 128,
        width: 128,
        backgroundColor: '#ffffff',
        loader: 'spinner'
      },
      dataRaw: [],
      data: {},
      searchQuery: '',
      ascending: false,
      sortClick: false,
      sortColumn: '',
      pageSize: 10,
      currentPage: 1,
      checkFilter: [],
      title: [{
        name: 'ID',
        prop: 'id'
      }, {
        name: 'Name',
        prop: 'name'
      }, {
        name: 'Company',
        prop: 'company_no'
      }],
      selectAll: false,
      selected: []
    }
  },
  mounted () {
    window.scrollTo(0, 0)
    // this.refreshList()
  },
  computed: {
    ...mapState({
      rowsTable: state => state.data.rowsTable
    }),
    pageLength: function () {
      var length = this.dataRaw.length / this.pageSize
      return Math.ceil(length)
    },
    filterData: function () {
      if (this.searchQuery.length > 0) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.currentPage = 1
      }
      var col = this.sortColumn
      var ascending = this.ascending
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.dataRaw.sort(function (a, b) {
        if (a[col] > b[col]) {
          return ascending ? 1 : -1
        } else if (a[col] < b[col]) {
          return ascending ? -1 : 1
        }
        return 0
      }).filter(d => {
        for (const name in d) {
          if (d[name] !== null) {
            if (d[name].toString().toLowerCase().indexOf(this.searchQuery.toLowerCase()) > -1) {
              return d
            }
          }
        }
      }).filter((row, index) => {
        const start = (this.currentPage - 1) * this.pageSize
        const end = this.currentPage * this.pageSize
        if (index >= start && index < end) return true
      })
    }
  },
  methods: {
    setPageSizeOnChange: function () {
      this.currentPage = 1
    },
    sortTable: function sortTable (col) {
      this.currentPage = 1
      if (this.sortColumn === col) {
        this.ascending = !this.ascending
      } else {
        this.ascending = true
        this.sortColumn = col
      }
    },
    nextPage: function () {
      if ((this.currentPage * this.pageSize) < this.dataRaw.length) this.currentPage++
    },
    prevPage: function () {
      if (this.currentPage > 1) this.currentPage--
    },
    select () {
      this.selected = []
      this.currentPage = 1
      if (!this.selectAll) {
        for (const i in this.filterData) {
          this.selected.push(this.filterData[i].id)
        }
      }
    },
    editList (id) {
      axios.get(employeeUrl + '/department/' + id, { headers: getHeader() })
        .then(response => {
          this.$emit('editDepartment', response.data.data)
        })
        .catch(error => {
          console.log(error.response)
          this.$swal(
            'Error!!!',
            error.response.data.message,
            'error'
          )
        })
    },
    addList () {
      this.$emit('newGrade', true)
    },
    refreshList () {
      this.$Progress.start()
      this.loader.isLoading = true
      var url = employeeUrl + '/department'
      console.log(url)
    //   axios.get(url, { headers: getHeader() })
    //     .then(response => {
    //       this.dataRaw = response.data.data
    //       this.$Progress.finish()
    //       this.loader.isLoading = false
    //       this.currentPage = 1
    //       this.searchQuery = ''
    //     })
    //     .catch(error => {
    //       console.log(error.response)
    //       this.$swal(
    //         'Error',
    //         error.response.data.message,
    //         'error'
    //       )
    //     })
    }
  }
}
</script>
