<template>
    <div>
        <h5>Memo Path</h5>
        <div class="row">
            <div class="col-md-12">
                <div class="top-list-toolbar">
                    <h4 class="text-blue"><span @click="backParent" style="cursor: pointer;color: blue;">Memo Path</span> /
                        <span v-if="path.id === null">New</span>
                        <span v-else>Edit</span>
                    </h4>
                    <div>
                        <button type="button" @click="backParent" class="btn btn-sm btn-danger pull-left"><i class="fa fa-reply" aria-hidden="true"></i> Back</button>
                        <button type="button" @click="save" class="btn btn-sm btn-success pull-left"><i class="fa fa-check" aria-hidden="true"></i> Save</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="card card-list">
            <div class="card-header">
              <h5>Path</h5>
            </div>
            <div class="card-body">
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group row">
                        <label for="company_no" class="col-sm-3 col-form-label text-right">Company No.</label>
                        <div class="col-sm-9">
                          <v-select :options="companySelect" :reduce="company => company.meta.code" label="company" class="vselect-custom" v-model="path.company_no"/>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="category_id" class="col-sm-3 col-form-label text-right">Category</label>
                        <div class="col-sm-9">
                          <v-select :options="categorySelect" :reduce="category => category.meta.code" label="category" class="vselect-custom" v-model="path.category_id"/>
                        </div>
                    </div>
                    <div class="form-group row">
                      <label for="create_user" class="col-sm-3 col-form-label text-right">Create Memo</label>
                      <div class="col-sm-9">
                        <select v-model="path.create_user" class="form-control">
                          <option v-for="c in createSelect" :key="c.id" :value="c.id">[{{ c.id }}] {{ c.name }}</option>
                        </select>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label for="create_user" class="col-sm-3 col-form-label text-right">Payment Memo</label>
                      <div class="col-sm-9">
                        <select v-model="path.finish_user" class="form-control">
                          <option value="">-- Select --</option>
                          <option v-for="c in createSelect" :key="c.id" :value="c.id">[{{ c.id }}] {{ c.name }}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group row">
                      <label for="path" class="col-sm-3 col-form-label text-right">Path</label>
                      <div class="col-sm-9">
                        <!-- <input type="text" v-model="path.path" class="form-control"> -->
                        <v-select :options="positionSelect" :reduce="position => position.meta.code" label="company" :multiple="true" class="vselect-custom" v-model="path.path_array"/>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label for="level" class="col-sm-3 col-form-label text-right">level</label>
                      <div class="col-sm-9">
                        <select v-model="path.level" class="form-control" @change="getLevel">
                          <option value="1">1 - low</option>
                          <option value="2">2 - Middle</option>
                          <option value="3">3 - Auto Finish</option>
                        </select>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label for="is_budget" class="col-sm-3 col-form-label text-right">Is Budget?</label>
                      <div class="col-sm-9">
                        <input type="checkbox" class="form-check-input" id="is_budget" v-model="path.is_budget">
                      </div>
                    </div>
                    <div class="form-group row">
                      <label for="remaining_budget" class="col-sm-3 col-form-label text-right">Remaining Budget</label>
                      <div class="col-sm-9">
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <table class="table table-item mb-0 table-sm table-striped">
                      <thead>
                        <th>Type</th>
                        <th>Amount</th>
                        <th>Begin</th>
                        <th>End</th>
                        <th>Notes</th>
                        <th>#</th>
                      </thead>
                      <tbody>
                        <tr v-for="(d, index) in path.budgets" :key="d.id">
                          <td>
                            <select class="form-control" v-model="d.type">
                              <option value="regular">Regular</option>
                              <option value="prepayment">Prepayment</option>
                            </select>
                          </td>
                          <td>
                            <input type="text" v-model="d.amount" v-money="money" class="form-control"/>
                          </td>
                          <td><input type="text" v-mask="'##/##/####'" v-model="d.begin" class="form-control" placeholder="DD/MM/YYYY"/></td>
                          <td><input type="text" v-mask="'##/##/####'" v-model="d.end" class="form-control" placeholder="DD/MM/YYYY"/></td>
                          <td><input type="text" v-model="d.notes" class="form-control"/></td>
                          <td><button type="button" class="btn btn-sm btn-danger" @click="removeBudget(index)"><i class="fa fa-trash"></i></button></td>
                        </tr>
                        <tr>
                          <td colspan="5">
                            <span @click="addLine" style="color: blue">Add Line</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
            </div>
        </div>
        <pre>{{ path }}</pre>
    </div>
</template>

<script>
import axios from 'axios'
import { getHeader, memoUrl } from '../../config'
import { mapState } from 'vuex'
export default {
  name: 'MemoPathForm',
  props: ['path'],
  data () {
    return {
      budgetEnable: false,
      money: {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 0,
        masked: true /* doesn't work with directive */
      }
    }
  },
  computed: {
    ...mapState({
      companyList: state => state.companies.companyList,
      memoCategoryList: state => state.memos.memoCategoryList,
      positionList: state => state.employees.positionList
    }),
    companySelect () {
      var loop = []
      for (let i = 0; i < this.companyList.length; i++) {
        var obj = {
          company: this.companyList[i].company_no + ' - ' + this.companyList[i].name,
          meta: {
            code: this.companyList[i].company_no
          }
        }
        loop.push(obj)
      }
      return loop
    },
    categorySelect () {
      var loop = []
      var array = this.memoCategoryList.filter(d => d.company_no === this.path.company_no)
      for (let i = 0; i < array.length; i++) {
        var obj = {
          category: array[i].name,
          meta: {
            code: array[i].id
          }
        }
        loop.push(obj)
      }
      return loop
    },
    positionSelect: function () {
      var loop = []
      var array = this.positionList.filter(d => d.company_no === this.path.company_no)
      for (let i = 0; i < array.length; i++) {
        var obj = {
          company: array[i].id + ' - ' + array[i].name,
          meta: {
            code: array[i].id
          }
        }
        loop.push(obj)
      }
      return loop
    },
    categoryMemoSelect: function () {
      return this.memoCategoryList.filter(d => d.company_no === this.path.company_no)
    },
    createSelect: function () {
      return this.positionList.filter(d => d.company_no === this.path.company_no)
    }
  },
  methods: {
    getLevel () {
      var level = this.path.level
      if (level === '3') {
        console.log(level)
        this.path.finish_user = ''
      }
    },
    removeBudget: function (index) {
      var detail = this.path.budgets[index]
      if (detail.id) {
        alert(detail.id)
      } else {
        this.path.budgets.splice(index, 1)
      }
    },
    addLine: function () {
      var obj = {
        id: null,
        path_id: this.path.id,
        amount: 0,
        begin: null,
        end: null,
        notes: null,
        company_no: this.path.company_no
      }
      this.path.budgets.push(obj)
    },
    backParent: function () {
      this.$emit('backClicked')
    },
    async save () {
      var id = this.path.id
      var pathID = null
      if (id === null) {
        console.log(memoUrl + '/path')
        await axios.post(memoUrl + '/path', this.path, { headers: getHeader() })
          .then(response => {
            console.log(response)
            pathID = response.data.data.id
            if (response.data.data.is_budget === true) {
              var arrayBudget = this.path.budgets
              for (let i = 0; i < arrayBudget.length; i++) {
                if (arrayBudget[i].id == null) {
                  var obj = {
                    path_id: pathID,
                    amount: arrayBudget[i].amount.replace(/\./g, ''),
                    begin: arrayBudget[i].begin !== null ? arrayBudget[i].begin.replaceAll('/', '-') : null,
                    end: arrayBudget[i].end !== null ? arrayBudget[i].end.replaceAll('/', '-') : null,
                    notes: arrayBudget[i].notes,
                    company_no: arrayBudget[i].company_no,
                    type: arrayBudget[i].type
                  }
                  axios.post(memoUrl + '/path/budget', obj, { headers: getHeader() })
                    .then(response => {
                      console.log(response.data)
                    })
                    .catch(error => {
                      console.log(error.response)
                    })
                } else {
                  var obj2 = {
                    path_id: pathID,
                    amount: arrayBudget[i].amount.replace(/\./g, ''),
                    begin: arrayBudget[i].begin !== null ? arrayBudget[i].begin.replaceAll('/', '-') : null,
                    end: arrayBudget[i].end !== null ? arrayBudget[i].end.replaceAll('/', '-') : null,
                    notes: arrayBudget[i].notes,
                    company_no: arrayBudget[i].company_no
                  }
                  axios.put(memoUrl + '/path/budget/' + arrayBudget[i].id, obj2, { headers: getHeader() })
                    .then(response => {
                      console.log(response.data)
                    })
                    .catch(error => {
                      console.log(error.response)
                    })
                }
              }
            }
            this.$toasted.show(response.data.message, {
              theme: 'toasted-primary',
              position: 'top-right',
              duration: 5000,
              icon: 'check',
              type: 'success'
            })
            this.backParent()
          })
          .catch(error => {
            console.log(error.response)
            this.$toasted.show(error.response.data.message, {
              theme: 'toasted-primary',
              position: 'top-right',
              duration: 5000,
              icon: 'check',
              type: 'error'
            })
          })
      } else {
        var url = memoUrl + '/path/' + id
        axios.put(url, this.path, { headers: getHeader() })
          .then(response => {
            console.log(response)
            pathID = response.data.data.id
            var arrayBudget = this.path.budgets
            for (let i = 0; i < arrayBudget.length; i++) {
              if (arrayBudget[i].id == null) {
                var obj = {
                  path_id: pathID,
                  amount: arrayBudget[i].amount.replace(/\./g, ''),
                  begin: arrayBudget[i].begin !== null ? arrayBudget[i].begin.replaceAll('/', '-') : null,
                  end: arrayBudget[i].end !== null ? arrayBudget[i].end.replaceAll('/', '-') : null,
                  notes: arrayBudget[i].notes,
                  company_no: arrayBudget[i].company_no,
                  type: arrayBudget[i].type
                }
                axios.post(memoUrl + '/path/budget', obj, { headers: getHeader() })
                  .then(response => {
                    console.log(response.data)
                  })
                  .catch(error => {
                    console.log(error.response)
                  })
              } else {
                var obj2 = {
                  path_id: pathID,
                  amount: arrayBudget[i].amount.replace(/\./g, ''),
                  begin: arrayBudget[i].begin !== null ? arrayBudget[i].begin.replaceAll('/', '-') : null,
                  end: arrayBudget[i].end !== null ? arrayBudget[i].end.replaceAll('/', '-') : null,
                  notes: arrayBudget[i].notes,
                  company_no: arrayBudget[i].company_no,
                  type: arrayBudget[i].type
                }
                axios.put(memoUrl + '/path/budget/' + arrayBudget[i].id, obj2, { headers: getHeader() })
                  .then(response => {
                    console.log(response.data)
                  })
                  .catch(error => {
                    console.log(error.response)
                  })
              }
            }
            this.$toasted.show(response.data.message, {
              theme: 'toasted-primary',
              position: 'top-right',
              duration: 5000,
              icon: 'check',
              type: 'success'
            })
            this.backParent()
          })
          .catch(error => {
            console.log(error.response)
          })
      }
    }
  }
}
</script>
