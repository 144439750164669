<template>
    <div class="row">
      <loading
        :active.sync="loader.isLoading"
        :can-cancel="loader.canCancel"
        :is-full-page="loader.fullPage"
        :color="loader.color"
        :height="loader.height"
        :width="loader.width"
        :background-color="loader.backgroundColor"
        :loader="loader.loader">
      </loading>
      <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <h5>Employee</h5>
            </div>
            <div class="card-body">
              <ul class="nav nav-tabs nav-bordered">
                <li class="nav-item">
                  <a href="#data" data-toggle="tab" aria-expanded="false" class="nav-link active">Data</a>
                </li>
                <li class="nav-item">
                  <a href="#import" data-toggle="tab" aria-expanded="false" class="nav-link">Import</a>
                </li>
              </ul>
              <div class="tab-content">
                  <div class="tab-pane active" id="data">
                    <div class="table-responsive">
                      <div class="form-inline ml-auto">
                        <div class="button-group">
                            <!-- <button type="button" class="btn btn-outline-soft-primary btn-sm dropdown-toggle" data-toggle="dropdown">
                              <i class="fa fa-filter" aria-hidden="true"></i>
                                Filter
                                <span class="caret"></span>
                            </button>
                            <ul class="dropdown-menu p-2" >
                              <span>Status:</span>
                              <div class="dropdown-divider"></div>
                              <li v-for="c in companyList" :key="c.id"><a href="#" class="small" tabIndex="-1"><input type="checkbox" v-model="filterCompany" :value="c.company_no"/>{{ c.name }}</a></li>
                            </ul> -->
                            <select class="btn btn-sm btn-outline-soft-primary mr-1" v-model="filterCompany">
                              <option value="">-- All Company --</option>
                              <option v-for="c in companyList" :key="c.id" :value="c.company_no">{{ c.name }}</option>
                            </select>
                            <button type="button" class="btn btn-sm btn-soft-primary" @click="asUser">
                              <i class="fa fa-user-o" aria-hidden="true"></i> As User
                            </button>
                            <button type="button" class="btn btn-sm btn-soft-primary waves-light waves-effect" @click="addList">
                              <i class="fa fa-plus" aria-hidden="true"></i>
                            </button>
                            <button type="button" class="btn btn-sm  btn-soft-primary waves-light waves-effect" @click="refreshList">
                              <i class="fa fa-refresh" aria-hidden="true"></i>
                            </button>
                            rows:
                            <select id="select-rows" v-model="pageSize" @change="setPageSizeOnChange">
                              <option v-for="r in rowsTable" :key="r.row" :value="r.row">{{ r.row }}</option>
                            </select>
                            <span class="mr-1">
                              Total rows: <b>{{ filterData.length }}</b>
                            </span>
                        </div>
                        <div class="form-group mx-sm-3 mb-2">
                            <input type="search" placeholder="search..." class="form-control mr-1 input-search" v-model="searchQuery">
                        </div>
                        <span class="mr-1">{{ currentPage }} / {{ pageLength }}</span>
                        <nav aria-label="Page navigation example" class="pull-right">
                            <ul class="pagination">
                                <li class="page-item">
                                    <a class="btn btn-default btn-sm" href="#" @click="prevPage">
                                        <i class="fa fa-angle-left" aria-hidden="true"></i>
                                    </a>
                                </li>
                                <li class="page-item">
                                    <a class="btn btn-default btn-sm" href="#" @click="nextPage">
                                        <i class="fa fa-angle-right" aria-hidden="true"></i>
                                    </a>
                                </li>
                            </ul>
                        </nav>
                      </div>
                      <table class="table table-item mb-0 table-sm table-striped table-hover">
                          <thead>
                              <tr>
                                  <th>
                                    <label class="form-checkbox">
                                      <input type="checkbox" v-model="selectAll" @click="select">
                                      <i class="form-icon"></i>
                                    </label>
                                  </th>
                                  <th  v-for="t in title" v-bind:key="t.prop" @click="sortTable(t.prop)">
                                      <span>
                                        {{ t.name }}
                                        <div class="float-right fa" v-if="t.prop == sortColumn" v-bind:class="ascending ? 'fa-arrow-up' : 'fa-arrow-down'"></div>
                                      </span>
                                  </th>
                              </tr>
                          </thead>
                          <tbody>
                            <tr v-if="filterData.length < 1">
                              <td colspan="11" style="text-align: center;"><i>No Data</i></td>
                            </tr>
                            <tr v-for="u in filterData" v-bind:key="u.id" style="cursor: pointer">
                              <td>
                                <label class="form-checkbox">
                                  <input type="checkbox" :value="u.id" v-model="selected">
                                  <i class="form-icon"></i>
                                </label>
                              </td>
                              <td @click="editList(u.id)"><b>{{ u.employee_no }}</b></td>
                              <td @click="editList(u.id)">
                                <span v-if="u.isUser === true"><i class="fa fa-check" style="color: green"></i></span>
                              </td>
                              <td @click="editList(u.id)">
                                <span class="badge badge-success" v-if="u.status === 'active'">{{ u.status }}</span>
                                <span class="badge badge-danger" v-else-if="u.status === 'retired'">{{ u.status }}</span>
                                <span class="badge badge-warning" v-else>{{ u.status }}</span>
                              </td>
                              <td @click="editList(u.id)">{{ u.name }}</td>
                              <td @click="editList(u.id)">
                                <ul>
                                  <li v-for="c in u.company" :key="c.id">{{ c.name }}</li>
                                </ul>
                              </td>
                              <td @click="editList(u.id)">
                                <ul>
                                  <li v-for="c in u.position" :key="c.id"><b>{{ c.id }}</b> - <i>{{ c.name }}</i></li>
                                </ul>
                              </td>
                              <td @click="editList(u.id)">{{ u.email }}</td>
                              <td @click="editList(u.id)">{{ u.phone }}</td>
                            </tr>
                          </tbody>
                      </table>
                    </div>
                  </div>
                  <div class="tab-pane" id="import">
                    <div class="table-responsive">
                      <div class="form-inline ml-auto">
                        <div class="button-group">
                          <button class="btn btn-sm btn-primary" @click="getHadirApi"><i class="lni lni-cloud-sync"></i> Get API</button>
                          <button class="btn btn-sm btn-primary" @click="importFromApi"><i class="lni lni-download"></i> Import</button>
                        </div>
                      </div>
                      <table class="table table-item mb-0 table-sm table-striped table-hover">
                        <thead>
                          <tr>
                            <th>
                              <label class="form-checkbox">
                                <input type="checkbox" v-model="selectAll2" @click="select2">
                                <i class="form-icon"></i>
                              </label>
                            </th>
                            <th>Employee No</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Phone</th>
                            <th>Photo</th>
                            <th>Start Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="h in dataHadirr" :key="h.nik">
                            <td>
                              <label class="form-checkbox">
                                <input type="checkbox" :value="h.employee_no" v-model="selected2">
                                <i class="form-icon"></i>
                              </label>
                            </td>
                            <td>{{ h.employee_no }} - {{ h.is_exists }}</td>
                            <td>{{ h.name }}</td>
                            <td>{{ h.email }}</td>
                            <td>{{ h.phone }}</td>
                            <td>{{ h.photo }}</td>
                            <td>{{ h.start_date }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
              </div>
            </div>
          </div>
      </div>
    </div>
</template>

<script>
import axios from 'axios'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import { employeeUrl, getHeader, syncUrl, userUrl } from '../../config'
import { mapState } from 'vuex'

export default {
  name: 'BankList',
  components: {
    Loading
  },
  data () {
    var date = new Date()
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0)
    const date1 = new Date(firstDay.getFullYear(), firstDay.getMonth(), firstDay.getDate())
    const date2 = new Date(lastDay.getFullYear(), lastDay.getMonth(), lastDay.getDate())
    return {
      filterCompany: '',
      startDate: [date1, date2],
      loader: {
        isLoading: false,
        fullPage: true,
        color: '#007bff',
        canCancel: true,
        height: 128,
        width: 128,
        backgroundColor: '#ffffff',
        loader: 'spinner'
      },
      dataHadirr: [],
      dataRaw: [],
      data: {},
      searchQuery: '',
      ascending: true,
      sortClick: false,
      sortColumn: 'employee_no',
      pageSize: 50,
      currentPage: 1,
      checkFilter: [],
      title: [{
        name: 'Employee No',
        prop: 'employee_no'
      }, {
        name: 'Is User',
        prop: 'isUser'
      }, {
        name: 'Status',
        prop: 'status'
      }, {
        name: 'Name',
        prop: 'name'
      }, {
        name: 'Company No.',
        prop: 'company_no'
      }, {
        name: 'Position',
        prop: 'position_id'
      }, {
        name: 'Email',
        prop: 'email'
      }, {
        name: 'Phone',
        prop: 'phone'
      }],
      selectAll: false,
      selectAll2: false,
      selected: [],
      selected2: []
    }
  },
  created () {
    axios.get(userUrl + '/token', { headers: getHeader() })
      .then(response => {
        this.filterCompany = response.data.data.company_no
      })
      .catch(error => {
        this.$toasted.show(error.response.data.message, {
          theme: 'toasted-primary',
          position: 'top-right',
          duration: 5000,
          icon: 'check',
          type: 'error'
        })
      })
  },
  mounted () {
    window.scrollTo(0, 0)
    this.refreshList()
  },
  computed: {
    ...mapState({
      authUser: state => state.users.authUser,
      rowsTable: state => state.data.rowsTable,
      companyList: state => state.companies.companyList
    }),
    pageLength: function () {
      var length = this.dataRaw.length / this.pageSize
      return Math.ceil(length)
    },
    filterData: function () {
      if (this.searchQuery.length > 0) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.currentPage = 1
      }
      var col = this.sortColumn
      var ascending = this.ascending
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.dataRaw.sort(function (a, b) {
        if (a[col] > b[col]) {
          return ascending ? 1 : -1
        } else if (a[col] < b[col]) {
          return ascending ? -1 : 1
        }
        return 0
      }).filter(d => {
        for (const name in d) {
          if (d[name] !== null) {
            if (d[name].toString().toLowerCase().indexOf(this.searchQuery.toLowerCase()) > -1) {
              return d
            }
          }
        }
      }).filter(d => {
        if (this.filterCompany) {
          if (d.company_no === this.filterCompany) {
            return d
          }
        } else {
          return d
        }
      }).filter((row, index) => {
        const start = (this.currentPage - 1) * this.pageSize
        const end = this.currentPage * this.pageSize
        if (index >= start && index < end) return true
      })
    }
  },
  methods: {
    async importFromApi () {
      this.$Progress.start()
      this.loader.isLoading = true
      var array = this.selected2
      var failed = []
      var success = []
      for (let i = 0; i < array.length; i++) {
        var data = this.dataHadirr.find(d => d.employee_no === array[i])
        await axios.post(employeeUrl + '/import', data, { headers: getHeader() })
          .then(response => {
            console.log(response)
            success.push(response.data.data.employee_no)
          })
          .catch(error => {
            console.log(error.response)
            failed.push(array[i].employee_no)
          })
      }
      if (failed.length > 0) {
        this.$toasted.show(failed, {
          theme: 'toasted-primary',
          position: 'top-right',
          duration: 5000,
          icon: 'check',
          type: 'error'
        })
      }
      if (success.length > 0) {
        this.$toasted.show('Success Import Data Employee', {
          theme: 'toasted-primary',
          position: 'top-right',
          duration: 5000,
          icon: 'check',
          type: 'succes'
        })
      }
      this.$Progress.finish()
      this.loader.isLoading = false
    },
    async getHadirApi () {
      this.dataHadirr = []
      this.$Progress.start()
      this.loader.isLoading = true
      await axios.get(syncUrl + '/hadirr/employee', { headers: getHeader() })
        .then(response => {
          this.dataHadirr = response.data.data
          for (let i = 0; i < this.dataHadirr.length; i++) {
            if (this.dataHadirr[i].is_exists === false) {
              this.selected2.push(this.dataHadirr[i].employee_no)
            }
          }
          this.$Progress.finish()
          this.loader.isLoading = false
          this.$toasted.show(response.data.message, {
            theme: 'toasted-primary',
            position: 'top-right',
            duration: 5000,
            icon: 'check',
            type: 'success'
          })
        })
        .catch(error => {
          this.$Progress.finish()
          this.loader.isLoading = false
          this.$toasted.show(error.response.data.message, {
            theme: 'toasted-primary',
            position: 'top-right',
            duration: 5000,
            icon: 'check',
            type: 'error'
          })
        })
    },
    async asUser () {
      var array = this.selected
      for (let i = 0; i < array.length; i++) {
        var obj = {
          id: array[i]
        }
        console.log(obj)
        await axios.post(employeeUrl + '/as-user', obj, { headers: getHeader() })
          .then(response => {
            console.log(response.data.data)
          })
          .catch(error => {
            console.log(error.response)
          })
      }
      this.refreshList()
    },
    setPageSizeOnChange: function () {
      this.currentPage = 1
    },
    sortTable: function sortTable (col) {
      this.currentPage = 1
      if (this.sortColumn === col) {
        this.ascending = !this.ascending
      } else {
        this.ascending = true
        this.sortColumn = col
      }
    },
    nextPage: function () {
      if ((this.currentPage * this.pageSize) < this.dataRaw.length) this.currentPage++
    },
    prevPage: function () {
      if (this.currentPage > 1) this.currentPage--
    },
    select () {
      this.selected = []
      this.currentPage = 1
      if (!this.selectAll) {
        for (const i in this.filterData) {
          if (this.filterData[i].isUser === false) {
            this.selected.push(this.filterData[i].id)
          }
        }
      }
    },
    select2 () {
      this.selected2 = []
      if (!this.selectAll2) {
        for (const i in this.dataHadirr) {
          this.selected2.push(this.dataHadirr[i].employee_no)
        }
      }
    },
    editList (id) {
      axios.get(employeeUrl + '/' + id, { headers: getHeader() })
        .then(response => {
          this.$emit('editEmployee', response.data.data)
        })
        .catch(error => {
          console.log(error.response)
          this.$swal(
            'Error!!!',
            error.response.data.message,
            'error'
          )
        })
    },
    addList () {
      this.$emit('newEmployee', true)
    },
    refreshList () {
      this.$Progress.start()
      this.loader.isLoading = true
      var url = employeeUrl
      console.log(url)
      axios.get(url, { headers: getHeader() })
        .then(response => {
          this.dataRaw = response.data.data
          this.$Progress.finish()
          this.loader.isLoading = false
          this.currentPage = 1
          this.searchQuery = ''
        })
        .catch(error => {
          console.log(error.response)
          this.$swal(
            'Error',
            error.response.data.message,
            'error'
          )
        })
    }
  }
}
</script>
