<template>
    <div class="row">
        <loading
        :active.sync="loader.isLoading"
        :can-cancel="loader.canCancel"
        :is-full-page="loader.fullPage"
        :color="loader.color"
        :height="loader.height"
        :width="loader.width"
        :background-color="loader.backgroundColor"
        :loader="loader.loader">
        </loading>
        <div class="col-md-12">
            <div class="table-responsive">
                <div class="form-inline ml-auto">
                    <div class="button-group">
                        <button type="button" class="btn btn-outline-soft-primary btn-sm dropdown-toggle" data-toggle="dropdown">
                            <i class="fa fa-filter" aria-hidden="true"></i>
                              Filter
                              <span class="caret"></span>
                        </button>
                        <ul class="dropdown-menu p-2" >
                            <span>Company</span>
                            <div class="dropdown-divider"></div>
                            <li v-for="c in companyList" :key="c.id">
                              <a href="javascript:void(0)" class="small" tabIndex="-1"><input type="checkbox" v-model="filterCompany2" :value="c.company_no"/> {{ c.name }}</a>
                            </li>
                            <li>
                              <a href="javascript:void(0)"  class="small" tabIndex="-1" @click="uncheckfilter2"><i style="color: blue">Uncheck All</i></a>
                            </li>
                        </ul>
                        <button type="button" class="btn btn-sm  btn-soft-primary waves-light waves-effect" @click="refreshList">
                            <i class="fa fa-refresh" aria-hidden="true"></i>
                        </button>
                        rows:
                        <select id="select-rows" v-model="pageSize2" @change="setPageSizeOnChange2">
                            <option v-for="r in rowsTable" :key="r.row" :value="r.row">{{ r.row }}</option>
                        </select>
                        <span class="mr-1">
                            Total rows: <b>{{ filterData2.length }}</b>
                        </span>
                        <date-picker v-model="startDate2" type="date" range placeholder="Select date range" @change="refreshList"></date-picker>
                    </div>
                    <div class="form-group mx-sm-3 mb-2">
                        <input type="search" placeholder="search..." class="form-control mr-1 input-search" v-model="searchQuery2">
                    </div>
                    <span class="mr-1">{{ currentPage2 }} / {{ pageLength2 }}</span>
                    <nav aria-label="Page navigation example" class="pull-right">
                        <ul class="pagination">
                            <li class="page-item">
                                <a class="btn btn-default btn-sm" href="javascript:void(0)" @click="prevPage2">
                                  <i class="fa fa-angle-left" aria-hidden="true"></i>
                                </a>
                            </li>
                            <li class="page-item">
                                <a class="btn btn-default btn-sm" href="javascript:void(0)" @click="nextPage2">
                                  <i class="fa fa-angle-right" aria-hidden="true"></i>
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>
                <table class="table table-item mb-0 table-sm table-striped">
                    <thead>
                        <tr>
                            <th v-for="t in title" v-bind:key="t.prop" @click="sortTable(t.prop)">
                                <span v-if="t.name === 'Payment'" class="text-right">
                                {{ t.name }}
                                <div class="float-right fa" v-if="t.prop == sortColumn2" v-bind:class="ascending2 ? 'fa-arrow-up' : 'fa-arrow-down'"></div>
                                </span>

                                <span v-else>
                                    {{ t.name }}
                                    <div class="float-right fa" v-if="t.prop == sortColumn2" v-bind:class="ascending2 ? 'fa-arrow-up' : 'fa-arrow-down'"></div>
                                </span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-if="filterData.length < 1">
                        <td colspan="11" style="text-align: center;"><i>No Data</i></td>
                        </tr>
                        <tr v-for="u in filterData" v-bind:key="u.id" @click="showList(u.memo.id)" style="cursor: pointer">
                        <td><img :src="u.from_profile" style="max-width: 50px;border-radius: 50%;" /></td>
                        <td>
                            <span class="badge badge-success" v-if="u.memo.status === 'on progress'">{{ u.memo.status }}</span>
                            <span class="badge badge-warning" v-else-if="u.memo.status === 'revise'">{{ u.memo.status }}</span>
                            <span class="badge badge-danger" v-else-if="u.memo.status === 'reject'">{{ u.memo.status }}</span>
                            <span class="badge badge-primary" v-else>{{ u.memo.status }}</span>
                        </td>
                        <td>{{ u.memo_no }}</td>
                        <td>{{ u.log_date | dateFormat('DD/MM/YYYY HH:mm:ss') }}</td>
                        <td>{{ u.memo.memo_date | dateFormat('DD/MM/YYYY') }}</td>
                        <td>{{ u.memo.user_from }}</td>
                        <td>{{ u.memo.subject }}</td>
                        <td>{{ u.memo.total | currency }}</td>
                        <td>
                            <span v-if="u.memo.employee_no !== null">{{ u.memo.employee }}</span>
                            <span v-else>{{ u.memo.supplier }}</span>
                        </td>
                        <td><b>[{{ u.company_alias }}] {{ u.company }}</b></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
      <!-- <pre>{{ checkFilter }}</pre> -->
      <!-- <pre>{{ dataRaw }}</pre> -->
    </div>
</template>

<script>
import axios from 'axios'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import { memoUrl, getHeader } from '../../config'
import { mapState } from 'vuex'
import DatePicker from 'vue2-datepicker'

export default {
  name: 'MemoHistoryList',
  components: {
    Loading,
    DatePicker
  },
  data () {
    var date = new Date()
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0)
    const date1 = new Date(firstDay.getFullYear(), firstDay.getMonth(), firstDay.getDate())
    const date2 = new Date(lastDay.getFullYear(), lastDay.getMonth(), lastDay.getDate())
    return {
      filterCompany2: [],
      filterCompany: [],
      filterStatus: [],
      startDate: [date1, date2],
      startDate2: [date1, date2],
      loader: {
        isLoading: false,
        fullPage: true,
        color: '#007bff',
        canCancel: true,
        height: 128,
        width: 128,
        backgroundColor: '#ffffff',
        loader: 'spinner'
      },
      dataRaw: [],
      dataRaw2: [],
      data: {},
      searchQuery: '',
      searchQuery2: '',
      ascending: false,
      ascending2: false,
      sortClick: false,
      sortClick2: false,
      sortColumn: 'duration',
      sortColumn2: '',
      pageSize2: 100,
      pageSize: 100,
      currentPage2: 1,
      currentPage: 1,
      checkFilter2: [],
      checkFilter: [],
      title: [{
        name: '',
        prop: 'from_profile'
      }, {
        name: 'Status Approve',
        prop: 'status'
      }, {
        name: 'Memo No.',
        prop: 'memo_no'
      }, {
        name: 'Log Date',
        prop: 'log_date'
      }, {
        name: 'Date',
        prop: 'memo_date'
      }, {
        name: 'From',
        prop: 'from'
      }, {
        name: 'Subject',
        prop: 'subject'
      }, {
        name: 'Total',
        prop: 'total'
      }, {
        name: 'Supplier/Employee',
        prop: 'supplier_no'
      }, {
        name: 'Company',
        prop: 'company_no'
      }],
      selectAll: false,
      selectAll2: false,
      selected: [],
      selected2: []
    }
  },
  mounted () {
    window.scrollTo(0, 0)
    this.refreshList()
  },
  computed: {
    ...mapState({
      authUser: state => state.users.authUser,
      rowsTable: state => state.data.rowsTable,
      companyList: state => state.companies.companyList
    }),
    pageLength: function () {
      var length = this.dataRaw.length / this.pageSize
      return Math.ceil(length)
    },
    pageLength2: function () {
      var length = this.dataRaw.length / this.pageSize
      return Math.ceil(length)
    },
    filterData: function () {
      if (this.searchQuery.length > 0) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.currentPage = 1
      }
      var col = this.sortColumn
      var ascending = this.ascending
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.dataRaw.sort(function (a, b) {
        if (a[col] > b[col]) {
          return ascending ? 1 : -1
        } else if (a[col] < b[col]) {
          return ascending ? -1 : 1
        }
        return 0
      }).filter(d => {
        for (const name in d) {
          if (d[name] !== null) {
            if (d[name].toString().toLowerCase().indexOf(this.searchQuery.toLowerCase()) > -1) {
              return d
            }
          }
        }
      }).filter(d => {
        if (this.filterCompany.length > 0) {
          for (let a = 0; a < this.filterCompany.length; a++) {
            if (d.company_no === this.filterCompany[a]) {
              return d
            }
          }
        } else {
          return d
        }
      }).filter((row, index) => {
        const start = (this.currentPage - 1) * this.pageSize
        const end = this.currentPage * this.pageSize
        if (index >= start && index < end) return true
      })
    },
    filterData2: function () {
      if (this.searchQuery.length > 0) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.currentPage = 1
      }
      var col = this.sortColumn
      var ascending = this.ascending
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.dataRaw2.sort(function (a, b) {
        if (a[col] > b[col]) {
          return ascending ? 1 : -1
        } else if (a[col] < b[col]) {
          return ascending ? -1 : 1
        }
        return 0
      }).filter(d => {
        for (const name in d) {
          if (d[name] !== null) {
            if (d[name].toString().toLowerCase().indexOf(this.searchQuery.toLowerCase()) > -1) {
              return d
            }
          }
        }
      }).filter(d => {
        if (this.filterCompany2.length > 0) {
          for (let a = 0; a < this.filterCompany2.length; a++) {
            if (d.company_no === this.filterCompany2[a]) {
              return d
            }
          }
        } else {
          return d
        }
      }).filter((row, index) => {
        const start = (this.currentPage - 1) * this.pageSize
        const end = this.currentPage * this.pageSize
        if (index >= start && index < end) return true
      })
    }
  },
  methods: {
    uncheckfilter2: function () {
      this.filterCompany2 = []
    },
    uncheckfilter: function () {
      this.filterCompany = []
    },
    setPageSizeOnChange: function () {
      this.currentPage = 1
    },
    setPageSizeOnChange2: function () {
      this.currentPage2 = 1
    },
    sortTable: function sortTable (col) {
      this.currentPage = 1
      if (this.sortColumn === col) {
        this.ascending = !this.ascending
      } else {
        this.ascending = true
        this.sortColumn = col
      }
    },
    sortTable2: function sortTable (col) {
      this.currentPage2 = 1
      if (this.sortColumn2 === col) {
        this.ascending2 = !this.ascending2
      } else {
        this.ascending2 = true
        this.sortColumn2 = col
      }
    },
    nextPage: function () {
      if ((this.currentPage * this.pageSize) < this.dataRaw.length) this.currentPage++
    },
    nextPage2: function () {
      if ((this.currentPage2 * this.pageSize2) < this.dataRaw2.length) this.currentPage2++
    },
    prevPage: function () {
      if (this.currentPage > 1) this.currentPage--
    },
    prevPage2: function () {
      if (this.currentPage2 > 1) this.currentPage2--
    },
    select () {
      this.selected = []
      this.currentPage = 1
      if (!this.selectAll) {
        for (const i in this.filterData) {
          this.selected.push(this.filterData[i].id)
        }
      }
    },
    showList (id) {
      axios.get(memoUrl + '/' + id, { headers: getHeader() })
        .then(response => {
          this.$emit('showMemo', response.data.data)
        })
        .catch(error => {
          console.log(error.response)
          this.$swal(
            'Error!!!',
            error.response.data.message,
            'error'
          )
        })
    },
    editList (id) {
      console.log(memoUrl + '/inbox/' + id)
      axios.get(memoUrl + '/inbox/' + id, { headers: getHeader() })
        .then(response => {
          console.log(response.data.data)
          this.$emit('editMemoInbox', response.data.data)
        })
        .catch(error => {
          console.log(error.response)
          this.$swal(
            'Error!!!',
            error.response.data.message,
            'error'
          )
        })
    },
    addList () {
      this.$emit('newMemo', true)
    },
    async refreshList () {
      this.$Progress.start()
      this.loader.isLoading = true
      var user = this.authUser.username
      console.log(memoUrl + '/log?user=' + user)
      await axios.get(memoUrl + '/log?user=' + user, { headers: getHeader() })
        .then(response => {
          this.dataRaw = response.data.data
          this.$Progress.finish()
          this.loader.isLoading = false
          this.currentPage = 1
          this.searchQuery = ''
        })
        .catch(error => {
          console.log(error.response)
          this.$swal(
            'Error',
            error.response.data.message,
            'error'
          )
        })
    }
  }
}
</script>
