import axios from 'axios'
import { employeeUrl, getHeader } from './../config'

const state = {
  employeeList: [],
  departmentList: [],
  positionList: [],
  gradeList: [],
  companyListEmployee: []
}

const mutations = {
  SET_EMPLOYEE_LIST (state, obj) {
    state.employeeList = obj
  },
  SET_EMPLOYEE_DEPARTMENT_LIST (state, obj) {
    state.departmentList = obj
  },
  SET_EMPLOYEE_POSITION_LIST (state, obj) {
    state.positionList = obj
  },
  SET_EMPLOYEE_GRADE_LIST (state, obj) {
    state.gradeList = obj
  },
  SET_COMPANY_BY_EMPLOYEE (state, obj) {
    state.companyListEmployee = obj
  }
}

const actions = {
  getEmployeeList: ({ commit }) => {
    console.log(employeeUrl)
    axios.get(employeeUrl, { headers: getHeader() })
      .then(response => {
        commit('SET_EMPLOYEE_LIST', response.data.data)
      })
  },
  getEmployeeByCompany: ({ commit }, payload) => {
    var url = employeeUrl + '&comany=' + payload.payload
    console.log(url)
    axios.get(url, { headers: getHeader() })
      .then(response => {
        commit('SET_EMPLOYEE_LIST', response.data.data)
      })
  },
  getEmployeeDepartment: ({ commit }) => {
    console.log(employeeUrl + '/department')
    axios.get(employeeUrl + '/department', { headers: getHeader() })
      .then(response => {
        commit('SET_EMPLOYEE_DEPARTMENT_LIST', response.data.data)
      })
  },
  getEmployeePosition: ({ commit }) => {
    console.log(employeeUrl + '/position')
    axios.get(employeeUrl + '/position', { headers: getHeader() })
      .then(response => {
        commit('SET_EMPLOYEE_POSITION_LIST', response.data.data)
      })
  },
  getEmployeeGrade: ({ commit }) => {
    console.log(employeeUrl + '/grade')
    axios.get(employeeUrl + '/grade', { headers: getHeader() })
      .then(response => {
        commit('SET_EMPLOYEE_GRADE_LIST', response.data.data)
      })
  }
}

export default {
  state, mutations, actions
}
