<template>
    <div>
        <h5>Employee Grade</h5>
        <div class="row">
            <div class="col-md-12">
                <div class="top-list-toolbar">
                    <h4 class="text-blue"><span @click="backParent" style="cursor: pointer;color: blue;">Employee Grade</span> /
                        <span v-if="grade.id === null">New</span>
                        <span v-else>Edit</span>
                    </h4>
                    <div>
                        <button type="button" @click="backParent" class="btn btn-sm btn-danger pull-left"><i class="fa fa-reply" aria-hidden="true"></i> Back</button>
                        <button type="button" @click="save" class="btn btn-sm btn-success pull-left"><i class="fa fa-check" aria-hidden="true"></i> Save</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="card card-list">
            <div class="card-header">
                <h5 v-if="grade.id === null">New</h5>
                <h5 v-else>{{ grade.id }}</h5>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group row">
                            <label for="id" class="col-sm-3 col-form-label text-left">ID</label>
                            <div class="col-sm-9">
                                <input type="text" v-model="grade.id" class="form-control">
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="name" class="col-sm-3 col-form-label text-left">Name</label>
                            <div class="col-sm-9">
                                <input type="text" v-model="grade.name" class="form-control">
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="company_no" class="col-sm-3 col-form-label text-left">Company</label>
                            <div class="col-sm-9">
                                <select v-model="grade.company_no" class="form-control">
                                    <option v-for="c in companyList" :key="c.id" :value="c.company_no">[{{ c.company_no }}] {{ c.name }}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { getHeader, employeeUrl } from '../../config'
import { mapState } from 'vuex'
export default {
  name: 'EmployeeGradeForm',
  props: ['grade'],
  computed: {
    ...mapState({
      companyList: state => state.companies.companyList
    })
  },
  methods: {
    backParent: function () {
      this.$emit('backClicked')
    },
    async save () {
      var id = this.grade.id
      if (id === null) {
        console.log(employeeUrl + '/grade')
        axios.post(employeeUrl + '/grade', this.grade, { headers: getHeader() })
          .then(response => {
            console.log(response)
            this.$swal(
              'Success',
              response.data.message,
              'success'
            )
            this.backParent()
          })
          .catch(error => {
            console.log(error.response)
            this.$swal(
              'Error',
              error.response.data.message,
              'error'
            )
          })
      } else {
        var url = employeeUrl + '/grade/' + id
        axios.put(url, this.grade, { headers: getHeader() })
          .then(response => {
            console.log(response)
            this.$swal(
              'Success',
              response.data.message,
              'success'
            )
            this.backParent()
          })
          .catch(error => {
            console.log(error.response)
            this.$swal(
              'Error',
              error.response.data.message,
              'error'
            )
          })
      }
    }
  }
}
</script>
