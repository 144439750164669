<template>
    <div>
        <h5>Supplier</h5>
        <div class="row">
            <div class="col-md-12">
                <div class="top-list-toolbar">
                    <h4 class="text-blue"><span @click="backParent" style="cursor: pointer;color: blue;">Supplier</span> /
                        <span v-if="supplier.id === null">New</span>
                        <span v-else>Edit</span>
                    </h4>
                    <div>
                        <button type="button" @click="backParent" class="btn btn-sm btn-danger pull-left"><i class="fa fa-reply" aria-hidden="true"></i> Back</button>
                        <button type="button" @click="save" class="btn btn-sm btn-success pull-left"><i class="fa fa-check" aria-hidden="true"></i> Save</button>
                        <button type="button" @click="approve" class="btn btn-sm btn-primary pull-right" v-if="authUser.permissions.supplier_approval"><i class="fa fa-check" aria-hidden="true"></i> Approve</button>
                        <button type="button" @click="unlock" class="btn btn-sm btn-warning pull-right" v-if="authUser.permissions.supplier_approval"><i class="fa fa-unlock-alt" aria-hidden="true"></i> Revise</button>
                        <button type="button" @click="reject" class="btn btn-sm btn-danger pull-right" v-if="authUser.permissions.supplier_approval"><i class="fa fa-unlock-alt" aria-hidden="true"></i> Reject</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="card card-list">
            <div class="card-header">
                <h5 v-if="supplier.id === null">New</h5>
                <h5 v-else>{{ supplier.supplier_no }}</h5>
            </div>
            <div class="card-body">
               <ul class="nav nav-tabs nav-bordered">
                  <li class="nav-item"><a href="#general" data-toggle="tab" aria-expanded="false" class="nav-link active">General</a></li>
               </ul>
               <div class="tab-content">
                  <div class="tab-pane active" id="general">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group row">
                          <h5 class="col-sm-3 text-right align-label">Data:</h5>
                          <div class="col-sm-9">
                            &nbsp;
                          </div>
                        </div>
                        <div class="form-group row">
                          <label for="active" class="col-sm-3 col-form-label text-right align-label">&nbsp;</label>
                          <div class="col-sm-9">
                            <div class="custom-control custom-checkbox">
                              <input v-model="supplier.active" type="checkbox" class="custom-control-input" id="supplier-active" data-parsley-multiple="groups" data-parsley-mincheck="2">
                              <label class="custom-control-label" for="supplier-active">Active?</label>
                            </div>
                          </div>
                        </div>
                        <div class="form-group row">
                          <label for="pkp" class="col-sm-3 col-form-label text-right align-label">&nbsp;</label>
                          <div class="col-sm-9">
                            <div class="custom-control custom-checkbox">
                              <input v-model="supplier.pkp" type="checkbox" class="custom-control-input" id="supplier-pkp" data-parsley-multiple="groups" data-parsley-mincheck="2" :disabled="supplier.lock">
                              <label class="custom-control-label" for="supplier-pkp">Pengusaha Kena Pajak</label>
                            </div>
                          </div>
                        </div>
                        <div class="form-group row">
                            <label for="name" class="col-sm-3 col-form-label text-right align-label">Name</label>
                            <div class="col-sm-9">
                                <input type="text" v-model="supplier.name" class="form-control" :disabled="supplier.lock">
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="phone" class="col-sm-3 col-form-label text-right align-label">Phone</label>
                            <div class="col-sm-9">
                                <input type="text" v-model="supplier.phone" class="form-control" :disabled="supplier.lock">
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="address" class="col-sm-3 col-form-label text-right align-label">Address</label>
                            <div class="col-sm-9">
                                <textarea v-model="supplier.address" class="form-control" :disabled="supplier.lock"></textarea>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="vat" class="col-sm-3 col-form-label text-right align-label">NPWP</label>
                            <div class="col-sm-9">
                                <input type="text" v-model="supplier.vat" class="form-control" :disabled="supplier.lock">
                            </div>
                        </div>
                        <div class="form-group row">
                          <label for="company_no" class="col-sm-3 col-form-label text-right align-label">Company</label>
                          <div class="col-sm-9">
                            <v-select :options="companySelect" :reduce="company => company.meta.code" label="company" :multiple="true" class="vselect-custom" v-model="supplier.company" :disabled="supplier.lock"/>
                          </div>
                        </div>
                        <div class="form-group row">
                          <label for="description" class="col-sm-3 col-form-label text-right align-label">Description</label>
                          <div class="col-sm-9">
                            <textarea v-model="supplier.description" class="form-control" rows="5" placeholder="description...." :disabled="supplier.lock"></textarea>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group row">
                          <h5 class="col-sm-3 text-right align-label">Person In Charge:</h5>
                          <div class="col-sm-9">
                            &nbsp;
                          </div>
                        </div>
                        <div class="form-group row">
                          <label for="pic_name" class="col-sm-3 col-form-label text-right align-label">PIC Name</label>
                          <div class="col-sm-9">
                            <input type="text" class="form-control" v-model="supplier.pic_name" :disabled="supplier.lock"/>
                          </div>
                        </div>
                        <div class="form-group row">
                          <label for="pic_phone" class="col-sm-3 col-form-label text-right align-label">PIC Phone</label>
                          <div class="col-sm-9">
                            <input type="text" class="form-control" v-model="supplier.pic_phone" :disabled="supplier.lock"/>
                          </div>
                        </div>
                        <div class="form-group row">
                          <label for="pic_jabatan" class="col-sm-3 col-form-label text-right align-label">PIC Jabatan</label>
                          <div class="col-sm-9">
                            <input type="text" class="form-control" v-model="supplier.pic_jabatan" :disabled="supplier.lock"/>
                          </div>
                        </div>
                        <div class="form-group row">
                          <label for="pic_email" class="col-sm-3 col-form-label text-right align-label">PIC Email</label>
                          <div class="col-sm-9">
                            <input type="text" class="form-control" v-model="supplier.pic_email" :disabled="supplier.lock"/>
                          </div>
                        </div>
                        <div v-if="supplier.id !== null">
                          <h5>
                            <i class="fa fa-paperclip" aria-hidden="true"></i> Attachment
                          </h5>
                          <div><input type="file" id="file" ref="file" @change="upload('form-vendor')" hidden/></div>
                          <div class="upload-border">
                            <span class="filename" v-for="a in supplier.attachments" :key="a.id">
                              <span @click="downloadAttachment(a.url, a.filename)" style="cursor: pointer;">{{ a.filename }}</span>
                              <i v-if="supplier.status !== 'approve'" class="fa fa-times ml-1" aria-hidden="true"  style="cursor: pointer; color: red" @click="removeAttachment(a.id)"></i>
                            </span>
                            <span class="upload-button" v-if="supplier.status !== 'approve'">
                              <a href="javascript:void(0)" @click="chooseFiles" class="btn btn-sm btn-outline-primary"><i class="lni lni-cloud-upload"></i> UPLOAD</a>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr>
                    <div class="row mt-5">
                      <div class="col-md-12">
                        <h5 v-if="supplier.status !== 'approve'">Rekening <button class="btn btn-sm btn-primary" @click="addRekening"><i class="fa fa-plus"></i></button></h5>
                      </div>
                      <div class="col-md-6 mt-5" v-for="(acc, index) in supplier.accounts" :key="acc.id">
                        <div class="form-group row">
                          <label for="name" class="col-sm-3 col-form-label text-right align-label">
                            Alias
                          </label>
                          <div class="col-sm-9">
                            <input type="text" v-model="acc.alias" class="form-control" :disabled="supplier.lock">
                          </div>
                        </div>
                        <div class="form-group row">
                          <label for="type" class="col-sm-3 col-form-label text-right align-label">Type</label>
                          <div class="col-sm-9">
                            <select v-model="acc.type" class="form-control" :disabled="supplier.lock">
                              <option value="rekening">Rekening</option>
                              <option value="billing">Billing</option>
                            </select>
                          </div>
                        </div>
                        <span v-if="acc.type === 'rekening'">
                          <div class="form-group row">
                            <label for="bank_id" class="col-sm-3 col-form-label text-right align-label">Bank</label>
                            <div class="col-sm-9">
                              <select v-model="acc.bank_id" class="form-control" :disabled="supplier.lock">
                                <option v-for="b in bankList" :key="b.id" :value="b.id">{{ b.name }}</option>
                              </select>
                            </div>
                          </div>
                          <div class="form-group row">
                            <label for="bank_account_name" class="col-sm-3 col-form-label text-right align-label">Bank Account Name</label>
                            <div class="col-sm-9">
                              <input type="text" v-model="acc.bank_account_name" class="form-control" :disabled="supplier.lock">
                            </div>
                          </div>
                          <div class="form-group row">
                            <label for="bank_account" class="col-sm-3 col-form-label text-right align-label">Bank Account No. <span style="color: red;">*</span></label>
                            <div class="col-sm-9">
                                <input type="text" v-model="acc.bank_account" class="form-control" :disabled="supplier.lock">
                            </div>
                          </div>
                          <div class="form-group row">
                            <label for="bank_address" class="col-sm-3 col-form-label text-right align-label">Bank Address</label>
                            <div class="col-sm-9">
                                <input type="text" v-model="acc.bank_address" class="form-control" :disabled="supplier.lock">
                            </div>
                          </div>
                          <div class="form-group row">
                          <label for="bank_account" class="col-sm-3 col-form-label text-right align-label">&nbsp;</label>
                          <div class="col-sm-9">
                            <button type="button" class="btn btn-outline-danger btn-sm" @click="removeAccount(index)"><i class="fa fa-trash"></i> Delete</button>
                          </div>
                          </div>
                        </span>
                        <span v-else-if="acc.type === 'billing'">
                          <div class="form-group row">
                            <label for="billing_no" class="col-sm-3 col-form-label text-right align-label">Billing No.</label>
                            <div class="col-sm-9">
                                <input type="text" v-model="acc.billing_no" class="form-control" :disabled="supplier.lock">
                            </div>
                          </div>
                          <div class="form-group row">
                            <label for="bank_account" class="col-sm-3 col-form-label text-right align-label">&nbsp;</label>
                            <div class="col-sm-9">
                              <button type="button" class="btn btn-outline-danger btn-sm" @click="removeAccount(index)" v-if="supplier.status !== 'approve'"><i class="fa fa-trash"></i> Delete</button>
                            </div>
                          </div>
                        </span>
                      </div>
                    </div>
                    <hr>
                  </div>
               </div>
            </div>
        </div>
        <div class="row justify-content-md-center">
          <div class="col-md-6">
            <h5>Logs:</h5>
            <div class="alert alert-info" role="alert" v-for="l in supplier.logs" :key="l.id">
              <b>[{{ l.username }}] {{ l.user }}</b> - {{ l.log_date | dateFormat('DD/MM/YYYY HH:mm:ss') }} <span>"{{ l.description }}"</span>
            </div>
          </div>
        </div>
        <!-- <pre>{{ supplier }}</pre> -->
    </div>
</template>

<script>
import axios from 'axios'
import { getHeader, supplierUrl } from '../../config'
import { mapState } from 'vuex'
export default {
  name: 'SupplierForm',
  props: ['supplier'],
  computed: {
    ...mapState({
      authUser: state => state.users.authUser,
      companyList: state => state.companies.companyList,
      bankList: state => state.banks.bankList
    }),
    npwpValidate: function () {
      const array = this.supplier.attachments.filter(d => d.id !== null).filter(d => d.type === 'npwp')
      var validate = false
      for (let i = 0; i < array.length; i++) {
        if (array[i].validate === true) {
          validate = true
        }
      }
      return validate
    },
    pkpValidate: function () {
      const array = this.supplier.attachments.filter(d => d.id !== null).filter(d => d.type === 'pkp')
      var validate = false
      for (let i = 0; i < array.length; i++) {
        if (array[i].validate === true) {
          validate = true
        }
      }
      return validate
    },
    rekeningValidate: function () {
      const array = this.supplier.attachments.filter(d => d.id !== null).filter(d => d.type === 'rekening')
      var validate = false
      for (let i = 0; i < array.length; i++) {
        if (array[i].validate === true) {
          validate = true
        }
      }
      return validate
    },
    formVendorValidate: function () {
      const array = this.supplier.attachments.filter(d => d.id !== null).filter(d => d.type === 'form-vendor')
      var validate = false
      for (let i = 0; i < array.length; i++) {
        if (array[i].validate === true) {
          validate = true
        }
      }
      return validate
    },
    attachmentListVendor: function () {
      return this.supplier.attachments.filter(d => d.id !== null).filter(d => d.type === 'form-vendor')
    },
    attachmentListNpwp: function () {
      return this.supplier.attachments.filter(d => d.id !== null).filter(d => d.type === 'npwp')
    },
    attachmentListPkp: function () {
      return this.supplier.attachments.filter(d => d.id !== null).filter(d => d.type === 'pkp')
    },
    attachmentListRekening: function () {
      return this.supplier.attachments.filter(d => d.id !== null).filter(d => d.type === 'rekening')
    },
    companySelect () {
      var loop = []
      for (let i = 0; i < this.companyList.length; i++) {
        var obj = {
          company: this.companyList[i].company_no + ' - ' + this.companyList[i].name,
          meta: {
            code: this.companyList[i].id
          }
        }
        loop.push(obj)
      }
      return loop
    }
  },
  methods: {
    async reject () {
      await axios.post(supplierUrl + '/reject', { supplier_no: this.supplier.supplier_no }, { headers: getHeader() })
        .then(response => {
          this.$toasted.show(response.data.message, {
            theme: 'toasted-primary',
            position: 'bottom-left',
            duration: 5000,
            icon: 'check',
            type: 'success'
          })
          this.backParent()
        })
        .catch(error => {
          console.log(error.response)
          this.$toasted.show(error.response.data.message, {
            theme: 'toasted-primary',
            position: 'bottom-left',
            duration: 5000,
            icon: 'check',
            type: 'error'
          })
        })
    },
    async validateAttachment (type) {
      var obj = {
        supplier_no: this.supplier.supplier_no,
        type: type
      }
      await axios.post(supplierUrl + '/attachment/validation', obj, { headers: getHeader() })
        .then(response => {
          console.log(response.data.data)
          const res = response.data.data[0]
          const data = this.supplier.attachments.find(d => d.type === res.type)
          data.type = res.type
          data.validate = res.validate
          data.validate_by = res.validate_by
          data.validate_date = res.validate_date
          this.$toasted.show(response.data.message, {
            theme: 'toasted-primary',
            position: 'bottom-left',
            duration: 5000,
            icon: 'check',
            type: 'success'
          })
        })
        .catch(error => {
          console.log(error.response)
          this.$toasted.show(error.response.data.message, {
            theme: 'toasted-primary',
            position: 'bottom-left',
            duration: 5000,
            icon: 'check',
            type: 'error'
          })
        })
    },
    removeAccount (index) {
      const account = this.supplier.accounts[index]
      if (account.id) {
        axios.delete(supplierUrl + '/account/' + account.id, { headers: getHeader() })
          .then(response => {
            this.supplier.accounts.splice(index, 1)
            this.$toasted.show(response.data.message, {
              theme: 'toasted-primary',
              position: 'bottom-left',
              duration: 5000,
              icon: 'check',
              type: 'success'
            })
          })
          .catch(error => {
            console.log(error.response)
            this.$toasted.show(error.response.data.message, {
              theme: 'toasted-primary',
              position: 'bottom-left',
              duration: 5000,
              icon: 'check',
              type: 'error'
            })
          })
      } else {
        this.supplier.accounts.splice(index, 1)
        this.$toasted.show('Delete Rekening', {
          theme: 'toasted-primary',
          position: 'bottom-left',
          duration: 5000,
          icon: 'check',
          type: 'success'
        })
      }
    },
    addRekening () {
      var obj = {
        alias: null,
        supplier_no: null,
        bank_id: null,
        bank_account: null,
        bank_address: null,
        bank_account_name: null,
        notes: null
      }
      this.supplier.accounts.push(obj)
    },
    async removeAttachment (id) {
      var index = this.supplier.attachments.findIndex(d => d.id === id)
      await axios.delete(supplierUrl + '/attachment/' + id, { headers: getHeader() })
        .then(response => {
          this.supplier.attachments.splice(index, 1)
          this.$toasted.show('Delete Attachment', {
            theme: 'toasted-primary',
            position: 'bottom-left',
            duration: 5000
          })
        })
        .catch(error => {
          console.log(error.response)
          this.$toasted.show(error.response.data.message, {
            theme: 'toasted-primary',
            position: 'bottom-left',
            duration: 5000,
            icon: 'check',
            type: 'error'
          })
        })
    },
    async downloadAttachment (url, filename) {
      window.open(supplierUrl + '/attachment/file/' + url, '', 'fullscreen=yes')
      // console.log(supplierUrl + '/attachment/file/' + url)
      // await axios({
      //   url: supplierUrl + '/attachment/file/' + url,
      //   method: 'GET',
      //   responseType: 'blob',
      //   headers: getHeader()
      // }).then((response) => {
      //   var blob = new Blob([response.data], { type: 'application/jpg' })
      //   var fileURL = window.URL.createObjectURL(blob)
      //   var fileLink = document.createElement('a')
      //   fileLink.href = fileURL
      //   fileLink.setAttribute('download', filename)
      //   document.body.appendChild(fileLink)
      //   fileLink.click()
      // })
    },
    handleFileUpload: function () {
      this.file = this.$refs.file.files[0]
    },
    async upload () {
      this.file = this.$refs.file.files[0]
      const formData = new FormData()
      formData.append('file', this.file)
      if (this.supplier.supplier_no) {
        formData.append('supplier_no', this.supplier.supplier_no)
        formData.append('type', 'form-vendor')
      }
      const newHeader = {
        'Content-Type': 'multipart/form-data'
      }
      const newObj = Object.assign(getHeader(), newHeader)
      await axios.post(supplierUrl + '/attachment', formData, { headers: newObj })
        .then(response => {
          console.log(response.data)
          var data = response.data.data
          console.log(data)
          var obj = {
            validate_date: data.validate_date,
            validate_by: data.validate_by,
            validate: data.validate,
            type: data.type,
            supplier_no: data.supplier_no,
            filename: data.filename,
            mime_type: data.mime_type,
            extension: data.extension,
            url: data.url,
            id: data.id
          }
          console.log(obj)
          this.supplier.attachments.push(obj)
          this.$toasted.show(response.data.message, {
            theme: 'toasted-primary',
            position: 'bottom-left',
            duration: 5000,
            icon: 'check',
            type: 'success'
          })
        })
        .catch(error => {
          console.log(error.response)
          this.$toasted.show(error.response.data.message, {
            theme: 'toasted-primary',
            position: 'bottom-left',
            duration: 5000,
            icon: 'check',
            type: 'error'
          })
        })
    },
    async uploadNpwp () {
      alert('upload npwp')
      this.file = this.$refs.npwp.files[0]
      const formData = new FormData()
      formData.append('file', this.file)
      if (this.supplier.supplier_no) {
        formData.append('supplier_no', this.supplier.supplier_no)
        formData.append('type', 'npwp')
      }
      const newHeader = {
        'Content-Type': 'multipart/form-data'
      }
      console.log(formData)
      const newObj = Object.assign(getHeader(), newHeader)
      await axios.post(supplierUrl + '/attachment', formData, { headers: newObj })
        .then(response => {
          console.log(response.data)
          var data = response.data.data
          var obj = {
            validate_date: data.validate_date,
            validate_by: data.validate_by,
            validate: data.validate,
            type: data.type,
            supplier_no: data.supplier_no,
            filename: data.filename,
            mime_type: data.mime_type,
            extension: data.extension,
            url: data.url,
            id: data.id
          }
          this.supplier.attachments.push(obj)
          this.$toasted.show(response.data.message, {
            theme: 'toasted-primary',
            position: 'bottom-left',
            duration: 5000,
            icon: 'check',
            type: 'success'
          })
        })
        .catch(error => {
          console.log(error.response)
          this.$toasted.show(error.response.data.message, {
            theme: 'toasted-primary',
            position: 'bottom-left',
            duration: 5000,
            icon: 'check',
            type: 'error'
          })
        })
    },
    async uploadPkp () {
      this.file = this.$refs.pkp.files[0]
      console.log(this.file)
      const formData = new FormData()
      formData.append('file', this.file)
      if (this.supplier.supplier_no) {
        formData.append('supplier_no', this.supplier.supplier_no)
        formData.append('type', 'pkp')
      }
      const newHeader = {
        'Content-Type': 'multipart/form-data'
      }
      console.log(formData)
      const newObj = Object.assign(getHeader(), newHeader)
      await axios.post(supplierUrl + '/attachment', formData, { headers: newObj })
        .then(response => {
          console.log(response.data)
          var data = response.data.data
          var obj = {
            validate_date: data.validate_date,
            validate_by: data.validate_by,
            validate: data.validate,
            type: data.type,
            supplier_no: data.supplier_no,
            filename: data.filename,
            mime_type: data.mime_type,
            extension: data.extension,
            url: data.url,
            id: data.id
          }
          this.supplier.attachments.push(obj)
          this.$toasted.show(response.data.message, {
            theme: 'toasted-primary',
            position: 'bottom-left',
            duration: 5000,
            icon: 'check',
            type: 'success'
          })
        })
        .catch(error => {
          console.log(error.response)
          this.$toasted.show(error.response.data.message, {
            theme: 'toasted-primary',
            position: 'bottom-left',
            duration: 5000,
            icon: 'check',
            type: 'error'
          })
        })
    },
    async uploadRekening () {
      this.file = this.$refs.rekening.files[0]
      const formData = new FormData()
      formData.append('file', this.file)
      if (this.supplier.supplier_no) {
        formData.append('supplier_no', this.supplier.supplier_no)
        formData.append('type', 'rekening')
      }
      const newHeader = {
        'Content-Type': 'multipart/form-data'
      }
      console.log(formData)
      const newObj = Object.assign(getHeader(), newHeader)
      await axios.post(supplierUrl + '/attachment', formData, { headers: newObj })
        .then(response => {
          console.log(response.data)
          var data = response.data.data
          var obj = {
            validate_date: data.validate_date,
            validate_by: data.validate_by,
            validate: data.validate,
            type: data.type,
            supplier_no: data.supplier_no,
            filename: data.filename,
            mime_type: data.mime_type,
            extension: data.extension,
            url: data.url,
            id: data.id
          }
          this.supplier.attachments.push(obj)
          this.$toasted.show(response.data.message, {
            theme: 'toasted-primary',
            position: 'bottom-left',
            duration: 5000,
            icon: 'check',
            type: 'success'
          })
        })
        .catch(error => {
          console.log(error.response)
          this.$toasted.show(error.response.data.message, {
            theme: 'toasted-primary',
            position: 'bottom-left',
            duration: 5000,
            icon: 'check',
            type: 'error'
          })
        })
    },
    chooseFiles: function () {
      document.getElementById('file').click()
    },
    chooseNpwp: function () {
      document.getElementById('npwp').click()
    },
    choosePkp: function () {
      document.getElementById('pkp').click()
    },
    chooseRekening: function () {
      document.getElementById('rekening').click()
    },
    async unlock () {
      console.log(supplierUrl + '/unlock/' + this.supplier.id)
      await axios.get(supplierUrl + '/unlock/' + this.supplier.id, { headers: getHeader() })
        .then(response => {
          this.$toasted.show(response.data.message, {
            theme: 'toasted-primary',
            position: 'bottom-right',
            duration: 5000,
            icon: 'check',
            type: 'success'
          })
          this.backParent()
        })
        .catch(error => {
          console.log(error.response)
          this.$toasted.show(error.response.data.message, {
            theme: 'toasted-primary',
            position: 'bottom-right',
            duration: 5000,
            icon: 'check',
            type: 'error'
          })
        })
    },
    async approve () {
      await axios.get(supplierUrl + '/approve/' + this.supplier.id, { headers: getHeader() })
        .then(response => {
          this.$toasted.show(response.data.message, {
            theme: 'toasted-primary',
            position: 'bottom-right',
            duration: 5000,
            icon: 'check',
            type: 'success'
          })
          this.backParent()
        })
        .catch(error => {
          console.log(error.response)
          this.$toasted.show(error.response.data.message, {
            theme: 'toasted-primary',
            position: 'bottom-right',
            duration: 5000,
            icon: 'check',
            type: 'error'
          })
        })
    },
    backParentID: function (id) {
      this.$emit('backParentID', id)
    },
    backParent: function () {
      this.$emit('backClicked')
    },
    async save () {
      var acc = this.supplier.accounts
      if (this.supplier.billing_no === null) {
        for (let i = 0; i < acc.length; i++) {
          if (acc[i].bank_account === null || acc[i].bank_account === '') {
            this.$swal(
              'Not Valid',
              'Check Your Bank Account Number',
              'error'
            )
            return false
          }
          if (acc[i].alias === null || acc[i].alias === '') {
            this.$swal(
              'Not Valid',
              '"Rekening Alias" cannot be null',
              'error'
            )
            return false
          }
        }
      }
      var id = this.supplier.id
      if (id === null) {
        console.log(supplierUrl)
        axios.post(supplierUrl, this.supplier, { headers: getHeader() })
          .then(response => {
            console.log(response)
            this.$swal(
              'Success',
              response.data.message,
              'success'
            )
            this.backParentID(response.data.data.id)
          })
          .catch(error => {
            console.log(error.response)
            var array = error.response.data.message
            if (array.length > 0) {
              for (let i = 0; i < array.length; i++) {
                this.$toasted.show(array[i], {
                  theme: 'toasted-primary',
                  position: 'bottom-left',
                  duration: 5000,
                  icon: 'check',
                  type: 'error'
                })
              }
            }
          })
      } else {
        var url = supplierUrl + '/' + id
        axios.put(url, this.supplier, { headers: getHeader() })
          .then(response => {
            console.log(response)
            this.$swal(
              'Success',
              response.data.message,
              'success'
            )
            this.backParent()
          })
          .catch(error => {
            console.log(error.response)
            this.$swal(
              'Error',
              error.response.data.message,
              'error'
            )
          })
      }
    }
  }
}
</script>
