const state = {
  rowsTable: [],
  currentMenu: '',
  mainMenu: [{
    name: 'dashboard',
    menu: [{
      name: 'Dashboard',
      header: null,
      url: 'home',
      subMenu: []
    }, {
      name: 'Report',
      header: 'Report',
      url: null,
      subMenu: [{
        header: 'Report',
        list: [{
          name: 'Report Daily',
          url: null
        }, {
          name: 'Report by Customer',
          url: null
        }]
      }, {
        header: 'Finance',
        list: [{
          name: 'Report Laba/Rugi',
          url: null
        }, {
          name: 'Report Neraca',
          url: null
        }]
      }]
    }]
  }, {
    name: 'inventory',
    menu: [{
      name: 'Inventory',
      header: 'Inventory',
      url: null,
      subMenu: [{
        header: 'Inventory',
        list: [{
          name: 'List',
          url: 'inventory'
        }, {
          name: 'transfer',
          url: 'inventory-transfer'
        }, {
          name: 'Stock Adjust',
          url: 'stock.adjust'
        }]
      }]
    }, {
      name: 'Product',
      header: 'Product',
      url: null,
      subMenu: [{
        header: 'Product',
        list: [{
          name: 'List',
          url: 'product'
        }, {
          name: 'Summary',
          url: 'product-summary'
        }, {
          name: 'History',
          url: 'product-history'
        }]
      }, {
        header: 'Price',
        list: [{
          name: 'Price List',
          url: 'setting.pricelist'
        }, {
          name: 'Cost (HPP)',
          url: 'cost'
        }, {
          name: 'Jounal',
          url: 'journal'
        }]
      }, {
        header: 'Setting',
        list: [{
          name: 'Unit Of Measure',
          url: 'setting.uom'
        }, {
          name: 'Category Product',
          url: 'setting.pc'
        }, {
          name: 'Warehouse',
          url: 'master.warehouse'
        }, {
          name: 'Vendor',
          url: 'vendor'
        }]
      }]
    }, {
      name: 'Purchasing',
      header: 'Purchasing',
      url: null,
      subMenu: [{
        header: 'Purchasing',
        list: [{
          name: 'List',
          url: 'purchasing'
        }, {
          name: 'Payment',
          url: 'purchasing-payment'
        }]
      }]
    }]
  }, {
    name: 'purchasing',
    menu: [{
      name: 'Purchasing',
      header: 'Purchasing',
      url: null,
      subMenu: [{
        header: 'Purchasing',
        list: [{
          name: 'List',
          url: 'purchasing'
        }, {
          name: 'Payment',
          url: 'purchasing-payment'
        }]
      }]
    }, {
      name: 'Product',
      header: 'Product',
      url: null,
      subMenu: [{
        header: 'Product',
        list: [{
          name: 'List',
          url: 'product'
        }, {
          name: 'Summary',
          url: 'product-summary'
        }, {
          name: 'History',
          url: 'product-history'
        }]
      }, {
        header: 'Price',
        list: [{
          name: 'Price List',
          url: 'setting.pricelist'
        }, {
          name: 'Cost (HPP)',
          url: 'cost'
        }, {
          name: 'Jounal',
          url: 'journal'
        }]
      }, {
        header: 'Setting',
        list: [{
          name: 'Unit Of Measure',
          url: 'setting.uom'
        }, {
          name: 'Category Product',
          url: 'setting.pc'
        }, {
          name: 'Warehouse',
          url: 'master.warehouse'
        }, {
          name: 'Vendor',
          url: 'vendor'
        }]
      }]
    }, {
      name: 'Inventory',
      header: 'Inventory',
      url: null,
      subMenu: [{
        header: 'Inventory',
        list: [{
          name: 'List',
          url: 'inventory'
        }, {
          name: 'transfer',
          url: 'inventory-transfer'
        }, {
          name: 'Stock Adjust',
          url: 'stock.adjust'
        }]
      }]
    }]
  }, {
    name: 'master',
    menu: [{
      name: 'Master',
      header: 'Master',
      url: null,
      subMenu: [{
        header: 'Master',
        list: [{
          name: 'Warehouse',
          url: 'master.warehouse'
        }, {
          name: 'Branch',
          url: 'master.branch'
        }, {
          name: 'Organization',
          url: 'master.org'
        }]
      }, {
        header: 'Finance',
        list: [{
          name: 'Chart Of Account',
          url: 'coa'
        }, {
          name: 'Bank',
          url: 'master.bank'
        }, {
          name: 'Bank Group',
          url: 'master.bg'
        }]
      }]
    }, {
      name: 'Manage Lock',
      header: null,
      url: 'lock',
      subMenu: []
    }]
  }, {
    name: 'hrm',
    menu: [{
      name: 'Human Resources',
      header: 'Human Resources',
      url: null,
      subMenu: [{
        header: 'Employee',
        list: [{
          name: 'List',
          url: 'setting.user'
        }, {
          name: 'Job Position',
          url: 'setting.jp'
        }, {
          name: 'Role',
          url: 'setting.role'
        }]
      }]
    }, {
      name: 'Sales & Project',
      header: 'Sales & Project',
      url: null,
      subMenu: [{
        header: 'Sales',
        list: [{
          name: 'Survey',
          url: 'sale.survey'
        }, {
          name: 'Order',
          url: 'sale.order'
        }, {
          name: 'Sales',
          url: 'sales'
        }]
      }, {
        header: 'Project',
        list: [{
          name: 'List',
          url: 'project'
        }]
      }]
    }]
  }, {
    name: 'customer',
    menu: [{
      name: 'Customer',
      header: 'Customer',
      url: null,
      subMenu: [{
        header: 'Customer',
        list: [{
          name: 'List',
          url: 'customer'
        }]
      }]
    }]
  }, {
    name: 'service',
    menu: [{
      name: 'Service',
      header: null,
      url: 'workshop',
      subMenu: [{
        header: 'Service',
        list: [{
          name: 'Service',
          url: 'workshop'
        }]
      }]
    }]
  }, {
    name: 'project',
    menu: [{
      name: 'Project',
      header: null,
      url: 'project',
      subMenu: []
    }, {
      name: 'Sales',
      header: 'Sales',
      url: null,
      subMenu: [{
        header: 'Sales',
        list: [{
          name: 'Survey',
          url: 'sale.survey'
        }, {
          name: 'Order',
          url: 'sale.order'
        }, {
          name: 'Sales',
          url: 'sales'
        }]
      }]
    }, {
      name: 'Customer',
      header: null,
      url: 'customer',
      subMenu: []
    }]
  }, {
    name: 'sales',
    menu: [{
      name: 'Sales',
      header: 'Sales',
      url: null,
      subMenu: [{
        header: 'Sales',
        list: [{
          name: 'Survey',
          url: 'sale.survey'
        }, {
          name: 'Order',
          url: 'sale.order'
        }, {
          name: 'Sales',
          url: 'sales'
        }]
      }, {
        header: 'Project',
        list: [{
          name: 'List',
          url: 'project'
        }]
      }, {
        header: 'Etc',
        list: [{
          name: 'Customer',
          url: 'customer'
        }, {
          name: 'Product Template',
          url: 'product.template'
        }, {
          name: 'Term & Condition',
          url: 'term.condition'
        }]
      }]
    }, {
      name: 'Customer',
      header: null,
      url: 'customer',
      subMenu: []
    }]
  }]
}

const mutations = {
  SET_ROW_TABLE (state, obj) {
    state.rowsTable = obj
  },
  SET_CURRENT_MENU (state, obj) {
    state.currentMenu = obj
  }
}

const actions = {
  getRowTable: ({ commit }) => {
    var data = [
      {
        row: 10
      },
      {
        row: 25
      },
      {
        row: 50
      },
      {
        row: 100
      }]
    commit('SET_ROW_TABLE', data)
  }
}

export default {
  state, mutations, actions
}
