<template>
  <div class="home">
    <MemoInboxList v-show="listView" @newMemo="onClickNewFromList" @editMemo="onClickEditFromList" ref="refreshList" @showMemo="onClickShowFormList"/>
    <MemoInboxForm v-show="formView" :memo="memo" @backClicked="onBackChild" @backParentID="backParentID" ref="setApproval"/>
    <MemoShow v-show="showView" :memo="memo" @backClicked="onBackChild" @backParentID="backParentShowID"/>
  </div>
</template>

<script>
// @ is an alias to /src
import MemoInboxList from '@/components/memo/MemoInboxList.vue'
import MemoInboxForm from '@/components/memo/MemoInboxForm.vue'
import MemoShow from '@/components/memo/MemoShow.vue'
import { getHeader, memoUrl } from '../config'
import { mapState } from 'vuex'
import Axios from 'axios'

export default {
  name: 'MemoInbox',
  components: {
    MemoInboxList,
    MemoInboxForm,
    MemoShow
  },
  data () {
    return {
      listView: true,
      formView: false,
      showView: false,
      memo: {
        id: null,
        memo_no: null,
        memo_date: null,
        from: '',
        to: null,
        path_id: null,
        path: null,
        path_array: [{
          path: null,
          position: null,
          class: null
        }],
        remaining_budget: '0',
        subject: null,
        supplier_no: null,
        employee_no: null,
        company_no: null,
        category_id: null,
        notes: null,
        subtotal: '0',
        discount: '0',
        tax: '0',
        total: '0',
        status: null,
        lock: null,
        position_id: null,
        alias_id: null,
        alias: null,
        account_no: null,
        account_name: null,
        account_bank_id: null,
        billing_no: null,
        approve_list: [{
          employee_no: null,
          name: null
        }],
        details: [{
          id: null,
          memo_no: null,
          company_no: null,
          memo_date: null,
          description: null,
          qty: '0',
          amount: '0',
          discount: '0',
          tax: '0',
          total: '0'
        }],
        attachments: [{
          id: null,
          memo_no: null,
          filename: null,
          mime_type: null,
          extension: null,
          url: null
        }],
        logs: [{
          id: null,
          memo_no: null,
          memo_type: null,
          log_date: null,
          log_notes: null,
          log_user: null,
          subtotal: null,
          discount: null,
          tax: null,
          total: null,
          path_id: null,
          path: null,
          from: null,
          to: null,
          user: null
        }],
        employee: {
          id: null,
          employee_no: null,
          bank_account_no: null,
          bank_account_name: null,
          bank_id: null
        },
        supplier: {
          id: null,
          supplier_no: null
        },
        payment_bank_id: null,
        payment_bank_account: null,
        payment_name: null,
        bank_approval: [{
          no: null,
          id: null,
          type: null,
          approval_date: null,
          approval_by: null,
          approval: null
        }],
        payments: [{
          id: null,
          memo_no: null,
          payment_amount: null,
          payment_date: null,
          type: null
        }],
        accounts: [{
          id: null,
          memo_no: null,
          type: null,
          method: null,
          employee_no: null,
          alias_id: null,
          account_no: null,
          account_name: null,
          account_bank_id: null,
          billing_no: null
        }]
      }
    }
  },
  computed: {
    ...mapState({
      authUser: state => state.users.authUser
    })
  },
  methods: {
    onClickShowFormList: function (value) {
      this.showView = true
      this.listView = false
      this.formView = false
      this.memo = value
    },
    async backParentShowID (id) {
      await Axios.get(memoUrl + '/inbox/' + id, { headers: getHeader() })
        .then(response => {
          this.showView = true
          this.listView = false
          this.formView = false
          this.memo = response.data.data
        })
        .catch(error => {
          console.log(error.response)
          this.$swal(
            'Error',
            error.response.data.message,
            'error'
          )
        })
    },
    async backParentID (id) {
      await Axios.get(memoUrl + '/inbox/' + id, { headers: getHeader() })
        .then(response => {
          this.listView = false
          this.formView = true
          this.showView = false
          this.memo = response.data.data
        })
        .catch(error => {
          console.log(error.response)
          this.$swal(
            'Error',
            error.response.data.message,
            'error'
          )
        })
    },
    onClickEditFromList: function (value) {
      this.listView = false
      this.formView = true
      this.showView = false
      // set memo value
      this.memo.id = value.id
      this.memo.memo_no = value.memo_no
      this.memo.memo_date = value.memo_date
      this.memo.from = value.from
      this.memo.to = value.to
      this.memo.path_id = value.path_id
      this.memo.path = value.path
      this.memo.path_array = value.path_array
      this.memo.remaining_budget = value.remaining_budget
      this.memo.subject = value.subject
      this.memo.supplier_no = value.supplier_no
      this.memo.employee_no = value.employee_no
      this.memo.company_no = value.company_no
      this.memo.category_id = value.category_id
      this.memo.notes = value.notes
      this.memo.subtotal = value.subtotal
      this.memo.discount = value.discount
      this.memo.tax = value.tax
      this.memo.total = value.total
      this.memo.status = value.status
      this.memo.lock = value.lock
      this.memo.position_id = value.position_id
      this.memo.alias_id = value.alias_id
      this.memo.alias = value.alias
      this.memo.account_no = value.account_no
      this.memo.account_name = value.account_name
      this.memo.account_bank_id = value.account_bank_id
      this.memo.billing_no = value.billing_no
      this.memo.approve_list = value.approve_list
      this.memo.details = value.details
      this.memo.attachments = value.attachments
      this.memo.logs = value.logs
      this.memo.employee = value.employee
      this.memo.supplier = value.supplier
      this.memo.from_profile = value.from_profile
      this.memo.payment_bank_id = value.payment_bank_id
      this.memo.payment_bank_account = value.payment_bank_account
      this.memo.payment_name = value.payment_name
      this.memo.bank_approval = value.bank_approval
      this.memo.payments = value.payments
      this.memo.accounts = value.accounts
    },
    onClickNewFromList: function () {
      // var date = new Date()
      // date = ('0' + (date.getDate())).slice(-2) + '/' + ('0' + (date.getMonth() + 1)).slice(-2) + '/' + date.getFullYear()
      this.listView = false
      this.formView = true
      this.showView = true
      this.memo = {
        id: null,
        memo_no: null,
        memo_date: null,
        from: null,
        to: null,
        path_id: null,
        path: null,
        path_array: [{
          path: null,
          position: null,
          class: null
        }],
        remaining_budget: '0',
        subject: null,
        supplier_no: null,
        employee_no: null,
        company_no: null,
        category_id: null,
        notes: null,
        subtotal: '0',
        discount: '0',
        tax: '0',
        total: '0',
        status: null,
        lock: null,
        alias_id: null,
        alias: null,
        account_no: null,
        account_name: null,
        account_bank_id: null,
        billing_no: null,
        approve_list: [{
          employee_no: null,
          name: null
        }],
        details: [{
          id: null,
          memo_no: null,
          company_no: null,
          memo_date: null,
          description: null,
          qty: '0',
          amount: '0',
          discount: '0',
          tax: '0',
          total: '0'
        }],
        attachments: [{
          id: null,
          memo_no: null,
          filename: null,
          mime_type: null,
          extension: null,
          url: null
        }],
        logs: [{
          id: null,
          memo_no: null,
          memo_type: null,
          log_date: null,
          log_notes: null,
          log_user: null,
          subtotal: null,
          discount: null,
          tax: null,
          total: null,
          path_id: null,
          path: null,
          from: null,
          to: null,
          user: null
        }],
        employee: {
          id: null,
          employee_no: null,
          bank_account_no: null,
          bank_account_name: null,
          bank_id: null
        },
        supplier: {
          id: null,
          supplier_no: null
        },
        bank_approval: [{
          no: null,
          id: null,
          type: null,
          approval_date: null,
          approval_by: null,
          approval: null
        }],
        payments: [{
          id: null,
          memo_no: null,
          payment_amount: null,
          payment_date: null,
          type: null
        }],
        accounts: [{
          id: null,
          memo_no: null,
          type: null,
          method: null,
          employee_no: null,
          alias_id: null,
          account_no: null,
          account_name: null,
          account_bank_id: null,
          billing_no: null
        }]
      }
    },
    async getFromParam (id) {
      await Axios.get(memoUrl + '/inbox/' + id, { headers: getHeader() })
        .then(response => {
          this.onClickEditFromList(response.data.data)
        })
        .catch(error => {
          console.log(error.response)
        })
    },
    onBackChild: function () {
      this.listView = true
      this.formView = false
      this.showView = false
      this.memo = {
        id: null,
        memo_no: null,
        memo_date: null,
        from: null,
        to: null,
        path_id: null,
        path: null,
        path_array: [{
          path: null,
          position: null,
          class: null
        }],
        remaining_budget: '0',
        subject: null,
        supplier_no: null,
        employee_no: null,
        company_no: null,
        category_id: null,
        notes: null,
        subtotal: '0',
        discount: '0',
        tax: '0',
        total: '0',
        status: null,
        lock: null,
        alias_id: null,
        alias: null,
        account_no: null,
        account_name: null,
        account_bank_id: null,
        billing_no: null,
        approve_list: [{
          employee_no: null,
          name: null
        }],
        details: [{
          id: null,
          memo_no: null,
          company_no: null,
          memo_date: null,
          description: null,
          qty: '0',
          amount: '0',
          discount: '0',
          tax: '0',
          total: '0'
        }],
        attachments: [{
          id: null,
          memo_no: null,
          filename: null,
          mime_type: null,
          extension: null,
          url: null
        }],
        logs: [{
          id: null,
          memo_no: null,
          memo_type: null,
          log_date: null,
          log_notes: null,
          log_user: null,
          subtotal: null,
          discount: null,
          tax: null,
          total: null,
          path_id: null,
          path: null,
          from: null,
          to: null,
          user: null
        }],
        employee: {
          id: null,
          employee_no: null,
          bank_account_no: null,
          bank_account_name: null,
          bank_id: null
        },
        supplier: {
          id: null,
          supplier_no: null
        },
        bank_approval: [{
          no: null,
          id: null,
          type: null,
          approval_date: null,
          approval_by: null,
          approval: null
        }],
        payments: [{
          id: null,
          memo_no: null,
          payment_amount: null,
          payment_date: null,
          type: null
        }],
        accounts: [{
          id: null,
          memo_no: null,
          type: null,
          method: null,
          employee_no: null,
          alias_id: null,
          account_no: null,
          account_name: null,
          account_bank_id: null,
          billing_no: null
        }]
      }
      this.$refs.refreshList.refreshList()
    },
    getMounted: function (company) {
    //   this.$store.dispatch('getMemoCategoryByCompany', { payload: this.authUser.company})
    //   this.$store.dispatch('getSupplierByCompany', { payload: this.authUser.company_no})
    //   this.$store.dispatch('getEmployeeByCompany', { payload: this.authUser.company_no})
    //   this.$store.dispatch('getCompanyList')
    }
  },
  mounted () {
    if (typeof this.$route.query.id !== 'undefined') {
      var id = this.$route.query.id
      this.getFromParam(id)
    }
    this.$store.dispatch('getCompanyList')
    this.$store.dispatch('getEmployeeList')
    this.$store.dispatch('getMemoCategoryList')
    this.$store.dispatch('getBankList')
    this.$store.dispatch('getRowTable')
    this.$store.dispatch('getEmployeePosition')
    this.$store.dispatch('getMemoInboxNotif')
    this.$store.dispatch('getSupplierList')
  }
}
</script>
