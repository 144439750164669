import axios from 'axios'
import { getHeader, memoUrl } from './../config'

const state = {
  memoCategoryList: [],
  memoInboxNotif: [],
  memoInboxTotal: 0
}

const mutations = {
  SET_MEMO_CATEGORY_LIST (state, obj) {
    state.memoCategoryList = obj
  },
  SET_MEMO_INBOX_NOTIF (state, obj) {
    state.memoInboxNotif = obj
  },
  SET_MEMO_INBOX_TOTAL (state, obj) {
    state.memoInboxTotal = obj
  }
}

const actions = {
  getMemoCategoryList: ({ commit }) => {
    console.log(memoUrl)
    axios.get(memoUrl + '/category', { headers: getHeader() })
      .then(response => {
        commit('SET_MEMO_CATEGORY_LIST', response.data.data)
      })
  },
  getMemoInboxNotif: ({ commit }) => {
    var url = memoUrl + '/inbox/notif'
    axios.get(url, { headers: getHeader() })
      .then(response => {
        commit('SET_MEMO_INBOX_NOTIF', response.data.data)
        commit('SET_MEMO_INBOX_TOTAL', response.data.total)
      })
      .catch(error => {
        console.log(error.response)
      })
  }
}

export default {
  state, mutations, actions
}
