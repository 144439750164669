<template>
    <div>
        <h5>Employee Form</h5>
        <div class="row">
            <div class="col-md-12">
                <div class="top-list-toolbar">
                    <h4 class="text-blue"><span @click="backParent" >Employee</span>
                    </h4>
                    <div>
                        <button type="button" @click="backParent" class="btn btn-sm btn-danger pull-left"><i class="fa fa-reply" aria-hidden="true"></i> Back</button>
                        <button type="button" @click="save" class="btn btn-sm btn-success pull-left"><i class="fa fa-check" aria-hidden="true"></i> Save</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="card card-list">
            <div class="card-header">
                <h5>
                    <span v-if="employee.id === null">New</span>
                </h5>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group row">
                          <label for="profile" class="col-sm-3 col-form-label text-left">&nbsp;</label>
                          <div class="col-sm-9">
                            <div id="profile-border" @click="chooseFiles">
                                <img :src="employee.profiles" id="profile" v-if="employee.picture === null" style="width: 180px; height: 180px; object-fit: cover;">
                                <img :src="employee.picture" id="profile" v-else  style="width: 180px; height: 180px; object-fit: cover;">
                                <input type="file" id="file" ref="file" @change="handleFileUpload()" hidden/>
                            </div>
                          </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group row">
                            <label for="employee_no" class="col-sm-3 col-form-label text-right">Employee No</label>
                            <div class="col-sm-9">
                                <input type="text" v-model="employee.employee_no" class="form-control" placeholder="[auto]">
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="name" class="col-sm-3 col-form-label text-right">Name</label>
                            <div class="col-sm-9">
                                <input type="text" v-model="employee.name" class="form-control">
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="company_no" class="col-sm-3 col-form-label text-right">Company</label>
                            <div class="col-sm-9">
                              <v-select :options="companySelect" :reduce="company => company.meta.code" label="company" :multiple="true" class="vselect-custom" v-model="employee.company"/>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="position_id" class="col-sm-3 col-form-label text-right">Position</label>
                            <div class="col-sm-9">
                              <v-select :options="getPositionSelect" :reduce="position => position.meta.code" label="position" :multiple="true" class="vselect-custom" v-model="employee.position"/>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="email" class="col-sm-3 col-form-label text-right">Email</label>
                            <div class="col-sm-9">
                                <input type="text" v-model="employee.email" class="form-control">
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="phone" class="col-sm-3 col-form-label text-right">Phone</label>
                            <div class="col-sm-9">
                                <input type="text" v-model="employee.phone" class="form-control">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group row">
                            <label for="address" class="col-sm-3 col-form-label text-right">Address</label>
                            <div class="col-sm-9">
                                <textarea v-model="employee.address" class="form-control" cols="5"/>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="npwp" class="col-sm-3 col-form-label text-right">NPWP</label>
                            <div class="col-sm-9">
                                <input type="text" v-model="employee.npwp" class="form-control">
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="ktp" class="col-sm-3 col-form-label text-right">KTP</label>
                            <div class="col-sm-9">
                                <input type="text" v-model="employee.ktp" class="form-control">
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="kk" class="col-sm-3 col-form-label text-right">KK</label>
                            <div class="col-sm-9">
                                <input type="text" v-model="employee.kk" class="form-control">
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="mother_name" class="col-sm-3 col-form-label text-right">Nama Ibu Kandung</label>
                            <div class="col-sm-9">
                                <input type="text" v-model="employee.mother_name" class="form-control">
                            </div>
                        </div>
                         <div class="form-group row">
                            <label for="birthday" class="col-sm-3 col-form-label text-right">Tgl. Lahir</label>
                            <div class="col-sm-9">
                                <input type="text" v-model="employee.birthday" class="form-control" v-mask="'##/##/####'" placeholder="DD/MM/YYYY">
                            </div>
                        </div>
                         <div class="form-group row">
                            <label for="birthplace" class="col-sm-3 col-form-label text-right">Tempat Lahir</label>
                            <div class="col-sm-9">
                                <input type="text" v-model="employee.birthplace" class="form-control">
                            </div>
                        </div>
                    </div>
                </div>
                <hr>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group row">
                            <label for="status" class="col-sm-3 col-form-label text-right">Status</label>
                            <div class="col-sm-9">
                                <select v-model="employee.status" class="form-control">
                                    <option value="active">Active</option>
                                    <option value="skorsing">Skorsing</option>
                                    <option value="move">Move</option>
                                    <option value="fired">Fired</option>
                                    <option value="retired">Retired</option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="job_start" class="col-sm-3 col-form-label text-right">Job Start</label>
                            <div class="col-sm-9">
                                <input type="text" v-model="employee.job_start" class="form-control" v-mask="'##/##/####'" placeholder="DD/MM/YYYY">
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="job_end" class="col-sm-3 col-form-label text-right">Job End</label>
                            <div class="col-sm-9">
                                <input type="text" v-model="employee.job_end" class="form-control" v-mask="'##/##/####'" placeholder="DD/MM/YYYY">
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="grade_id" class="col-sm-3 col-form-label text-right">Grade</label>
                            <div class="col-sm-9">
                                <select v-model="employee.grade_id" class="form-control">
                                    <option v-for="g in gradeList" :key="g.id" :value="g.id">
                                        [{{ g.id }}] {{ g.name }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="absen_id" class="col-sm-3 col-form-label text-right">Absen ID</label>
                            <div class="col-sm-9">
                                <input type="text" v-model="employee.absen_id" class="form-control">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group row">
                            <label for="bank_id" class="col-sm-3 col-form-label text-right">Bank</label>
                            <div class="col-sm-9">
                                <select v-model="employee.bank_id" class="form-control">
                                    <option v-for="b in bankList" :key="b.id" :value="b.id">{{ b.name }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="bank_account_name" class="col-sm-3 col-form-label text-right">Nama Sesuai Rekening Bank</label>
                            <div class="col-sm-9">
                                <input type="text" v-model="employee.bank_account_name" class="form-control">
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="bank_account_no" class="col-sm-3 col-form-label text-right">Nomor Rekening Bank</label>
                            <div class="col-sm-9">
                                <input type="text" v-model="employee.bank_account_no" class="form-control">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { getHeader, employeeUrl, userUrl } from '../../config'
import { mapState } from 'vuex'
export default {
  name: 'EmployeeForm',
  props: ['employee'],
  computed: {
    ...mapState({
      departmentList: state => state.employees.departmentList,
      positionList: state => state.employees.positionList,
      gradeList: state => state.employees.gradeList,
      companyList: state => state.companies.companyList,
      bankList: state => state.banks.bankList
    }),
    companySelect () {
      var loop = []
      for (let i = 0; i < this.companyList.length; i++) {
        var obj = {
          company: this.companyList[i].company_no + ' - ' + this.companyList[i].name,
          meta: {
            code: this.companyList[i].id
          }
        }
        loop.push(obj)
      }
      return loop
    },
    getPositionSelect: function () {
      var array = this.positionList.filter(d => {
        if (this.employee.company) {
          for (let a = 0; a < this.employee.company.length; a++) {
            if (d.company_id === this.employee.company[a]) {
              return d
            }
          }
        } else {
          return d
        }
      })
      var loop = []
      for (let i = 0; i < array.length; i++) {
        var obj = {
          position: array[i].id + ' - ' + array[i].name,
          meta: {
            code: array[i].id
          }
        }
        loop.push(obj)
      }
      return loop
    }
  },
  methods: {
    chooseFiles () {
      document.getElementById('file').click()
    },
    async handleFileUpload () {
      var company = this.companyList.find(d => d.company_no === this.employee.company_no)
      var empNo = company.alias + '/' + this.employee.employee_no
      this.file = this.$refs.file.files[0]
      const formData = new FormData()
      formData.append('file', this.file)
      formData.append('username', empNo)
      const newHeader = {
        'Content-Type': 'multipart/form-data'
      }
      const newObj = Object.assign(getHeader(), newHeader)
      await axios.post(userUrl + '/profile', formData, { headers: newObj })
        .then(response => {
          console.log(response.data.data)
          this.employee.profiles = response.data.data.url
          this.$toasted.show(response.data.message, {
            theme: 'toasted-primary',
            position: 'top-right',
            duration: 5000,
            icon: 'check',
            type: 'success'
          })
        })
        .catch(error => {
          console.log(error.response)
          this.$toasted.show(error.response.data.message, {
            theme: 'toasted-primary',
            position: 'top-right',
            duration: 5000,
            icon: 'check',
            type: 'error'
          })
        })
    },
    backParent: function () {
      this.$emit('backClicked')
    },
    async save () {
      var id = this.employee.id
      this.employee.job_start = this.employee.job_start === null ? null : this.employee.job_start.replaceAll('/', '-')
      this.employee.job_end = this.employee.job_end === null ? null : this.employee.job_end.replaceAll('/', '-')
      if (id === null) {
        axios.post(employeeUrl, this.employee, { headers: getHeader() })
          .then(response => {
            console.log(response)
            this.$toasted.show(response.data.message, {
              theme: 'toasted-primary',
              position: 'top-right',
              duration: 5000,
              icon: 'check',
              type: 'success'
            })
            this.backParent()
          })
          .catch(error => {
            console.log(error.response)
            this.$swal(
              'Error',
              error.response.data.message,
              'error'
            )
          })
      } else {
        var url = employeeUrl + '/' + id
        axios.put(url, this.employee, { headers: getHeader() })
          .then(response => {
            console.log(response)
            this.$toasted.show(response.data.message, {
              theme: 'toasted-primary',
              position: 'top-right',
              duration: 5000,
              icon: 'check',
              type: 'success'
            })
            this.backParent()
          })
          .catch(error => {
            console.log(error.response)
            this.$swal(
              'Error',
              error.response.data.message,
              'error'
            )
          })
      }
    }
  }
}
</script>

<style>
.vselect-custom{
    border-bottom: 1px solid #DBDBDB;
    padding-top: 0.375rem;
    padding-right: 0.75rem;
    padding-bottom: 0.375rem;
    padding-left: 0.75rem;
}
.vselect-custom .vs__dropdown-toggle {
    border: 0;
}
.vselect-custom .vs__selected {
    background: #41A29D !important;
    color: white;
    padding: 5px;
}
</style>
