<template>
    <div>
        <h5>Notification Message</h5>
        <div class="row">
            <div class="col-md-12">
                <div class="top-list-toolbar">
                    <h4 class="text-blue"><span @click="backParent" style="cursor: pointer;color: blue;">Notification</span> /
                        <span v-if="notif.id === null">New</span>
                        <span v-else>Edit</span>
                    </h4>
                    <div>
                        <button type="button" @click="backParent" class="btn btn-sm btn-danger pull-left"><i class="fa fa-reply" aria-hidden="true"></i> Back</button>
                        <button type="button" @click="save" class="btn btn-sm btn-success pull-left"><i class="fa fa-check" aria-hidden="true"></i> Save</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="card card-list">
            <div class="card-header">
                <h5 v-if="notif.id === null">New</h5>
                <h5 v-else>{{ notif.id }}</h5>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group row">
                            <label for="template_no" class="col-sm-3 col-form-label text-right">template_no</label>
                            <div class="col-sm-9">
                                <select class="form-control" v-model="notif.template_no">
                                    <option value="">---</option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group row">
                          <label for="number" class="col-sm-3 col-form-label text-right">Number</label>
                            <div class="col-sm-9">
                                <input type="text" class="form-control" v-model="notif.number"/>
                            </div>
                        </div>
                        <div class="form-group row">
                          <label for="message" class="col-sm-3 col-form-label text-right">Message</label>
                            <div class="col-sm-9">
                                <input type="text" class="form-control" v-model="notif.message"/>
                            </div>
                        </div>
                        <div class="form-group row">
                          <label for="status" class="col-sm-3 col-form-label text-right">Status</label>
                            <div class="col-sm-9">
                                <input type="text" class="form-control" v-model="notif.status"/>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="message" class="col-sm-3 col-form-label text-right">Message</label>
                            <div class="col-sm-9">
                                <textarea class="form-control" v-model="notif.message" rows="5" />
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="check" class="col-sm-3 col-form-label text-right">Check</label>
                            <div class="col-sm-9">
                                <input type="text" class="form-control" v-model="notif.check">
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="ref_no" class="col-sm-3 col-form-label text-right">Ref No.</label>
                            <div class="col-sm-9">
                                <input type="text" class="form-control" v-model="notif.ref_no">
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="ref_note" class="col-sm-3 col-form-label text-right">Ref Note</label>
                            <div class="col-sm-9">
                                <input type="text" class="form-control" v-model="notif.ref_note">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <pre>{{ template }}</pre> -->
    </div>
</template>

<script>
import axios from 'axios'
import { getHeader, notifUrl } from '../../config'
import { mapState } from 'vuex'
export default {
  name: 'NotificationForm',
  props: ['notif'],
  computed: {
    ...mapState({
      companyList: state => state.companies.companyList
    })
  },
  methods: {
    backParent: function () {
      this.$emit('backClicked')
    },
    async save () {
      var id = this.notif.id
      if (id === null) {
        axios.post(notifUrl, this.template, { headers: getHeader() })
          .then(response => {
            console.log(response)
            this.$swal(
              'Success',
              response.data.message,
              'success'
            )
            this.backParent()
          })
          .catch(error => {
            console.log(error.response)
            this.$swal(
              'Error',
              error.response.data.message,
              'error'
            )
          })
      } else {
        var url = notifUrl + '/' + id
        axios.put(url, this.notif, { headers: getHeader() })
          .then(response => {
            console.log(response)
            this.$swal(
              'Success',
              response.data.message,
              'success'
            )
            this.backParent()
          })
          .catch(error => {
            console.log(error.response)
            this.$swal(
              'Error',
              error.response.data.message,
              'error'
            )
          })
      }
    }
  }
}
</script>
