import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import Login from './views/Login.vue'
import Reset from './views/Reset.vue'
import User from './views/User.vue'
import Company from './views/Company.vue'
import Bank from './views/Bank.vue'
import EmployeeGrade from './views/EmployeeGrade.vue'
import EmployeeDepartment from './views/EmployeeDepartment.vue'
import EmployeePosition from './views/EmployeePosition.vue'
import Employee from './views/Employee.vue'
import Supplier from './views/Supplier.vue'
import MemoCategory from './views/MemoCategory.vue'
import MemoPath from './views/MemoPath.vue'
import Memo from './views/Memo.vue'
import MemoInbox from './views/MemoInbox.vue'
import Setting from './views/Setting.vue'
import IntegrationApi from './views/IntegrationApi.vue'
import Report from './views/Report.vue'
import NotificationTemplate from './views/NotificationTemplate.vue'
import Notification from './views/Notification.vue'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    { path: '/login', name: 'login', component: Login },
    { path: '/reset-login', name: 'reset.login', component: Reset, meta: { title: 'Reset Login', requiresAuth: true, menu: 'reset.login' } },
    { path: '/', name: 'home', component: Home, meta: { title: 'Dashboard', requiresAuth: true, menu: 'dashboard' } },
    { path: '/user', name: 'user', component: User, meta: { title: 'User', requiresAuth: true, menu: 'user' } },
    { path: '/company', name: 'company', component: Company, meta: { title: 'Company', requiresAuth: true, menu: 'master' } },
    { path: '/bank', name: 'bank', component: Bank, meta: { title: 'Bank', requiresAuth: true, menu: 'master' } },
    { path: '/employee/grade', name: 'employee.grade', component: EmployeeGrade, meta: { title: 'Employee Grade', requiresAuth: true, menu: 'employee' } },
    { path: '/employee/department', name: 'employee.department', component: EmployeeDepartment, meta: { title: 'Employee Department', requiresAuth: true, menu: 'employee' } },
    { path: '/employee/position', name: 'employee.position', component: EmployeePosition, meta: { title: 'Employee Position', requiresAuth: true, menu: 'employee' } },
    { path: '/employee', name: 'employee', component: Employee, meta: { title: 'Employee', requiresAuth: true, menu: 'employee' } },
    { path: '/supplier', name: 'supplier', component: Supplier, meta: { title: 'Supplier', requiresAuth: true, menu: 'supplier' } },
    { path: '/memo/category', name: 'memo.category', component: MemoCategory, meta: { title: 'MemoCategory', requiresAuth: true, menu: 'memo' } },
    { path: '/memo/path', name: 'memo.path', component: MemoPath, meta: { title: 'MemoPath', requiresAuth: true, menu: 'memo' } },
    { path: '/memo', name: 'memo', component: Memo, meta: { title: 'Memo', requiresAuth: true, menu: 'memo' } },
    { path: '/memo/inbox', name: 'memo.inbox', component: MemoInbox, meta: { title: 'Memo Inbox', requiresAuth: true, menu: 'memo' } },
    { path: '/setting', name: 'setting', component: Setting, meta: { title: 'Setting', requiresAuth: true, menu: 'setting' } },
    { path: '/integration-api', name: 'integration.api', component: IntegrationApi, meta: { title: 'Integration Api', requiresAuth: true, menu: 'integration-api' } },
    { path: '/report', name: 'report', component: Report, meta: { title: 'Report', requiresAuth: true, menu: 'report' } },
    { path: '/notification', name: 'notification', component: Notification, meta: { title: 'Notification', requiresAuth: true, menu: 'setting' } },
    { path: '/notification/template', name: 'notification.template', component: NotificationTemplate, meta: { title: 'Notif Template', requiresAuth: true, menu: 'setting' } }
  ]
})

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth) {
    const authUser = JSON.parse(window.localStorage.getItem('authUser'))
    if (authUser && authUser.token) {
      document.title = to.meta.title + ' | ' + authUser.organization
      var meta = to.meta.permission
      if (meta) {
        console.log(to.meta.permission)
        if (authUser.permissions[meta] === true) {
          next()
        } else {
          next({ name: 'login' })
        }
      } else {
        next()
      }
    } else {
      next({ name: 'login' })
    }
  }
  next()
})

export default router
