<template>
    <div>
        <h5>Bank Form</h5>
        <div class="row">
            <div class="col-md-12">
                <div class="top-list-toolbar">
                    <h4 class="text-blue"><span @click="backParent" style="cursor: pointer;color: blue;">Bank</span> /
                        <span v-if="bank.id === null">New</span>
                        <span v-else>Edit</span>
                    </h4>
                    <div>
                        <button type="button" @click="backParent" class="btn btn-sm btn-danger pull-left"><i class="fa fa-reply" aria-hidden="true"></i> Back</button>
                        <button type="button" @click="save" class="btn btn-sm btn-success pull-left"><i class="fa fa-check" aria-hidden="true"></i> Save</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="card card-list">
            <div class="card-header">
                <h5 v-if="bank.id === null">New</h5>
                <h5 v-else>{{ bank.id }}</h5>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group row">
                            <label for="id" class="col-sm-3 col-form-label text-left">ID</label>
                            <div class="col-sm-9">
                                <input type="text" v-model="bank.id" class="form-control">
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="name" class="col-sm-3 col-form-label text-left">Name</label>
                            <div class="col-sm-9">
                                <input type="text" v-model="bank.name" class="form-control">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { getHeader, bankUrl } from '../../config'
export default {
  name: 'BankForm',
  props: ['bank'],
  methods: {
    backParent: function () {
      this.$emit('backClicked')
    },
    async save () {
      var id = this.bank.id
      if (id === null) {
        console.log(bankUrl)
        axios.post(bankUrl, this.bank, { headers: getHeader() })
          .then(response => {
            console.log(response)
            this.$toasted.show(response.data.message, {
              theme: 'toasted-primary',
              position: 'top-right',
              duration: 5000,
              icon: 'check',
              type: 'success'
            })
            this.backParent()
          })
          .catch(error => {
            console.log(error.response)
            this.$swal(
              'Error',
              error.response.data.message,
              'error'
            )
          })
      } else {
        var url = bankUrl + '/' + id
        axios.put(url, this.bank, { headers: getHeader() })
          .then(response => {
            console.log(response)
            this.$toasted.show(response.data.message, {
              theme: 'toasted-primary',
              position: 'bottom-left',
              duration: 5000,
              icon: 'check',
              type: 'success'
            })
            this.backParent()
          })
          .catch(error => {
            console.log(error.response)
            this.$toasted.show(error.response.data.message, {
              theme: 'toasted-primary',
              position: 'bottom-left',
              duration: 5000,
              icon: 'check',
              type: 'error'
            })
          })
      }
    }
  }
}
</script>
