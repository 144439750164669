<template>
  <div class="home">
    <EmployeeGradeList v-show="listView" @newGrade="onClickNewFromList" @editGrade="onClickEditFromList" ref="refreshList"/>
    <EmployeeGradeForm v-show="formView" :grade="grade" @backClicked="onBackChild" @backParentID="backParentID"/>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios'
import EmployeeGradeList from '@/components/employee/EmployeeGradeList.vue'
import EmployeeGradeForm from '@/components/employee/EmployeeGradeForm.vue'
import { getHeader, employeeUrl } from '../config'

export default {
  name: 'EmployeeGrade',
  components: {
    EmployeeGradeList,
    EmployeeGradeForm
  },
  data () {
    return {
      listView: true,
      formView: false,
      grade: {
        id: null,
        name: null,
        company_no: null
      }
    }
  },
  methods: {
    backParentID: function (id) {
      axios.get(employeeUrl + '/' + id, { headers: getHeader() })
        .then(response => {
          this.listView = false
          this.formView = true
          this.grade = response.data.data
        })
        .catch(error => {
          console.log(error.response)
          this.$swal(
            'Error',
            error.response.data.message,
            'error'
          )
        })
    },
    onClickEditFromList: function (value) {
      this.listView = false
      this.formView = true
      this.grade = value
    },
    getEdit: function (value) {
      console.log(employeeUrl + '/grade/' + value)
      axios.get(employeeUrl + '/grade/' + value, { headers: getHeader() })
        .then(response => {
          console.log(response.data.data)
          this.listView = false
          this.formView = true
          this.grade = response.data.data
        })
        .catch(error => {
          console.log(error.response)
        })
    },
    onClickNewFromList: function () {
      this.listView = false
      this.formView = true
      this.grade = {
        id: null,
        name: null,
        company_no: null
      }
    },
    onBackChild: function () {
      this.listView = true
      this.formView = false
      this.grade = {
        id: null,
        name: null,
        company_no: null
      }
      this.$refs.refreshList.refreshList()
      // this.$router.replace({ path: 'sales', query: null })
    }
  },
  mounted () {
    // this.$store.dispatch('getCompanyList')
  }
}
</script>
