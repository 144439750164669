<template>
    <div class="row">
      <loading
        :active.sync="loader.isLoading"
        :can-cancel="loader.canCancel"
        :is-full-page="loader.fullPage"
        :color="loader.color"
        :height="loader.height"
        :width="loader.width"
        :background-color="loader.backgroundColor"
        :loader="loader.loader">
      </loading>
      <div class="col-md-12">
        <div class="table-responsive">
          <div class="form-inline ml-auto">
            <div class="button-group">
                <button type="button" class="btn btn-outline-soft-primary btn-sm dropdown-toggle" data-toggle="dropdown">
                  <i class="fa fa-filter" aria-hidden="true"></i>
                    Filter
                    <span class="caret"></span>
                </button>
                <ul class="dropdown-menu p-2" >
                  <span>Company:</span>
                  <div class="dropdown-divider"></div>
                  <li v-for="c in companyList" :key="c.id">
                    <a href="#" class="small" tabIndex="-1"><input type="checkbox" v-model="filterCompany" :value="c.company_no"/> {{ c.name }}</a>
                  </li>
                  <li><a href="#" class="small" tabIndex="-1"><input type="checkbox" v-model="filterPrepayment" value="true"/> Prepayment</a></li>
                  <div class="dropdown-divider"></div>
                  <li><a href="#" class="small" tabIndex="-1" @click="uncheckFilter" style="color: blue; cursor: pointer;">Uncheck All</a></li>
                </ul>
                <button type="button" class="btn btn-sm btn-soft-primary waves-light waves-effect" data-toggle="modal" data-target="#modalMemoCategorySetting" data-backdrop="static" data-keyboard="false" @click="newCategory">
                  <i class="fa fa-plus" aria-hidden="true"></i>
                </button>
                <button type="button" class="btn btn-sm  btn-soft-primary waves-light waves-effect" @click="refreshList">
                  <i class="fa fa-refresh" aria-hidden="true"></i>
                </button>
                rows:
                <select id="select-rows" v-model="pageSize" @change="setPageSizeOnChange">
                  <option v-for="r in rowsTable" :key="r.row" :value="r.row">{{ r.row }}</option>
                </select>
                <span class="mr-1">
                  Total rows: <b>{{ filterData.length }}</b>
                </span>
                <!-- <date-picker v-model="startDate" type="date" range placeholder="Select date range" @change="refreshList"></date-picker> -->
            </div>
            <div class="form-group mx-sm-3 mb-2">
                <input type="search" placeholder="search..." class="form-control mr-1 input-search" v-model="searchQuery">
            </div>
            <span class="mr-1">{{ currentPage }} / {{ pageLength }}</span>
            <nav aria-label="Page navigation example" class="pull-right">
                <ul class="pagination">
                    <li class="page-item">
                        <a class="btn btn-default btn-sm" href="javascript:void(0)" @click="prevPage">
                            <i class="fa fa-angle-left" aria-hidden="true"></i>
                        </a>
                    </li>
                    <li class="page-item">
                        <a class="btn btn-default btn-sm" href="javascript:void(0)" @click="nextPage">
                            <i class="fa fa-angle-right" aria-hidden="true"></i>
                        </a>
                    </li>
                </ul>
            </nav>
          </div>
          <table class="table table-item mb-0 table-sm table-striped table-hover">
              <thead>
                  <tr>
                      <th>
                        <label class="form-checkbox">
                          <input type="checkbox" v-model="selectAll" @click="select">
                          <i class="form-icon"></i>
                        </label>
                      </th>
                      <th  v-for="t in title" v-bind:key="t.prop" @click="sortTable(t.prop)">
                          <span v-if="t.name === 'Payment'" class="text-right">
                          {{ t.name }}
                          <div class="float-right fa" v-if="t.prop == sortColumn" v-bind:class="ascending ? 'fa-arrow-up' : 'fa-arrow-down'"></div>
                          </span>

                          <span v-else>
                            {{ t.name }}
                            <div class="float-right fa" v-if="t.prop == sortColumn" v-bind:class="ascending ? 'fa-arrow-up' : 'fa-arrow-down'"></div>
                          </span>
                      </th>
                      <th>#</th>
                  </tr>
              </thead>
              <tbody>
                <tr v-if="filterData.length < 1">
                  <td colspan="11" style="text-align: center;"><i>No Data</i></td>
                </tr>
                <tr v-for="u in filterData" v-bind:key="u.id" style="cursor: pointer">
                  <td>
                    <label class="form-checkbox">
                      <input type="checkbox" :value="u.id" v-model="selected">
                      <i class="form-icon"></i>
                    </label>
                  </td>
                  <td>{{ u.name }}</td>
                  <td>[{{ u.company_no }}] {{ u.company }}</td>
                  <td>{{ u.type }}</td>
                  <td>
                    <span v-if="u.prepayment"><i class="fa fa-check" style="color: green;"></i></span>
                  </td>
                  <td> <button type="button" class="btn btn-sm btn-primary" data-toggle="modal" data-target="#modalMemoCategorySetting" data-backdrop="static" data-keyboard="false" @click="editCategory(u.id)">Edit</button></td>
                </tr>
              </tbody>
          </table>
        </div>
      </div>
      <!-- <pre>{{ filterPrepayment }}</pre> -->
      <!-- <pre>{{ dataRaw }}</pre> -->
      <SettingMemoCategoryModal :category="category" @processCategory="save"/>
    </div>
</template>

<script>
import axios from 'axios'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import { memoUrl, getHeader } from '../../config'
import { mapState } from 'vuex'
import SettingMemoCategoryModal from '@/components/setting/SettingMemoCategoryModal.vue'

export default {
  name: 'SettingMemoCategoryList',
  components: {
    Loading,
    SettingMemoCategoryModal
  },
  data () {
    var date = new Date()
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0)
    const date1 = new Date(firstDay.getFullYear(), firstDay.getMonth(), firstDay.getDate())
    const date2 = new Date(lastDay.getFullYear(), lastDay.getMonth(), lastDay.getDate())
    return {
      category: {
        id: null,
        name: null,
        company_no: null,
        type: null
      },
      filterPrepayment: false,
      filterCompany: [],
      startDate: [date1, date2],
      loader: {
        isLoading: false,
        fullPage: true,
        color: '#007bff',
        canCancel: true,
        height: 128,
        width: 128,
        backgroundColor: '#ffffff',
        loader: 'spinner'
      },
      dataRaw: [],
      data: {},
      searchQuery: '',
      ascending: false,
      sortClick: false,
      sortColumn: '',
      pageSize: 10,
      currentPage: 1,
      checkFilter: [],
      title: [{
        name: 'Name',
        prop: 'name'
      }, {
        name: 'Company',
        prop: 'company_no'
      }, {
        name: 'Type',
        prop: 'type'
      }, {
        name: 'Prepayment',
        prop: 'prepayment'
      }],
      selectAll: false,
      selected: []
    }
  },
  mounted () {
    this.$store.dispatch('getCompanyList')
    window.scrollTo(0, 0)
    this.refreshList()
  },
  computed: {
    ...mapState({
      rowsTable: state => state.data.rowsTable,
      companyList: state => state.companies.companyList
    }),
    pageLength: function () {
      var length = this.dataRaw.length / this.pageSize
      return Math.ceil(length)
    },
    filterData: function () {
      if (this.searchQuery.length > 0) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.currentPage = 1
      }
      var col = this.sortColumn
      var ascending = this.ascending
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.dataRaw.sort(function (a, b) {
        if (a[col] > b[col]) {
          return ascending ? 1 : -1
        } else if (a[col] < b[col]) {
          return ascending ? -1 : 1
        }
        return 0
      }).filter(d => {
        for (const name in d) {
          if (d[name] !== null) {
            if (d[name].toString().toLowerCase().indexOf(this.searchQuery.toLowerCase()) > -1) {
              return d
            }
          }
        }
      }).filter(d => {
        if (this.filterCompany.length > 0) {
          for (let i = 0; i < this.filterCompany.length; i++) {
            if (d.company_no === this.filterCompany[i]) {
              return d
            }
          }
        } else {
          return d
        }
      }).filter(d => {
        if (this.filterPrepayment === true) {
          if (d.prepayment === true) {
            console.log('prepayment: ')
            console.log(d)
            return d
          }
        } else {
          return d
        }
      }).filter((row, index) => {
        const start = (this.currentPage - 1) * this.pageSize
        const end = this.currentPage * this.pageSize
        if (index >= start && index < end) return true
      })
    }
  },
  methods: {
    uncheckFilter () {
      this.filterCompany = []
    },
    newCategory () {
      this.category = {
        id: null,
        name: null
      }
    },
    editCategory (id) {
      this.category = this.dataRaw.find(d => d.id === id)
    },
    async save (value) {
      console.log(memoUrl + '/category')
      await axios.post(memoUrl + '/category', value, { headers: getHeader() })
        .then(response => {
          this.$toasted.show(response.data.message, {
            theme: 'toasted-primary',
            position: 'bottom-left',
            duration: 5000,
            icon: 'check',
            type: 'success'
          })
          this.refreshList()
        })
        .catch(error => {
          console.log(error.response)
          this.$toasted.show(error.response.data.message, {
            theme: 'toasted-primary',
            position: 'bottom-left',
            duration: 5000,
            icon: 'check',
            type: 'error'
          })
        })
    },
    setPageSizeOnChange: function () {
      this.currentPage = 1
    },
    sortTable: function sortTable (col) {
      this.currentPage = 1
      if (this.sortColumn === col) {
        this.ascending = !this.ascending
      } else {
        this.ascending = true
        this.sortColumn = col
      }
    },
    nextPage: function () {
      if ((this.currentPage * this.pageSize) < this.dataRaw.length) this.currentPage++
    },
    prevPage: function () {
      if (this.currentPage > 1) this.currentPage--
    },
    select () {
      this.selected = []
      this.currentPage = 1
      if (!this.selectAll) {
        for (const i in this.filterData) {
          this.selected.push(this.filterData[i].id)
        }
      }
    },
    editList (id) {
      axios.get(memoUrl + '/category/' + id, { headers: getHeader() })
        .then(response => {
          this.$emit('editCategory', response.data.data)
        })
        .catch(error => {
          console.log(error.response)
          this.$swal(
            'Error!!!',
            error.response.data.message,
            'error'
          )
        })
    },
    addList () {
      this.$emit('newCategory', true)
    },
    refreshList () {
      this.$Progress.start()
      this.loader.isLoading = true
      var url = memoUrl + '/category'
      console.log(url)
      axios.get(url, { headers: getHeader() })
        .then(response => {
          this.dataRaw = response.data.data
          this.$Progress.finish()
          this.loader.isLoading = false
          this.currentPage = 1
          this.searchQuery = ''
        })
        .catch(error => {
          console.log(error.response)
          this.$swal(
            'Error',
            error.response.data.message,
            'error'
          )
        })
    }
  }
}
</script>
