<template>
  <div class="home">
    <MemoPathList v-show="listView" @newPath="onClickNewFromList" @editPath="onClickEditFromList" ref="refreshList"/>
    <MemoPathForm v-show="formView" :path="path" @backClicked="onBackChild" @backParentID="backParentID"/>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios'
import MemoPathList from '@/components/memo/MemoPathList.vue'
import MemoPathForm from '@/components/memo/MemoPathForm.vue'
import { getHeader, memoUrl } from '../config'
import { mapState } from 'vuex'

export default {
  name: 'MemoPath',
  components: {
    MemoPathList,
    MemoPathForm
  },
  data () {
    return {
      listView: true,
      formView: false,
      path: {
        id: null,
        path: null,
        path_array: [],
        category_id: null,
        create_user: null,
        finish_user: '',
        company_no: null,
        budget: '0',
        begin: null,
        end: null,
        is_budget: false,
        level: null,
        budgets: [{
          id: null,
          path_id: null,
          amount: 0,
          begin: null,
          end: null,
          notes: null,
          company_no: null,
          type: null
        }]
      }
    }
  },
  computed: {
    ...mapState({
      authUser: state => state.users.authUser
    })
  },
  methods: {
    backParentID: function (id) {
      axios.get(memoUrl + '/path/' + id, { headers: getHeader() })
        .then(response => {
          this.listView = false
          this.formView = true
          this.path = response.data.data
        })
        .catch(error => {
          console.log(error.response)
          this.$swal(
            'Error',
            error.response.data.message,
            'error'
          )
        })
    },
    onClickEditFromList: function (value) {
      this.$store.dispatch('getMemoCategoryList')
      this.$store.dispatch('getEmployeePosition')
      this.listView = false
      this.formView = true
      this.path = value
    },
    getEdit: function (value) {
      console.log(memoUrl + '/path/' + value)
      axios.get(memoUrl + '/path/' + value, { headers: getHeader() })
        .then(response => {
          console.log(response.data.data)
          this.listView = false
          this.formView = true
          this.path = response.data.data
        })
        .catch(error => {
          console.log(error.response)
        })
    },
    onClickNewFromList: function () {
      this.$store.dispatch('getMemoCategoryList')
      this.$store.dispatch('getEmployeePosition')
      this.listView = false
      this.formView = true
      this.path = {
        id: null,
        path: null,
        path_array: [],
        category_id: null,
        create_user: null,
        finish_user: '',
        company_no: this.authUser.company_no,
        budget: '0',
        begin: null,
        end: null,
        is_budget: false,
        level: null,
        budgets: [{
          id: null,
          path_id: null,
          amount: 0,
          begin: null,
          end: null,
          notes: null,
          company_no: null,
          type: 'regular'
        }]
      }
    },
    onBackChild: function () {
      this.listView = true
      this.formView = false
      this.path = {
        id: null,
        path: null,
        path_array: [],
        category_id: null,
        create_user: null,
        finish_user: '',
        company_no: null,
        budget: '0',
        begin: null,
        end: null,
        is_budget: false,
        level: null,
        budgets: [{
          id: null,
          path_id: null,
          amount: 0,
          begin: null,
          end: null,
          notes: null,
          company_no: null
        }]
      }
      this.$refs.refreshList.refreshList()
      // this.$router.replace({ path: 'sales', query: null })
    }
  },
  mounted () {
    this.$store.dispatch('getCompanyList')
    this.$store.dispatch('getRowTable')
  }
}
</script>
