<template>
    <div>
        <h5>Notification Template</h5>
        <div class="row">
            <div class="col-md-12">
                <div class="top-list-toolbar">
                    <h4 class="text-blue"><span @click="backParent" style="cursor: pointer;color: blue;">Notification Template</span> /
                        <span v-if="template.id === null">New</span>
                        <span v-else>Edit</span>
                    </h4>
                    <div>
                        <button type="button" @click="backParent" class="btn btn-sm btn-danger pull-left"><i class="fa fa-reply" aria-hidden="true"></i> Back</button>
                        <button type="button" @click="save" class="btn btn-sm btn-success pull-left"><i class="fa fa-check" aria-hidden="true"></i> Save</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="card card-list">
            <div class="card-header">
                <h5 v-if="template.id === null">New</h5>
                <h5 v-else>{{ template.template_no }}</h5>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group row">
                            <label for="id" class="col-sm-3 col-form-label text-right">Name</label>
                            <div class="col-sm-9">
                                <input type="text" v-model="template.name" class="form-control">
                            </div>
                        </div>
                        <div class="form-group row">
                          <label for="company_no" class="col-sm-3 col-form-label text-right">Url</label>
                            <div class="col-sm-9">
                                <input type="text" class="form-control" v-model="template.url"/>
                            </div>
                        </div>
                        <div class="form-group row">
                          <label for="api_key" class="col-sm-3 col-form-label text-right">API Key</label>
                            <div class="col-sm-9">
                                <input type="text" class="form-control" v-model="template.api_key"/>
                            </div>
                        </div>
                        <div class="form-group row">
                          <label for="type" class="col-sm-3 col-form-label text-right">Type</label>
                            <div class="col-sm-9">
                                <select v-model="template.type" class="form-control">
                                    <option value="memo-create">Memo Create</option>
                                    <option value="memo-approve">Memo approve</option>
                                    <option value="memo-revise">Memo Revise</option>
                                    <option value="memo-reject">Memo Reject</option>
                                    <option value="memo-finish">Memo finish</option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="message" class="col-sm-3 col-form-label text-right">Message</label>
                            <div class="col-sm-9">
                                <textarea class="form-control" v-model="template.message" rows="5" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <pre>{{ template }}</pre> -->
    </div>
</template>

<script>
import axios from 'axios'
import { getHeader, notifUrl } from '../../config'
import { mapState } from 'vuex'
export default {
  name: 'NotificatinTemplateForm',
  props: ['template'],
  computed: {
    ...mapState({
      companyList: state => state.companies.companyList
    })
  },
  methods: {
    backParent: function () {
      this.$emit('backClicked')
    },
    async save () {
      var id = this.template.id
      if (id === null) {
        console.log(notifUrl + '/template')
        axios.post(notifUrl + '/template', this.template, { headers: getHeader() })
          .then(response => {
            console.log(response)
            this.$swal(
              'Success',
              response.data.message,
              'success'
            )
            this.backParent()
          })
          .catch(error => {
            console.log(error.response)
            this.$swal(
              'Error',
              error.response.data.message,
              'error'
            )
          })
      } else {
        var url = notifUrl + '/template/' + id
        axios.put(url, this.template, { headers: getHeader() })
          .then(response => {
            console.log(response)
            this.$swal(
              'Success',
              response.data.message,
              'success'
            )
            this.backParent()
          })
          .catch(error => {
            console.log(error.response)
            this.$swal(
              'Error',
              error.response.data.message,
              'error'
            )
          })
      }
    }
  }
}
</script>
