<template>
    <div class="row">
      <loading
        :active.sync="loader.isLoading"
        :can-cancel="loader.canCancel"
        :is-full-page="loader.fullPage"
        :color="loader.color"
        :height="loader.height"
        :width="loader.width"
        :background-color="loader.backgroundColor"
        :loader="loader.loader">
      </loading>
      <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <h5>Memo</h5>
            </div>
            <div class="card-body">
              <ul class="nav nav-tabs nav-bordered">
                <li class="nav-item"><a href="#memo" data-toggle="tab" aria-expanded="false" class="nav-link active">Memo</a></li>
                <li class="nav-item"><a href="#inbox" data-toggle="tab" aria-expanded="false" class="nav-link">Inbox <span class="badge badge-success">{{ memoInboxTotal }}</span></a></li>
                <li class="nav-item" v-if="authUser.permissions.memo_bank_approval"><a href="#create-bank" data-toggle="tab" aria-expanded="false" class="nav-link">Create Bank</a></li>
                <li class="nav-item" v-if="authUser.permissions.memo_bank_approval"><a href="#approve-bank" data-toggle="tab" aria-expanded="false" class="nav-link">Approve Bank</a></li>
                <li class="nav-item"><a href="#report" data-toggle="tab" aria-expanded="false" class="nav-link">Accounting</a></li>
                <li class="nav-item" v-if="authUser.permissions.memo_all_list"><a href="#all" data-toggle="tab" aria-expanded="false" class="nav-link">All</a></li>
                <li class="nav-item" v-if="authUser.permissions.memo_all_list"><a href="#history" data-toggle="tab" aria-expanded="false" class="nav-link">History</a></li>
                <li class="nav-item"><a href="#budget-prepayment" data-toggle="tab" aria-expanded="false" class="nav-link">Budget Prepayment</a></li>
              </ul>
              <div class="tab-content">
                <div class="tab-pane active" id="memo">
                  <div class="table-responsive">
                    <div class="form-inline ml-auto">
                      <div class="button-group">
                          <button type="button" class="btn btn-outline-soft-primary btn-sm dropdown-toggle" data-toggle="dropdown">
                            <i class="fa fa-filter" aria-hidden="true"></i>
                              Filter
                              <span class="caret"></span>
                          </button>
                          <ul class="dropdown-menu p-2" >
                            <span>Status:</span>
                            <div class="dropdown-divider"></div>
                            <li><a href="#" class="small" tabIndex="-1"><input type="checkbox" v-model="filterStatus" value="on progress"/> On Progress</a></li>
                            <li><a href="#" class="small" tabIndex="-1"><input type="checkbox" v-model="filterStatus" value="approve"/> Approve</a></li>
                            <li><a href="#" class="small" tabIndex="-1"><input type="checkbox" v-model="filterStatus" value="finish"/> Finish</a></li>
                            <li><a href="#" class="small" tabIndex="-1"><input type="checkbox" v-model="filterStatus" value="revise"/> Revise</a></li>
                            <li><a href="#" class="small" tabIndex="-1"><input type="checkbox" v-model="filterStatus" value="reject"/> Reject</a></li>
                            <div class="dropdown-divider"></div>
                            <span>Company</span>
                            <li v-for="c in companyList" :key="c.id">
                              <a href="#" class="small" tabIndex="-1"><input type="checkbox" v-model="filterCompany" :value="c.company_no"/> {{ c.name }}</a>
                            </li>
                          </ul>
                          <button type="button" class="btn btn-sm btn-soft-primary" @click="addList('regular')" data-placement="bottom" title="New Memo">
                            <i class="fa fa-plus" aria-hidden="true"></i>
                          </button>
                          <button type="button" class="btn btn-sm btn-soft-primary" @click="addList('prepayment')" data-placement="bottom" title="New Memo">
                            <i class="fa fa-plus" aria-hidden="true"></i> Prepayment
                          </button>
                          <button type="button" class="btn btn-sm  btn-soft-primary waves-light waves-effect" @click="refreshList" data-placement="bottom" title="Refresh">
                            <i class="fa fa-refresh" aria-hidden="true"></i>
                          </button>
                          rows:
                          <select id="select-rows" v-model="pageSize" @change="setPageSizeOnChange">
                            <option v-for="r in rowsTable" :key="r.row" :value="r.row">{{ r.row }}</option>
                          </select>
                          <span class="mr-1">
                            Total rows: <b>{{ filterData.length }}</b>
                          </span>
                          <date-picker v-model="startDate" type="date" range placeholder="Select date range" @change="refreshList"></date-picker>
                      </div>
                      <div class="form-group mx-sm-3 mb-2">
                        <input type="search" placeholder="search..." class="form-control mr-1 input-search" v-model="searchQuery">
                      </div>
                      <span class="mr-1">{{ currentPage }} / {{ pageLength }}</span>
                      <nav aria-label="Page navigation example" class="pull-right">
                          <ul class="pagination">
                              <li class="page-item">
                                  <a class="btn btn-default btn-sm" href="javascript:void(0)" @click="prevPage">
                                      <i class="fa fa-angle-left" aria-hidden="true"></i>
                                  </a>
                              </li>
                              <li class="page-item">
                                  <a class="btn btn-default btn-sm" href="javascript:void(0)" @click="nextPage">
                                      <i class="fa fa-angle-right" aria-hidden="true"></i>
                                  </a>
                              </li>
                          </ul>
                      </nav>
                    </div>
                    <table class="table table-item mb-0 table-sm table-striped table-hover">
                        <thead>
                            <tr>
                                <th>
                                  <label class="form-checkbox">
                                    <input type="checkbox" v-model="selectAll" @click="select">
                                    <i class="form-icon"></i>
                                  </label>
                                </th>
                                <th  v-for="t in title" v-bind:key="t.prop" @click="sortTable(t.prop)">
                                    <span v-if="t.name === 'Payment'" class="text-right">
                                    {{ t.name }}
                                    <div class="float-right fa" v-if="t.prop == sortColumn" v-bind:class="ascending ? 'fa-arrow-up' : 'fa-arrow-down'"></div>
                                    </span>

                                    <span v-else>
                                      {{ t.name }}
                                      <div class="float-right fa" v-if="t.prop == sortColumn" v-bind:class="ascending ? 'fa-arrow-up' : 'fa-arrow-down'"></div>
                                    </span>
                                </th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                          <tr v-if="filterData.length < 1">
                            <td colspan="13" style="text-align: center;"><i>No Data</i></td>
                          </tr>
                          <tr v-for="u in filterData" v-bind:key="u.id" style="cursor: pointer">
                            <td>
                              <label class="form-checkbox">
                                <input type="checkbox" :value="u.id" v-model="selected">
                                <i class="form-icon"></i>
                              </label>
                            </td>
                            <td>
                              <span class="badge badge-success" v-if="u.status === 'on progress'">{{ u.status }}</span>
                              <span class="badge badge-warning" v-else-if="u.status === 'revise'">{{ u.status }}</span>
                              <span class="badge badge-danger" v-else-if="u.status === 'reject'">{{ u.status }}</span>
                              <span class="badge badge-primary" v-else>{{ u.status }}</span>
                            </td>
                            <td>{{ u.type }} {{ u.prepayment_finish }}</td>
                            <td>{{ u.memo_no }}</td>
                            <td>{{ u.memo_date | dateFormat('DD/MM/YYYY') }}</td>
                            <td>{{ u.category }}</td>
                            <td @click="showList(u.id)">[{{ u.from }}] {{ u.user_from }}</td>
                            <td>
                              <span v-if="u.to !== null">
                                <b>[{{ u.to }}] {{ u.user_to }}</b>
                              </span>
                            </td>
                            <td>{{ u.subject }}</td>
                            <td>{{ u.total | currency }}</td>
                            <td>
                              <span v-if="u.supplier_no !== null">{{ u.supplier }}</span>
                              <span v-else>{{ u.employee }}</span>
                            </td>
                            <td><b>[{{ u.company_alias }}]</b> {{ u.company }}</td>
                            <td>
                              <span class="badge badge-danger" v-if="u.duration >= 72">{{ u.duration }} hours</span>
                              <span class="badge badge-success" v-else>{{ u.duration }}</span>
                            </td>
                            <td>
                              <button type="button" class="btn btn-sm btn-soft-warning" @click="showList(u.id)">Show</button>
                              <button type="button" class="btn btn-sm btn-soft-warning" @click="editList(u.id, 'regular')" v-if="u.lock === false">Revise</button>
                              <button type="button" class="btn btn-sm btn-soft-warning" @click="editList(u.id, 'prepayment-final')" v-if="u.prepayment_finish === true && u.status === 'finish'">Report</button>
                            </td>
                          </tr>
                        </tbody>
                    </table>
                  </div>
                </div>
                <div class="tab-pane" id="inbox">
                  <MemoInboxList @editMemoInbox="onClickEditFromInboxList" ref="refreshInboxList"/>
                </div>
                <div class="tab-pane" id="report">
                  <MemoReport @showMemo="showMemoFromReport"/>
                </div>
                <div class="tab-pane" id="create-bank">
                  <MemoApprove @showMemo="showMemoFromReport" :type="'create'" ref="refreshCreateApproveList"/>
                </div>
                <div class="tab-pane" id="approve-bank">
                  <MemoApprove @showMemo="showMemoFromReport" :type="'approve'" ref="refreshApproveList"/>
                </div>
                <div class="tab-pane" id="finish">
                  <h5>Finish</h5>
                </div>
                <div class="tab-pane" id="all">
                  <MemoAll @showMemo="showMemoFromReport"/>
                </div>
                <div class="tab-pane" id="history">
                  <MemoHistoryList @showMemo="showMemoFromReport"/>
                </div>
                <div class="tab-pane" id="budget-prepayment">
                  <MemoBudgetPrepaymentList />
                </div>
              </div>
            </div>
          </div>
      </div>
      <!-- <pre>{{ memo }}</pre> -->
      <!-- <pre>{{ dataRaw }}</pre> -->
      <!-- <pre>{{ filterCompany }}</pre> -->
    </div>
</template>

<script>
import axios from 'axios'
import Loading from 'vue-loading-overlay'
import DatePicker from 'vue2-datepicker'
import 'vue-loading-overlay/dist/vue-loading.css'
import { memoUrl, getHeader, memo2Url } from '../../config'
import { mapState } from 'vuex'
import MemoReport from '@/components/memo/MemoReport.vue'
import MemoApprove from '@/components/memo/MemoApprove.vue'
import MemoAll from '@/components/memo/MemoAll.vue'
import MemoInboxList from '@/components/memo/MemoInboxList2.vue'
import MemoHistoryList from '@/components/memo/MemoHistoryList.vue'
import MemoBudgetPrepaymentList from '@/components/memo/MemoBudgetPrepaymentList.vue'

export default {
  name: 'MemoList',
  components: {
    Loading,
    DatePicker,
    MemoReport,
    MemoApprove,
    MemoAll,
    MemoInboxList,
    MemoHistoryList,
    MemoBudgetPrepaymentList
  },
  data () {
    var date = new Date()
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0)
    const date1 = new Date(firstDay.getFullYear(), firstDay.getMonth(), firstDay.getDate())
    const date2 = new Date(lastDay.getFullYear(), lastDay.getMonth(), lastDay.getDate())
    return {
      filterCompany: [],
      filterStatus: [],
      startDate: [date1, date2],
      loader: {
        isLoading: false,
        fullPage: true,
        color: '#007bff',
        canCancel: true,
        height: 128,
        width: 128,
        backgroundColor: '#ffffff',
        loader: 'spinner'
      },
      dataRaw: [],
      data: {},
      searchQuery: '',
      ascending: false,
      sortClick: false,
      sortColumn: '',
      pageSize: 10,
      currentPage: 1,
      checkFilter: [],
      title: [{
        name: 'Status',
        prop: 'status'
      }, {
        name: 'Type',
        prop: 'type'
      }, {
        name: 'Memo No.',
        prop: 'memo_no'
      }, {
        name: 'Date',
        prop: 'memo_date'
      }, {
        name: 'Category',
        prop: 'category_id'
      }, {
        name: 'From',
        prop: 'from'
      }, {
        name: 'To',
        prop: 'to'
      }, {
        name: 'Subject',
        prop: 'subject'
      }, {
        name: 'Total',
        prop: 'total'
      }, {
        name: 'Payment To:',
        prop: 'supplier_no'
      }, {
        name: 'Company',
        prop: 'company_no'
      }, {
        name: 'Duration',
        prop: 'duration'
      }],
      selectAll: false,
      selected: []
    }
  },
  mounted () {
    window.scrollTo(0, 0)
    this.refreshList()
  },
  computed: {
    ...mapState({
      companyList: state => state.companies.companyList,
      rowsTable: state => state.data.rowsTable,
      authUser: state => state.users.authUser,
      memoInboxTotal: state => state.memos.memoInboxTotal
    }),
    pageLength: function () {
      var length = this.dataRaw.length / this.pageSize
      return Math.ceil(length)
    },
    filterData: function () {
      if (this.searchQuery.length > 0) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.currentPage = 1
      }
      var col = this.sortColumn
      var ascending = this.ascending
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.dataRaw.sort(function (a, b) {
        if (a[col] > b[col]) {
          return ascending ? 1 : -1
        } else if (a[col] < b[col]) {
          return ascending ? -1 : 1
        }
        return 0
      }).filter(d => {
        for (const name in d) {
          if (d[name] !== null) {
            if (d[name].toString().toLowerCase().indexOf(this.searchQuery.toLowerCase()) > -1) {
              return d
            }
          }
        }
      }).filter(d => {
        if (this.filterStatus.length > 0) {
          for (let i = 0; i < this.filterStatus.length; i++) {
            if (d.status === this.filterStatus[i]) {
              return d
            }
          }
        } else {
          return d
        }
      }).filter(d => {
        if (this.filterCompany.length > 0) {
          for (let a = 0; a < this.filterCompany.length; a++) {
            if (d.company_no === this.filterCompany[a]) {
              return d
            }
          }
        } else {
          return d
        }
      }).filter((row, index) => {
        const start = (this.currentPage - 1) * this.pageSize
        const end = this.currentPage * this.pageSize
        if (index >= start && index < end) return true
      })
    }
  },
  methods: {
    onClickEditFromInboxList: function (value) {
      this.$emit('editMemoInbox', value)
    },
    showMemoFromReport: function (value) {
      this.$emit('showMemo', value)
    },
    print (id) {
      const url = memo2Url + '/pdf/' + id
      window.open(url, '_blank')
    },
    setPageSizeOnChange: function () {
      this.currentPage = 1
    },
    sortTable: function sortTable (col) {
      this.currentPage = 1
      if (this.sortColumn === col) {
        this.ascending = !this.ascending
      } else {
        this.ascending = true
        this.sortColumn = col
      }
    },
    nextPage: function () {
      if ((this.currentPage * this.pageSize) < this.dataRaw.length) this.currentPage++
    },
    prevPage: function () {
      if (this.currentPage > 1) this.currentPage--
    },
    select () {
      this.selected = []
      this.currentPage = 1
      if (!this.selectAll) {
        for (const i in this.filterData) {
          this.selected.push(this.filterData[i].id)
        }
      }
    },
    async showList (id) {
      await axios.get(memoUrl + '/' + id, { headers: getHeader() })
        .then(response => {
          this.$emit('showMemo', response.data.data)
        })
        .catch(error => {
          console.log(error.response)
          this.$swal(
            'Error!!!',
            error.response.data.message,
            'error'
          )
        })
    },
    editList (id, type) {
      console.log(memoUrl + '/' + id)
      axios.get(memoUrl + '/' + id, { headers: getHeader() })
        .then(response => {
          var data = {
            data: response.data.data,
            type: type
          }
          var acc = response.data.data.accounts.find(d => d.method === 'to')
          var obj = {
            memo_no: acc.memo_no,
            type: type,
            method: 'to',
            employee_no: acc.employee_no,
            alias_id: acc.alias_id,
            account_no: acc.account_no,
            account_name: acc.account_name,
            account_bank_id: acc.account_bank_id,
            billing_no: acc.billing_no
          }
          response.data.data.accounts.push(obj)
          response.data.data.accounts.push({
            memo_no: acc.memo_no,
            type: type,
            method: 'from',
            employee_no: null,
            alias_id: null,
            account_no: null,
            account_name: null,
            account_bank_id: null,
            billing_no: null
          })
          this.$emit('editMemo', data)
        })
        .catch(error => {
          console.log(error.response)
        })
    },
    addList (type) {
      this.$emit('newMemo', type)
    },
    refreshList () {
      this.$Progress.start()
      this.loader.isLoading = true
      const begin = this.startDate[0] ? this.startDate[0].getFullYear() + '-' + (this.startDate[0].getMonth() + 1) + '-' + this.startDate[0].getDate() : null
      const end = this.startDate[1] ? this.startDate[1].getFullYear() + '-' + (this.startDate[1].getMonth() + 1) + '-' + this.startDate[1].getDate() : null
      var url = memoUrl + '?begin=' + begin + '&end=' + end + '&user=true'
      console.log(url)
      axios.get(url, { headers: getHeader() })
        .then(response => {
          this.dataRaw = response.data.data
          this.$Progress.finish()
          this.loader.isLoading = false
          this.currentPage = 1
          this.searchQuery = ''
          this.$refs.refreshInboxList.refreshList()
          this.$refs.refreshCreateApproveList.refreshList()
          this.$refs.refreshApproveList.refreshList()
        })
        .catch(error => {
          console.log(error.response)
          this.$swal(
            'Error',
            error.response.data.message,
            'error'
          )
        })
    }
  }
}
</script>
